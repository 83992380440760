var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chatbot-wrapper" }, [
    _c(
      "button",
      {
        attrs: { id: "open-chatbot" },
        on: { click: _vm.showChatbotClickHandle }
      },
      [_c("i", { staticClass: "far fa-comment-dots fa-2x" })]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showChatbot,
            expression: "showChatbot"
          }
        ],
        attrs: { id: "chatbot-container" }
      },
      [
        _c("div", { attrs: { id: "chatbot-header" } }, [
          _c(
            "button",
            {
              attrs: { id: "close-chatbot" },
              on: { click: _vm.closeChatbotClickHandle }
            },
            [
              _c("i", { staticClass: "fa fa-times mr-2" }),
              _vm._v(" Fermer\n            ")
            ]
          )
        ]),
        _vm._v(" "),
        _c("iframe", {
          staticStyle: { overflow: "hidden" },
          attrs: {
            src:
              "https://www.embed.swiftask.ai/?clientToken=" + _vm.clientToken,
            id: "chatbot",
            frameBorder: "0",
            title: "Embedded Swiftask"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }