import Vue from "vue";

Vue.mixin({
    methods: {
        planAllow(option, user) {
            if (!user) user = this.user;

            if (this.hasAPremiumPlan(user)) return true;

            let finded = user.plan_options?.options.find(o => o.slug == option);

            if (finded) {
                return true;
            }

            return false;
        },

        planAllowAny(options) {
            options.forEach(function (option) {
                if (this.planAllow(option)) return true
            })

            return false;
        },

        planAllowAll(options) {
            options.forEach(function (option) {
                if (!this.planAllow(option)) return false
            })

            return true;
        },

        hasAPremiumPlan(user) {
            return user.plan_options?.slug === 'premium'
        },

        planName(user) {
            if (!user) user = this.user;

            return user.plan_options?.name
        }
    }
});
