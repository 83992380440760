<template>
    <div>
        <DialogPrompt
            :active.sync="activePrompt"
            :is-valid="password.length > 0 && validatePassword() && confirm === newPassword"
            :title="'Compte'"
            cancel-text="Annuler"
            accept-text="Enregistrer"
            @accept="updatePassword"
        >
        <vs-row class="mb-10" vs-w="12" style="font-size: 1.2em">
            <vs-col v-if="window.Laravel.user.cgu_accepted_at" class="text-success">
                <b>CGU acceptées le :</b> {{ formatDateTime(window.Laravel.user.cgu_accepted_at) }}
            </vs-col>
            <vs-col v-else class="text-danger">
                <strong>CGU en attente d'acceptation.</strong>
            </vs-col>
        </vs-row>

        <vs-row class="mb-10" vs-w="12">
            <vs-col>
                <p>
                    Votre mot de passe doit contenir :
                    <ul style="list-style: inside;">
                        <li>12 caractères minimum</li>
                    </ul>
                    Au moins :
                    <ul style="list-style: inside;">
                        <li>1 majuscule</li>
                        <li>1 minuscule</li>
                        <li>1 chiffre</li>
                        <li>1 caractère spécial</li>
                    </ul>
                </p>
            </vs-col>
        </vs-row>

        <vs-row class="mb-10 mt-10" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="4" vs-xs="12">
                <span>Mot de passe actuel *:</span>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="8" vs-xs="12">
                <input-password
                    vs-lg="4"
                    v-model="password"
                    :success="!!(password.length)"
                    :danger="!!(localErrors.length)"
                    class="no-icon-border"
                    :inputClasses="'w-full'"
                    >
                </input-password>
            </vs-col>
        </vs-row>

        <vs-row class="mb-10" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="4" vs-xs="12">
                <span>Nouveau mot de passe *:</span>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="8" vs-xs="12">
                <input-password
                    v-model="newPassword"
                    :success="!!(validatePassword() && !localErrors.length)"
                    :danger="!!(newPassword !== confirm || localErrors.length)"
                    class="no-icon-border"
                    :inputClasses="'w-full'"
                >
                </input-password>
            </vs-col>
        </vs-row>

        <vs-row class="mb-10" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="4" vs-xs="12">
                <span>Confirmer le nouveau mot de passe *:</span>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="8" vs-xs="12">
                <input-password
                    v-model="confirm"
                    :success="!!(validatePassword() && confirm === newPassword && !localErrors.length)"
                    :danger="!!(newPassword !== confirm || localErrors.length)"
                    class="no-icon-border"
                    :inputClasses="'w-full'"
                >
                </input-password>
            </vs-col>
        </vs-row>
        </DialogPrompt>
        <div class="vx-navbar-wrapper">
            <vs-navbar class="navbar-custom" :color="navbarColor">
                <!-- SM - OPEN SIDEBAR BUTTON -->
                <feather-icon
                class="sm:inline-flex xl:hidden cursor-pointer mr-1 text-primary"
                icon="MenuIcon"
                @click.stop="showSidebar"
                />

                <template v-if="breakpoint != 'md' && user">
                    <ul class="vx-navbar__starred-pages">
                        <li>MON ESPACE SECURISÉ</li>
                    </ul>
                </template>

                <vs-spacer />

                <!-- USER META -->
                <div class="the-navbar__user-meta flex items-center">
                    <div class="text-right leading-tight hidden sm:block">
                        <p class="font-semibold">
                            <b>{{
                                user
                                ? user.first_name
                                    ? user.first_name + ' ' + user.last_name.toUpperCase()
                                    : user.name
                                : ''
                            }}</b>
                        </p>
                        <!-- <small>Available</small> -->
                    </div>
                    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                        <div class="con-img ml-3">
                        <img
                            :src="user.image"
                            alt=""
                            width="40"
                            height="40"
                            class="rounded-full shadow-md cursor-pointer block"
                        />
                        </div>
                        <vs-dropdown-menu class="vx-navbar-dropdown">
                            <ul style="min-width: 9rem">
                                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="changePassword()">
                                    <feather-icon icon="Edit3Icon" svg-classes="w-4 h-4" />
                                    <span class="ml-2">Compte</span>
                                </li>
                                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout()">
                                    <feather-icon icon="LogOutIcon" svg-classes="w-4 h-4" />
                                    <span class="ml-2">Déconnexion</span>
                                </li>
                                <form id="logout-form" :action="logoutUrl" method="POST" style="display: none;">
                                    <input type="hidden" name="_token" :value="window.Laravel.csrfToken">
                                </form>
                            </ul>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
            </vs-navbar>
        </div>
    </div>
</template>

<script>
import InputPassword from '../theme/InputPassword';
export default {
    name: 'Navbar',

    components: {
        'input-password': InputPassword,
    },
    props: {
        navbarColor: {
            type: String,
            default: '#fff'
        },
        logoutUrl: {
            type: String,
            default: '/logout'
        },
    },
    data() {
        return {
            window: window,
            breakpoint: 'lg',
            activePrompt: false,
            password: '',
            newPassword: '',
            confirm: '',
            localErrors: []
        }
    },

    computed: {


    },

    watch: {

    },

    methods: {
        logout: function() {
            document.getElementById('logout-form').submit();
        },

        changePassword: function() {
            this.activePrompt = true;
        },

        validatePassword: function() {
            return (
                (this.newPassword.length >= 12) &&
                (this.newPassword.match(/[A-Z]/g)) &&
                (this.newPassword.match(/[a-z]/g)) &&
                (this.newPassword.match(/[0-9]/g)) &&
                (this.newPassword.match(/[^a-zA-Z\d]/g))
            )
        },

        updatePassword: function() {
            this.localErrors = []

            window.axios
                .patch('/api/change-password', {
                    oldPassword: this.password,
                    newPassword: this.newPassword,
                    newPassword_confirmation: this.confirm
                })
                .then(() => {
                    this.activePrompt = false
                    this.clearPassword();
                    this.notifySuccess('Mot de passe mis à jour')
                })
                .catch(error => {
                    alert(error.message);
                    if (error.response.status == 422) {
                        const errs = error.response.data.errors
                        Object.keys(errs).forEach(field => {
                            this.localErrors.push(errs[field][0])
                        })
                    } else {
                        this.localErrors.push(
                            'Something went wrong, please refresh and try again.'
                        )
                    }
                })
        },

        clearPassword: function() {
            this.password = '';
            this.newPassword = '';
            this.confirm = '';
        },
    },
    created() {

    }
}
</script>
