var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { attrs: { "vs-align": "left", "vs-w": "6" } },
            [
              _vm.resourceReadonly
                ? _c(
                    "DialogPrompt",
                    {
                      attrs: {
                        active: _vm.showPopup,
                        title: "Consulter une compétence",
                        "cancel-text": "Fermer",
                        "button-accept": ""
                      },
                      on: {
                        "update:active": function($event) {
                          _vm.showPopup = $event
                        },
                        cancel: _vm.resetResource,
                        close: _vm.resetResource
                      }
                    },
                    [
                      _c("SkillForm", {
                        attrs: { readonly: _vm.resourceReadonly },
                        on: { error: _vm.setFormError },
                        model: {
                          value: _vm.resource,
                          callback: function($$v) {
                            _vm.resource = $$v
                          },
                          expression: "resource"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "DialogPrompt",
                    {
                      attrs: {
                        active: _vm.showPopup,
                        title: "Modifier une compétence existante",
                        "cancel-text": "Annuler",
                        "accept-text": "Enregistrer",
                        "is-valid": !_vm.formError
                      },
                      on: {
                        "update:active": function($event) {
                          _vm.showPopup = $event
                        },
                        cancel: _vm.resetResource,
                        close: _vm.resetResource,
                        accept: _vm.saveResource
                      }
                    },
                    [
                      _c("SkillForm", {
                        attrs: { readonly: _vm.resourceReadonly },
                        on: { error: _vm.setFormError },
                        model: {
                          value: _vm.resource,
                          callback: function($$v) {
                            _vm.resource = $$v
                          },
                          expression: "resource"
                        }
                      })
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c("vs-col", {
            attrs: { "vs-type": "flex", "vs-align": "left", "vs-w": "6" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            sst: true,
            search: "",
            "no-data-text": _vm.loading
              ? "Chargement de vos données..."
              : "Aucune donnée à afficher",
            data: _vm.itemsData
          },
          on: { search: _vm.eventSearchItems, sort: _vm.eventSortByCol }
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Image")]),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "admin_skill,title" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Compétence")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "admin_skill.category,title" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Catégorie")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-th")
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.items.data, function(item, i) {
            return _c(
              "vs-tr",
              { key: i },
              [
                _c(
                  "vs-td",
                  { attrs: { data: item.photo } },
                  [
                    _vm.getPhotoUrl(item)
                      ? _c("img", {
                          attrs: {
                            src: _vm.getPhotoUrl(item),
                            width: "70",
                            height: "70"
                          }
                        })
                      : _c("vs-avatar", {
                          attrs: {
                            size: "70px",
                            text: _vm.get(item, ":admin_skill.title"),
                            color: _vm.generateColor(
                              _vm.get(item, ":admin_skill.title")
                            )
                          }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "vs-td",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: item.description_falc
                            ? item.description_falc
                            : item.description,
                          placement: "top-start",
                          classes: ["occ-description"]
                        },
                        expression:
                          "{\n                        content: item.description_falc ? item.description_falc : item.description,\n                        placement: 'top-start',\n                        classes: ['occ-description'],\n                    }"
                      }
                    ],
                    attrs: { data: _vm.get(item, ":admin_skill.title") }
                  },
                  [
                    _vm._v(
                      "\n\n                    " +
                        _vm._s(_vm.get(item, ":admin_skill.title")) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("vs-td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.get(item, ":admin_skill.category.title")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("vs-td", { staticClass: "action" }, [
                  _c(
                    "div",
                    { staticClass: "action-wrapper" },
                    [
                      _vm.can("read", "skills", item.id)
                        ? _c("vs-button", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Consulter",
                                expression: "'Consulter'"
                              }
                            ],
                            attrs: {
                              id: "showSkill_" + i,
                              color: "primary",
                              type: "border",
                              "icon-pack": "feather",
                              icon: "icon-list"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showFormRead(item)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.can("update", "skills", item.id)
                        ? _c("vs-button", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Modifier",
                                expression: "'Modifier'"
                              }
                            ],
                            attrs: {
                              id: "modifySkill_" + i,
                              color: "primary",
                              type: "border",
                              "icon-pack": "feather",
                              icon: "icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showFormUpdate(item)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _vm.items.total > 0
            ? _c(
                "vs-col",
                { staticClass: "mt-4" },
                [
                  _c("vs-pagination", {
                    attrs: {
                      total: Math.ceil(_vm.items.total / _vm.apiParams.perpage)
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }