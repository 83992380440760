<template>
    <div class="dashboard">
        <LimitatedPlan v-if="!planAllow('dashbords')" class="mb-10"/>
        <h4>Analyser les compétences individuelles et/ou collectives</h4>
        <p>Ce tableau de bord permet consulter les indicateurs de maîtrise des compétences pour un métier, un poste ou un savoir-faire à l'échelle d’un ou plusieurs établissements / services. Il permet également, pour une compétence donnée, de lister les niveaux de maîtrise des personnes.</p>

        <vx-card class="mb-4">
            <vs-row class="mb-6">
                <vs-col vs-justify="center" vs-align="center" vs-w="3">
                    <label class="typo__label">Métier</label>
                    <Multiselect
                        v-model="filters.occupation"
                        :options="occupations.list"
                        :multiple="false"
                        group-label="group"
                        group-values="items"
                        :group-select="false"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="title"
                        select-label
                        selected-label
                        deselect-label
                        :loading="occupations.loading"
                        :disabled="!planAllow('dashbords')"
                    >
                    <span slot="noResult">Aucun résultat.</span>
                        <span slot="noOptions">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-w="3">
                    <label class="typo__label">Poste</label>
                    <Multiselect
                        v-model="filters.job"
                        :options="jobs.list"
                        :multiple="false"
                        group-label="group"
                        group-values="items"
                        :group-select="false"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="title"
                        select-label
                        selected-label
                        deselect-label
                        :loading="jobs.loading"
                        :disabled="!planAllow('dashbords')"
                    >
                    <span slot="noResult">Aucun résultat.</span>
                        <span slot="noOptions">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-w="3">
                    <label class="typo__label">Savoir-faire</label>
                    <Multiselect
                        v-model="filters.occupationskill"
                        :options="shownOccupationSkills"
                        :multiple="false"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="titleEstablishment"
                        select-label
                        selected-label
                        deselect-label
                        :loading="occupationskills.loading"
                        :disabled="!planAllow('dashbords')"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                        <span slot="noOptions">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-w="3">
                    <label class="typo__label">Compétence</label>
                    <Multiselect
                        v-model="filters.skills"
                        :options="shownSkills"
                        :multiple="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="title"
                        select-label
                        selected-label
                        deselect-label
                        :loading="skills.loading"
                        :disabled="!planAllow('dashbords')"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
            </vs-row>
            <vs-row class="checkboxes-container" vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-w="8">
                    <vs-checkbox
                        v-model="filters.mine"
                        :disabled="disabledFilterByUserRelated || !planAllow('dashbords')"
                    >
                        Mes savoir-faire uniquement
                    </vs-checkbox>
                </vs-col>
            </vs-row>
            <vs-row v-if="can('read', 'skill_reviews') || can('read', 'ratings')" class="mt-5">
                <vs-col vs-justify="center" vs-align="center">
                    <vs-row>
                        <vs-col class="vx-row" vs-align="flex-start">
                            Résultats basés sur :
                            <vs-radio
                                v-model="typeCompute"
                                vs-name="radios2"
                                id="radios2-ratings"
                                class="mr-5 ml-2"
                                :vs-value="typeComputeValues.RATINGS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings')) || !planAllow('dashbords')"
                            >
                                {{ projectNames.plural }}
                            </vs-radio>
                            <vs-radio
                                v-model="typeCompute"
                                vs-name="radios2"
                                id="radios2-reviews"
                                class="mr-5"
                                :vs-value="typeComputeValues.REVIEWS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings')) || !planAllow('dashbords')"
                            >
                                {{ skillRatingNames.plural }}
                            </vs-radio>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>

        <vx-card class="mb-4 mt-3">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="4">
                    <label class="typo__label">Sélectionner au moins un établissement</label>
                    <Multiselect
                        v-model="filters.establishments"
                        :options="establishments.list"
                        :multiple="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="name"
                        select-label
                        selected-label
                        deselect-label
                        :loading="establishments.loading"
                        :disabled="!planAllow('dashbords')"
                    >
                    <span slot="noResult">Aucun résultat.</span>
                        <span slot="noOptions">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-w="3">
                    <label class="typo__label"
                        >ou un service</label
                    >
                    <Multiselect
                        v-model="filters.services"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="title"
                        select-label
                        select-group-label=" L'établissement"
                        selected-label
                        deselect-label
                        deselect-group-label
                        :disabled="!planAllow('dashbords')"
                    >
                    <span slot="noResult">Aucun résultat.</span>
                        <span slot="noOptions">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>

            </vs-row>

        </vx-card>

        <!-- Action -->
        <vs-row class="mt-4 mb-2">
            <vs-col vs-type="flex"  vs-justify="flex-end">
                <vs-button
                    v-if="planAllow('dashbords')"
                    id="search-button"
                    color="primary"
                    :disabled="!canSearch"
                    @click="searchClick"
                >
                    Lancer la recherche
                </vs-button>

                <div v-else style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                    <vs-button
                        id="search-button"
                        color="primary"
                        disabled="true"
                    >
                        Lancer la recherche
                    </vs-button>
                    <vs-icon class="button-bullet" bg="#FF9B3A" round >
                        <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                    </vs-icon>
                </div>
            </vs-col>
        </vs-row>

        <!-- Tableau affichage par compétence(s) selectionnée(s) -->
        <vx-card v-if="canDisplayTableEmployeeBySkills" class="mb-4">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">

                    <vs-table
                        v-if="ratingParams"
                        :data="employeesFilteredByServicesAndEstablishments"
                        :no-data-text="$constants.TABLE_NODATA_TEXT"
                    >
                        <template slot="thead">
                            <vs-th>Nom</vs-th>
                            <vs-th>Prénom</vs-th>
                            <vs-th v-for="param in ratingParams" :key="`param-${param.id}`">
                                {{ param.label }}
                            </vs-th>
                        </template>
                        <template slot-scope="{ data }">
                            <vs-tr v-for="(item, i) in data" :key="i">
                                <vs-td :data="item.first_name">
                                    {{ item.first_name }}
                                </vs-td>
                                <vs-td :data="item.last_name">
                                    {{ item.last_name }}
                                </vs-td>
                                <vs-td
                                    v-for="param in ratingParams"
                                    :key="`param-${param.id}`"
                                    :data="item"
                                >
                                    <vs-radio
                                        :key="`param-${item.id}-${param.id}`"
                                        :disabled="true"
                                        :value="employeeGradePerSkill(item, filters.skills[0])"
                                        :vs-name="`param-${item.id}-${param.id}`"
                                        color="primary"
                                        :vs-value="parseFloat(param.id)"
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>
        </vx-card>

        <!-- Choix de l'indicateur -->
        <vx-card class="mb-4" v-if="canDisplayIndicator">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="6">
                    <label class="typo__label">Sélectionner l'indicateur à afficher</label>
                    <multiselect
                        v-model="currentIndicator"
                        :options="indicators"
                        :multiple="false"
                        placeholder="Recherche ..."
                        track-by="title"
                        label="title"
                        select-label
                        selected-label
                        deselect-label
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </vs-col>
            </vs-row>

            <!-- Tableau principal -->
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="12">
                    <vs-table v-if="ratingParams" :data="currentSkills" :no-data-text="$constants.TABLE_NODATA_TEXT">
                        <template slot="thead">
                            <vs-th style="width:15%;">
                                <Sort name="title" @sort="eventSortByCol">Compétences</Sort>
                            </vs-th>
                            <vs-th v-if="canDisplayRequiredLevel" style="width:15%;">
                                Niveau appelé
                            </vs-th>
                            <vs-th style="width:20%">
                                <Sort name="categoryTitle" @sort="eventSortByCol">Catégorie</Sort>
                            </vs-th>
                            <th
                                v-for="(item, index) in tableGroupedServices"
                                :key="index"
                                :style="(item.id == undefined ? 'color:#972F5F;' : 'color: #636b6f;') + 'width: 6%; text-align: center'"
                            >
                                {{ item.title }}
                            </th>
                        </template>
                        <template slot-scope="{ data }">
                            <vs-tr v-for="(skill, i) in data" :key="`skill-${i}`">
                                <vs-td
                                    v-tooltip="{
                                        content: skill.description,
                                        placement: 'top-start',
                                        classes: ['occ-description']
                                    }"
                                    :data="skill.title"
                                >
                                    {{ skill.title }}
                                </vs-td>
                                <vs-td v-if="canDisplayRequiredLevel">
                                    {{
                                        requiredEntitySkillLevel(filters.occupationskill || filters.job || filters.occupation, skill,
                                            (type = filters.occupationskill ? "occupation_skill" : filters.job ? "job" : filters.occupation ? "occupation" : "occupation_skill")
                                        )
                                    }}
                                </vs-td>
                                <vs-td :data="categoryTitle(skill)">
                                    {{ categoryTitle(skill) }}
                                </vs-td>

                                <vs-td
                                    v-for="(item, j) in tableGroupedServices"
                                    :key="j"
                                    :style="item.id == undefined ? 'color:#972F5F' : 'color: #636b6f'"
                                >
                                    <div
                                        v-if="item.id == undefined"
                                        style="font-weight:bold; text-align: center;"
                                    >
                                        {{ acquisitionPerSkillPerEstablishment(item.services, skill) }}
                                    </div>
                                    <div v-else style="text-align: center;">
                                        {{ acquisitionPerSkillPerService(item, skill) }}
                                    </div>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>
        </vx-card>

    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ratingChartsHelper from "@/mixins/ratingChartsHelper";
import ratingService from "@components/services/ratings";
import CrownIcon from '@components/svg/CrownIcon';
import LimitatedPlan from '@components/divers/LimitatedPlan';

export default {
    name: "SkillDashboard",
    components: {
        Multiselect, CrownIcon, LimitatedPlan
    },
    props: {
        skills: {
            type: Array,
            require: true
        },
        adminSkills: {
            type: Array,
            require: true
        },
        skill_occupationSkill: {
            type: Array,
            require: true
        },
        adminSkill_adminOccupationSkill: {
            type: Array,
            require: true
        }
    },
    data() {
        return {
            log: console.log,
            jobs: {
                data: [],
                loading: false,
                list: []
            },
            occupations: {
                data: [],
                loading: false,
                list: []
            },
            occupationskills: {
                data: [],
                loading: false,
            },
            establishments: {
                data: [],
                loading: false,
                list: []
            },
            employees: {
                data: [],
                loading: false
            },
            filters: {
                occupation : null,
                job: null,
                occupationskill: null,
                skills: [],
                mine: true,
                establishments: [],
                services: []
            },
            ratings: {
                data: [],
                loading: true,
            },
            disabledFilterByUserRelated: false,
            currentIndicator: null,
            searchLoading: false,
            resultCompleted: false,
            typeComputeValues: {
                RATINGS: 1,
                REVIEWS: 2,
            },
            typeCompute: 1,
        }
    },

    created() {
        this.localLoading();
        let start = Date.now();

        if (this.can('read', 'skill_reviews') && !this.can('read', 'ratings')) {
            this.typeCompute = this.typeComputeValues.REVIEWS
        }

        if (this.planAllow('dashbords')) {
            Promise.all([
                this.loadEmployees(),
                this.loadJobs(),
                this.loadOccupations(),
                this.loadOccupationskills(),
                this.loadEstablishments()
            ]).then(() => {
                console.info('=> Chargement des listes : +' + (Date.now() - start) + 'ms');
                this.endLocalLoading();
            });

            this.currentIndicator = this.indicators.find(i => i.id == 0);
        }
    },

    computed: {

        canDisplayRequiredLevel() {
            return this.filters.job || this.filters.occupation || this.filters.occupationskill;
        },

        filteredEmployeeIds() {
            return this.employeesFilteredByServicesAndEstablishments.map(e => e.id);
        },

        employeesFilteredByServicesAndEstablishments() {
            let filteredEmployees = [];
            const establishments = this.filters.establishments.length > 0  ? this.filters.establishments : this.establishments.list;
            establishments.forEach(e => {
                let employees = [];
                const establishmentEmployees = this.employees.data.filter(
                    employee =>
                        employee.services.length &&
                        employee.services?.map(s => s.establishment_id).includes(e.id)
                )

                if (this.filters.services.length > 0) {
                    const services = this.filters.services.map(s => s.id);
                    employees = establishmentEmployees.filter(employee =>
                        services.filter(s => employee.services.map(es => es.id).includes(s)).length
                    );
                } else {
                    employees = establishmentEmployees;
                }
                filteredEmployees.push(...employees);
            });
            return this.$_.uniqBy(filteredEmployees, "id");
        },

        ratingParams() {
            if (this.ratingSettings && this.ratingSettings.params) {
                return Object.keys(this.ratingSettings.params).map(id => {
                    let param = this.ratingSettings.params[id];
                    param.id = id;
                    return param;
                });
            }

            return [];
        },

        //Les indicateurs en dur peuvent reprendre d'autres indicateur qui sont générés. Faire le tri et supprimer les doublons
        indicators() {
            let indicators = [
                {
                    id: 0,
                    title: "Taux d’acquisition par compétence",
                    evalFn: level =>
                        this.noteById(level) >
                        this.ratingSettings.NonAcquiredLevel,
                    number: false
                },
                //Est répété deux fois
                {
                    id: 2,
                    title: "Taux de Non mesuré par compétence",
                    evalFn: level =>
                        this.noteById(level) ===
                        this.ratingSettings.NonMesuredLevel,
                    number: false
                },
                {
                    id: 1,
                    title: "Nb de personnes maitrisant la compétence",
                    evalFn: level =>
                        this.noteById(level) >
                        this.ratingSettings.NonAcquiredLevel,
                    number: true
                },
                {
                    id: 7,
                    title: "Nb de personnes Non mesuré sur la compétence",
                    evalFn: level =>
                        this.noteById(level) ===
                        this.ratingSettings.NonMesuredLevel,
                    number: true
                },
                {
                    id: 11,
                    title:
                        "Nb de personnes maitrisant la compétence en autonomie",
                    evalFn: level =>
                        this.noteById(level) ===
                        this.ratingSettings.AutonomyLevel.coef,
                    number: true
                },
                {
                    id: 10,
                    title:
                        "Taux de personnes maitrisant la compétence en autonomie",
                    evalFn: level =>
                        this.noteById(level) ===
                        this.ratingSettings.AutonomyLevel.coef,
                    number: false
                }
            ];
            this.ratingParams.forEach(param => {
                if (param.id == "1" && param.label.lower == "non mesuré") {
                    // continue
                } else {
                    indicators.push({
                        id: 3 + parseFloat(param.id) + 1,
                        title: `Taux de ${param.label} par compétence`,
                        evalFn: level => this.noteById(level) === param.note,
                        number: false
                    });
                    indicators.push({
                        id: 8 + parseFloat(param.id) + 1,
                        title: `Nb de ${param.label} par compétence`,
                        evalFn: level => this.noteById(level) === param.note,
                        number: true
                    });
                }
            });
            return indicators.sort(function(a, b) {
                return a.title.localeCompare(b.title, "fr", {
                    sensitivity: "base"
                });
            });
        },

        /**
         * Filtre les savoir-faire en fonction du filtre métier et poste
         */
        shownOccupationSkills() {
            let occupationskills = [];

            if (this.filters.job) {
                // Filtrage par poste
                let jobOccupationSkills = this.filters.job.occupation_skills
                    ? this.filters.job.occupation_skills
                    : this.joboccupationskills(this.filters.job.id);
                occupationskills = jobOccupationSkills
                    .slice()
                    .sort((a, b) => +a.pivot.order - +b.pivot.order);
            } else if (this.filters.occupation) {
                // Filtrage par métier
                if (this.filters.occupation.admin_id) {
                    // Métier centralisé
                    occupationskills = this.filters.occupation.admin_occupation_skills.length > 0
                        ? this.filters.occupation.admin_occupation_skills.slice().sort((a, b) => +a.pivot.order - +b.pivot.order)
                        : [];
                } else {
                    // Métier tenant
                    occupationskills = this.filters.occupation.occupation_skills
                        ? this.filters.occupation.occupation_skills.slice().sort((a, b) => +a.pivot.order - +b.pivot.order)
                        : [];
                }
            } else {
                //Only Client OccupationSkills
                occupationskills = this.occupationskills.data.slice()
                    .sort((a, b) => {
                        return a.title.localeCompare(b.title, "fr", {
                            sensitivity: "base"
                        });
                    });
            }

            // Mes savoir-faire uniquement
            if (this.filters.mine) {
                occupationskills = occupationskills.filter(os => {
                    return (
                        !os.establishment_id ||
                        this.userEstablishmentsIds.includes(os.establishment_id)
                    );
                });
            }

            return occupationskills.map(os => ({
                ...os,
                titleEstablishment:
                    os.title +
                    (os.establishment ? " - " + os.establishment.name : "")
            }));
        },

        /**
         * Filtre les compétences en fonction du filtre savoir-faire, métier et poste
         */
        shownSkills() {
            let skills = [];

            if (this.filters.occupationskill) {
                if (this.filters.occupationskill.resource == 'OccupationSkill') {
                    skills = this.getSkillsByOccupationSkillId(this.filters.occupationskill.id)
                } else {
                    skills = this.getSkillsByAdminOccupationSkillId(this.filters.occupationskill.id)
                }

            } else if (this.filters.job || this.filters.occupation) {

                // Récuperation des SF filtrés
                this.shownOccupationSkills.forEach(os => {

                    // Récupération des compétences associées aux SF
                    let osSkills = [];
                    if (os.resource == 'OccupationSkill') {
                        osSkills = this.getSkillsByOccupationSkillId(os.id);
                    } else {
                        osSkills = this.getSkillsByAdminOccupationSkillId(os.id);
                    }

                    // Ajout des compétences dans la liste
                    osSkills.forEach(skill => {
                        if (!skills.map(s => s.id).includes(skill.id)) {
                            skills.push(skill);
                        }
                    })
                });
            } else {
                skills = this.skills;
            }

            // Compatibilité pour le Multiselect
            skills = skills.map(s => {
                if (!s.title) {
                    s.title = s.admin_skill.title
                }
                return s;
            })

            // Tri
            skills.sort((a, b) => {
                return a.title.localeCompare(b.title, "fr", {
                    sensitivity: "base"
                });
            });

            return skills;
        },

        currentSkills() {
            return this.filters.skills.length > 0 ? this.filters.skills : this.shownSkills.slice();
        },

        /**
         * Liste des services groupé par établissement
         */
        groupedServicesByEstablishments() {
            let toShow = [];
            let establishments =
                this.filters.establishments &&
                this.filters.establishments.length > 0
                    ? this.filters.establishments
                    : this.establishments.list;

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.services
                        ? this.services
                              .filter(
                                  service =>
                                      service.establishment_id === e.id &&
                                      this.listOfServicesViewableFor(
                                          "employees"
                                      ).includes(service.id)
                              )
                              .sort(this.sortServices)
                        : []
                });
            });
            return toShow;
        },



        canDisplayMoreFilters() {
            return this.filters.services.length > 0 || this.filters.establishments.length > 0
        },

        canSearch() {
            return (this.filters.occupation || this.filters.job || this.filters.occupationskill || this.filters.skills.length > 0)
                        && (this.filters.establishments.length > 0 || this.filters.services.length > 0);
        },

        canDisplayTableEmployeeBySkills() {
            return this.filters.skills.length > 0 && this.filters.skills.length < 2 && this.resultCompleted == true
        },

        canDisplayIndicator() {
            return this.resultCompleted == true
        },

        tableGroupedServices() {
            let data = [];
            let services = [];
            const servicesByEstablishments = this.filters.services.length > 0
                ? this.groupedServicesSelectedByEstablishments
                : this.groupedServicesByEstablishments;

            servicesByEstablishments.forEach(group => {
                services = group.services;
                data.push({
                    title: group.establishment,
                    services: services
                });
                services.forEach(service => {
                    data.push(service);
                });
            });

            return data;
        },

        groupedServicesByEstablishments() {
            let toShow = [];
            let establishments = this.filters.establishments.length > 0 ? this.filters.establishments : this.establishments.list;

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.services
                        ? this.services
                              .filter(
                                  service =>
                                      service.establishment_id === e.id &&
                                      this.listOfServicesViewableFor(
                                          "employees"
                                      ).includes(service.id)
                              )
                              .sort(this.sortServices)
                        : []
                });
            });
            return toShow;
        },
        groupedServicesSelectedByEstablishments() {
            let toShow = [];
            let tabEstablishmentsServices = [];
            this.filters.services.forEach(s => {
                if (tabEstablishmentsServices.length > 0) {
                    var found = 0;
                    tabEstablishmentsServices.forEach(e => {
                        if (e.establishment.id === s.establishment.id) {
                            found = 1;
                            e.services.push(s);
                        }
                    });
                    if (found === 0) {
                        tabEstablishmentsServices.push({
                            establishment: s.establishment,
                            services: [s]
                        });
                    }
                } else {
                    tabEstablishmentsServices.push({
                        establishment: s.establishment,
                        services: [s]
                    });
                }
            });
            tabEstablishmentsServices.forEach(group => {
                toShow.push({
                    establishment: group.establishment.name,
                    services: group.services
                        ? group.services.sort(this.sortServices)
                        : []
                });
            });
            return toShow;
        },

        userEstablishments() {
            if (this.user && this.user.establishments?.length) {
                return this.user.establishments;
            } else if (this.isASuperAdmin(this.user) || (this.isAnAdmin(this.user) && this.user.establishments?.length == 0)) {
                return this.establishments.data;
            }
            return [];
        },

        userEstablishmentsIds() {
            return this.userEstablishments.flatMap(e => e.id);
        }
    },

    watch: {
        searchLoading(value) {
            if (value) {
                this.$vs.loading({
                    background: this.backgroundLoading,
                    color: this.colorLoading,
                    container: document.getElementById('search-button'),
                    scale: 0.45
                })
            } else {
                setTimeout(() => {
                    this.$vs.loading.close(document.getElementById('search-button'))
                }, 100)
            }
        },

        'filters.establishments': {
            handler(value) {
                this.resultCompleted = false;

                if (value) {
                    this.filters.services = [];
                }
            }
        },

        'filters.services': {
            handler() {
                this.resultCompleted = false;
            }
        },

        'filters.occupation': {
            handler(value) {
                this.filters.occupationskill = null;
                this.filters.skills = [];

                if (value) {
                    this.filters.job = null;
                    this.filters.mine = false;
                    this.disabledFilterByUserRelated = true;
                } else if (!this.filters.job && !this.filters.occupationskill) {
                    this.filters.mine = true;
                    this.disabledFilterByUserRelated = false;
                }
            }
        },

        'filters.job': {
            handler(value) {
                this.filters.occupationskill = null;
                this.filters.skills = [];
                if (value) {
                    this.filters.occupation = null;
                    this.filters.occupationskill = null;
                    this.filters.mine = false;
                    this.disabledFilterByUserRelated = true;
                } else if (!this.filters.occupation && !this.filters.occupationskill) {
                    this.filters.mine = true;
                    this.disabledFilterByUserRelated = false;
                }
            }
        },
    },

    methods: {

        /**
         * Récupère des compétences depuis l'ID d'un savoir-faire (grâce à la table relationnelle)
         * @param {Number} occupationSkillId
         */
        getSkillsByOccupationSkillId(occupationSkillId) {
            const skillIds = this.skill_occupationSkill.filter(sos => sos.occupation_skill_id == occupationSkillId).map(sos => sos.skill_id);

            return this.skills.filter(s => skillIds.includes(s.id));
        },

        /**
         * Récupère des compétences depuis l'ID d'un savoir-faire admin (grâce à la table relationnelle)
         * @param {Number} adminOccupationSkillId
         */
        getSkillsByAdminOccupationSkillId(adminOccupationSkillId) {
            const adminSkillIds = this.adminSkill_adminOccupationSkill.filter(sos => sos.admin_occupation_skill_id == adminOccupationSkillId).map(sos => sos.admin_skill_id);

            return this.adminSkills.filter(s => adminSkillIds.includes(s.id));
        },

        // Personnes
        async loadEmployees() {
            this.employees.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: { perpage: 9999, include: ['services.establishment'] }
                })
                .then(response => {
                    this.employees.data = response.data.data;
                    this.employees.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        employeesByService(id) {
            return this.employees.data.filter(e => e.services.map(s => s.id).includes(id))
        },

        employeesByServices(ids) {
            return this.employees.data.filter(e => !!ids.filter(s => e.services.map(es => es.id).includes(s)).length)
        },

        // Postes
        async loadJobs() {
            this.jobs.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/jobs",
                    params: { perpage: 9999, include: ['occupation_skills', 'service.establishment'] }
                })
                .then(response => {
                    let jobs = response.data.data
                    jobs.forEach(j => {
                        j.occupation_skills.forEach(jos => {
                            this.reconstructSkillOccupationSkillRelation(jos, this.skill_occupationSkill)
                        })
                    })

                    this.jobs.data = jobs;

                    const grouped = this.groupForMultiselect(jobs, ['service.establishment.name', 'service.title'], null, ' / ');
                    this.jobs.list = window._.sortBy(grouped, 'group');

                    this.jobs.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        // Métiers
        async loadOccupations() {
            this.occupations.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: {
                        perpage: 9999,
                        include: [
                            "sector",
                            "admin_occupation_skills",
                            "occupation_skills"
                        ]
                    }
                })
                .then(response => {
                    let occupations = response.data.data
                    occupations.forEach(o => {
                        o.occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(oos, this.skill_occupationSkill, false)
                        })
                        o.admin_occupation_skills.forEach(oos => {
                            this.reconstructSkillOccupationSkillRelation(oos, this.adminSkill_adminOccupationSkill, true)
                        })
                    })
                    this.occupations.data = occupations;
                    this.occupations.list = this.groupForMultiselect(occupations, ['type'])
                    this.occupations.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        // Savoir-faire
        async loadOccupationskills() {
            this.occupationskills.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills",
                    params: {
                        perpage: 9999,
                        include: ["sector", "jobs", "establishment"],
                    }
                })
                .then(response => {
                    let occupationskills = response.data.data
                    occupationskills.forEach(os => {
                        this.reconstructSkillOccupationSkillRelation(os, this.skill_occupationSkill, false)
                    })
                    this.occupationskills.data = occupationskills;
                    this.occupationskills.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        occupationskillById(id) {
            return this.occupationskills.find(os => os.id == id);
        },
        // Compétences
        async loadSkills() {
            this.skills.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/skills",
                    params: {
                        perpage: 9999,
                        include: ['admin_skill.category', 'level'],
                    }
                })
                .then(response => {
                    this.skills.data = response.data.data;
                    this.skills.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        // Etablissements
        async loadEstablishments() {
            this.establishments.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ['services']
                    }
                })
                .then(response => {
                    this.establishments.data = response.data.data;
                    this.establishments.loading = false;
                    this.establishments.list = this.filterEstablishmentsByPerimeter(response.data.data)
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        filterEstablishmentsByPerimeter(establishments) {

            if (this.isASuperAdmin(this.user)) {
                return establishments;
            } else {
                const userServices = this.services.slice().filter(service =>
                    this.listOfServicesViewableFor("employees")
                        .map(id => id)
                        .includes(service.id)
                );
                return establishments
                    .slice()
                    .filter(e =>
                        userServices
                            .map(service => service.establishment_id)
                            .includes(e.id)
                    );
            }
        },

        searchClick() {
            this.resultCompleted = false;
            this.searchLoading = true;
            this.setCurrentEmployeesRatings();
        },

        requiredEntitySkillLevel(entity, skill, type = "occupation_skill") {
            let requiredLevel = null;

            const adminSkillId = skill.resource == 'AdminSkill' ? skill.id : skill.admin_skill_id

            switch (type) {
                case "occupation_skill": {
                    //entity = occupation_skill
                    const skillLevel = entity.adminskills
                        ? entity.adminskills.find(
                              s => s.id == adminSkillId
                          )
                        : entity.skills.find(
                              s => s.admin_skill_id == adminSkillId
                          );
                    if (skillLevel && skillLevel.pivot) {
                        if (
                            requiredLevel == null ||
                            (this.levelById(skillLevel.pivot.required_level)
                                ? this.levelById(
                                      skillLevel.pivot.required_level
                                  ).note
                                : this.NonMesuredLevel) >
                                (this.levelById(requiredLevel)
                                    ? this.levelById(requiredLevel).note
                                    : this.NonMesuredLevel)
                        ) {
                            requiredLevel = skillLevel.pivot.required_level;
                        }
                    }
                    break;
                }
                case "job": {
                    //entity = job
                    entity.occupation_skills.forEach(occupation_skill => {
                        const skillLevel = occupation_skill.skills.find(
                            s => s.admin_skill_id == adminSkillId
                        );
                        if (skillLevel && skillLevel.pivot) {
                            if (
                                requiredLevel == null ||
                                (this.levelById(skillLevel.pivot.required_level)
                                    ? this.levelById(
                                          skillLevel.pivot.required_level
                                      ).note
                                    : this.NonMesuredLevel) >
                                    (this.levelById(requiredLevel)
                                        ? this.levelById(requiredLevel).note
                                        : this.NonMesuredLevel)
                            ) {
                                requiredLevel = skillLevel.pivot.required_level;
                            }
                        }
                    });
                    break;
                }
                case "occupation": {
                    //entity = occupation
                    entity.admin_occupation_skills.forEach(occupation_skill => {

                        const skillLevel = occupation_skill.adminskills.find(
                            s => s.id == adminSkillId
                        );

                        if (skillLevel && skillLevel.pivot) {
                            if (
                                requiredLevel == null ||
                                (this.levelById(skillLevel.pivot.required_level)
                                    ? this.levelById(
                                          skillLevel.pivot.required_level
                                      ).note
                                    : this.NonMesuredLevel) >
                                    (this.levelById(requiredLevel)
                                        ? this.levelById(requiredLevel).note
                                        : this.NonMesuredLevel)
                            ) {
                                requiredLevel = skillLevel.pivot.required_level;
                            }
                        }
                    });
                    break;
                }
            }

            return this.levelById(requiredLevel) ? this.levelById(requiredLevel).label : "";
        },

        acquisitionPerSkillPerEstablishment(services, skill) {
            const evalFn = this.currentIndicator
                ? this.currentIndicator.evalFn
                : this.indicators.find(i => i.id === 0).evalFn;

            const servicesEmployees = this.employeesByServices(
                services.map(s => s.id)
            );

            if (servicesEmployees.length == 0) {
                return this.currentIndicator && this.currentIndicator.number
                    ? 0
                    : `0%`;
            }

            let counter = 0;
            servicesEmployees.forEach(e => {
                const level = parseFloat(this.employeeGradePerSkill(e, skill));
                counter += evalFn(level) ? 1 : 0;
            });

            return this.currentIndicator && this.currentIndicator.number
                ? counter
                : `${this.round((counter / servicesEmployees.length) * 100, 0)}%`;
        },

        acquisitionPerSkillPerService(service, skill) {
            const evalFn = this.currentIndicator
                ? this.currentIndicator.evalFn
                : this.indicators.find(i => i.id === 0).evalFn;
            const serviceEmployees = this.employeesByService(service.id);

            if (serviceEmployees.length === 0) {
                return this.currentIndicator && this.currentIndicator.number ? 0 : `0%`;
            }


            let counter = 0;
            serviceEmployees.forEach(e => {
                const level = parseFloat(this.employeeGradePerSkill(e, skill));
                counter += evalFn(level) ? 1 : 0;
            });
            return this.currentIndicator && this.currentIndicator.number
                ? counter
                : `${this.round((counter / serviceEmployees.length) * 100, 0)}%`;
        },

        employeeGradePerSkill(employee, skill) {
            const rating = this.employeeRating(employee);
            let level = this.findParameter(this.ratingSettings.NonMesuredLevel);

            if (!Object.values(rating).length) return level;

            const adminSkillId = skill.resource == 'AdminSkill' ? skill.id : skill.admin_skill_id

            const skillLevel = rating.skills.find(
                s => s.admin_skill_id == adminSkillId
            );

            if (skillLevel) {
                level = skillLevel.pivot.level;
            }
            return level;
        },

        employeeRating(employee) {
            if (!Object.values(this.ratings.data).length) return [];
            if (!this.ratings.data[employee.id]) return [];
            return this.ratings.data[employee.id];
        },

        findParameter(NonMesuredLevel) {
            if (this.ratingSettings.params) {
                switch (NonMesuredLevel) {
                    case this.ratingSettings.params[1].note:
                        return 1;
                    case this.ratingSettings.params[2].note:
                        return 2;
                    case this.ratingSettings.params[3].note:
                        return 3;
                    case this.ratingSettings.params[4].note:
                        return 4;
                    default:
                        return null;
                }
            }

            return null;
        },

        setCurrentEmployeesRatings() {
            let start = Date.now();

            this.ratings.loading = true;
            if (this.filteredEmployeeIds.length == 0) {
                this.ratings.data = []
                this.ratings.loading = false;
                this.searchLoading = false;
                this.resultCompleted = true;
                return;
            }

            this.loadLastEmployeesRating(this.filteredEmployeeIds).then(ratings => {
                const nbRatings = Object.values(ratings).length;
                this.ratings.data = ratings
                this.ratings.loading = false;
                this.searchLoading = false;
                this.resultCompleted = true;

                console.info('=> Chargement de '+nbRatings+' projets personnalisés : +' + (Date.now() - start) + 'ms');
            })
        },

        loadLastEmployeesRating(employeeIds) {
            return window.axios({
                    method: "get",
                    url: `/api/gestion/employees/-1/ratings/employees-last`,
                    params: {
                        include: ['occupationSkills', 'skills'],
                        employee_ids: employeeIds,
                        skill_reviews: this.typeCompute === this.typeComputeValues.REVIEWS,
                    }
                })
                .then(response => {
                    let ratings = response.data

                    // for (var i in ratings) {
                    //     this.reconstructRating(ratings[i]);
                    // }

                    return ratings;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        categoryTitle(skill) {
            let categoryTitle = "_";

            if (skill.category || skill.admin_skill?.category) {
                categoryTitle = skill.category?.title ? skill.category?.title : skill.admin_skill?.category.title;
            }

            return categoryTitle;
        },

        round(value, decimals) {
            return Number(value.toFixed(decimals));
        },

        sortJobs(a, b) {
            if (a.service.title > b.service.title) return 1;
            if (a.service.title < b.service.title) return -1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
        },

        sortServices(a, b) {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

.dashboard::v-deep .multiselect {
    font-size: 14px;
    color: grey;
    .multiselect__single {
        font-size: 14px;
        color: grey;
    }
    .multiselect__placeholder {
        color: grey;
        font-size: 14px;
        margin-left: 10px;
    }

    .multiselect__content-wrapper {
        border: none;
    }

    .multiselect__input {
        background: $primary-light;
        font-size: 14px;
    }

    .multiselect__tag {
        background: $primary;
        font-size: 14px;
        &-icon:hover {
            background: rgb(151, 47, 95);
        }

        &-icon:after {
            color: $white;
        }
    }

    .multiselect__single {
        background: $primary-light;
        margin-top: 4px;
    }
    .multiselect__select:before {
        top: 45%;
    }
    .multiselect__tags {
        padding-top: 4px;
        min-height: 33px;
        max-height: 500px;
        background: $primary-light;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }

    .multiselect__content {
        background: $primary-light;
    }
    .multiselect__tag-icon::after {
        background: rgb(151, 47, 95);
    }
    .multiselect__option--highlight {
        background: $primary;
        outline: none;
        color: #fff;
        &::after {
            display: none;
            &:hover {
                background: rgb(151, 47, 95);
            }
        }
    }
}
.checkboxes-container {
    padding: 10px 1em 0;
    border-top: 1px solid $grid-gray;
    z-index: 0;
}
</style>
