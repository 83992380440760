<template>
    <div>
        <JobsOccupationsResume
            :jobs="value.jobs"
            :occupations="value.occupations"
            :displayWishes="false"
        />

        <vs-row v-if="resource.skills.length" class="checkboxes-container mt-10">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <label>Toutes les compétences</label>
                <vs-switch v-model="resource.allSkills" name="showAllSkills" class="ml-2" />

                <vs-button 
                    color="primary"
                    type="border"
                    class="ml-5"
                    icon-pack="feather"
                    icon="icon-refresh-cw"
                    @click="refreshClickHandle"
                >
                    Regénérer le pré-remplissage
                </vs-button>

                <template v-if="typeForm !== 'creation' && typeForm !== 'poursuite'">
                    <label class="ml-20">Affichage :</label>
                    <vs-radio
                        v-model="displayMode"
                        vs-name="radios-occSkill-display"
                        id="radio-occSkill-display-table"
                        class="ml-2"
                        vs-value="table"
                    >
                        Tableau
                    </vs-radio>
                    <vs-radio
                        v-model="displayMode"
                        vs-name="radios-occSkill-display"
                        id="radio-occSkill-display-graphic"
                        class="ml-2"
                        vs-value="graphic"
                    >
                        Graphique
                    </vs-radio>
                </template>
            </vs-col>
        </vs-row>
        <div v-else-if="!loading" class="not-data-table vs-table--not-data">Il n'y a aucune compétence à évaluer</div>

        <Rating-Manager
            v-if="displayMode === 'table' && (resource.skills.length || loading)"
            name="skills"
            :type-form="typeForm"
            v-model="resource.skills"
            :show-hidden="resource.allSkills"
            :col1="{label: 'Compétence', value: 'title', title: 'description'}"
            :col2="{label: 'Catégorie', value: 'category_name'}"
            :loading="loading"
            :skill-review="skillReview"
        />

        <div v-else-if="displayMode === 'graphic'" class="chart main">
            <Rating-Chart
                :employee="value.employee"
                class="main-chart"
                :options="chart.options"
                :chart-data="chart.data"
            />
        </div>

    </div>
</template>

<script>
import JobsOccupationsResume from "../JobsOccupationsResume";
import RatingManager from '../RatingManager';
import RatingChart from "@components/app/RatingChart";
import ratingService from "@components/services/ratings";
import ratingChartsHelper from "@/mixins/ratingChartsHelper";

export default {
    components: {
        JobsOccupationsResume, RatingManager, RatingChart
    },
    mixins: [ratingChartsHelper],
    props: {
        typeForm: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        adminSkills: {
            type: Object,
            required: true
        },
        colors: {
            type: Array,
            required: true
        },
        skillReview: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            resource: {
                skills: [],
                allSkills: true
            },
            loading: true,
            displayMode: 'table'
        };
    },
    created() {
        this.loading = true;
        this.initResource();
        this.loadDefaultRatings();

        if (this.typeForm === 'consultation' || this.typeForm === 'validation') {
            this.displayMode = 'graphic'
        }
    },
    computed: {
        chart() {
            return {
                data: {
                    labels: this.resource.skills.map(s =>
                        this.adminSkillById(s.admin_skill_id)
                            ? this.adminSkillById(s.admin_skill_id).title
                            : ""
                    ),
                    datasets: this.generateDatasets([this.resource.skills])
                },
                options: this.getChartOptions()
            };
        }
    },
    watch: {
        resource: {
            handler: function(val) {

                let data = window._.cloneDeep(this.value);
                data.skills = val.skills
                data.allSkills = val.allSkills

                this.$emit('input', data);
            },
            deep: true
        }
    },
    methods: {
        initResource() {
            this.resource = window._.cloneDeep(this.value);
        },

        adminSkillById(id) {
            if (this.adminSkills.data.length == 0) {
                return;
            }
            return this.adminSkills.data.find(s => s.id == id);
        },

        loadDefaultRatings() {

            let start = Date.now();
            var employee_rating = window._.cloneDeep(this.value);
            employee_rating.skills       = this.resource.skills;
            employee_rating.occupations  = null;
            employee_rating.jobs         = null;
            employee_rating.externals    = employee_rating.externals?.map(o => o.id) || [];
            employee_rating.personals    = employee_rating.personals?.map(o => o.id) || [];
            employee_rating.skill_review = this.skillReview

            window
                .axios({
                    method: "post",
                    url: `/api/gestion/employees/${this.value.employee.id}/ratings/default-skills`,
                    params: {},
                    data: employee_rating,
                })
                .then(response => {
                    this.resource.skills = Object.values(response.data);
                    this.loading = false;

                    console.info('=> Chargement des compétences depuis API : ' + (Date.now() - start) + 'ms');
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    this.loading = false;
                });
        },

        refreshClickHandle() {
            
            this.$swal({
                title: `Regénérer le pré-remplissage ?`,
                text: "Attention, les notes vont être recalculées.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Je confirme",
                cancelButtonText: "J'annule"
            }).then(result => {

                if (result.value) {
                    this.resource.skills = [];
                    this.loadDefaultRatings();
                }
            });
        },

        generateDatasets(ratings) {
            let NonMesuredLevel = 0;
            let datasets = [];
            if (this.ratingSettings.NonMesuredLevel !== null) {
                NonMesuredLevel = this.ratingSettings.NonMesuredLevel;
            }

            const date = this.$moment(this.resource.rating_date).format("l");

            datasets.push({
                label: `${this.resource.skill_review ? this.skillRatingNames.singular : this.projectNames.singular} du ${date}`,
                fill: true,
                ...this.getRatingColorsBySlug(this.resource.skill_review ? 'skill-review-0' : 'pp-0'),
                pointBorderColor: "#fff",
                data: this.showedSkillsNotes(this.resource.skills, NonMesuredLevel, "note"),
                levels: this.showedSkillsNotes(this.resource.skills, NonMesuredLevel, "label")
            });

            return datasets;
        },

        showedSkillsNotes(skills, NonMesuredLevel, type) {
            return skills.map(s =>
                this.ratedSkillNote(s, NonMesuredLevel, type)
            );
        },

        ratedSkillNote(s, NonMesuredLevel, type) {
            if (type === "note") {
                return this.noteById(s.level) || NonMesuredLevel;
            }

            return this.levelLabelById(s.level) || "Non mesuré";
        },

        getChartOptions() {
            return {
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: `Compétences`
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        title: function(tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        },
                        label: (tooltipItem, data) => {
                            const label =
                                data.datasets[tooltipItem.datasetIndex].label;
                            const value =
                                label != "Montée en compétences"
                                    ? data.datasets[tooltipItem.datasetIndex]
                                          .levels[tooltipItem.index]
                                    : null;
                            return value ? `${label}: ${value} ` : `${label}`;
                        }
                    },
                    filter: x => x.yLabel >= 0
                },
                scale: {
                    pointLabels: {
                        callback: function(value, index, values) {
                            // if more than 25 values, display the top label with space under and bottom label with space above
                            if (values.length < 25) {
                                return value;
                            } else {
                                if (index == 0) {
                                    return [value, [" "]];
                                } else if (
                                    values.length % 2 == 0 &&
                                    index == values.length / 2
                                ) {
                                    return [" ", [value]];
                                } else {
                                    return value;
                                }
                            }
                        }
                    },
                    ticks: {
                        min: this.ratingSettings
                            ? this.ratingSettings.RadarCenter
                            : -1,
                        max: 3,
                        stepSize: 1
                    }
                }
            };
        },
    }
};
</script>


<style lang="scss" scoped>
.chart {
    &.main {
        height: 750px;
        position: relative;
        max-width: 750px;
        margin-left: auto !important;
        margin-right: auto !important;
        @media screen and (max-width: 750px) {
            width: 100%;
            height: 400px;
        }
    }

    .main-chart {
        height: 100%;

        > #radar-chart {
            height: 100%;
        }
    }
}
</style>
