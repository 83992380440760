<template>
  <div  :style="getStyle" class="bg-white" id="employeesCreatedCard">
    <vx-card>
    <h2 class="mb-2">
        Dernières personnes créées
      </h2>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <DialogPrompt
          :active.sync="showPopup"
          title="Consulter une personne"
          cancel-text="Fermer"
          accept-text="Enregistrer"
          button-accept="false"
        >
          <EmployeeForm
              v-model="resource"
              type="show"
          />
        </DialogPrompt>
        <vs-table
                :sst="true"
                no-data-text="Aucune donnée à afficher"
                style="width:100%"
                :data="persons"
            >

                <template slot="thead">
                    <vs-th></vs-th>
                    <vs-th>Nom</vs-th>
                    <vs-th>Prénom</vs-th>
                    <vs-th>Service</vs-th>
                    <vs-th>Etablissement</vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr v-for="(item, i) in persons" :key="i" :data="item">
                        <vs-td>
                          <feather-icon icon="UserIcon" class="" />
                        </vs-td>
                        <vs-td
                            v-tooltip="{
                                content: item.description,
                                placement: 'top-start',
                                classes: ['occ-description']
                            }"
                        >
                            <p @click="showResource(item)">{{ get(item, ":last_name") }}</p>
                        </vs-td>
                        <vs-td>
                            <p @click="showResource(item)">{{ get(item, ":first_name") }}</p>
                        </vs-td>
                        <vs-td>
                            <p @click="showResource(item)">{{ get(item, ":services").map(s => s.title).join(", ") }}</p>
                        </vs-td>
                        <vs-td>
                            <p @click="showResource(item)">{{ get(item, ":services").map(s => s.establishment.name).join(", ") }}</p>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
      </vs-col>
    </vs-row>

    </vx-card>
  </div>
</template>
<script>
import EmployeeForm from '@components/views/rh/gestion/employee/EmployeeForm'
export default {
  components: {
      EmployeeForm
  },
  data () {
    return {
      persons:'',
      showPopup: false,
      resource: {},
    }
  },
  computed: {
    getStyle () {
      return 'height: 100%; background-size: contain; background-repeat: no-repeat; border: 2px solid #FCE4EC; border-radius: 5px; background-position: center;'
    }
  },
  created() {
    this.loadpersons();
  },
  methods: {
    showResource(resource) {
      this.resetResource();
      this.setResource(resource);
      this.showPopup = true;
    },
    setResource(data) {
      this.resource = JSON.parse(JSON.stringify(data));
    },
    resetResource() {
      this.resource = {
          title: null,
          description: null,
          sector: null,
          skills: {},
      };
    },
    loadpersons() {
        window.axios({
            method: 'get',
            url: '/api/gestion/employees',
            params: {perpage: 4, sortBy: {'created_at': 'desc'}, include: ['services.establishment']},
        })
        .then(response => {
          response.data.data.forEach(element =>
            {
              element['created_at'] = new Date(element['created_at']).toLocaleString('fr-FR');
            });

            this.persons = response.data.data;
        })
        .catch((err) => {
            console.log(err)
            this.notifyError(err, `Une erreur est survenue`)
        })
    },
  }
}
</script>
