<template>
    <div>
        <!-- Liste des objectifs & commentaires des compétences -->
        <div v-if="!occupationSkillOnly">
            <vs-row class="mb-4" vs-w="12">
                <vs-col
                    class="sm:w-1/5 w-full mb-4"
                    vs-type="flex"
                    vs-align="flex-start"
                >
                    <span class="font-bold text-lg">Compétences évaluées :</span>
                </vs-col>
                <vs-col class="sm:w-3/5 w-full">
                    <vs-collapse>
                        <vs-collapse-item>
                            <div slot="header"><p class="mr-6"><b>Synthèse des objectifs et commentaires</b></p></div>
                            <div style="padding-left: 10px !important;">
                                <vs-table
                                    v-if="commentedOrGoaledSkills.length"
                                    style="width:100%"
                                    :data="commentedOrGoaledSkills"
                                >
                                    <template slot="thead">
                                        <vs-th>Compétence</vs-th>
                                        <vs-th>Evaluée</vs-th>
                                        <vs-th>Objectif fixé</vs-th>
                                        <vs-th>Commentaires</vs-th>
                                    </template>
                                    <template slot-scope="{ data }">
                                        <vs-tr v-for="(skill, i) in data" :key="'row-'+i">
                                            <vs-td>{{ skill.title }}</vs-td>
                                            <vs-td>{{ getRatingLevelLabel(skill) }}</vs-td>
                                            <vs-td>
                                                {{ value.skills_goals.find(sg => sg.skill.id === skill.id) ?
                                                    value.skills_goals.find(sg => sg.skill.id === skill.id).level.label
                                                    :
                                                    ''
                                                }}
                                            </vs-td>
                                            <vs-td>{{ skill.comment }}</vs-td>
                                        </vs-tr>
                                    </template>
                                </vs-table>
                                <div v-else>/</div>
                            </div>
                        </vs-collapse-item>
                    </vs-collapse>
                </vs-col>
            </vs-row>
        </div>

        <JobsOccupationsResume
            :jobs="value.jobs"
            :occupations="value.occupations"
            :jobsWish="value.wishlist_jobs"
            :occupationsWish="value.wishlist_occupations"
            :evaluatedOccupationSkills="value.occupations_skills"
            :display-collapses="true"
            :display-comment-in-collapse="true"
            :occupation-skills-goals="value.occupation_skills_goals"
        />

        <!-- Liste des commentaires des évaluations externes -->
        <div v-for="(rating, i) in value.externals" :key="i" class="mt-20 mb-30">
            <h3 class="mb-6 pl-5">Commentaires de {{ rating.creator.last_name }} {{ rating.creator.first_name }}</h3>
            <vs-row class="mb-3">
                <vs-col class="sm:w-1/5 w-full font-weight-bold" >
                    <b>Synthèse de l'évaluation :</b>
                </vs-col>
                <vs-col class="sm:w-4/5 w-full">
                    <div v-html="rating.comment['Synthèse']"></div>
                </vs-col>
            </vs-row>
            <vs-row>
                <vs-col class="sm:w-1/5 w-full font-weight-bold" >
                    <b>Autres commentaires :</b>
                </vs-col>
                <vs-col class="sm:w-4/5 w-full">
                    <div v-for="(comment, type) in rating.comment" :key="type">
                        <div v-if="type != 'Synthèse'"><b>{{ type }}:</b></div>
                        <div v-if="type != 'Synthèse'" v-html="comment"></div>
                    </div>
                </vs-col>
            </vs-row>
            <hr>
        </div>

        <!-- Saisie des commentaires de l'évaluation -->
        <template v-if="resource.comment || Object.values(resource.comment).length == 0">
            <vs-row v-for="ratingField in ratingFields" :key="ratingField.id" class="mb-20 mt-10">
                <vs-col class="sm:w-1/5 w-full">
                    <span class="font-bold text-lg"
                        >{{ ratingField.name }} :</span
                    >
                </vs-col>
                <vs-col class="sm:w-4/5 w-full">
                    <quill-editor
                        v-model="resource.comment[ratingField.name]"
                        :name="'ratingField' + ratingField.id"
                        :options="options"
                        :disabled="typeForm === 'consultation'"
                        @input="inputHandle"
                    />
                </vs-col>
            </vs-row>
        </template>

        <template v-else>
            <vs-row v-for="(ratingFieldName, i) in Object.keys(resource.comment)" :key="i" class="mb-20 mt-10">
                 <vs-col class="sm:w-1/5 w-full">
                    <span class="font-bold text-lg"
                        >{{ ratingFieldName }} :</span
                    >
                </vs-col>
                <vs-col class="sm:w-4/5 w-full">
                    <quill-editor
                        v-model="resource.comment[ratingFieldName]"
                        :name="'ratingField' + i"
                        :options="options"
                        :disabled="typeForm === 'consultation'"
                        @input="inputHandle"
                    />
                </vs-col>
            </vs-row>
        </template>
    </div>
</template>

<script>
import JobsOccupationsResume from "../JobsOccupationsResume";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
    components: {
        JobsOccupationsResume,
        quillEditor
    },
    props: {
        typeForm: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            resource: {
                comment: {}
            },
            ratingFields: null,
            options: {
                placeholder: "...",
                modules: {
                    toolbar: [
                        [{ size: ["small", false, "large"] }],
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"]
                    ]
                }
            }
        };
    },
    created() {
        this.initResource();
        this.loadRatingFields();

        console.log(this.value);
    },
    computed: {
        occupationsSkillsRating() {
            if (this.settings) {
                return this.settingByName("occupationSkillsRatings");
            }
            return null;
        },

        activeRatingOs() {
            if (this.occupationsSkillsRating) {
                return this.occupationsSkillsRating.value.enabled;
            }

            return false;
        },
        acquiredOccupationSkills() {

            let acquiredOS = {};

            this.value.occupations_skills.forEach(os => {

                // Si savoir faire acquis ou maîtrisé
                if (os.level == 3 || os.level == 4) {
                    const key = `${os.id}-${os.job_id}`;

                    // Création d'un tableau pour compatibilité avec ancien système
                    if (!acquiredOS[os.uid]) {
                        acquiredOS[os.uid] = [];
                    }

                    acquiredOS[os.uid].push(os);
                }
            })

            return acquiredOS;
        },
        commentedOrGoaledSkills() {
            return this.value.skills.filter(s => (s.comment && s.comment !== '') || this.value.skills_goals.find(sg => sg.skill.id === s.id))
        },
        occupationSkillOnly() {
            return this.value.employee.occupation_skills_rating_only;
        },
    },
    watch: {},
    methods: {

        initResource() {
            this.resource = window._.cloneDeep(this.value);
        },

        /**
         * Chargement des zones de commentaires paramétrées sur la base client
         */
        loadRatingFields() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/rating-fields",
                    params: {
                        perpage: 9999,
                        include: []
                    }
                })
                .then(response => {

                    // Init de la resource
                    if (!this.resource.comment || Object.values(this.resource.comment).length == 0) {
                        response.data.forEach(item => {
                            if (!this.resource.comment[item.name]) {
                                this.resource.comment[item.name] = '';
                            }
                        })
                    }

                    this.ratingFields = response.data.sort((a, b) => (a.order > b.order ? 1 : -1));
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        inputHandle() {
            let data = window._.cloneDeep(this.value);
            data.comment = this.resource.comment

            this.$emit('input', data);
        },
        getRatingLevelLabel(skill) {
            return this.ratingSettings.params[skill.level || 1]?.label || ''
        }
    }
};
</script>

<style lang="scss" scoped>
    ::v-deep .vs-collapse {
        padding: 0 !important;

        .vs-collapse-item {
            border-bottom: none;
            > header {
                width: fit-content;
                font-size: 1rem;
                padding: 0 !important;
                padding-bottom: 2px !important;
                padding-right: 5px !important;
                > span.icon-header {
                    top: 50% !important;
                }
            }
        }
    }

    ::v-deep .vs-con-table {
        border: 0px;
        tr {
            border-bottom: 0px;
        }
    }
    ::v-deep .vs-con-tbody {
        border: 0px;
    }
</style>
