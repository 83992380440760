var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("JobsOccupationsResume", {
        attrs: {
          jobs: _vm.value.jobs,
          occupations: _vm.value.occupations,
          displayWishes: false
        }
      }),
      _vm._v(" "),
      _vm.resource.occupations_skills
        ? _c(
            "vs-row",
            { staticClass: "checkboxes-container mt-10" },
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:w-4/5 w-full sm:w-",
                  attrs: { "vs-type": "flex", "vs-align": "center" }
                },
                [
                  _c("div", { staticClass: "text-white" }, [
                    _vm._v("_______________________________--")
                  ]),
                  _vm._v(" "),
                  _vm.typeForm !== "consultation" &&
                  _vm.typeForm !== "validation"
                    ? [
                        _c("label", { staticClass: "ml-20" }, [
                          _vm._v("Préremplir avec :")
                        ]),
                        _vm._v(" "),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "vs-name": "radios1",
                              id: "radio-last-auto-eval",
                              "vs-value": _vm.selfRatingSourceValues.LAST_SELF
                            },
                            on: {
                              change: function($event) {
                                return _vm.setDefaultHandle(
                                  _vm.selfRatingSourceValues.LAST_SELF
                                )
                              }
                            },
                            model: {
                              value: _vm.resource.self_occupation_skills_source,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.resource,
                                  "self_occupation_skills_source",
                                  $$v
                                )
                              },
                              expression:
                                "resource.self_occupation_skills_source"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Dernière sauvegarde\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "vs-name": "radios1",
                              id: "radio-declaration-competence",
                              "vs-value": _vm.selfRatingSourceValues.MOBILITY,
                              disabled: !_vm.mobilityRating
                            },
                            on: {
                              change: function($event) {
                                return _vm.setDefaultHandle(
                                  _vm.selfRatingSourceValues.MOBILITY
                                )
                              }
                            },
                            model: {
                              value: _vm.resource.self_occupation_skills_source,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.resource,
                                  "self_occupation_skills_source",
                                  $$v
                                )
                              },
                              expression:
                                "resource.self_occupation_skills_source"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Auto-évaluation Portail\n                    " +
                                _vm._s(
                                  !!_vm.mobilityRating
                                    ? "du " +
                                        this.$moment(
                                          _vm.mobilityRating.rating_date
                                        ).format("l")
                                    : ""
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.typeForm !== "consultation" && _vm.typeForm !== "validation"
                ? _c(
                    "vs-col",
                    {
                      staticClass: "sm:w-4/5 w-full sm:w-",
                      attrs: { "vs-type": "flex", "vs-align": "center" }
                    },
                    [
                      _c("div", { staticClass: "text-white" }, [
                        _vm._v("_______________________________--")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "ml-20" }, [
                        _vm._v(
                          "\n                En cas de nouvelle auto-évaluation Portail transmise depuis la date de création,\n                veuillez sélectionner l’auto-évaluation Portail\n            "
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : !_vm.loading
        ? _c("div", { staticClass: "not-data-table vs-table--not-data" }, [
            _vm._v("Il n'y a aucun savoir-faire à évaluer")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.resource.occupations_skills || _vm.loading
        ? _c("Rating-Manager", {
            attrs: {
              name: "self-occupation-skills",
              "type-form": _vm.typeForm,
              "show-deleted": _vm.showDeletedOccSkills,
              col1: {
                label: "Savoir-faire",
                value: "title",
                title: "description"
              },
              col2: {
                label: "Poste / Métier",
                value: "job_name",
                title: "type"
              },
              col3: { label: "Service / Filière", value: "service_name" },
              loading: _vm.loading,
              "skill-review": _vm.skillReview
            },
            model: {
              value: _vm.resource.self_occupation_skills,
              callback: function($$v) {
                _vm.$set(_vm.resource, "self_occupation_skills", $$v)
              },
              expression: "resource.self_occupation_skills"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }