var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-alert",
        { attrs: { active: "true", color: "warning" } },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "2" } },
                [
                  !_vm.planAllow("indicateurs-activite")
                    ? _c(
                        "vs-icon",
                        { attrs: { bg: "#FF9B3A", round: "" } },
                        [
                          _c("CrownIcon", {
                            staticStyle: {
                              width: "56px",
                              height: "56px",
                              padding: "10px"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-col", { staticClass: "py-4", attrs: { "vs-w": "10" } }, [
                _vm._v("\n                Votre formule "),
                _c("b", [_vm._v(_vm._s(_vm.planName()))]),
                _vm._v(" n'inclut pas ce module. "),
                _c("br"),
                _vm._v(
                  "\n                Veuillez contacter votre administrateur\n            "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }