<template>
    <div>
        <vs-row>
            <vs-col vs-align="left" vs-w="6">
                <vs-button
                    id="addResource"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="showFormCreate"
                >
                    Ajouter un nouveau service
                </vs-button>

                <DialogPrompt
                    :active.sync="showPopup"
                    :title="getDataPrompt.title"
                    cancel-text="Annuler"
                    accept-text="Enregistrer"
                    :button-accept="getDataPrompt.btnAccept"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @close="resetResource"
                    @accept="saveResource"
                >
                     <ServiceForm
                        v-model="resource"
                        :type="formType"
                        @error="setFormError"
                    />
                </DialogPrompt>

            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="itemsData"
            :loading="true"
            >
            <template slot="thead">
                <vs-th>
                    <Sort name="title" @sort="eventSortByCol">Service</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="sector_id" @sort="eventSortByCol">Filière</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="establishment_id" @sort="eventSortByCol">Établissement</Sort>
                </vs-th>
                <vs-th>Référents</vs-th>
                <vs-th></vs-th>
            </template>

            <template>
                <vs-tr v-for="(item, i) in items.data" :key="i">
                    <vs-td>
                        {{ get(item, ':title') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':sector.title') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':establishment.name') }}
                    </vs-td>
                    <vs-td>
                        <div v-for="(el, index) in get(item, ':referents')" :key="index">
                        {{ el.first_name }} {{ el.last_name }}
                        </div>
                    </vs-td>
                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="can('update', 'services', item.id)"
                                :id="'modifyService_' + i"
                                v-tooltip="'Modifier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click.stop="showFormUpdate(item)"
                            />

                            <vs-button
                                v-if="can('delete', 'services', item.id)"
                                :id="'deleteService_' + i"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click.stop="removeResource(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>

        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination :total="Math.ceil(items.total / apiParams.perpage)" v-model="currentPage"></vs-pagination>
            </vs-col>
        </vs-row>
    </div>

</template>

<script>

import ServiceForm from '@components/views/rh/referentiel/service/ServiceForm';
import { reloadAppUser as reloadAppUserService } from "@components/services/resources.js";

export default {
    name: 'Services',
    components: {
        ServiceForm
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            reloadAppUserService,
            config: [],
            currentPage: 1,
            loading: false,
            showPopup: false,
            isFormCreation: true,
            resource: {
                title: null,
                sector: null,
                establishment: null,
                referents: []
            },
            formError: true,
            formType: "create",
            prompt: {
                show: {
                    title: 'Consulter un service',
                    btnAccept: 'false'
                },
                create: {
                    title: 'Ajouter un service',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier un service existant',
                    btnAccept: 'filled'
                }
            }
        }
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },

    computed: {
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                    break;
                case 'create':
                    return this.prompt.create
                    break;
                case 'update':
                    return this.prompt.update
                    break;
                default:
                    return this.prompt.show
            }
        },
    },

    methods: {

        showFormCreate() {
            this.resetResource();
            this.formType = "create";
            this.showPopup = true
        },

        showFormUpdate(resource) {
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true
        },

        saveResource() {
            let data               = JSON.parse(JSON.stringify(this.resource));
                //data.sector        = data.sector.id;
                data.establishment = data.establishment.id;
                data.referents     = data.referents.map(el => el.id);

            if (data.id) {
                this.apiUpdate(data, data.id,
                    (resp) => this.loadItems(),
                    (err)  => this.showPopup = true
                );
            } else {
                this.apiCreate(data,
                    (resp) => {
                        // Rechargement de l'utilisateur connecté (il a potentiellement un service de plus à un de ses établissement)
                        reloadAppUserService().then(() => {
                            this.loadItems();
                        })
                    },
                    (err)  => this.showPopup = true
                );
            }
        },

        removeResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                id: null,
                title: null,
                sector: null,
                establishment: null,
                referents: []
            }
        },

        setFormError (value) {
            this.formError = value;
        },

    },
    created() {
        this.apiParams.include = ['establishment', 'referents', 'sector'];
        this.loadItems();
    }
}
</script>
