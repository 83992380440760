var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parentx-static" },
    [
      _c(
        "vs-sidebar",
        {
          ref: "mainSidebar",
          staticClass: "sidebarx main-menu-sidebar items-no-padding",
          attrs: {
            color: "primary",
            parent: ".layout--main",
            "hidden-background": true,
            reduce: false,
            "default-index": _vm.currentIndex,
            "click-not-close": false,
            "reduce-not-rebound": false,
            "reduce-not-hover-expand": true
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "header-sidebar",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("div", { staticClass: "logo flex items-center" }, [
                _c("img", {
                  staticClass: "main-logo-image",
                  attrs: {
                    src: "/assets/images/" + _vm.menuLogo + "-icon.png",
                    height: "32px"
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "main-logo-image truncate",
                  attrs: {
                    src: "/assets/images/" + _vm.menuLogo + "-text.png",
                    height: "32px"
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "scroll-container" }, [
            _c("div", { staticClass: "shadow-bottom" }),
            _vm._v(" "),
            _c(
              "div",
              { ref: "sideBar", staticClass: "scroll-area--main-sidebar" },
              [
                _c(
                  "vs-sidebar-item",
                  {
                    ref: "sidebarLink",
                    attrs: { href: "/rh", index: "/rh", title: "Accueil" }
                  },
                  [
                    _c("feather-icon", { attrs: { icon: "HomeIcon" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "truncate" }, [_vm._v("Accueil")])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "navigation-header truncate" }, [
                  _vm._v("Espace Utilisateur")
                ]),
                _vm._v(" "),
                _vm.can("read", "employees")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/gestion/personnes",
                          index: "/rh/gestion/personnes",
                          title: "Personnes"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "UsersIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Personnes")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.can("read", "employee_ratings")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/gestion/bilans",
                          index: "/rh/gestion/bilans",
                          title: _vm.projectNames.plural
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "CheckSquareIcon" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Evaluations")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.isASuperAdmin() ||
                  _vm.isAnAdmin() ||
                  _vm.isADrh() ||
                  _vm.isAMonitor()) &&
                _vm.planAllow("suivis")
                  ? _c(
                      "app-menu-group",
                      {
                        attrs: {
                          icon: "PenToolIcon",
                          name: "Suivis",
                          "base-index": "/rh/gestion/suivis*",
                          title: "Suivi",
                          "required-plan": "suivis"
                        }
                      },
                      [
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/suivis/objectifs",
                            icon: "TrendingUpIcon",
                            label: "Objectifs",
                            title: "Suivi des objectifs",
                            "required-plan": "suivi-objectifs"
                          }
                        }),
                        _vm._v(" "),
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/parcours",
                            icon: "AlignRightIcon",
                            label: "Parcours",
                            title: "Suivi des parcours",
                            "required-plan": "parcours_graphique"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.isASuperAdmin() ||
                  _vm.isAnAdmin() ||
                  _vm.isADrh() ||
                  _vm.isAMonitor()) &&
                _vm.planAllow("dashbords")
                  ? _c(
                      "app-menu-group",
                      {
                        attrs: {
                          icon: "PieChartIcon",
                          name: "Tableau de bord",
                          "base-index": "/rh/gestion/tableaux*",
                          title: "Tableau de bord",
                          "required-plan": "dashbords"
                        }
                      },
                      [
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/tableaux/personnes",
                            icon: "UserIcon",
                            label: "Personnes",
                            title: "Tableau de bord - Personnes",
                            "required-plan": "dashbords_persons"
                          }
                        }),
                        _vm._v(" "),
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/tableaux/metiers-postes",
                            icon: "BookmarkIcon",
                            label: "Métiers / postes",
                            title: "Tableau de bord - Métiers / poste",
                            "required-plan": "dashbords_occupations_jobs"
                          }
                        }),
                        _vm._v(" "),
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/tableaux/competences",
                            icon: "BoxIcon",
                            label: "Compétences",
                            title: "Tableau de bord - Compétences",
                            "required-plan": "dashbords_skills"
                          }
                        }),
                        _vm._v(" "),
                        _c("item", {
                          attrs: {
                            href: "/rh/gestion/tableaux/savoir-faire",
                            icon: "ApertureIcon",
                            label: "Savoir-faire",
                            title: "Tableau de bord - Savoir-faire",
                            "required-plan": "dashbords_occupationskills"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "navigation-header truncate" }, [
                  _vm._v("Administration")
                ]),
                _vm._v(" "),
                _vm.can("read", "users")
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/referentiel/utilisateurs",
                          index: "/rh/referentiel/utilisateurs",
                          title: "Utilisateurs"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "UsersIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Utilisateurs")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "app-menu-group",
                  {
                    attrs: {
                      icon: "ListIcon",
                      name: "Référentiel",
                      "base-index": "/rh/referentiel*",
                      title: "Référentiel"
                    }
                  },
                  [
                    _vm.isAbleTo("read-skills", undefined, _vm.user)
                      ? _c("item", {
                          attrs: {
                            href: "/rh/referentiel/competences",
                            icon: "AwardIcon",
                            label: "Compétences",
                            title: "Référentiel - Compétences"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAbleTo("read-occupation_skills", undefined, _vm.user)
                      ? _c("item", {
                          attrs: {
                            href: "/rh/referentiel/savoir-faire",
                            icon: "ApertureIcon",
                            label: "Savoir-faire",
                            title: "Référentiel - Savoir-faire"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAbleTo("read-jobs", undefined, _vm.user)
                      ? _c("item", {
                          attrs: {
                            href: "/rh/referentiel/postes",
                            icon: "ClipboardIcon",
                            label: "Postes/stages",
                            title: "Référentiel - Postes"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAbleTo("read-occupations", undefined, _vm.user)
                      ? _c("item", {
                          attrs: {
                            href: "/rh/referentiel/metiers",
                            icon: "TargetIcon",
                            label: "Métiers",
                            title: "Référentiel - Métiers"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAbleTo("read-services", undefined, _vm.user)
                      ? _c("item", {
                          attrs: {
                            href: "/rh/referentiel/services",
                            icon: "LayersIcon",
                            label: "Services",
                            title: "Référentiel - Services"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAbleTo("read-establishments", undefined, _vm.user)
                      ? _c("item", {
                          attrs: {
                            href: "/rh/referentiel/etablissements",
                            icon: "GridIcon",
                            label: "Établissements",
                            title: "Référentiel - Établissements"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isASuperAdmin()
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/laratrust",
                          index: "/rh/referentiel/laratrust",
                          title: "Rôles & Permissions"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "UnlockIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Rôles & Permissions")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isASuperAdmin()
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        staticStyle: { opacity: "0.5" },
                        attrs: {
                          href: "#",
                          "data-href": "/rh/referentiel/alertes",
                          index: "/rh/referentiel/alertes",
                          title: "Message d'informations"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "InfoIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Message d'informations")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.isASuperAdmin() ||
                  _vm.isAnAdmin() ||
                  _vm.isADrh() ||
                  _vm.isAMonitor()) &&
                _vm.planAllow("indicateurs-activite")
                  ? _c(
                      "app-menu-group",
                      {
                        attrs: {
                          icon: "TrelloIcon",
                          name: "Statistiques",
                          "base-index": "/rh/referentiel*",
                          title: "Statistiques",
                          "required-plan": "indicateurs-activite"
                        }
                      },
                      [
                        _c("item", {
                          attrs: {
                            href: "/rh/referentiel/statistiques",
                            icon: "PercentIcon",
                            label: "Suivi d'activité",
                            title: "Statistiques - Suivi d'activité",
                            "required-plan": "indicateurs-activite"
                          }
                        }),
                        _vm._v(" "),
                        _c("item", {
                          attrs: {
                            href: "/rh/referentiel/usages",
                            icon: "ActivityIcon",
                            label: "Suivi des usages",
                            title: "Statistiques - Suivi des usages",
                            "required-plan": "indicateurs-activite"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isASuperAdmin() ||
                (_vm.isAnAdmin() && _vm.planAllow("params"))
                  ? _c(
                      "app-menu-group",
                      {
                        attrs: {
                          icon: "SettingsIcon",
                          name: "Paramètres",
                          "base-index": "/rh/referentiel/parametres*",
                          title: "Paramètres",
                          "required-plan": "params"
                        }
                      },
                      [
                        _vm.isASuperAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/parcours",
                                icon: "AlignRightIcon",
                                label: "Parcours",
                                title: "Paramètres - Parcours",
                                "required-plan": "params_parcours"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/notation",
                                icon: "TagIcon",
                                label: "Notation",
                                title: "Paramètres - Notation",
                                "required-plan": "params_notations"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/bilans",
                                icon: "CheckSquareIcon",
                                label: "Evaluations",
                                title: "Paramètres - Evaluations",
                                "required-plan": "params_evaluations"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin() || _vm.isAnAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/images",
                                icon: "ImageIcon",
                                label: "Images",
                                title: "Paramètres - Images",
                                "required-plan": "params_images"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin() || _vm.isAnAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/graphisme",
                                icon: "ApertureIcon",
                                label: "Graphisme",
                                title: "Paramètres - Graphisme",
                                "required-plan": "params_graphics"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin() || _vm.isAnAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/imports",
                                icon: "ApertureIcon",
                                label: "Imports",
                                title: "Paramètres - Imports",
                                "required-plan": "params_imports"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isASuperAdmin()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/exports",
                                icon: "DownloadIcon",
                                label: "Exports",
                                title: "Paramètres - Exports",
                                "required-plan": "params_exports"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.can("read", "trainings") && !_vm.shouldHideOptions()
                          ? _c("item", {
                              attrs: {
                                href: "/rh/referentiel/parametres/trainings",
                                icon: "FileIcon",
                                label: "Ressources",
                                title: "Paramètres - Ressources",
                                "required-plan": "params_resources"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isASuperAdmin() || _vm.isAnAdmin() || _vm.isADrh()
                  ? _c("span", { staticClass: "navigation-header truncate" }, [
                      _vm._v("Archives")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isASuperAdmin() || _vm.isAnAdmin() || _vm.isADrh()
                  ? _c(
                      "vs-sidebar-item",
                      {
                        ref: "sidebarLink",
                        attrs: {
                          href: "/rh/gestion/personnes/archives",
                          index: "/rh/gestion/personnes/archives",
                          title: "Liste des archivés"
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "UserXIcon" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v("Archivés")
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }