// Composants du thème
import Vue from 'vue';
import VxTooltip from '@components/theme/VxTooltip.vue';
import MsTooltip from '@components/theme/MsTooltip.vue';
import VxCard from '@components/theme/VxCard.vue';
import VxList from '@components/theme/VxList.vue';
import FeatherIcon from '@components/theme/FeatherIcon.vue';
import VxInputGroup from '@components/theme/VxInputGroup.vue';
import vSelect from 'vue-select';
Vue.component(VxTooltip.name, VxTooltip);
Vue.component(MsTooltip.name, MsTooltip);
Vue.component(VxCard.name, VxCard);
Vue.component(VxList.name, VxList);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(VxInputGroup.name, VxInputGroup);


// Composants du template
import GlobalStyles from '@components/theme/GlobalStyles.vue';
import Menu from '@components/theme/Menu.vue';
import MenuGroup from '@components/theme/MenuGroup.vue';
import Navbar from '@components/theme/Navbar.vue';
import Footer from '@components/theme/Footer.vue';
import Login from '@components/views/Login.vue';
import ResetPassword from '@components/views/ResetPassword.vue';
import Sort from '@components/theme/Sort.vue';
import DialogPrompt from '@components/theme/DialogPrompt.vue';

Vue.component('global-styles', GlobalStyles);
Vue.component('app-menu', Menu);
Vue.component('app-menu-group', MenuGroup);
Vue.component('app-navbar', Navbar);
Vue.component('app-footer', Footer);
Vue.component('app-login', Login);
Vue.component('app-reset-password', ResetPassword);
Vue.component('Sort', Sort);
Vue.component('DialogPrompt', DialogPrompt);


// Composants des pages
import ImportExport from '@components/form/ImportExport';
import Home from '@components/views/rh/Home';
import Employees from '@components/views/rh/gestion/employee/Employees';
import ArchivedEmployees from '@components/views/rh/gestion/employee/ArchivedEmployees';
import EmployeeCareer from '@components/views/rh/gestion/employee/career/Careers';
import EmployeeRating from '@components/views/rh/gestion/employee/rating/Ratings';
import EmployeeRatingForm from '@components/views/rh/gestion/employee/rating/RatingForm';
import Careers from '@components/views/rh/gestion/career/Careers';
import Ratings from "@components/views/rh/gestion/rating/Ratings";
import Services from '@components/views/rh/referentiel/service/Services';
import Trainings from '@components/views/rh/referentiel/parameters/Training/Trainings';
import Skills from '@components/views/rh/referentiel/skill/Skills';
import OccupationSkills from '@components/views/rh/referentiel/occupation-skill/OccupationSkills';
import Jobs from '@components/views/rh/referentiel/job/Jobs';
import Occupations from '@components/views/rh/referentiel/occupation/Occupations';
import Establishments from '@components/views/rh/referentiel/establishment/Establishments';
import Users from '@components/views/rh/referentiel/user/Users';
import ContactForm from '@components/views/rh/referentiel/contact/ContactForm';
import Statistics from '@components/views/rh/referentiel/statistic/Statistics';
import ParametersExperience from '@components/views/rh/referentiel/parameters/Experience';
import ParametersRatingForm from '@components/views/rh/referentiel/parameters/RatingForm';
import ParametersRatingFields from '@components/views/rh/referentiel/parameters/RatingFields';
import ParametersImages from '@components/views/rh/referentiel/parameters/Images';
import ParametersGraphics from '@components/views/rh/referentiel/parameters/Graphics';
import ParametersImportsServer from '@components/views/rh/referentiel/parameters/ImportsServer';
import ParametersExport from '@components/views/rh/referentiel/parameters/Exports/Index';
import CvExport from "@components/views/rh/gestion/employee/career/CVExport";
import SkillBookExport from "@components/views/rh/gestion/employee/career/SkillBookExport";

import DashboardOccupationJobs from "@components/views/rh/gestion/dashboards/DashboardOccupationJobs";
import DashboardEmployees from "@components/views/rh/gestion/dashboards/DashboardEmployees";
import DashboardSkills from "@components/views/rh/gestion/dashboards/DashboardSkills";
import DashboardOccupationSkills from "@components/views/rh/gestion/dashboards/DashboardOccupationSkills";

import FollowedGoals from "@components/views/rh/gestion/followed/Goals.vue";


Vue.component('ImportExport', ImportExport);
Vue.component('CvExport', CvExport);
Vue.component('SkillBookExport', SkillBookExport);
Vue.component('Home', Home);
Vue.component('Employees', Employees);
Vue.component('ArchivedEmployees', ArchivedEmployees);
Vue.component('EmployeeCareer', EmployeeCareer);
Vue.component('EmployeeRating', EmployeeRating);
Vue.component('EmployeeRatingForm', EmployeeRatingForm);

Vue.component('DashboardOccupationJobs', DashboardOccupationJobs);
Vue.component('DashboardEmployees', DashboardEmployees);
Vue.component('DashboardSkills', DashboardSkills);
Vue.component('DashboardOccupationSkills', DashboardOccupationSkills);

Vue.component('FollowedGoals', FollowedGoals);

Vue.component('Careers', Careers);
Vue.component('Ratings', Ratings);
Vue.component('Services', Services);
Vue.component('Trainings', Trainings);
Vue.component('Skills', Skills);
Vue.component('Occupationskills', OccupationSkills);
Vue.component('Jobs', Jobs);
Vue.component('Occupations', Occupations);
Vue.component('Establishments', Establishments);
Vue.component('Users', Users);
Vue.component('ContactForm', ContactForm);
Vue.component('Statistics', Statistics);
Vue.component('ParametersExperience', ParametersExperience);
Vue.component('ParametersRatingForm', ParametersRatingForm);
Vue.component('ParametersRatingFields', ParametersRatingFields);
Vue.component('ParametersImages', ParametersImages);
Vue.component('ParametersGraphics', ParametersGraphics);
Vue.component('ParametersImportsServer', ParametersImportsServer);
Vue.component('ParametersExport', ParametersExport);

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement('feather-icon', {
        props: {
          icon: 'XIcon',
          svgClasses: 'w-4 h-4 mt-1'
        }
      })
  },
  OpenIndicator: {
    render: createElement =>
      createElement('feather-icon', {
        props: {
          icon: 'ChevronDownIcon',
          svgClasses: 'w-5 h-5'
        }
      })
  }
})

Vue.component(vSelect)

