import Vue from "vue";
import { format } from 'date-fns'

Vue.mixin({
    methods: {
        /**
         * Solution which does take into account the timezone offset when outputing a Date to String format.
         * @param date
         * @param type (undefined, 'start' or 'end')
         * @returns {string}
         */
        getDate(date, type) {
            let local = date ? new Date(date) : new Date();
            local.setMinutes(local.getMinutes() - local.getTimezoneOffset());

            if (!type) return local.toISOString().substr(0, 10);

            return (
                local.toISOString().substr(0, 10) +
                " " +
                (type === "end" ? "20:00:00" : "00:00:00")
            );
        },

        getDateTime(date_time) {
            return dateTime(date_time);
        },

        addYearsToToday(years, date) {
            return new Date(
                new Date(date).setFullYear(new Date(date).getFullYear() + years)
            );
        },

        addDaysToToday(days, date) {
            return new Date(
                new Date(date).setDate(new Date(date).getDate() + days)
            );
        },

        formatDate(date, pattern = "dd/MM/yyyy") {
            if (!date) return "-";
            return format(new Date(date), pattern);
        },

        formatDateTime(date, pattern = "dd/MM/yyyy HH:mm:ss") {
            if (!date) return "-";
            return format(new Date(date), pattern);
        }
    }
});
