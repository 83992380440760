<template>
    <vx-card id="card-list-occupation-skills">
        <template slot="actions">
            <Import-Export
                name="services"
                import-url="/api/referentiel/occupation-skills/import"
                :import-acl="can('import', 'occupation_skills')"
                export-url="/api/referentiel/occupation-skills/export"
                :export-acl="can('export', 'occupation_skills')"
                @imported="loadItems()"
            >
                <template
                    v-if="can('export', 'occupation_skill-pdf')"
                    slot="actions-before"
                >
                    <vs-button
                        id="exportPDFOccupationSkills"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-download"
                        style="margin-right:1rem;"
                        @click.prevent="exportAsPDF"
                    >
                        Exporter PDF
                    </vs-button>
                </template>
            </Import-Export>
        </template>
        <div>
            <vs-row>
                <vs-col vs-align="left" vs-w="6">
                    <vs-button
                        v-if="can('update', 'occupation_skills')"
                        id="btn-create"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="createResourceExpress"
                    >
                        Ajout express
                    </vs-button>

                    <vs-button
                        v-if="can('create', 'occupation_skills')"
                        class="ml-5"
                        id="btn-create"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="createResource"
                    >
                        Ajouter un nouveau savoir-faire
                    </vs-button>

                    <DialogPrompt
                        :active.sync="showPopupExpress"
                        :title="getDataPrompt.titleExpress"
                        cancel-text="Fermer"
                        :accept-text="getDataPrompt.btnAcceptExpressText"
                        :button-accept="getDataPrompt.btnAccept"
                        :is-valid="!formExpressError"
                        @cancel="resetResourceExpress"
                        @accept="addResourcesExpress"
                        @close="resetResourceExpress"
                    >
                        <OccupationSkillExpressForm
                            v-model="resourcesExpress"
                            @error="setFormExpressError"
                        />

                        <div class="text-center mt-10">
                            <vs-radio
                                v-model="expressCreateMode"
                                vs-name="express-create-mode"
                                class="ml-2"
                                vs-value="only-os"
                            >
                                Ne créer que les savoir-faire
                            </vs-radio>
                            <vs-radio
                                v-model="expressCreateMode"
                                vs-name="express-create-mode"
                                class="ml-2"
                                vs-value="os-and-job"
                            >
                                Créer un poste associé
                            </vs-radio>
                            <vs-radio
                                v-model="expressCreateMode"
                                vs-name="express-create-mode"
                                class="ml-2"
                                vs-value="os-and-occupation"
                            >
                                Créer un métier associé
                            </vs-radio>

                        </div>
                    </DialogPrompt>

                    <DialogPrompt
                        :active.sync="showPopup"
                        :title="getDataPrompt.title"
                        cancel-text="Fermer"
                        :accept-text="getDataPrompt.btnAcceptText"
                        :button-accept="getDataPrompt.btnAccept"
                        :is-valid="!formError"
                        @cancel="resetResource"
                        @accept="storeResource"
                        @close="resetResource"
                    >
                        <OccupationSkillForm
                            v-model="resource"
                            :type="formType"
                            @error="setFormError"
                        />
                        <div v-if="getDataPrompt.help && formError" class="text-right text-warning">
                            {{ getDataPrompt.help }}
                        </div>
                    </DialogPrompt>
                </vs-col>
                <vs-col vs-type="flex" vs-align="left" vs-w="6" />
            </vs-row>

            <vs-table
                :sst="true"
                v-model="selected"
                multiple
                @input="selectedInputHandle"
                @search="eventSearchItems"
                @sort="eventSortByCol"
                search
                :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
                style="width:100%"
                :data="modifiedItems"
            >
                <template slot="header">
                    <vs-row>
                        <vs-col vs-type="flex" vs-align="left" vs-w="3" class="mb-2">
                            <vs-checkbox
                                v-model="filterByOnlyMine"
                                name="filterByOnlyMine"
                            >
                                Mes savoir-faire uniquement
                            </vs-checkbox>
                        </vs-col>

                        <vs-col v-if="isASuperAdmin() || isAnAdmin()" vs-type="flex" vs-align="left" vs-w="3" class="mb-2">
                            <vs-checkbox
                                v-model="filterByUpdateAvailable"
                                name="filterByUpdateAvailable"
                            >
                                MAJ disponible
                            </vs-checkbox>
                        </vs-col>

                        <vs-col v-if="can('create', 'occupation_skills') || can('update', 'occupation_skills')" vs-type="flex" vs-align="left" vs-w="2" class="mb-2">
                            <vs-checkbox
                                v-model="filterBySkillsEmpty"
                                name="filterBySkillsEmpty"
                            >
                                Vide
                            </vs-checkbox>
                        </vs-col>

                        <vs-col v-if="isASuperAdmin()" vs-type="flex" vs-align="left" vs-w="2" class="mb-2">
                            <vs-checkbox
                                v-model="filterByWithParent"
                                name="filterByWithParent"
                            >
                                Avec parent
                            </vs-checkbox>
                        </vs-col>

                        <vs-col vs-type="flex" vs-align="left" vs-w="2" class="mb-2">
                            <vs-checkbox
                                v-model="filterByWithoutJob"
                                name="filterByWithoutJob"
                            >
                                Sans poste
                            </vs-checkbox>
                        </vs-col>
                    </vs-row>
                </template>

                <template v-if="!selected.length" slot="thead">
                    <vs-th>
                        <Sort name="title" @sort="eventSortByCol">Savoir-faire</Sort>
                    </vs-th>
                    <vs-th>
                        <Sort v-if="can('update', 'occupation_skills')" name="admin_occupation_skill,title" @sort="eventSortByCol">Savoir-faire Référentiel</Sort>
                        <Sort v-else name="admin_occupation_skill,type" @sort="eventSortByCol">Type</Sort>
                    </vs-th>
                    <vs-th>
                        <Sort name="sector,title" @sort="eventSortByCol">Filière</Sort>
                    </vs-th>
                    <vs-th>
                        <Sort name="establishment,name" @sort="eventSortByCol">Spécifique à</Sort>
                    </vs-th>
                    <vs-th>
                        Utilisé par
                    </vs-th>
                    <vs-th>
                        <Sort name="updated_at" @sort="eventSortByCol" :value="apiParams.sortBy.updated_at">Modifié le</Sort>
                    </vs-th>
                    <vs-th></vs-th>
                </template>

                <template v-else slot="thead">
                    <vs-th colspan="2">
                        <span class="selected-lines mr-5">
                            {{ selectedLinesText }}
                        </span>
                        <vs-checkbox
                                v-model="selectAll"
                                name="selectAll"
                                @input="selectAllItemsHandle"
                            >
                            Sélectionner la totalité des lignes
                        </vs-checkbox>
                    </vs-th>

                    <vs-th colspan="4" style="font-weight: 400;">
                            <Multiselect
                                v-model="massAction"
                                :options="massActionList"
                                :multiple="false"
                                :group-select="false"
                                placeholder="Choisir une action..."
                                track-by="id"
                                label="label"
                                select-label
                                select-group-label
                                selected-label
                                deselect-label
                                deselect-group-label
                                open-direction="bottom"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </Multiselect>
                    </vs-th>

                    <vs-th>
                        <vs-button
                            v-if="canDoMassAction()"
                            color="secondary"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-check"
                            size="large"
                            @click="massActionHandle"
                        >
                            Valider
                        </vs-button>
                    </vs-th>

                </template>

                <template slot-scope="{ data }">
                    <vs-tr v-for="(item, i) in data" :key="i" :data="item" @click="$event.stopPropagation()">
                        <vs-td
                            v-tooltip="{
                                content: item.description,
                                placement: 'top-start',
                                classes: ['occ-description']
                            }"
                        >
                            <div style="text-wrap: nowrap; display: flex" @click="$event.stopPropagation()">
                                <div class="float-left mr-5" style="line-height: 25px;">{{ get(item, ":title") }}</div>
                                <vs-chip v-if="item.update_available" color="primary" class="bg-chip-transparent">
                                    MAJ disponible
                                </vs-chip>
                                <vs-chip v-if="get(item, ':admin_occupation_skill.beta_status') == true">
                                    Bêta
                                </vs-chip>
                                <vs-chip v-if="item.skills.length == 0">
                                    Vide
                                </vs-chip>
                            </div>
                        </vs-td>
                        <vs-td @click="$event.stopPropagation()">
                            <span v-if="can('update', 'occupation_skills')" class="wrap-select-referentiel" @click="$event.stopPropagation()">
                                <Multiselect
                                    v-model="item.admin_occupation_skill"
                                    class="select-referentiel-field"
                                    :options="adminOccupationSkills.data"
                                    group-label="group"
                                    group-values="items"
                                    :group-select="false"
                                    placeholder="Choisir..."
                                    track-by="id"
                                    label="title"
                                    select-label
                                    select-group-label
                                    selected-label
                                    deselect-label
                                    deselect-group-label
                                    open-direction="bottom"
                                    @input="referentialOccupationSkillChangeHandle(item, $event)"
                                >
                                    <span slot="noResult">Aucun résultat.</span>
                                </Multiselect>
                            </span>
                            <span v-else>{{ get(item, ":admin_occupation_skill.type", '--') }}</span>
                        </vs-td>
                        <vs-td>
                            <div @click="$event.stopPropagation()">
                                {{ get(item, ":sector.title") }}
                            </div>
                        </vs-td>
                        <!-- Etablissement spécifique à un savoir-faire-->
                        <vs-td>
                            <div @click="$event.stopPropagation()">
                                {{ get(item, ":establishment.name") }}
                            </div>
                        </vs-td>
                        <!-- Etablissement(s) qui utilise(nt) le savoir-->
                        <vs-td>
                            <div @click="$event.stopPropagation()">
                                <vs-chip
                                    v-for="(el, index) in get(item, ':establishments')"
                                    :key="index"
                                    v-tooltip="{
                                        content: el.jobs,
                                        placement: 'top-start',
                                        classes: ['occ-description']
                                    }"
                                >
                                    <b>{{
                                        get(el, ":name")
                                    }}</b>
                                </vs-chip>
                            </div>
                        </vs-td>
                        <vs-td>
                            {{ localeDateTime(item.updated_at) }}
                        </vs-td>
                        <vs-td class="action">
                            <div class="action-wrapper" @click="$event.stopPropagation()">
                                <vs-button
                                    v-if="can('read', 'occupation_skills')"
                                    :id="'btn-show-' + i"
                                    v-tooltip="'Consulter'"
                                    color="primary"
                                    type="border"
                                    icon-pack="feather"
                                    icon="icon-list"
                                    @click="showResource(item)"
                                />
                                <vs-button
                                    v-if="
                                        can('update', 'occupation_skills', item.id) && (isASuperAdmin() || isAnAdmin())
                                    "
                                    :id="'btn-update-from-admin-' + i"
                                    v-tooltip="'Traiter la mise à jour'"
                                    color="primary"
                                    type="border"
                                    icon-pack="feather"
                                    icon="icon-refresh-cw"
                                    @click="updateFromAdminResource(item)"
                                    :disabled="!item.update_available"
                                />
                                <vs-button
                                    v-if="
                                        can('update', 'occupation_skills', item.id)
                                    "
                                    :id="'btn-update-' + i"
                                    v-tooltip="'Modifier'"
                                    color="primary"
                                    type="border"
                                    icon-pack="feather"
                                    icon="icon-edit"
                                    @click="updateResource(item)"
                                />
                                <vs-button
                                    v-if="
                                        can('create', 'occupation_skills', item.id)
                                    "
                                    :id="'btn-create-' + i"
                                    v-tooltip="'Dupliquer'"
                                    color="primary"
                                    type="border"
                                    icon-pack="feather"
                                    icon="icon-copy"
                                    @click="duplicateResource(item)"
                                />
                                <vs-button
                                    v-if="
                                        can('delete', 'occupation_skills', item.id)
                                    "
                                    :id="'btn-delete-' + i"
                                    v-tooltip="'Supprimer'"
                                    color="primary"
                                    type="border"
                                    icon-pack="feather"
                                    icon="icon-trash"
                                    @click="deleteResource(item)"
                                />
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>

            <vs-row>
                <vs-col class="mt-4" v-if="items.total > 0">
                    <vs-pagination
                        :total="Math.ceil(items.total / apiParams.perpage)"
                        v-model="currentPage"
                    >
                    </vs-pagination>
                </vs-col>
            </vs-row>
        </div>
    </vx-card>
</template>

<script>
import OccupationSkillForm from "@components/views/rh/referentiel/occupation-skill/OccupationSkillForm";
import OccupationSkillExpressForm from "@components/views/rh/referentiel/occupation-skill/OccupationSkillExpressForm";
import { localeDateTime } from "@assets/utils/helpers/helpers.js";
import Multiselect from "vue-multiselect";

export default {
    name: "OccupationSkills",
    components: {
        OccupationSkillForm, OccupationSkillExpressForm, Multiselect
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            localeDateTime,
            log: console.log,
            selected: [],
            currentPage: 1,
            loading: true,
            showPopup: false,
            showPopupExpress: false,
            resourceReadonly: true,
            resource: {},
            resourcesExpress: [],
            formError: true,
            formExpressError: true,
            formType: "show",
            filterByOnlyMine: false,
            filterByUpdateAvailable: false,
            filterByWithParent: false,
            filterBySkillsEmpty: false,
            filterByWithoutJob: false,
            prompt: {
                show: {
                    title: "Consulter un savoir-faire",
                    btnAccept: "false",
                    btnAcceptText : "--"
                },
                create: {
                    title: "Ajouter un savoir-faire",
                    titleExpress: "Ajout express",
                    btnAccept: "filled",
                    btnAcceptText : "Créer le savoir-faire",
                    btnAcceptExpressText : "Créer les savoir-faire"
                },
                update: {
                    title: "Modifier un savoir-faire",
                    btnAccept: "filled",
                    btnAcceptText : "Modifier le savoir-faire"
                },
                updateFromAdmin: {
                    title: "Mettre à jour un savoir-faire",
                    btnAccept: "filled",
                    btnAcceptText : "Mettre à jour le savoir-faire",
                    help: "Veuillez valider toutes les valeurs en jaune pour mettre à jour"
                }
            },
            skills: [],
            adminskills: [],
            adminOccupationSkills: {
                data: [],
                loading: false
            },
            filterByUserEstablishments: false,
            selectAll: false,
            massAction: null,
            massActionList: [
                {id: 1, label: 'Accepter les MAJ des lignes sélectionnées', action: 'massUpdateFromAdminResource', type: 'version'},
                {id: 2, label: 'Ignorer les MAJ des lignes sélectionnées', action: 'massIgnoreVersion', type: 'version'},
                {id: 3, label: 'Supprimer les lignes sélectionnées', action: 'massDelete', type: 'destroy'},
                {id: 4, label: 'Créer un poste / stage', action: 'massCreateJob', type: 'create'},
                {id: 5, label: 'Créer un métier', action: 'massCreateOccupation', type: 'create'},
            ],
            expressCreateMode: 'only-os',
        };
    },

    watch: {
        filterByOnlyMine(value) {
            this.apiParams.only_mine = value;
            this.currentPage = 1
            this.loadItems();
        },
        filterByUpdateAvailable(value) {
            this.apiParams.update_available = value;
            this.currentPage = 1
            this.loadItems();
        },
        filterByWithParent(value) {
            this.apiParams.with_parent = value;
            this.currentPage = 1
            this.loadItems();
        },
        filterBySkillsEmpty(value) {
            this.apiParams.skills_empty = value;
            this.currentPage = 1
            this.loadItems();
        },
        filterByWithoutJob(value) {
            this.apiParams.without_job = value;
            this.currentPage = 1
            this.loadItems();
        },
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        },
        items(value) {
            this.updateSelectedItems(this.modifiedItems)
        }
    },

    computed: {
        adminRouter() {
            return false;
        },
        usedSkills() {
            return this.adminRouter ? this.adminskills : this.skills;
        },
        getDataPrompt() {
            switch (this.formType) {
                case "show":
                    return this.prompt.show;
                    break;
                case "create":
                    return this.prompt.create;
                    break;
                case "update":
                    return this.prompt.update;
                    break;
                case "update-from-admin":
                    return this.prompt.updateFromAdmin;
                    break;
                default:
                    return this.prompt.show;
            }
        },
        modifiedItems() {
            return this.items.data.map((d) => {
                let establishments = [...new Set(d.jobs.map(j => j.service.establishment.name))];

                if (this.filterByUserEstablishments) {
                    if (this.user && this.user.establishments?.length) {
                        establishments = this.user.establishments
                    }
                    else if (this.$acl.check('superadministrator') || ((this.$acl.check('administrator') && this.user.establishments?.length==0))) {
                        establishments = this.establishments
                    }
                }
                establishments = establishments.map((establishment) => {
                    let jobsItems = d.jobs.filter(j => j.service.establishment.name === establishment && !j.deleted_at).map(j => j.title)
                    return {
                        name: establishment,
                        jobs: jobsItems.join(" / ")
                    }
                })

                // Suppression des établissements sans postes
                establishments = establishments.filter(e => e.jobs);

                return {
                    ...d,
                    establishments: establishments
                }
            })
        },
    },

    methods: {
        canDoMassAction() {
            const actionSelected = !!this.massAction;

            if (actionSelected && this.massAction.type == 'version') {
                return actionSelected && this.selectedContainsAvailableUpdate();
            }
            return actionSelected;
        },

        selectedContainsAvailableUpdate() {
            return this.selected.filter(s => s.update_available == true).length > 0;
        },

        massActionHandle() {
            this[this.massAction.action]();
        },

        selectAllItemsHandle(value) {
            if (value) {
                // Ajout de tous les IDs à la liste des selectionné
                this.selectAllItemsFromIds();

                // Convertion des élements avec la liste réel API
                this.updateSelectedItems(this.modifiedItems);
            } else {
                this.selected = [];
            }

        },
        showResource(resource) {
            this.resourceReadonly = true;
            this.resetResource();
            this.setResource(resource);
            this.formType = "show";
            this.showPopup = true;
        },

        createResource() {
            this.resourceReadonly = false;
            this.resetResource();
            this.formType = "create";
            this.showPopup = true;
        },

        createResourceExpress()
        {
            this.resourceReadonly = false;
            this.resetResource();
            this.formType = "create";
            this.showPopupExpress = true;
        },

        updateResource(resource) {
            this.resourceReadonly = false;
            this.resetResource();
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true;
        },

        updateFromAdminResource(resource) {
            this.resourceReadonly = false;
            this.resetResource();
            this.setResource(resource);
            this.formType = "update-from-admin";
            this.showPopup = true;
        },

        massUpdateFromAdminResource() {
            window.axios({
                method: 'patch',
                url: `${this.api}/skills`,
                params: this.apiParams,
                data: {
                    items: this.selected.filter(s => s.update_available).map(p => p.id),
                    all: this.selectAll
                }
            })
            .then(response => {
                this.notifySuccess('Succès', 'Élements mis à jour avec succès !')
                this.selectAll = false;
                this.unselectLines();
                this.loadItems();
            })
            .catch((err) => {
                this.notifyErrorValidation(err)
            })
        },

        massIgnoreVersion() {
            window.axios({
                method: 'patch',
                url: `${this.api}/versions`,
                params: this.apiParams,
                data: {
                    items: this.selected.filter(s => s.update_available).map(p => p.id),
                    all: this.selectAll
                }
            })
            .then(response => {
                this.notifySuccess('Succès', 'Élements ignorés avec succès !')
                this.unselectLines();
                this.loadItems();
            })
            .catch((err) => {
                this.notifyErrorValidation(err)
            })
        },

        massDelete() {
            this.$swal({
                title: `Êtes-vous sûr de vouloir supprimer les éléments sélectionnés ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    window.axios({
                        method: 'delete',
                        url: `${this.api}/multiple`,
                        params: this.apiParams,
                        data: {
                            items: this.selected.map(p => p.id),
                            all: this.selectAll
                        }
                    })
                    .then(response => {
                        this.notifySuccess('Succès', 'Élements supprimés avec succès !')
                        this.unselectLines();
                        this.loadItems();
                    })
                    .catch((err) => {
                        this.notifyErrorValidation(err)
                    })
                }
            });
        },

        // getAllOccupationSkillIds(){

        //     let params = this.apiParams;
        //         params.items = this.selected.map(p => p.id);
        //         params.all = this.selectAll;

        //     return window.axios({
        //             method: 'get',
        //             url: `${this.api}/ids-selection`,
        //             params: params
        //         })
        //         .catch((err) => {
        //             this.notifyErrorValidation(err)
        //         })
        // },

        massCreateJob() {
            // if (this.selectAll) {
            //     this.getAllOccupationSkillIds().then(response => {
            //         const ids = response.data.join(',');
            //         document.location.href = `${document.location.origin}/rh/referentiel/postes?create=true&os=${ids}`;
            //     })
            // } else {
                const ids = this.selected.map(s => s.id).join(',');
                document.location.href = `${document.location.origin}/rh/referentiel/postes?create=true&os=${ids}`;
            //}
        },

        massCreateOccupation() {
            // if (this.selectAll) {
            //     this.getAllOccupationSkillIds().then(response => {
            //         const ids = response.data.join(',');
            //         document.location.href = `${document.location.origin}/rh/referentiel/metiers?create=true&os=${ids}`;
            //     })
            // } else {
                const ids = this.selected.map(s => s.id).join(',');
                document.location.href = `${document.location.origin}/rh/referentiel/metiers?create=true&os=${ids}`;
            //}
        },

        massUnbind() {
            this.$swal({
                title: `Êtes-vous sûr de vouloir délier les éléments sélectionnés ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    this.selected.forEach(s => {
                        this.removeReferentialOccupationSkill(s, true);
                    })
                    this.unselectLines();
                }
            });
        },

        storeResource() {
            let data = JSON.parse(JSON.stringify(this.resource));
            data.sector = data.sector.id;

            if (data.establishment) {
                data.establishment_id = data.establishment.id
            }

            if (data.id) {
                this.apiUpdate(
                    data,
                    this.resource.id,
                    resp => this.loadItems(),
                    err => (this.showPopup = true)
                );
            } else {
                this.apiCreate(
                    data,
                    resp => this.loadItems(),
                    err => (this.showPopup = true)
                );
            }
        },

        addResourcesExpress() {
            const hasOrphan = this.resourcesExpress.filter(r => r.admin_occupation_skill_id == null).length > 0;

            if (hasOrphan) {
                this.$swal({
                    title: `En l'absence de source, le pourcentage d'accessibilité risque d'être affecté`,
                    text: '',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Valider',
                    cancelButtonText: 'Annuler'
                }).then(async result => {
                    if (result.value) {
                        this.storeResourceExpress();
                    }
                });
            } else {
                this.storeResourceExpress()
            }
        },

        storeResourceExpress() {
            window.axios({
                method: 'post',
                url: '/api/referentiel/occupation-skills/express',
                data: {occupation_skills: this.resourcesExpress},
            })
            .then(response => {
                let url;

                if (response.status === 200 && response.data != '') {
                    this.notifySuccess('Succès', 'Élements ajoutés avec succès !')
                } else {
                    this.notifyError('Erreur', `Une erreur est survenue lors de la création`)
                }

                if (this.expressCreateMode == 'os-and-occupation') {
                    url = `${document.location.origin}/rh/referentiel/metiers?create=true&os=${response.data.map(i => i.id).join(',')}`;
                    document.location.href = url;
                }else if (this.expressCreateMode == 'os-and-job') {
                    url = `${document.location.origin}/rh/referentiel/postes?create=true&os=${response.data.map(i => i.id).join(',')}`;
                    document.location.href = url;
                } else {
                    this.loadItems();
                }

            })
            .catch((err) => {
                this.showPopupExpress = true
                this.notifyErrorValidation(err)
            })
        },

        duplicateResource(resource) {
            this.apiDuplicate(resource.id, () => this.loadItems());
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = window._.cloneDeep(data);

        },

        resetResource() {
            this.resource = {
                title: null,
                description: null,
                sector: null,
                establishment: null,
                skills: {},
                photo: null,
                level: {
                    level_0: this.occSkillDescriptionById(1),
                    level_1: this.occSkillDescriptionById(2),
                    level_2: this.occSkillDescriptionById(3),
                    level_3: this.occSkillDescriptionById(4),
                }
            };
        },

        resetResourceExpress() {
            this.resourcesExpress = [];
        },

        setFormError(value) {
            this.formError = value;
        },

        setFormExpressError(value) {
            this.formExpressError = value;
        },

        loadSkills() {
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/skills",
                    params: { perpage: 9999, include: ['admin_skill.category'] }
                })
                .then(response => {
                    this.skills = response.data.data;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        occupationskillById(id) {
            return this.skills.find(el => el.id == id);
        },

        adminoccupationskillById(id) {
            return this.skills.find(el => el.admin_skill_id == adminSkillId)
        },

        loadAdminOccupationSkills() {
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills/referential",
                    params: { perpage: 9999, include: ['sector'] }
                })
                .then(response => {
                    this.adminOccupationSkills.data = this.groupForMultiselect(response.data.data, ['sector.title'], 'title');
                    this.adminOccupationSkills.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async exportAsPDF() {
            if (!this.selected.length) {
                this.notifyWarning(
                    "ATTENTION !",
                    "Sélectionnez au moins un Savoir-Faire"
                );
                return;
            }

            const selectedIds = this.selected.map(el => el.id);
            const all = selectedIds.length == this.items.data.length ? 1 : 0;

            let url = '/rh/referentiel/savoir-faire/export-pdf?';

            for (var uid in selectedIds) {
                url += encodeURIComponent('occupation_skills[]') + '=' + selectedIds[uid] + '&';
            }
            url+= 'all=' + all;

            window.open(url);
        },

        referentialOccupationSkillChangeHandle(item, adminOccupationSkills) {

            // Timeout pour laisser le temps au Multiselect de se fermer et de ne pas se rouvrir après le processus popup
            setTimeout(() => {
                if (adminOccupationSkills) {
                    let erase = true;
                    if (item.skills.length > 0) {

                            this.$swal({
                                title: `Souhaitez-vous écraser les compétences déjà appelées ?`,
                                text: '',
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Oui',
                                cancelButtonText: 'Non'
                            }).then(async result => {
                                erase = result.value ? true : false;
                                this.storeReferentialOccupationSkill(item, adminOccupationSkills, erase);
                            });

                    } else {
                        this.storeReferentialOccupationSkill(item, adminOccupationSkills, erase);
                    }
                } else {
                    this.$swal({
                        title: `Souhaitez-vous conserver les compétences appelées ?`,
                        text: '',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Oui',
                        cancelButtonText: 'Non'
                    }).then(async result => {
                        const preserve = result.value ? true : false;
                        this.removeReferentialOccupationSkill(item, preserve);
                    });
                }
            }, 100)
        },

        storeReferentialOccupationSkill(item, adminOccupationSkills, erase = false) {
            window.axios({
                method: 'patch',
                url: `${this.api}/${item.id}/parent`,
                params: this.apiParams,
                data: {
                    parent_id: adminOccupationSkills.id,
                    erase: erase
                }
            })
            .then(response => {
                this.notifySuccess('Succès', 'Élements mis à jour avec succès !')
                this.loadItems();
            })
            .catch((err) => {
                this.notifyErrorValidation(err)
            })
        },

        removeReferentialOccupationSkill(item, preserve) {
            window.axios({
                method: 'delete',
                url: `${this.api}/${item.id}/parent`,
                params: this.apiParams,
                data: {
                    preserve: preserve
                }
            })
            .then(response => {
                this.notifySuccess('Succès', 'Élements mis à jour avec succès !')
                this.loadItems();
            })
            .catch((err) => {
                this.notifyErrorValidation(err)
            })
        }

    },

    created() {
        this.$vs.loading();

        this.apiParams.include = [
            "sector",
            "establishment",
            "jobs.service.establishment",
            "skills",
            "level"
        ];
        this.apiParams.append = [
            'update_available'
        ];
        this.apiParams.sortBy = {updated_at: "desc"}
        this.resetResource();
        this.loadItems();
        this.loadSkills();
        this.loadAdminOccupationSkills();

        if (this.isASuperAdmin()) {
            this.massActionList.push({id: 6, label: 'Délier', action: 'massUnbind', type: 'change'})
        }
    }
};
</script>

<style scoped>
/* Bug sur le multiselect sans cette règle
.vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover,
.vs-table-primary .is-selected {
    transform: initial !important;
} */

.vs-dialog {
    max-width: 90vw !important;
}
::v-deep.vs-con-table .vs-con-tbody {
    min-height: 300px;
}

</style>

<style>
.select-referentiel-field .multiselect__single {
    font-size: 11px !important;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    letter-spacing: 0.11px;
    color: rgb(98, 98, 98);
}

.select-referentiel-field .multiselect__placeholder {
    opacity: 0.5;
}
</style>
