var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-prompt" },
    [
      _c(
        "vs-prompt",
        _vm._g(
          _vm._b({ ref: "vsPrompt" }, "vs-prompt", _vm.$attrs, false),
          _vm.$listeners
        ),
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }