<template>
    <div
        :class="[
            { 'vs-sidebar-group-open': isOpen },
            { 'disabled-item pointer-events-none': isDisabled }
        ]"
        class="vs-sidebar-group ml-2"
    >
        <div class="group-header w-full" @click="clickGroup" :title="title">
            <span class="flex items-center w-full">
                <feather-icon v-if="!requiredPlan || planAllow(requiredPlan)" :icon="icon" />
                <CrownIcon v-else style="width: 20px; margin-right: 14px"/>
                <span class="truncate mr-3 select-none">{{ name }}</span>
            </span>
            <feather-icon
                icon="ChevronRightIcon"
                svg-classes="w-4 h-4"
                :class="[{ rotate90: isOpen }, 'feather-grp-header-arrow']"
            />
            <span class="vs-sidebar--tooltip">{{ name }}</span>
        </div>
        <ul ref="items" :style="styleItems" class="vs-sidebar-group-items">
            <li v-for="(item, index) in this.$slots.default" :key="index">
                <div
                    v-if="item.tag"
                    :class="
                        isActiveItem(item)
                            ? 'vs-sidebar--item vs-sidebar-item-active'
                            : 'vs-sidebar--item'
                    "
                    :title="item.data.attrs.title"
                    style="position: relative"
                >
                    <a :href="item.data.attrs.href">
                        <feather-icon 
                            v-if="item.data.attrs.icon" 
                            :icon="item.data.attrs.icon" 
                            class="" 
                        />
                        <vs-icon 
                            v-if="item.data.attrs['required-plan'] && !planAllow(item.data.attrs['required-plan'])" 
                            class="menu-bullet" 
                            bg="#FF9B3A"
                            round 
                        >
                            <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                        </vs-icon>
                        <span
                            class="truncate"
                            v-html="item.data.attrs.label"
                        ></span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import CrownIcon from '@components/svg/CrownIcon';
export default {
    name: "MenuGroup",
    components: { CrownIcon },
    props: {
        title: {
            default: null,
            type: String
        },
        icon: {
            type: String,
            require: false
        },
        name: {
            type: String,
            require: true
        },
        baseIndex: {
            type: String,
            require: true
        },
        requiredPlan: {
            type: String,
            require: false
        }
    },
    data: () => ({
        maxHeight: "0px",
        openItems: false,
        groupIndex: 1,
        sidebarItemsMin: 0,
        isOpen: false,
        isDisabled: false // TODO - Cablage avec permission
    }),
    computed: {
        styleItems() {
            return {
                maxHeight: this.maxHeight
            };
        },
        isItemActive(test) {
            console.log(test);
        }
    },
    mounted() {
        this.isOpen = this.isActive();

        if (this.isOpen) {
            this.maxHeight = "none";
        }
    },
    methods: {
        currentUri: function() {
            return document.location.pathname;
        },
        isActive: function() {
            let regex = new RegExp(
                this.baseIndex.replaceAll("/", "\\/").replaceAll("*", ".*")
            );

            let active = this.currentUri().match(regex) != null;

            return active;
        },
        isActiveItem: function(item) {
            return item.data.attrs.href == this.currentUri();
        },
        clickGroup: function() {
            let thisScrollHeight = this.$refs.items.scrollHeight;
            if (this.maxHeight == "0px") {
                this.maxHeight = `${thisScrollHeight}px`;
                setTimeout(() => {
                    this.maxHeight = "initial";
                }, 300);
                this.isOpen = true;
            } else {
                this.maxHeight = `${thisScrollHeight}px`;
                setTimeout(() => {
                    this.maxHeight = `${0}px`;
                }, 50);
                this.isOpen = false;
            }
        }
    }
};
</script>

<style lang="scss">
.feather-sousgroupe {
    .feather-user {
        color: #972f5f !important;
    }
    .feather-bookmark {
        color: #972f5f !important;
    }
    .feather-box {
        color: #972f5f !important;
    }
}

@import "../../../sass/vuesax/components/vxSidebarGroup.scss";
</style>
