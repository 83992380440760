<template>
    <div>
        <vs-alert active="true" color="warning">
            <vs-row>
                <vs-col vs-w="2">
                    <vs-icon 
                            v-if="!planAllow('indicateurs-activite')" 
                            bg="#FF9B3A"
                            round 
                        >
                            <CrownIcon style="width: 56px; height: 56px; padding: 10px;"/>
                        </vs-icon>
                </vs-col>

                <vs-col vs-w="10" class="py-4">
                    Votre formule <b>{{ planName() }}</b> n'inclut pas ce module. <br>
                    Veuillez contacter votre administrateur
                </vs-col>
            </vs-row>
        </vs-alert>
    </div>
</template>

<script>
// import LimitatedPlan from '@components/divers/LimitatedPlan';

import CrownIcon from '@components/svg/CrownIcon';
export default {
    components: {
        CrownIcon
    },
    data() {
        return {
            showPopup: true
        }
    }
}
</script>