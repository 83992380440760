<template>
    <div class="dialog-prompt" >
        <vs-prompt v-bind="$attrs" v-on="$listeners" ref="vsPrompt">
            <slot></slot>
        </vs-prompt>
    </div>
</template>

<script>
export default {
    name: 'DialogPrompt',
    inheritAttrs: false,
    props: {
        preventOutsideClick: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        '$attrs.active': {
            immediate: true,
            handler(newValue) {
                if (newValue === true) {
                    
                    this.$nextTick(() => {
                        this.neutralizeOverlay()
                    })
                }
            }
        }
    },
    methods: {
        neutralizeOverlay() {
            if (this.preventOutsideClick) {
                const promptEl = this.$refs.vsPrompt?.$el
                if (!promptEl) {
                    return
                }

                const overlay = promptEl.querySelector('.vs-dialog-dark')
                if (overlay) {
                    const clone = overlay.cloneNode(true)
                    
                    // Rupture de l'écoute sur l'overlay
                    overlay.parentNode.replaceChild(clone, overlay)
                }
            }
        }
    }
}
</script>