//import { mapState, mapGetters } from "vuex";
import hexToRgba from "hex-to-rgba";
import randomColor from "randomcolor";
import { formatDate } from '@assets/utils/helpers/helpers.js'

let padArray = (length, item, value) => [
    ...value,
    ...Array(length - value.length > 0 ? length - value.length : 0).fill(item)
];

let ratingChartsHelper = {
    created() {
        if (this.skills && !this.skills.length) {
            console.log('Load skills in ratingChartsHelper ... :-(')
            this.loadSkills()
        }
        if (this.colors && !this.colors.length) {
            console.log('Load colors in ratingChartsHelper ... :-(')
            this.loadColors()
        }
    },
    computed: {
        listColorsJob() {
            let sortedValues = this.colors;
            let listColors = [];
            sortedValues.forEach(color => {
                listColors.push(color.rgb);
            });
            return listColors;
        }
    },

    methods: {
        async loadColors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/settings/colors",
                    params: { perpage: 9999 }
                })
                .then(response => {
                    this.colors = response.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadSkills() {
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/skills",
                    params: { perpage: 9999, include: [] }
                })
                .then(response => {
                    this.skills = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        formatDate(input) {
            if (input == null) {
                return null;
            } else {
                var datePart = input.match(/\d+/g),
                    year = datePart[0],
                    month = datePart[1],
                    day = datePart[2];
                return day + "-" + month + "-" + year;
            }
        },
        round(value, decimals) {
            return Number(value.toFixed(decimals));
        },

        showedSkills(ratings, employee, current = true, wishlist = true, isPDF = false) {
            //For PDF take the job/occupations associated to the considered rating.
            // If old rating (before "Old ratings" feature), still take jobs and occupations from employee (current jobs/occupations)
            const jobs = !!isPDF && ratings[0]?.jobs.length ? ratings[0].jobs : employee.current_jobs
            const occupations = !!isPDF && ratings[0]?.occupations.length ? ratings[0].occupations : employee.current_occupations

            let skills = [];

            if (!current && !wishlist) {
                this.skills.slice().forEach(skill => {
                    this.skillCheckLevelAndPush(skill, skills);
                });
            }

            if (wishlist) {
                ratings.forEach(rating => {
                    if (rating.wishlist_occupations) {
                        rating.wishlist_occupations.forEach(occupation => {
                            if (occupation.admin_id) {
                                occupation.admin_occupation_skills.forEach(
                                    occupationSkill => {
                                        occupationSkill.adminskills.forEach(
                                            skill => {
                                                this.skillCheckLevelAndPush(skill, skills, true);
                                            }
                                        );
                                    }
                                );
                            } else {
                                occupation.occupation_skills.forEach(
                                    occupationSkill => {
                                        occupationSkill.skills.forEach(
                                            skill => {
                                                this.skillCheckLevelAndPush(skill, skills, false);
                                            }
                                        );
                                    }
                                );
                            }
                        });
                    }

                    if (rating.wishlist_jobs) {
                        rating.wishlist_jobs.forEach(j => {
                            j.occupation_skills.forEach(o => {
                                o.skills.forEach(skill => {
                                    this.skillCheckLevelAndPush(skill, skills);
                                });
                            });
                        });
                    }
                });
            }

            if (current) {
                occupations.forEach(o => {
                    if (!o) return;

                    if (o.admin_id) {
                        o.admin_occupation_skills.forEach(aos => {
                            aos.adminskills.forEach(skill => {
                                this.skillCheckLevelAndPush(
                                    skill,
                                    skills,
                                    true
                                );
                            });
                        });
                    } else {
                        o.occupation_skills.forEach(os => {
                            os.skills.forEach(skill => {
                                this.skillCheckLevelAndPush(
                                    skill,
                                    skills,
                                    false
                                );
                            });
                        });
                    }
                });

                jobs.forEach(j => {
                    if (!j) return;

                    j.occupation_skills.forEach(o => {
                        o.skills.forEach(skill => {
                            this.skillCheckLevelAndPush(skill, skills);
                        });
                    });
                });

                //If no Jobs/Occupations let's consider all skills
                if (!occupations.length && !jobs.length) {
                    this.skills.forEach(skill => {
                        this.skillCheckLevelAndPush(skill, skills);
                    });
                }

                // TODO - Ajouter les skills évalués mais non pris en compte par "occupations" et "jobs" (page /rh/gestion/personnes/{id}/bilans)
                // skills.*.pivot.level > 0 (Depuis employee.employee_ratings.skills)
                // Ajout de tous les skills qui ne sont pas encore dans la liste mais qui ont une note > 0
            }

            skills.map(s => {
                s.category_title = s.admin_skill.category
                    ? s.admin_skill.category.title
                    : "";
                s.title = s.admin_skill ? s.admin_skill.title : "";
            });

            skills.sort(this.compare);

            return skills;
        },

        employeeAccessibility: function(rating, entity, type = "job", onlyOccSkills = false) {
            if (!rating) return [1, false];

            var skills = [];
            var occSkills = [];

            switch (type) {
                case "jobOffer": {
                    if (entity.transversal_skills.length) {
                        const jobSkills = entity.transversal_skills.slice();
                        jobSkills.forEach(skill => {
                            this.skillCheckLevelAndPush(skill, skills);
                        });
                    } else if (entity.occupation_skills.length) {
                        const occupation_skills = entity.occupation_skills.slice();
                        occupation_skills.forEach(os => {
                            os.skills.forEach(skill => {
                                this.skillCheckLevelAndPush(skill, skills);
                            });
                        });
                    } else {
                        //TODO
                    }
                    break;
                }
                case "job": {
                    let occupation_skills = entity.occupation_skills.slice();
                    occupation_skills.forEach(o => {
                        if (onlyOccSkills) {
                            this.occSkillCheckLevelAndPushWithCoef(rating, o, occSkills);
                        } else {
                            o.skills.forEach(skill => {
                                this.skillCheckLevelAndPush(skill, skills);
                            });
                        }
                    });
                    break;
                }
                case "admin_occupation":
                case "occupation": {
                    if (entity.admin_id) {
                        
                        let occupation_skills = entity.admin_occupation_skills.slice();
                        occupation_skills.forEach(o => {
                            if (onlyOccSkills) {
                                this.occSkillCheckLevelAndPushWithCoef(rating, o, occSkills);
                            } else {
                                o.adminskills.forEach(skill => {
                                    this.skillCheckLevelAndPush(
                                        skill,
                                        skills,
                                        true
                                    );
                                });
                            }
                        });
                    } else {
                        let occupation_skills = entity.occupation_skills.slice();
                        occupation_skills.forEach(o => {
                            if (onlyOccSkills) {
                                this.occSkillCheckLevelAndPushWithCoef(rating, o, occSkills);
                            } else {
                                o.skills.forEach(skill => {
                                    this.skillCheckLevelAndPush(
                                        skill,
                                        skills,
                                        false
                                    );
                                });
                            }
                        });
                    }
                    break;
                }
                case "admin_occupation_skill": {
                    entity.adminskills.forEach(skill => {
                        this.skillCheckLevelAndPush(skill, skills, true);
                    });
                    break;
                }
                case "occupation_skill": {
                    entity.skills.forEach(skill => {
                        this.skillCheckLevelAndPush(skill, skills, skill.resource == 'AdminSkill');
                    });
                    break;
                }
                default:
                    return NaN;
            }
            
            
            
            skills.sort(this.compare);

            let sum = 0;
            let sumCoef = 0;
            let check = true;
            
            // Eval uniquement sur les savoir-faire
            if (onlyOccSkills) {
                
                // Somme de tous les éléments du tableau
                occSkills.forEach(num => {
                    sum += num;
                })

                // Total d'élément x le coef de l'autonomie x la note de l'autonomie
                const autonomyLevel = this.occSkillLevelByNote(3)
                sumCoef = occSkills.length * (autonomyLevel.note * autonomyLevel.coef);
                check = false;
                
            } else {

                for (let skill of skills) {
                    //Quelques skills dans les métiers n'ont pas de titre de chargé
                    if (!skill.title) {
                        skill.title =
                            skill.admin_skill_id && skill.admin_skill
                                ? skill.admin_skill.title
                                : "N/A";
                    }
    
                    const ratedSkill = rating.skills.find(
                        s => s.admin_skill_id === skill.admin_skill_id
                    );
                    if (ratedSkill && this.levelById(skill.pivot.required_level)) {
                        var employeeHasSkill =
                            this.noteById(
                                ratedSkill.pivot
                                    ? ratedSkill.pivot.level
                                    : ratedSkill.level
                            ) >= this.levelById(skill.pivot.required_level).note;
                        sum += employeeHasSkill
                            ? this.levelById(skill.pivot.required_level).coef
                            : 0;
                        sumCoef += this.levelById(skill.pivot.required_level).coef;
                        if (skill.pivot.required_level >= 2 && !employeeHasSkill) {
                            check = false;
                        }
                    } else {
                        if (this.levelById(skill.pivot.required_level)) {
                            sumCoef = sumCoef += this.levelById(
                                skill.pivot.required_level
                            ).coef;
                        }
                        if (skill.pivot.required_level >= 2) {
                            check = false;
                        }
                    }
                }
            }

            return [this.round(sumCoef ? sum / sumCoef : 1, 2), check];
        },

        employeeAccessibilityString(rating, entity, type) {
            let result = this.employeeAccessibility(rating, entity, type);
            return (
                this.round(result[0] * 100, 0) + "% " + (result[1] ? "✓" : "")
            );
        },

        employeeAccessibilityGenerateDatasets(
            ratings,
            skills,
            label,
            entity,
            type,
            scorePerPlot,
            colors = false,
            selfRating = false
        ) {
            if (!Array.isArray(ratings)) ratings = [ratings];

            let charts = [];
            if (selfRating) {
                charts.push({
                    label: `Auto-évaluation`,
                    fill: true,
                    pointBorderColor: "#fff",
                    //...this.getRatingColors(0),
                    ...this.getRatingColorsBySlug('auto-eval-pp-0'),
                    data: padArray(
                        5,
                        0,
                        skills.map(s => {
                            if (!s.title) {
                                s.title = s.admin_skill ? s.admin_skill.title : "N/A";
                            }
                            const ratedSkill = selfRating.self_skills.find(
                                skill =>
                                    skill.admin_skill_id === s.admin_skill_id
                            );
                            return ratedSkill
                                ? this.noteById(
                                      ratedSkill.pivot
                                          ? ratedSkill.pivot.level
                                          : ratedSkill.level
                                  )
                                : this.ratingSettings.NonMesuredLevel;
                        })
                    ),
                    levels: padArray(
                        5,
                        0,
                        skills.map(s => {
                            if (!s.title) {
                                s.title = s.title = s.admin_skill ? s.admin_skill.title : "N/A";
                            }
                            const ratedSkill = selfRating.self_skills.find(
                                skill =>
                                    skill.admin_skill ? skill.admin_skill.title === s.title : false
                            );
                            return ratedSkill
                                ? this.levelLabelById(
                                      ratedSkill.pivot
                                          ? ratedSkill.pivot.level
                                          : ratedSkill.level
                                  )
                                : "Non mesuré";
                        })
                    ),
                    order: 1
                });
            }

            let colorIndice = {};

            let skillsGoalsData = [];

            ratings.forEach((rating, idx) => {
                let score = "";
                if (scorePerPlot && entity && type) {
                    score =
                        " : " +
                        this.employeeAccessibilityString(rating, entity, type);
                }

                if (!label) {
                    var prefix = this.getLabelFromRatingType(rating);
                    var color = this.getColorFromRatingType(rating, colorIndice);
                }

                let ratingColor = !colors ? color : colors[rating.id];

                // Si il y a des objectifs de compétences
                if (rating.skills_goals.length > 0) {
                    skillsGoalsData.push({
                        rating: rating,
                        values: padArray(
                            5,
                            0,
                            skills.map(s => {
                                if (!s.title) {
                                    s.title = s.title = s.admin_skill ? s.admin_skill.title : "N/A";
                                }
                                const ratedSkill = rating.skills_goals.find(
                                    skill => skill.admin_skill_id === s.admin_skill_id
                                );
                                return ratedSkill
                                    ? ratedSkill.pivot ? ratedSkill.pivot.level : ratedSkill.level
                                    : this.ratingSettings.NonMesuredLevel;
                            })
                        ),
                    });
                }

                charts.push({
                    label: label || `${prefix} du ${this.formatDate(rating.rating_date) + score}`,
                    fill: true,
                    pointBorderColor: "#fff",
                    ...ratingColor,
                    data: padArray(
                        5,
                        0,
                        skills.map(s => {
                            if (!s.title) {
                                s.title = s.title = s.admin_skill ? s.admin_skill.title : "N/A";
                            }
                            const ratedSkill = rating.skills.find(
                                skill =>
                                    skill.admin_skill_id === s.admin_skill_id
                            );
                            return ratedSkill
                                ? this.noteById(
                                      ratedSkill.pivot
                                          ? ratedSkill.pivot.level
                                          : ratedSkill.level
                                  )
                                : this.ratingSettings.NonMesuredLevel;
                        })
                    ),
                    levels: padArray(
                        5,
                        0,
                        skills.map(s => {
                            if (!s.title) {
                                s.title = s.title = s.admin_skill ? s.admin_skill.title : "N/A";
                            }
                            const ratedSkill = rating.skills.find(skill =>
                                skill.admin_skill ? skill.admin_skill.title === s.title : false
                            );
                            return ratedSkill
                                ? this.levelLabelById(
                                      ratedSkill.pivot
                                          ? ratedSkill.pivot.level
                                          : ratedSkill.level
                                  )
                                : "Non mesuré";
                        })
                    )
                });
            });

            let skillColor = this.getColorBySlug('skills').rgb;

            charts.push({
                label: `Compétence appelée`,
                fill: true,
                backgroundColor: hexToRgba(skillColor, "0.2"),
                borderColor: hexToRgba(skillColor),
                pointBackgroundColor: hexToRgba(skillColor, "0.2"),
                data: padArray(
                    5,
                    0,
                    skills.map(s => {
                        return this.levelById(s.pivot.required_level)
                            ? this.levelById(s.pivot.required_level).note
                            : 0;
                    })
                ),
                levels: padArray(
                    5,
                    0,
                    skills.map(s => {
                        return this.levelById(s.pivot.required_level)
                            ? this.levelById(s.pivot.required_level).label
                            : "Non travaillé";
                    })
                ),
                pointBorderWidth: padArray(
                    5,
                    0,

                    skills.map(s => {
                        return this.levelById(s.pivot.required_level).id >= 2
                            ? 5
                            : 1;
                    })
                ),
                pointBorderColor: padArray(
                    5,
                    0,

                    skills.map(s => {
                        return this.levelById(s.pivot.required_level).id >= 2
                            ? hexToRgba(this.getColorBySlug('note').rgb, "0.5")
                            : "#fff";
                    })
                )
            });

            // Traitement des objectifs de compétences
            const NonMesuredLevel = 0;
            if (skillsGoalsData.length > 0) {
                let goalsColor = this.getColorBySlug('goals') ? this.getColorBySlug('goals').rgb : "#4377df";

                skillsGoalsData.forEach((data) => {
                    charts.push({
                        label: `Objectifs (${formatDate(data.rating.rating_date)})`,
                        backgroundColor: hexToRgba(goalsColor, "0.4"),
                        pointBorderColor: hexToRgba(goalsColor, "0.7"),
                        borderColor: hexToRgba(goalsColor, "0.4"),
                        pointBorderWidth: 5,
                        borderWidth: 0,
                        fill: false,
                        data: data.values,
                        showLines: false,
                        pointRadius: data.values.map((v) => (v > NonMesuredLevel ? 5 : 0)),
                        levels: data.values.map((levelNote) => this.levelLabelByNote(levelNote)),
                    });
                });
            }

            return charts;
        },

        getLabelFromRatingType(rating) {
            let label;

            if (rating.status === 2 ) {
                label = 'Auto-évaluation Portail';
            } else if (rating.status === 3) {
                label = rating.eval_type;
            } else if (rating.skill_review) {
                label = this.skillRatingNames.singular;
            } else {
                label = this.projectNames.singular;
            }

            return label;
        },

        getColorFromRatingType(rating, colorIndice) {
            let color;

            if (rating.status === 2 ) {
                if (!colorIndice['auto-eval-mobility']) {
                    colorIndice['auto-eval-mobility'] = 0;
                }
                color = this.getRatingColorsBySlug(`auto-eval-mobility-${colorIndice['auto-eval-mobility']}`);
                colorIndice['auto-eval-mobility']++;
            } else if (rating.status === 3) {
                if (!colorIndice['eval-tiers']) {
                    colorIndice['eval-tiers'] = 0;
                }
                color = this.getRatingColorsBySlug(`eval-tiers-${colorIndice['eval-tiers']}`);
                colorIndice['eval-tiers']++;
            } else if (rating.skill_review) {
                if (!colorIndice['skill-review']) {
                    colorIndice['skill-review'] = 0;
                }
                color = this.getRatingColorsBySlug(`skill-review-${colorIndice['skill-review']}`);
                colorIndice['skill-review']++;
            } else {
                if (!colorIndice['pp']) {
                    colorIndice['pp'] = 0;
                }
                color = this.getRatingColorsBySlug(`pp-${colorIndice['pp']}`);
                colorIndice['pp']++;
            }

            return color;
        },

        employeeAccessibilityGenerateDatasetsOfOccupationSkills(
            ratings,
            occSkills,
            label,
            entity,
            type,
            scorePerPlot,
            colors,
            autoRating
        ) {
            if (!Array.isArray(ratings)) ratings = [ratings];

            let charts = [];
            let occupationSkillsGoalsData = [];

            let pEntityId =
                type === "job"
                    ? "job_id"
                    : type === "occupation"
                    ? "occupation_id"
                    : "admin_occupation_id";
            let ratingProperty =
                type === "admin_occupation"
                    ? "admin_occupation_skills"
                    : "occupations_skills";
            let ratingSelfProperty = type === "admin_occupation"
                    ? "self_admin_occupation_skills"
                    : "self_occupation_skills";


            let osPropertyId =
                type === "admin_occupation"
                    ? "admin_occupation_skill_id"
                    : "id";

            let colorIndice = {};

            ratings.forEach((rating, idx) => {

                var color = this.getColorFromRatingType(rating, colorIndice);

                if (!label) {
                    var prefix = this.getLabelFromRatingType(rating);
                }

                let ratingColor = !colors ? color : colors[rating.id];

                charts.push({
                    label:
                        label || `${prefix} du ${this.formatDate(rating.rating_date)}`,
                    fill: true,
                    pointBorderColor: "#fff",
                    ...ratingColor,
                    data: padArray(
                        5,
                        0,
                        occSkills.map(occSkill => {
                            // S'il y a des objectifs de savoir faire
                            if (type !== "admin_occupation") {
                                if (rating.occupation_skills_goals && rating.occupation_skills_goals.length > 0) {
                                    let occupationSkillGoal = rating.occupation_skills_goals.find(osg => osg.id == occSkill.id);
                                    occupationSkillsGoalsData.push(occupationSkillGoal ? occupationSkillGoal.pivot.level : null);
                                }
                            }

                            // S'il y a des objectifs de savoir faire centraux
                            if (type === "admin_occupation") {
                                if (rating.admin_occupation_skills_goals && rating.admin_occupation_skills_goals.length > 0) {
                                    let adminOccupationSkillGoal = rating.admin_occupation_skills_goals.find(osg => osg.id == occSkill.id);
                                    occupationSkillsGoalsData.push(adminOccupationSkillGoal ? adminOccupationSkillGoal.pivot.level : null);
                                }
                            }

                            const ratedSkill = rating[ratingProperty].find(
                                os => os[osPropertyId] === occSkill.id
                            );
                            let ratedSkillPivot = ratedSkill?.pivots?.find(
                                p => p[pEntityId] === entity.id
                            );
                            if (type === "admin_occupation")
                                ratedSkillPivot = ratedSkill; //TODO: mais pour les adminOccSkills arrivent sans pivot ?

                            return ratedSkillPivot
                                ? this.noteById(ratedSkillPivot.level)
                                : this.ratingSettings.NonMesuredLevel;
                        })
                    ),
                    levels: padArray(
                        5,
                        0,
                        occSkills.map(occSkill => {
                            const ratedSkill = rating[ratingProperty].find(
                                os => os[osPropertyId] === occSkill.id
                            );
                            let ratedSkillPivot = ratedSkill?.pivots?.find(
                                p => p[pEntityId] === entity.id
                            );
                            if (type === "admin_occupation")
                                ratedSkillPivot = ratedSkill; //TODO: mais pour les adminOccSkills arrivent sans pivot ?

                            return ratedSkillPivot
                                ? this.levelLabelById(ratedSkillPivot.level)
                                : "Non mesuré";
                        })
                    )
                });

                // S'il au moins un objectif de savoir faire est dispo
                if (occupationSkillsGoalsData.filter(osgd => osgd != null).length > 0) {

                    charts.push({
                        label: `Objectifs (${formatDate(rating.rating_date)})`,
                        backgroundColor: hexToRgba(this.listColors[19] ? this.listColors[19] : '#4377df', "0.4"),
                        pointBorderColor: hexToRgba(this.listColors[19] ? this.listColors[19] : '#4377df', "0.7"),
                        borderColor: hexToRgba(this.listColors[19] ? this.listColors[19] : '#4377df', "0.4"),
                        pointBorderWidth: 5,
                        borderWidth: 0,
                        fill: false,
                        showLines: false,
                        pointRadius: occupationSkillsGoalsData.map(osg => (osg > this.ratingSettings.NonMesuredLevel ? 5 : 0)),
                        data: occupationSkillsGoalsData,
                        levels: occupationSkillsGoalsData.map(levelNote => this.levelLabelByNote(levelNote)),
                    })
                }

                if (autoRating) {
                    const levels = occSkills.map(occSkill => {
                        const ratedSkill = autoRating[ratingSelfProperty].find(
                            os => os[osPropertyId] === occSkill.id
                        );

                        let ratedSkillPivot = ratedSkill && ratedSkill.pivot ? ratedSkill.pivot : null;
                        
                        if (type === "admin_occupation") {
                            ratedSkillPivot = ratedSkill; //TODO: mais pour les adminOccSkills arrivent sans pivot ?
                        }

                        return ratedSkillPivot ? ratedSkillPivot.level : this.ratingSettings.NonMesuredLevel;
                    });

                    charts.push({
                        label: `Auto-évaluation SF`,
                        fill: true,
                        pointBorderColor: "#fff",
                        //...this.getRatingColors(0),
                        ...this.getRatingColorsBySlug('auto-eval-pp-0'),
                        data: padArray(
                            5,
                            0,
                            levels.map(l => this.noteById(l))
                        ),
                        levels: padArray(
                            5,
                            0,
                            levels.map(l => this.levelLabelById(l))
                        ),
                        order: 1,
                        status: 2,
                        skillReview: 0,
                    });
                }
            });

            return charts;
        },



        employeeAccessibilityChartData: function(
            rating,
            entity,
            type = "job",
            scorePerPlot = false,
            colors = false,
            selfRating = false
        ) {
            let skills = [];
            let label
            switch (type) {
                case "jobOffer": {
                    label = rating.skill_review ? this.skillRatingNames.singular : "Auto-évaluation Portail";
                    if (entity.transversal_skills.length) {
                        const jobSkills = entity.transversal_skills.slice();
                        jobSkills.forEach(skill => {
                            this.skillCheckLevelAndPush(skill, skills);
                        });
                    } else if (entity.occupation_skills.length) {
                        const occupation_skills = entity.occupation_skills.slice();
                        occupation_skills.forEach(os => {
                            os.skills.forEach(skill => {
                                this.skillCheckLevelAndPush(skill, skills);
                            });
                        });
                    } else {
                        //TODO
                    }

                    break;
                }
                case "job": {
                    const occupation_skills = entity.occupation_skills.slice();
                    occupation_skills.forEach(os => {
                        os.skills.forEach(skill => {
                            this.skillCheckLevelAndPush(skill, skills);
                        });
                    });
                    break;
                }
                case "occupation": {
                    if (entity.admin_id) {
                        entity.admin_occupation_skills.forEach(
                            occupation_skill => {
                                occupation_skill.adminskills.forEach(skill => {
                                    this.skillCheckLevelAndPush(
                                        skill,
                                        skills,
                                        true
                                    );
                                });
                            }
                        );
                    } else {
                        /*let occupation_skills = entity.occupation_skills.map(
                            os => this.occupationskillById(os.id)
                        );*/
                        entity.occupation_skills.forEach(occupation_skill => {
                            occupation_skill.skills.forEach(skill => {
                                this.skillCheckLevelAndPush(
                                    skill,
                                    skills,
                                    false
                                );
                            });
                        });
                    }
                    break;
                }
                case "occupation_skill": {
                    entity.skills.forEach(skill => {
                        // TODO - Revoir la modélisation, car il y a de l'AdminSkill dans les Skills
                        // Cas d'usage : TdB Personne -> choix d'une personne, choix d'un métier « centralisé », affichage des savoirs-faire
                        this.skillCheckLevelAndPush(skill, skills, skill.resource == 'AdminSkill');
                    });
                    break;
                }
                case "admin_occupation_skill": {
                    entity.adminskills.forEach(skill => {
                        this.skillCheckLevelAndPush(skill, skills, true);
                    });
                    break;
                }
                default:
                    return NaN;
            }
            skills.sort(this.compare);

            return {
                labels: padArray(
                    5,
                    "",
                    skills.map(s =>
                        s.admin_skill
                            ? s.admin_skill.title
                            : s.title
                            ? s.title
                            : "N/A"
                    )
                ),
                datasets: this.employeeAccessibilityGenerateDatasets(
                    rating,
                    skills,
                    label,
                    entity,
                    type,
                    scorePerPlot,
                    colors,
                    selfRating
                )
            };
        },
        employeeAccessibilityChartDataOfOccupationSkills: function(
            rating,
            entity,
            type = "job",
            scorePerPlot = false,
            colors = false,
            autoRating = null
        ) {
            let occupationSkills = [];
            let label;
            switch (type) {
                case "job":
                case "occupation": {
                    const occupation_skills = entity.occupation_skills.slice();
                    occupation_skills.forEach(os => {
                        this.occSkillCheckLevelAndPush(os, occupationSkills, false);
                    });
                    break;
                }
                case "admin_occupation": {
                    const occupation_skills = entity.admin_occupation_skills.slice();
                    occupation_skills.forEach(os => {
                        this.occSkillCheckLevelAndPush(
                            os,
                            occupationSkills,
                            true
                        );
                    });
                    break;
                }
                default:
                    return NaN;
            }
            occupationSkills.sort(); //TODO : define sort function

            return {
                labels: padArray(
                    5,
                    "",
                    occupationSkills.map(os => (os.title ? os.title : "N/A"))
                ),
                datasets: this.employeeAccessibilityGenerateDatasetsOfOccupationSkills(
                    rating,
                    occupationSkills,
                    label,
                    entity,
                    type,
                    scorePerPlot,
                    colors,
                    autoRating
                )
            };
        },

        compare(a, b) {
            if (
                a.admin_skill &&
                a.admin_skill.category &&
                b.admin_skill &&
                b.admin_skill.category
            ) {
                if (a.admin_skill.category.id > b.admin_skill.category.id)
                    return 1;
                else if (a.admin_skill.category.id < b.admin_skill.category.id)
                    return -1;
                else if (a.title && b.title)
                    return a.title.toUpperCase() > b.title.toUpperCase()
                        ? 1
                        : -1;
                else if (a.admin_skill.title && b.admin_skill.title)
                    return a.admin_skill.title.toUpperCase() >
                        b.admin_skill.title.toUpperCase()
                        ? 1
                        : -1;
                else return 0;
            }

            return 0;
        },

        skillCheckLevelAndPush(skill, skills, isAdminSkill = false) {
            skill = window._.cloneDeep(skill);
            skill.is_admin_skill = isAdminSkill === true;
            //We want adminSkill and skill to have same informations
            if (isAdminSkill) {
                skill.admin_skill_id = skill.id; // Petit problème de modélisation je crois...
                skill.admin_skill = skill;
            } else {
                if (!skill.admin_skill) {
                    //TODO : do something when no admin_skill (notify someone ...)
                    // if (!this.skillAlreadyNotify(skill.id)) {
                    //     this.$store.commit(
                    //         "ADD_SKILL_WITHOUT_ADMINSKILL",
                    //         skill
                    //     );
                    //     window.axios.post(
                    //         `/api/skills/${skill.id}/noAdminSkill`
                    //     );
                    // }
                    // skill.error = true;
                    console.error('WARNING: no admin_skill for skill #'+skill.id, skill)
                }
                skill.title = skill.admin_skill
                    ? skill.admin_skill.title
                    : "N/A";
                skill.category = skill.admin_skill
                    ? skill.admin_skill.category
                    : null;
            }

            if (!skill.error) {
                if (
                    !skills
                        .map(s => s.admin_skill_id)
                        .includes(skill.admin_skill_id)
                ) {
                    skills.push(skill);
                } else {
                    //Not only keep the not yet included skills but replace existing skills if required level is higher
                    let storedIndex = skills.findIndex(
                        s => s.admin_skill_id === skill.admin_skill_id
                    );
                    if (
                        skills[storedIndex].pivot.required_level <
                        skill.pivot?.required_level
                    ) {
                        skills[storedIndex].pivot = skill.pivot;
                    }
                }
            }
        },
        occSkillCheckLevelAndPush(occSkill, occSkills, isAdminOccSkill = false) {
            occSkill = window._.cloneDeep(occSkill);
            if (!occSkills.map(os => os.id).includes(occSkill.id)) {
                occSkills.push(occSkill);
            }
        },

        occSkillCheckLevelAndPushWithCoef(rating, occSkill, occSkillValues) {
            let ratingOccSkill = null;
            let ratingLevel = 0;
            if (occSkill.resource == 'OccupationSkill') {
                ratingOccSkill = rating.occupations_skills.find(os => os.id === occSkill.id)
                ratingLevel = ratingOccSkill?.pivots[0].level;
            } else {
                ratingOccSkill = rating.admin_occupation_skills.find(aos => aos.admin_occupation_skill_id === occSkill.id)
                ratingLevel = ratingOccSkill.level;
            }

            // Savoir-faire non évalué
            if (!ratingLevel) {
                occSkillValues.push(0);
                return;
            }

            const note = ratingLevel - 1;
            const level = this.occSkillLevelByNote(note)
            let coef = level.coef;

            if(!coef) {
                coef = 0;
            }

            // Ajoute la note associé au coef
            occSkillValues.push(level.note * coef);
        },

        // getRatingColors(idx) {
        //     let borderColor = "";
        //     let backgroundColor = "";
        //     if (idx < this.listColorsJob.slice(1, 16).length - 1) {
        //         backgroundColor = hexToRgba(
        //             this.listColorsJob.slice(1, 16)[idx],
        //             "0.2"
        //         );
        //         borderColor = hexToRgba(this.listColorsJob.slice(1, 16)[idx]);
        //     } else {
        //         const seed = this.$moment().unix();
        //         borderColor = randomColor({
        //             format: "rgba",
        //             alpha: 1,
        //             seed: seed
        //         });
        //         backgroundColor = randomColor({
        //             format: "rgba",
        //             alpha: 0.2,
        //             seed: seed
        //         });
        //     }
        //     return {
        //         backgroundColor: backgroundColor,
        //         borderColor: borderColor,
        //         pointBackgroundColor: backgroundColor
        //     };
        // },

        /**
         * Retourne une couleur depuis son slug
         * @param slug
         */
        getColorBySlug(slug) {
            return this.colors.find(c => c.slug == slug);
        },

        /**
         * Retourne une configuration de couleur à partir d'un slug de couleur
         * @param slug
         */
        getRatingColorsBySlug(slug) {
            let borderColor = null;
            let backgroundColor = null;
            const color = this.getColorBySlug(slug);

            if (color) {
                borderColor = hexToRgba(color.rgb);
                backgroundColor = hexToRgba(color.rgb, "0.2");
            } else {
                const seed = this.$moment().unix();
                borderColor = randomColor({
                    format: "rgba",
                    alpha: 1,
                    seed: seed,
                });
                backgroundColor = randomColor({
                    format: "rgba",
                    alpha: 0.2,
                    seed: seed,
                });
            }

            return {
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                pointBackgroundColor: backgroundColor,
            };
        },

        employeeEvaluationChartData(rating, evaluation, onlyOccSkills = false) {

            let dataEval = onlyOccSkills ? evaluation.occupation_skills : evaluation.skills
            dataEval.sort((a, b) => {
                if (a.category_id > b.category_id) {
                    return 1;
                } else if (a.category_id < b.category_id) {
                    return -1;
                } else {
                    return a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1
                }
            });

            let datasets = [];

            let colorEvaluation, nameEvaluation;

            const colorSkills = this.getColorBySlug('skills');

            if (rating.skill_review) {
                nameEvaluation = this.skillRatingNames.singular;
                colorEvaluation = this.getRatingColorsBySlug('skill-review-0');
            } else {
                nameEvaluation = this.projectNames.singular;
                colorEvaluation = this.getRatingColorsBySlug('pp-0');
            }

            datasets.push({
                label: `${nameEvaluation} du ${this.formatDate(rating.rating_date)}`,
                fill: true,
                pointBorderColor: "#fff",
                ...colorEvaluation,
                data: padArray(5, 0, dataEval.map(s => s.rated)),
                levels: dataEval.map(s => this.levelLabelByNote(s.rated))
            })

            if (!onlyOccSkills) {
                datasets.push({
                    label: `Compétence appelée`,
                    fill: true,
                    backgroundColor: hexToRgba(colorSkills.rgb, "0.2"),
                    borderColor: hexToRgba(colorSkills.rgb),
                    pointBackgroundColor: hexToRgba(colorSkills.rgb, "0.2"),
                    data: padArray(5, 0, dataEval.map(s => this.levelById(s.required_level).note)),
                    levels: padArray(5, 0, dataEval.map(s => this.levelById(s.required_level).label)),
                    pointBorderWidth: dataEval.map(s => this.levelById(s.required_level).id >= 2 ? 5 : 1),
                    pointBorderColor: dataEval.map(s => this.levelById(s.required_level).id >= 2 ? hexToRgba(this.getColorBySlug('note').rgb, "0.5") : "#fff")
                })
            }

            return {
                labels: padArray(5, '', dataEval.map(s => s.title)),
                datasets: datasets
            };
        },


    }
};

export default ratingChartsHelper;
