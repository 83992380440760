var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.resourceLoading
    ? _c(
        "div",
        { staticClass: "con-exemple-prompt" },
        [
          !_vm.isAdminInterface() && _vm.usersList
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/3 w-full" },
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.users,
                        name: "user",
                        label: "display_name",
                        placeholder: "Recherche ...",
                        "select-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        loading: _vm.usersLoading
                      },
                      model: {
                        value: _vm.resource.user,
                        callback: function($$v) {
                          _vm.$set(_vm.resource, "user", $$v)
                        },
                        expression: "resource.user"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/3 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "userLastName" },
                  model: {
                    value: _vm.resource.last_name,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "last_name", $$v)
                    },
                    expression: "resource.last_name"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/3 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "userFirstName" },
                  model: {
                    value: _vm.resource.first_name,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "first_name", $$v)
                    },
                    expression: "resource.first_name"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/3 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "userEmail",
                    type: "email",
                    "description-text": _vm.resource.sendEmail
                      ? "Un email d'invitation sera envoyé automatiquement à cette adresse."
                      : "",
                    danger: _vm.validationHasError(
                      _vm.formValidationErrors,
                      "email"
                    ),
                    "danger-text": _vm.displayValidationError(
                      _vm.formValidationErrors,
                      "email"
                    )
                  },
                  on: {
                    blur: function($event) {
                      return _vm.checkExistingEmailInDeleted(_vm.resource.email)
                    }
                  },
                  model: {
                    value: _vm.resource.email,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "email", $$v)
                    },
                    expression: "resource.email"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          (_vm.isASuperAdmin(_vm.user) || _vm.isAnAdmin(_vm.user)) &&
          !_vm.reduced
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(4),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/3 w-full" },
                  [
                    _c("vs-switch", {
                      attrs: { name: "sendEmail" },
                      model: {
                        value: _vm.resource.sendEmail,
                        callback: function($$v) {
                          _vm.$set(_vm.resource, "sendEmail", $$v)
                        },
                        expression: "resource.sendEmail"
                      }
                    }),
                    _vm._v(" "),
                    _vm._m(5)
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          (!_vm.isAdminInterface() &&
            !_vm.isAMonitor(_vm.user) &&
            _vm.can("update", "users") &&
            !_vm.isEvaluatorForm()) ||
          _vm.debug
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(6),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/3 w-full" },
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.rolesList,
                        name: "roles",
                        label: "display_name",
                        placeholder: "Recherche ...",
                        "select-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        loading: _vm.rolesLoading
                      },
                      model: {
                        value: _vm.resource.roles[0],
                        callback: function($$v) {
                          _vm.$set(_vm.resource.roles, 0, $$v)
                        },
                        expression: "resource.roles[0]"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          (!_vm.isAdminInterface() &&
            !_vm.isAMonitor(_vm.user) &&
            _vm.can("update", "users") &&
            !_vm.isEvaluatorForm()) ||
          _vm.debug
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                  _vm.establishmentRequired
                    ? _c("span", [_vm._v("Établissement* :")])
                    : _c("span", [_vm._v("Établissement(s) :")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/3 w-full" },
                  [
                    _c("multiselect", {
                      attrs: {
                        name: "establishments",
                        options: _vm.establishments,
                        multiple: true,
                        placeholder: "Recherche ...",
                        "track-by": "id",
                        label: "name",
                        "select-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        loading: _vm.establishmentsLoading
                      },
                      on: { input: _vm.eventEstablishmentHandle },
                      model: {
                        value: _vm.resource.establishments,
                        callback: function($$v) {
                          _vm.$set(_vm.resource, "establishments", $$v)
                        },
                        expression: "resource.establishments"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          (!_vm.isAdminInterface() &&
            !_vm.isAMonitor(_vm.user) &&
            _vm.can("update", "users") &&
            !_vm.isEvaluatorForm()) ||
          _vm.debug
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(7),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/3 w-full" },
                  [
                    _c("multiselect", {
                      attrs: {
                        name: "userServices",
                        options: _vm.shownServices,
                        multiple: true,
                        "group-values": "items",
                        "group-label": "group",
                        "group-select": false,
                        placeholder: "Recherche ...",
                        "track-by": "id",
                        label: "title",
                        "select-label": "",
                        "select-group-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        "deselect-group-label": ""
                      },
                      on: { input: _vm.eventServicesHandle },
                      model: {
                        value: _vm.resource.services,
                        callback: function($$v) {
                          _vm.$set(_vm.resource, "services", $$v)
                        },
                        expression: "resource.services"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          (!_vm.isAdminInterface() &&
            !_vm.isAMonitor(_vm.user) &&
            _vm.can("update", "users") &&
            !_vm.isEvaluatorForm()) ||
          _vm.debug
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _vm._m(8),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/3 w-full" },
                  [
                    _c("vs-switch", {
                      attrs: { name: "userIsRef" },
                      model: {
                        value: _vm.resource.reference,
                        callback: function($$v) {
                          _vm.$set(_vm.resource, "reference", $$v)
                        },
                        expression: "resource.reference"
                      }
                    }),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "Un utilisateur référent recevra les emails du formulaire de\n                contact."
                      )
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAdminInterface() &&
          !_vm.isAnEvaluator(_vm.resource) &&
          !_vm.isAMonitor(_vm.user) &&
          !_vm.isEvaluatorForm() &&
          _vm.can("update", "users") &&
          _vm.hasRole
            ? _c("UserAuthorizationsForm", {
                attrs: {
                  serviceslist: _vm.services,
                  establishments: _vm.establishments
                },
                model: {
                  value: _vm.authorizations,
                  callback: function($$v) {
                    _vm.authorizations = $$v
                  },
                  expression: "authorizations"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isEvaluatorForm() || _vm.can("update", "users")
            ? _c("UserEvaluatorForm", {
                attrs: {
                  establishmentsList: _vm.resource.establishments.length
                    ? _vm.resource.establishments
                    : _vm.establishments,
                  servicesList: _vm.resource.services.length
                    ? _vm.resource.services
                    : _vm.services,
                  evaluator: _vm.isAnEvaluator(_vm.resource),
                  reduced: _vm.reduced,
                  "reduced-for": _vm.reducedFor,
                  "reduced-type": _vm.reducedType
                },
                model: {
                  value: _vm.evaluatorScope,
                  callback: function($$v) {
                    _vm.evaluatorScope = $$v
                  },
                  expression: "evaluatorScope"
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Utilisateur déjà existant :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Nom* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Prénom* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Email* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Envoi de l'email d’initialisation ?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("strong", [_vm._v("Oui")]),
      _vm._v(
        ": un email sera envoyé automtiquement\n                au nouvel utilisateur créé. "
      ),
      _c("strong", [_vm._v("Non")]),
      _vm._v(
        ": vous\n                devrez envoyer l'email manuellement ultérieurement à\n                l'utilisateur créé"
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Role* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Service(s) :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Utilisateur référent ?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }