<template>
    <div class="form-type-container">
        <template v-if="!showForm">
            <multiselect
                v-if="types.length"
                v-model="type"
                :options="types"
                :multiple="false"
                placeholder="Recherche ..."
                select-label
                selected-label
                deselect-label
            >
                <span slot="noResult">Aucun résultat.</span>
            </multiselect>
            <p class="newType-link text-primary" @click="showForm = true">
                + Ajouter un type
            </p>
        </template>

        <template v-else>
            <vs-input autofocus v-model="newType" name="type" class="w-full" />
            <vs-button
                color="primary"
                type="border"
                icon="done"
                :disabled="!newType"
                @click="add"
            ></vs-button>
            <vs-button
                color="primary"
                type="border"
                icon="clear"
                @click="cancel"
            ></vs-button>
        </template>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "TypeForm",
    components: {
        Multiselect
    },
    props: {
        value: {
            required: false,
            type: String
        },
        availableTypes: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            log: console.log,
            showForm: false,
            type: null,
            newType: null,
            types: []
        };
    },
    created() {
        // Unique types
        this.types = this.getAvailableTypes();
        this.type = this.value;
    },
    watch: {
        value(value) {
            this.type = value;
        },
        newType(value) {
            this.$emit("input", value);
        },
        type(value) {
            this.$emit("input", value);
        },
        availableTypes: {
            handler() {
                this.types = this.availableTypes;
            },
            deep: true
        },
    },
    methods: {
        getAvailableTypes() {
            return this.availableTypes.filter((v, i, a) => a.indexOf(v) === i);
        },
        add() {
            if (!this.newType) {
                return;
            }

            this.types = this.getAvailableTypes().slice();
            let exists = this.types.find(t => t == this.newType);
            if (!exists) {
                this.types.push(this.newType);
                this.types.sort((a, b) => a.localeCompare(b));
            }
            this.showForm = false;
        },
        cancel() {
            this.newType = null;
            this.showForm = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.form-type-container {
    width: 100%;
    display: flex;
    align-items: center;
}
.newType-link {
    color: rgb(151, 47, 95);
    font-weight: 500;
    text-align: right;
    margin-right: 10px;
    white-space: nowrap;
    margin-left: 10px;
}
.vs-component.vs-input {
    display: flex;
    flex: 1;
    min-height: 43px;
    ::v-deep .vs-con-input {
        width: 100%;
        align-items: initial;
        flex-direction: row;
        input {
            font-size: 14px;
            margin-right: 10px;
        }
    }
}
button:not(:last-child) {
    margin-right: 10px;
}
</style>
