var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { attrs: { "vs-align": "left", "vs-w": "6" } },
            [
              _vm.can("create", "occupations")
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        id: "btn-create",
                        color: "primary",
                        type: "filled",
                        "icon-pack": "feather",
                        icon: "icon-plus"
                      },
                      on: { click: _vm.createResource }
                    },
                    [
                      _vm._v(
                        "\n                Ajouter un nouveau métier\n            "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "DialogPrompt",
                {
                  attrs: {
                    active: _vm.showPopup,
                    title: _vm.getDataPrompt.title,
                    "cancel-text": "Fermer",
                    "accept-text": "Enregistrer",
                    "button-accept": _vm.getDataPrompt.btnAccept,
                    "is-valid": !_vm.formError
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.showPopup = $event
                    },
                    cancel: function($event) {
                      _vm.resetResource()
                      _vm.resetSearchParams()
                    },
                    accept: _vm.storeResource,
                    close: function($event) {
                      _vm.resetResource()
                      _vm.resetSearchParams()
                    }
                  }
                },
                [
                  _c("OccupationForm", {
                    attrs: { type: _vm.formType },
                    on: { error: _vm.setFormError },
                    model: {
                      value: _vm.resource,
                      callback: function($$v) {
                        _vm.resource = $$v
                      },
                      expression: "resource"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "DialogPrompt",
                {
                  attrs: {
                    active: _vm.showPopupOccupationSkill,
                    title: "Consulter un savoir faire",
                    "cancel-text": "Fermer",
                    "accept-text": "Enregistrer",
                    "button-accept": "false"
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.showPopupOccupationSkill = $event
                    },
                    cancel: _vm.resetResource,
                    close: _vm.resetResource
                  }
                },
                [
                  _c("OccupationSkillForm", {
                    attrs: { type: "show" },
                    model: {
                      value: _vm.resourceOccupationSkill,
                      callback: function($$v) {
                        _vm.resourceOccupationSkill = $$v
                      },
                      expression: "resourceOccupationSkill"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("vs-col", {
            attrs: { "vs-type": "flex", "vs-align": "left", "vs-w": "6" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            sst: true,
            search: "",
            "no-data-text": _vm.loading
              ? "Chargement de vos données..."
              : "Aucune donnée à afficher",
            data: _vm.items.data
          },
          on: { search: _vm.eventSearchItems, sort: _vm.eventSortByCol },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return _vm._l(data, function(item, i) {
                  return _c(
                    "vs-tr",
                    { key: i, attrs: { data: item } },
                    [
                      _c(
                        "vs-td",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: item.description,
                                placement: "top-start",
                                classes: ["job-description"]
                              },
                              expression:
                                "{\n                        content: item.description,\n                        placement: 'top-start',\n                        classes: ['job-description']\n                    }"
                            }
                          ]
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-wrap": "nowrap",
                                display: "flex"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "float-left mr-5",
                                  staticStyle: { "line-height": "25px" }
                                },
                                [_vm._v(_vm._s(_vm.get(item, ":title")))]
                              ),
                              _vm._v(" "),
                              item.is_updated
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "bg-chip-transparent",
                                      attrs: { color: "primary" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Mis à jour\n                        "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.is_new
                                ? _c(
                                    "vs-chip",
                                    {
                                      staticClass: "bg-chip-transparent",
                                      attrs: { color: "warning" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Nouveau\n                        "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.get(item, ":sector.title")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.get(item, ":type")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.localeDateTime(item.updated_at)) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "vs-td",
                        [
                          _vm._l(
                            _vm.get(item, ":admin_occupation_skills"),
                            function(aos, index) {
                              return _c(
                                "vs-chip",
                                { key: index, attrs: { color: "secondary" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.tooltipContent(aos),
                                          expression: "tooltipContent(aos)"
                                        }
                                      ],
                                      staticStyle: {
                                        cursor: "pointer !important",
                                        "min-height": "26px",
                                        "min-width": "26px",
                                        "padding-top": "6px"
                                      },
                                      attrs: { name: "zone" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showAdminOccupationSkill(
                                            aos
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(aos.title) +
                                          "\n                        "
                                      )
                                    ]
                                  )
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.get(item, ":occupation_skills"), function(
                            os,
                            index
                          ) {
                            return _c(
                              "vs-chip",
                              { key: index, attrs: { color: "primary" } },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.tooltipContent(os),
                                        expression: "tooltipContent(os)"
                                      }
                                    ],
                                    staticStyle: {
                                      cursor: "pointer !important",
                                      "min-height": "26px",
                                      "min-width": "26px",
                                      "padding-top": "6px"
                                    },
                                    attrs: { name: "zone" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showOccupationSkill(os)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(os.title) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("vs-td", { staticClass: "action" }, [
                        _c(
                          "div",
                          { staticClass: "action-wrapper" },
                          [
                            _vm.can("read", "occupations", item.id)
                              ? _c("vs-button", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Consulter",
                                      expression: "'Consulter'"
                                    }
                                  ],
                                  attrs: {
                                    id: "showSkill_" + i,
                                    color: "primary",
                                    type: "border",
                                    "icon-pack": "feather",
                                    icon: "icon-list"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showResource(item)
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isEditable(item)
                              ? _c("vs-button", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Modifier",
                                      expression: "'Modifier'"
                                    }
                                  ],
                                  attrs: {
                                    id: "btn-update-" + i,
                                    color: "primary",
                                    type: "border",
                                    "icon-pack": "feather",
                                    icon: "icon-edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateResource(item)
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isDeletable(item)
                              ? _c("vs-button", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Supprimer",
                                      expression: "'Supprimer'"
                                    }
                                  ],
                                  attrs: {
                                    id: "btn-delete-" + i,
                                    color: "primary",
                                    type: "border",
                                    "icon-pack": "feather",
                                    icon: "icon-trash"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteResource(item)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                })
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                [
                  _c("Sort", {
                    attrs: { name: "title" },
                    on: { sort: _vm.eventSortByCol }
                  }),
                  _vm._v("Métier\n            ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c("Sort", {
                    attrs: { name: "sector,title" },
                    on: { sort: _vm.eventSortByCol }
                  }),
                  _vm._v("Filière\n            ")
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Type")]),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: {
                        name: "updated_at",
                        value: _vm.apiParams.sortBy.updated_at
                      },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Modifié le")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Savoir-faire")]),
              _vm._v(" "),
              _c("vs-th", { attrs: { width: "160" } })
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _vm.items.total > 0
            ? _c(
                "vs-col",
                { staticClass: "mt-4" },
                [
                  _c("vs-pagination", {
                    attrs: {
                      total: Math.ceil(_vm.items.total / _vm.apiParams.perpage)
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }