<template>
    <div>
        <slot name="actions-before"></slot>
        <vs-button
            v-if="exportUrl && exportAcl"
            id="boutonExporter"
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-download"
            style="margin-right:1rem;"
            @click.prevent="downloadWithAxios()"
        >
            Exporter
        </vs-button>

        <vs-button
            v-if="(importUrl && importAcl) || isAdminInterface()"
            id="boutonImporter"
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-upload"
            @click="uploadPrompt()"
        >
            Importer
        </vs-button>
        <slot name="actions-after"></slot>

        <DialogPrompt
            :active.sync="active"
            :title="`Importer des ${name}`"
            cancel-text="Annuler"
            accept-text="Importer"
            :is-valid="true"
            @cancel="close()"
            @accept="startImport()"
        >
            <div class="con-exemple-prompt">
                <div class="vx-row mb-6 ml-10">
                    <span>Sélectionner un fichier excel : </span>
                </div>
                <div class="vx-row mb-6 ml-10">
                    <input
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        name="file"
                        class="w-full"
                        @change="selectFile"
                    />
                </div>
            </div>
        </DialogPrompt>
    </div>
</template>

<script>
const contentDisposition = require("content-disposition");
export default {
    props: {
        name: {
            type: String,
            require: true
        },
        importUrl: {
            type: String,
            default: () => null
        },
        importAcl: {
            type: Boolean,
            default: () => true
        },
        exportUrl: {
            type: String,
            default: () => null
        },
        exportAcl: {
            type: Boolean,
            default: () => true
        }
    },
    data() {
        return {
            active: false,
            file: null,
            loading: false
        };
    },

    watch: {
        loading(newValue) {
            if (newValue) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        }
    },
    methods: {
        uploadPrompt() {
            this.active = true;
        },
        close() {
            this.active = false;
            this.file = null;
        },
        startImport() {
            this.loading = true;

            let form = new FormData();
            form.append('importFile', this.file);

            window.axios({
                method: 'post',
                url: `${this.importUrl}`,
                data: form,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(res => {
                const body = res.data;
                if (body.status === "success") {
                    this.close();
                    this.loading = false;
                    this.notifySuccess('Succès', 'Données importées !')
                    this.$emit('imported');
                } else {
                    this.loading = false;
                    this.notifyError('Erreur', body.message);
                }
            })
            .catch(err => {
                console.error(err.response);
                this.loading = false
                this.notifyError(err)
            });
        },
        selectFile(e) {
            if (e.target.files.length !== 0) {
                this.file = e.target.files[0];
            }
        },
        forceFileDownload(response) {
            const disposition = contentDisposition.parse(
                response.headers["content-disposition"]
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", disposition.parameters.filename);
            document.body.appendChild(link);
            link.click();
            this.loading = false;
        },
        downloadWithAxios() {
            this.loading = true;
            window.axios({
                method: "get",
                url: this.exportUrl,
                responseType: "arraybuffer"
            })
            .then(response => {
                this.forceFileDownload(response);
            })
            .catch((err) => {
                this.notifyError(err, "Erreur !", true);
                this.loading = false;
            });

        }
    }
};
</script>
