var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con-exemple-prompt mb-6 scrollBug" },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "resourceTitle" },
                  model: {
                    value: _vm.resource.title,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "title", $$v)
                    },
                    expression: "resource.title"
                  }
                })
              : _c("b", [_vm._v(_vm._s(_vm.resource.title))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.type != "show"
        ? _c("div", { staticClass: "vx-row mb-6" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/3 w-full" },
              [
                _c("Multiselect", {
                  attrs: {
                    options: _vm.groupedAdminOccupationSkills,
                    "group-label": "group",
                    "group-values": "items",
                    "group-select": false,
                    placeholder: "Choisir...",
                    "track-by": "id",
                    label: "title",
                    multiple: false,
                    "select-label": "",
                    "select-group-label": "",
                    "selected-label": "",
                    "deselect-label": "",
                    "deselect-group-label": "",
                    loading: _vm.adminOccupationSkillsLoading
                  },
                  on: { select: _vm.adminOccupationSkillChangeHandle },
                  model: {
                    value: _vm.adminOccupationSkill,
                    callback: function($$v) {
                      _vm.adminOccupationSkill = $$v
                    },
                    expression: "adminOccupationSkill"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? _c(
                  "Multiselect",
                  {
                    attrs: {
                      id: "addSFEstablishment",
                      options: _vm.establishments,
                      "track-by": "id",
                      multiple: false,
                      "group-select": false,
                      name: "establishments",
                      label: "name",
                      placeholder: "Recherche ...",
                      "select-label": "",
                      "select-group-label": "",
                      "selected-label": "",
                      "deselect-label": "",
                      "deselect-group-label": ""
                    },
                    model: {
                      value: _vm.resource.establishment,
                      callback: function($$v) {
                        _vm.$set(_vm.resource, "establishment", $$v)
                      },
                      expression: "resource.establishment"
                    }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "noResult" }, slot: "noResult" },
                      [_vm._v("Aucun résultat.")]
                    )
                  ]
                )
              : _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.resource.establishment
                        ? _vm.resource.establishment.name
                        : "--"
                    )
                  )
                ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? _c("vs-textarea", {
                  attrs: { name: "resourceDescription" },
                  model: {
                    value: _vm.resource.description,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "description", $$v)
                    },
                    expression: "resource.description"
                  }
                })
              : _c("span", [_vm._v(_vm._s(_vm.resource.description))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(4),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-2/3 w-full" },
          [
            _vm.type != "show"
              ? _c(
                  "Multiselect",
                  {
                    attrs: {
                      options: _vm.sectors,
                      "track-by": "id",
                      label: "title",
                      multiple: false,
                      "group-select": false,
                      placeholder: "Recherche ...",
                      "select-label": "",
                      "select-group-label": "",
                      "selected-label": "",
                      "deselect-label": "",
                      "deselect-group-label": ""
                    },
                    model: {
                      value: _vm.resource.sector,
                      callback: function($$v) {
                        _vm.$set(_vm.resource, "sector", $$v)
                      },
                      expression: "resource.sector"
                    }
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "noResult" }, slot: "noResult" },
                      [_vm._v("Aucun résultat.")]
                    )
                  ]
                )
              : _c("span", [_vm._v(_vm._s(_vm.resource.sector.title))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(5),
        _vm._v(" "),
        _c("div", { staticClass: "vx-col sm:w-2/3 w-full" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.data_get(_vm.resource, "admin_occupation_skill.type", "--")
              )
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("TransversalSkillsSelector", {
        attrs: {
          value: _vm.resource.skills,
          type: _vm.formType,
          skills: _vm.skills,
          "admin-os":
            _vm.resource.resource == "AdminOccupationSkill"
              ? _vm.resource
              : _vm.resource.admin_occupation_skill
        },
        on: {
          input: _vm.setTranversalSkills,
          needvalidation: function($event) {
            _vm.needValidation = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-10" },
        [
          _c(
            "vs-collapse",
            { staticClass: "mb-6 mt-10", attrs: { accordion: "" } },
            [
              _c("vs-collapse-item", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("h5", [_vm._v("Signification des notes : ")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.occSkillNoteById(1)) +
                          " - " +
                          _vm._s(_vm.occSkillLevelLabelById(1)) +
                          " : "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-level vx-col sm:w-4/5 w-full" },
                    [
                      _vm.type == "show"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.resource.level.level_0))
                          ])
                        : _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              name: "modifyOccSkillLevel_O",
                              placeholder: "0 : ",
                              type: "text"
                            },
                            on: { input: _vm.levelChangeHandle },
                            model: {
                              value: _vm.resource.level.level_0,
                              callback: function($$v) {
                                _vm.$set(_vm.resource.level, "level_0", $$v)
                              },
                              expression: "resource.level.level_0"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vx-row mt-3" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.occSkillNoteById(2)) +
                          " - " +
                          _vm._s(_vm.occSkillLevelLabelById(2)) +
                          " : "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-level vx-col sm:w-4/5 w-full" },
                    [
                      _vm.type == "show"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.resource.level.level_1))
                          ])
                        : _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              name: "modifyOccSkillLevel_1",
                              placeholder: "1 : ",
                              type: "text"
                            },
                            on: { input: _vm.levelChangeHandle },
                            model: {
                              value: _vm.resource.level.level_1,
                              callback: function($$v) {
                                _vm.$set(_vm.resource.level, "level_1", $$v)
                              },
                              expression: "resource.level.level_1"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vx-row mt-3" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.occSkillNoteById(3)) +
                          " - " +
                          _vm._s(_vm.occSkillLevelLabelById(3)) +
                          " : "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-level vx-col sm:w-4/5 w-full" },
                    [
                      _vm.type == "show"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.resource.level.level_2))
                          ])
                        : _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              name: "modifyOccSkillLevel_2",
                              placeholder: "2 : ",
                              type: "text"
                            },
                            on: { input: _vm.levelChangeHandle },
                            model: {
                              value: _vm.resource.level.level_2,
                              callback: function($$v) {
                                _vm.$set(_vm.resource.level, "level_2", $$v)
                              },
                              expression: "resource.level.level_2"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vx-row mt-3" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.occSkillNoteById(4)) +
                          " - " +
                          _vm._s(_vm.occSkillLevelLabelById(4)) +
                          " : "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-level vx-col sm:w-4/5 w-full" },
                    [
                      _vm.type == "show"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.resource.level.level_3))
                          ])
                        : _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              name: "modifyOccSkillLevel_3",
                              placeholder: " 3 : ",
                              type: "text"
                            },
                            on: { input: _vm.levelChangeHandle },
                            model: {
                              value: _vm.resource.level.level_3,
                              callback: function($$v) {
                                _vm.$set(_vm.resource.level, "level_3", $$v)
                              },
                              expression: "resource.level.level_3"
                            }
                          })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _vm._m(6),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vx-col sm:w-4/5 w-full pl-8 pr-12" },
          [
            _vm.type != "show"
              ? _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "resourcePhoto" },
                  model: {
                    value: _vm.resource.photo,
                    callback: function($$v) {
                      _vm.$set(_vm.resource, "photo", $$v)
                    },
                    expression: "resource.photo"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.resource.photo
              ? _c("img", {
                  attrs: {
                    id: "edited-pick-photo",
                    src: _vm.resource.photo,
                    width: "120px"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Savoir-faire* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Savoir-faire Référentiel :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Spécifique à :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Description du Savoir-faire* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Filière* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Type* :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/5 w-full pl-12" }, [
      _c("span", [_vm._v("Image du Savoir-Faire : ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }