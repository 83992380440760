<template>
    <div class="chatbot-wrapper">
        <button id="open-chatbot" @click="showChatbotClickHandle">
            <i class="far fa-comment-dots fa-2x"></i>
        </button>
        <div v-show="showChatbot" id="chatbot-container">
            <div id="chatbot-header">
                <button id="close-chatbot" @click="closeChatbotClickHandle">
                    <i class="fa fa-times mr-2"></i> Fermer
                </button>
            </div>
            <iframe :src="`https://www.embed.swiftask.ai/?clientToken=${clientToken}`" id="chatbot" frameBorder="0" title="Embedded Swiftask" style="overflow: hidden;"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: "Chatbot",
    props: {
        clientToken: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showChatbot: false
        }
    },
    methods: {
        showChatbotClickHandle() {
            this.showChatbot = true;
        },
        closeChatbotClickHandle() {
            this.showChatbot = false;
        }
    },
}
</script>

<style scoped>
#chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 400px;
    height: 600px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

#chatbot-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
}

#close-chatbot {
    background: none;
    border: none;
    color: #999;
    font-size: 14px;
    cursor: pointer;
}

#chatbot {
    width: 100%;
    height: calc(100% - 40px);
    border: none;
}

#open-chatbot {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 20px;
    right: 20px;
    background-color: #972f5f;
    color: white;
    border: none;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
</style>
