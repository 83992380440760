var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("JobsOccupationsResume", {
        attrs: {
          jobs: _vm.value.jobs,
          occupations: _vm.value.occupations,
          displayWishes: false
        }
      }),
      _vm._v(" "),
      _vm.resource.skills.length
        ? _c(
            "vs-row",
            { staticClass: "checkboxes-container mt-10" },
            [
              _c(
                "vs-col",
                {
                  staticClass: "sm:w-4/5 w-full sm:w-",
                  attrs: { "vs-type": "flex", "vs-align": "center" }
                },
                [
                  _c("label", [_vm._v("Toutes les compétences")]),
                  _vm._v(" "),
                  _c("vs-switch", {
                    staticClass: "ml-2",
                    attrs: { name: "showAllSkills", disabled: "" },
                    model: {
                      value: _vm.value.allSkills,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "allSkills", $$v)
                      },
                      expression: "value.allSkills"
                    }
                  }),
                  _vm._v(" "),
                  _vm.typeForm !== "consultation" &&
                  _vm.typeForm !== "validation"
                    ? [
                        _c("label", { staticClass: "ml-20" }, [
                          _vm._v("Préremplir avec :")
                        ]),
                        _vm._v(" "),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "vs-name": "radios1",
                              id: "radio-last-auto-eval",
                              "vs-value": _vm.selfRatingSourceValues.LAST_SELF
                            },
                            on: {
                              change: function($event) {
                                return _vm.setDefaultHandle(
                                  _vm.selfRatingSourceValues.LAST_SELF
                                )
                              }
                            },
                            model: {
                              value: _vm.resource.self_skills_source,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.resource,
                                  "self_skills_source",
                                  $$v
                                )
                              },
                              expression: "resource.self_skills_source"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Dernière sauvegarde\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "vs-name": "radios1",
                              id: "radio-declaration-competence",
                              "vs-value": _vm.selfRatingSourceValues.MOBILITY,
                              disabled: !_vm.mobilityRating
                            },
                            on: {
                              change: function($event) {
                                return _vm.setDefaultHandle(
                                  _vm.selfRatingSourceValues.MOBILITY
                                )
                              }
                            },
                            model: {
                              value: _vm.resource.self_skills_source,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.resource,
                                  "self_skills_source",
                                  $$v
                                )
                              },
                              expression: "resource.self_skills_source"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Auto-évaluation Portail\n                    " +
                                _vm._s(
                                  !!_vm.mobilityRating
                                    ? "du " +
                                        this.$moment(
                                          _vm.mobilityRating.rating_date
                                        ).format("l")
                                    : ""
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.typeForm !== "creation" && _vm.typeForm !== "poursuite"
                    ? [
                        _c("label", { staticClass: "ml-20" }, [
                          _vm._v("Affichage :")
                        ]),
                        _vm._v(" "),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "vs-name": "radios-occSkill-display",
                              id: "radio-occSkill-display-table",
                              "vs-value": "table"
                            },
                            model: {
                              value: _vm.displayMode,
                              callback: function($$v) {
                                _vm.displayMode = $$v
                              },
                              expression: "displayMode"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Tableau\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "vs-name": "radios-occSkill-display",
                              id: "radio-occSkill-display-graphic",
                              "vs-value": "graphic"
                            },
                            model: {
                              value: _vm.displayMode,
                              callback: function($$v) {
                                _vm.displayMode = $$v
                              },
                              expression: "displayMode"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Graphique\n                "
                            )
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.typeForm !== "consultation" && _vm.typeForm !== "validation"
                ? _c(
                    "vs-col",
                    {
                      staticClass: "sm:w-4/5 w-full sm:w-",
                      attrs: { "vs-type": "flex", "vs-align": "center" }
                    },
                    [
                      _c("div", { staticClass: "text-white" }, [
                        _vm._v("_______________________________--")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "ml-20" }, [
                        _vm._v(
                          "\n                En cas de nouvelle auto-évaluation Portail transmise depuis la date de création,\n                veuillez sélectionner l’auto-évaluation Portail\n            "
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : !_vm.loading
        ? _c("div", { staticClass: "not-data-table vs-table--not-data" }, [
            _vm._v("Il n'y a aucune compétence à évaluer")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.displayMode === "table" &&
      (_vm.resource.self_skills.length || _vm.loading)
        ? _c("Rating-Manager", {
            attrs: {
              name: "self-skills",
              "type-form": _vm.typeForm,
              "show-hidden": _vm.value.allSkills,
              col1: {
                label: "Compétence",
                value: "title",
                title: "description"
              },
              col2: { label: "Catégorie", value: "category_name" },
              loading: _vm.loading,
              "skill-review": _vm.skillReview
            },
            model: {
              value: _vm.resource.self_skills,
              callback: function($$v) {
                _vm.$set(_vm.resource, "self_skills", $$v)
              },
              expression: "resource.self_skills"
            }
          })
        : _vm.displayMode === "graphic"
        ? _c(
            "div",
            { staticClass: "chart main" },
            [
              _c("Rating-Chart", {
                staticClass: "main-chart",
                attrs: {
                  employee: _vm.value.employee,
                  options: _vm.chart.options,
                  "chart-data": _vm.chart.data
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }