<template>

    <span>
        <vs-button
            id="export-skill-book-button"
            color="primary"
            type="filled"
            icon-pack="feather"
            icon="icon-file-text"
            :class="[
                'ml-2',
                'vs-con-loading__container',
            ]"
            @click="clickExportHandle"
        >
            Carnet de compétences
        </vs-button>

        <DialogPrompt
            :active.sync="exportPrompt"
            title="Exporter le canet de compétence"
            cancel-text="Annuler"
            accept-text="Exporter"
            :is-valid="valid"
            @cancel="cancelExport"
            @close="cancelExport"
            @accept="initExport()"
        >
            <div class="con-exemple-prompt">
                <vs-row class="mb-6">
                    <vs-col class="w-full">
                        <div class="mb-1">
                            <label class="font-weight-bold">Choisissez le modèle*</label>
                        </div>
                        <Multiselect
                            v-model="exportValue"
                            label="title"
                            :options="allModels"
                            placeholder="Recherche ..."
                            select-label
                            select-group-label
                            selected-label
                            deselect-label
                            deselect-group-label
                            :loading="exportModels.loading && exportAdminModels.loading"
                        >
                            <span slot="noOptions">Aucun résultat</span>
                        </Multiselect>
                    </vs-col>
                </vs-row>

                <vs-row class="mb-6">
                    <vs-col class="w-full">
                        <div class="mb-1">
                            <label class="font-weight-bold">Choisissez le format*</label>
                        </div>
                        <div>
                            <span v-for="(mime, i) in mimes" :key="i" class="mr-5">
                                <vs-radio v-model="exportMime" vs-name="mime" :vs-value="mime.mime">{{ mime.name }}</vs-radio>
                            </span>
                        </div>
                    </vs-col>
                </vs-row>
            </div>
        </DialogPrompt>
    </span>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    name: "SkillBookExport",
    components: {
        Multiselect
    },
    props: {
        employeeId: {
            type: Number,
            required: true
        },
        mimes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            exportPrompt: false,
            exportModels: {
                data: [],
                loading: false
            },
            exportAdminModels: {
                data: [],
                loading: false
            },
            exportValue: null,
            exportMime: '.docx'
        }
    },
    created() {
        this.loadModels();
        this.loadAdminModels();
    },
    computed: {
        allModels() {
            return [...this.exportModels.data, ...this.exportAdminModels.data]
        },

        valid() {
            return this.exportValue != null;
        }
    },
    watch: {},
    methods: {
        loadModels() {
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/parameters/exports",
                    params: { perpage: 100, filter: 'visibility|=|1' }
                })
                .then(response => {
                    this.exportModels.data = response.data.data;
                    this.exportModels.loading = false;
                })
                .catch(err => {

                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        loadAdminModels() {
            window
                .axios({
                    method: "get",
                    url: "/api/admin/parameters/exports",
                    params: { perpage: 100, filter: 'visibility|=|1' }
                })
                .then(response => {
                    const items = response.data.data
                    items.map(i => i.title = `${i.title} (defaut)`);
                    this.exportAdminModels.data = items;
                    this.exportAdminModels.loading = false;
                })
                .catch(err => {

                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        clickExportHandle() {
            this.exportPrompt = true;
        },

        cancelExport() {
            this.exportPrompt = false;
            this.exportModels.value = null;
        },

        setLoadingButton() {
            this.$vs.loading({
                background: "primary",
                color: "#FFF",
                container: "#export-skill-book-button",
                scale: 0.45
            });
        },

        stopLoadingButton() {
            this.$vs.loading.close("#export-skill-book-button > .con-vs-loading");
        },

        initExport() {
            this.setLoadingButton();

            window.axios({
                method: 'get',
                url: `/api/gestion/exports/skill-book/${this.employeeId}/${this.exportValue.id}?type=${this.exportValue.resource}&mime=${this.exportMime}`,
                responseType: 'blob',
            })
            .then(response => {
                this.stopLoadingButton();
                this.exportPrompt = false;

                this.downloadFile(response);
            })
            .catch((err, data) => {
                console.error(err);
                this.stopLoadingButton();
                if (err.response) {
                    console.log(err.response.data.message)
                    this.notifyError(err.response.data.message, 'Erreur');
                }
            })
        },

        downloadFile(response) {
            let blob = new Blob([response.data], { type: response.headers['content-type'] } );
            var link = document.createElement('a');
            const name = /.*filename="([^"]*)"$/.exec(response.headers['content-disposition'])[1];

            link.href = URL.createObjectURL(blob);
            link.download = name;
            link.dispatchEvent(new MouseEvent('click'));
        }
    }
}
</script>
