<template>
    <div>
        <LimitatedPlan v-if="!planAllow('indicateurs-activite')" class="mb-10"/>

        <div class="vx-row mt-10">
            <vx-card>
                <h3>Suivi des usages</h3>
                <div class="mt-10">
                    <p><b>Périmètre:</b></p>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/2 w-full">
                            <multiselect
                                v-model="establishmentsFilter"
                                name="establishments"
                                :options="establishments"
                                :multiple="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="name"
                                select-label=""
                                selected-label=""
                                deselect-label=""
                                :loading="establishmentsLoading"
                                :disabled="!planAllow('indicateurs-activite')"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </div>
                        <div class="vx-col sm:w-1/4 w-full">
                            <div class="vx-row">
                                <div class="vx-col sm:w-2/12 w-full pt-2">
                                    <span>Du :</span>
                                </div>
                                <div class="vx-col sm:w-10/12 w-full">
                                    <vs-input
                                        type="date"
                                        v-model="startDateFilter"
                                        name="start_date"
                                        class="w-full"
                                        :disabled="!planAllow('indicateurs-activite')"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="vx-col sm:w-1/4 w-full">
                            <div class="vx-row">
                                <div class="vx-col sm:w-2/12 w-full pt-2">
                                    <span>au :</span>
                                </div>
                                <div class="vx-col sm:w-10/12 w-full">
                                    <vs-input
                                        type="date"
                                        v-model="endDateFilter"
                                        name="start_date"
                                        class="w-full"
                                        :disabled="!planAllow('indicateurs-activite')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mt-5">
                        <div class="vx-col sm:w-1/2 w-full">
                            <div class="vx-row">
                                <div class="vx-col sm:w-1/12 w-full">
                                    <vs-switch v-model="includeEmployees" :disabled="!planAllow('indicateurs-activite')"/>
                                </div>
                                <div class="vx-col sm:w-1/4 w-full">
                                    Personnes
                                </div>
                                <div class="vx-col sm:w-1/12 w-full">
                                    <vs-switch v-model="includeNotInStaff" :disabled="!planAllow('indicateurs-activite')"/>
                                </div>
                                <div class="vx-col sm:w-1/4 w-full">
                                    Stagiaires / Hors effectif
                                </div>
                                <div class="vx-col sm:w-1/12 w-full">
                                    <vs-switch v-model="perService" :disabled="!planAllow('indicateurs-activite')"/>
                                </div>
                                <div class="vx-col sm:w-1/4 w-full">
                                    Détail par service
                                </div>
                            </div>
                        </div>
                    </div>

                    <p class="mt-5"><b>Sélectionner l'indicateur à utiliser:</b></p>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/2 w-full">
                            <multiselect
                                v-model="mode"
                                name="modes"
                                :options="modes"
                                :multiple="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="title"
                                select-label=""
                                selected-label=""
                                deselect-label=""
                                :disabled="!planAllow('indicateurs-activite')"
                            />
                        </div>
                    </div>
                </div>

                <vs-table
                    search
                    style="width:100%"
                    :data="usages"
                >
                    <template slot="thead">
                        <vs-th style="width: 40rem">
                            <Sort name="title">Usage</Sort>
                        </vs-th>
                        <vs-th style="width: 40rem">
                            <Sort :name="mode.title === 'Nombre' ? 'value':'percentageValue'" >Cumul périmètre</Sort>
                        </vs-th>
                        <template v-if="!perService">
                            <vs-th style="width: 40rem" v-for="(establishment, index) in establishmentsFilter" :key="'establishment_'+index">
                                {{ establishment.name }}
                            </vs-th>
                        </template>
                        <template v-else>
                            <template v-for="(establishment, index) in establishmentsFilter">
                                <vs-th style="width: 40rem" :key="'establishment_'+index">
                                    {{ establishment.name }}
                                </vs-th>
                                <vs-th style="width: 40rem; color: rgb(160, 160, 160)" v-for="(service, service_index) in establishment.services" :key="'establishment_'+index+'_service_'+service_index">
                                    {{ service.title }}
                                </vs-th>
                            </template>
                        </template>

                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr v-for="(item, i) in data" :key="i" :data="item">
                            <vs-td v-tooltip="{content: item.description, placement: 'top-start'}">
                                {{ get(item, ":title") }}
                            </vs-td>
                            <vs-td>
                                <span v-if="mode.title === 'Nombre'">{{ get(item, ":value") || 0 }}</span>
                                <span v-if="mode.title === 'Pourcentage'">{{ get(item, ":percentageValue") || 0 }}%</span>
                            </vs-td>

                            <template v-if="!perService">
                                <vs-td v-for="(establishment, index) in establishmentsFilter" :key="'usage_'+i+'_establishment_'+index">
                                    <span v-if="mode.title === 'Nombre'">{{ get(item, ":establishments")[index].value }}</span>
                                    <span v-if="mode.title === 'Pourcentage'">{{ get(item, ":establishments")[index].percentageValue }}%</span>
                                </vs-td>
                            </template>
                            <template v-else>
                                <template v-for="(establishment, index) in establishmentsFilter">
                                    <vs-td>
                                        <span v-if="mode.title === 'Nombre'">{{ get(item, ":establishments")[index].value }}</span>
                                        <span v-if="mode.title === 'Pourcentage'">{{ get(item, ":establishments")[index].percentageValue }}%</span>
                                    </vs-td>
                                    <vs-td v-for="(service, service_index) in establishment.services" :key="'usage_'+i+'_establishment_'+index+'_service_'+service_index">
                                        <span v-if="mode.title === 'Nombre'">{{ get(item, ":establishments")[index].services[service_index].value }}</span>
                                        <span v-if="mode.title === 'Pourcentage'">{{ get(item, ":establishments")[index].services[service_index].percentageValue }}%</span>
                                    </vs-td>
                                </template>
                            </template>
                        </vs-tr>
                    </template>
                </vs-table>
            </vx-card>
        </div>
    </div>
</template>

<script>
import StatisticsCardLine from "@/components/theme/StatisticsCardLine.vue";
import Multiselect from "vue-multiselect";
import {format} from "date-fns";
import LimitatedPlan from '@components/divers/LimitatedPlan';

export default {
    components: {
        Multiselect,
        StatisticsCardLine,
        LimitatedPlan
    },
    data() {
        return {
            log: console.log,
            skills: [],
            skillsLoading: false,
            adminoccupationskills: [],
            adminoccupationskillsLoading: false,
            occupationskills: [],
            occupationskillsLoading: false,
            jobs: [],
            jobsLoading: false,
            occupations: [],
            occupationsLoading: false,
            employees: [],
            employeesLoading: false,
            establishments: [],
            establishmentsLoading: false,
            establishmentsFilter: [],
            startDateFilter: null,
            endDateFilter: null,
            includeEmployees: true,
            includeNotInStaff: false,
            modes: [
                {id: 1, title: "Pourcentage"},
                {id: 2, title: "Nombre"},
            ],
            mode: {
                id: 1,
                title: "Pourcentage",
            },
            perService: false,
        };
    },

    mounted() {
        let today = new Date()
        let newDate = new Date(
            today.getFullYear()-1,
            today.getMonth(),
            today.getDate()+1
        )
        this.startDateFilter = format(newDate, "yyyy-MM-dd")
        this.endDateFilter = format(today, "yyyy-MM-dd")
        this.mode = this.modes[0]
    },

    computed: {
        usages() {
            let usageItems = [
                'Personnes sur le périmètre sur la plage de dates sélectionnées',
                'Personnes avec au moins un élément de parcours',
                'Personnes sans au moins un élément de parcours',
                'Personnes évaluées',
                'Personnes non évaluées',
                this.projectNames.plural+' en cours',
                this.projectNames.plural+' en attente de validation',
                this.projectNames.plural+' validés',
                this.projectNames.plural+' en cours, en attente de validation ou validés',
                this.skillRatingNames.plural+' en cours',
                this.skillRatingNames.plural+' en attente de validation',
                this.skillRatingNames.plural+' validés',
                this.skillRatingNames.plural+' en cours, en attente de validation ou validés',
                'Auto-évaluations transmises',
                'Evaluations Portail transmises',
            ]
            let usages = []
            usageItems.forEach((item, i) => {
                let usage = {
                    title: item,
                    establishments: [],
                }
                if (i === 0) { //Personnes sur le périmètre sur la plage de dates sélectionnées
                    usage.value = this.EmployeesInPerimeterAndDate?.length || 0
                    usage.percentageValue = 100
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let localValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValue,
                            percentageValue: localValue ? (localValue / localValue * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: localServiceValue ? (localServiceValue / localServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 1) { //Personnes avec au moins un élément de parcours
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.jobs.length || e.occupations.length || e.experiences.length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.jobs.length || e.occupations.length || e.experiences.length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.jobs.length || e.occupations.length || e.experiences.length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 2) { //Personnes sans au moins un élément de parcours
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => !e.jobs.length && !e.occupations.length && !e.experiences.length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => !e.jobs.length && !e.occupations.length && !e.experiences.length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => !e.jobs.length && !e.occupations.length && !e.experiences.length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 3) { //Personnes évaluées
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status < 2).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status < 2).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status < 2).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 4) { //Personnes non évaluées
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => !e.employee_ratings.filter(er => er.status < 2).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => !e.employee_ratings.filter(er => er.status < 2).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => !e.employee_ratings.filter(er => er.status < 2).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 5) { //PP en cours
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status === -1 && er.skill_review === 0).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status === -1 && er.skill_review === 0).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status === -1 && er.skill_review === 0).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 6) { //PP en attente de validation
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status === 0 && er.skill_review === 0).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status === 0 && er.skill_review === 0).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status === 0 && er.skill_review === 0).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 7) { //PP validés
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status === 1 && er.skill_review === 0).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status === 1 && er.skill_review === 0).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status === 1 && er.skill_review === 0).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 8) { //PP en cours, en attente de validation ou validés
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status < 2 && er.skill_review === 0).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status < 2 && er.skill_review === 0).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status < 2 && er.skill_review === 0).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 9) { //Bilan en cours
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status === -1 && er.skill_review === 1).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status === -1 && er.skill_review === 1).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status === -1 && er.skill_review === 1).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 10) { //Bilan en attente validation
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status === 0 && er.skill_review === 1).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status === 0 && er.skill_review === 1).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status === 0 && er.skill_review === 1).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 11) { //Bilan validés
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status === 1 && er.skill_review === 1).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status === 1 && er.skill_review === 1).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status === 1 && er.skill_review === 1).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 12) {//Bilans en cours, en attente de validation ou validés
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status < 2 && er.skill_review === 1).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status < 2 && er.skill_review === 1).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status < 2 && er.skill_review === 1).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 13) { //Auto-eval transmises
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status === 2).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status === 2).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status === 2).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }
                else if (i === 14) { //Evaluations Portail transmises
                    let localValue = this.EmployeesInPerimeterAndDate?.filter(e => e.employee_ratings.filter(er => er.status === 3).length).length || 0
                    usage.value = localValue
                    usage.percentageValue = (localValue / (this.EmployeesInPerimeterAndDate?.length || 0) * 100).toFixed(2)
                    this.establishmentsFilter.forEach((establishment, index) => {
                        let globalValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.length || 0
                        let localValuePerEts = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.employee_ratings.filter(er => er.status === 3).length).length || 0
                        usage.establishments[index] = {
                            title: establishment.name,
                            value: localValuePerEts,
                            percentageValue: globalValue ? (localValuePerEts / (globalValue) * 100).toFixed(2) : 0,
                        }
                        let services = []
                        establishment.services.forEach((service, index) => {
                            let globalServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id)).length || 0
                            let localServiceValue = this.EmployeesInPerimeterAndDatePerEstablishment[establishment.id]?.filter(e => e.active_services.map(s => s.id).includes(service.id))?.filter(e => e.employee_ratings.filter(er => er.status === 3).length).length || 0
                            services.push({
                                title: service.title,
                                value: localServiceValue,
                                percentageValue: globalServiceValue ? (localServiceValue / globalServiceValue * 100).toFixed(2) : 0,
                            })
                        })
                        usage.establishments[index]['services'] = services
                    })
                }

                usages.push(usage)
            })

            return usages
        },
        EmployeesInPerimeterAndDate() {
            let serviceIDs = []
            this.establishmentsFilter.forEach(establishment => {
                serviceIDs = serviceIDs.concat(establishment.services.map(s => s.id))
            })

            return this.employees.filter(employee => {
                if (this.includeNotInStaff && !this.includeEmployees && !employee.not_in_staff) return false
                if (this.includeEmployees && !this.includeNotInStaff && employee.not_in_staff) return false

                return !!serviceIDs.filter(
                    sid =>
                        employee.active_services.filter(
                            as =>
                                (!as.pivot.start_date || new Date(as.pivot.start_date).getTime() <= new Date(this.endDateFilter).getTime())
                                && (!as.pivot.end_date || new Date(as.pivot.end_date).getTime() >= new Date(this.startDateFilter).getTime())
                        ).map(s => s.id).includes(sid)
                ).length
            })
        },
        EmployeesInPerimeterAndDatePerEstablishment() {
            let employeesPerEstablishment = []
            this.establishmentsFilter.forEach(establishment => {
                let serviceIDs = establishment.services.map(s => s.id)
                employeesPerEstablishment[establishment.id] = this.employees.filter(employee => {
                    if (this.includeNotInStaff && !this.includeEmployees && !employee.not_in_staff) return false
                    if (this.includeEmployees && !this.includeNotInStaff && employee.not_in_staff) return false

                    return !!serviceIDs.filter(
                        sid =>
                            employee.active_services.filter(
                                as =>
                                    (!as.pivot.start_date || new Date(as.pivot.start_date).getTime() <= new Date(this.endDateFilter).getTime())
                                    && (!as.pivot.end_date || new Date(as.pivot.end_date).getTime() >= new Date(this.startDateFilter).getTime())
                            ).map(s => s.id).includes(sid)
                    ).length
                })
            })

            return employeesPerEstablishment
        },
    },

    methods: {
        loadSkills() {
            this.skillsLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/skills",
                    params: { perpage: 9999, include: [] }
                })
                .then(response => {
                    this.skills = response.data.data;
                    this.skillsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        loadAdminOccupationSkills() {
            this.adminoccupationskillsLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/admin/occupation-skills",
                    params: { perpage: 9999, sortBy: {"title": "asc"}, include: [] }
                })
                .then(response => {
                    this.adminoccupationskills = response.data.data;
                    this.adminoccupationskillsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        loadOccupationSkills() {
            this.occupationskillsLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills",
                    params: { perpage: 9999, sortBy: {"title": "asc"}, include: [] }
                })
                .then(response => {
                    this.occupationskills = response.data.data;
                    this.occupationskillsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        loadJobs() {
            this.jobsLoading = true;
            window.axios({
                method: 'get',
                url: '/api/referentiel/jobs',
                params: {perpage: 9999, include: []},
            })
            .then(response => {
                this.jobs = response.data.data;
                this.jobsLoading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        loadOccupations() {
            this.occupationsLoading = true;
            window.axios({
                method: 'get',
                url: '/api/referentiel/occupations',
                params: {perpage: 9999, include: []},
            })
            .then(response => {
                this.occupations = response.data.data;
                this.occupationsLoading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        loadEmployees() {
            this.employeesLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: {
                        perpage: 9999,
                        include: ['activeServices', 'experiences', 'establishments', 'jobs', 'occupations', 'employee_ratings'],
                    }
                })
                .then(response => {
                    //console.log('employee', response.data.data)
                    this.employees = response.data.data;
                    console.log('Employee 0', this.employees[0])
                    this.employeesLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        loadEstablishments() {
            this.establishmentsLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ['services']
                    }
                })
                .then(response => {
                    this.establishments = response.data.data;
                    let establishments = []
                    this.listOfServicesViewableFor("employees", this.user).forEach(service => {
                        let establishment = this.establishmentById(this.serviceById(service)?.establishment_id)
                        if (establishment && !establishments.map(e => e.id).includes(establishment.id)) {
                            establishments.push(establishment)
                        }
                    })

                    this.establishmentsFilter = establishments
                    this.establishmentsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        establishmentById(id) {
            return this.establishments.find(el => el.id === id);
        },
    },
    created() {
        if (this.planAllow('indicateurs-activite')) {
            this.loadSkills();
            this.loadAdminOccupationSkills();
            this.loadOccupationSkills();
            this.loadJobs();
            this.loadOccupations();
            this.loadEmployees();
            this.loadEstablishments();
        }

    }
};
</script>
<style lang="scss" scoped>
.dashboardLink {
    cursor: pointer;
}

.vx-row {
    margin: 0 -1rem
}

::v-deep input {
    min-height: 43px;
    padding: 1rem 1.3rem;
    font-size:1.1rem;
}

::v-deep .vx-card {
    .vx-card {
        box-shadow: 0px 4px 25px 10px rgba(0, 0, 0, 0.1);
    }
}
</style>
