<template>
    <!-- On attend l'exécution de GET_IMAGES pour afficher sinon les images par défaut sont affichées un court instant (moche) -->
    <div v-if="images[0][0].exists !== null">
        <div class="vx-row mt-10">
            <div
                v-for="image in images[0].slice(0, 3)"
                :key="image.id"
                class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3"
            >
                <vx-card>
                    <div slot="no-body">
                        <div
                            class="overlayed"
                            :style="image.exists ? image.custom : image.default"
                        />
                    </div>
                    <div style="display: inline-flex; align-items: center;">
                        <h2 class="mb-2">
                            {{ image.text }}
                        </h2>

                        <vs-button
                            v-if="isAdmin() && image.exists"
                            :id="'deleteImage_' + image.id"
                            v-tooltip="'Supprimer'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            style="position: absolute; right: 55px"
                            @click="deleteImage(image.id)"
                        />

                        <vs-button
                            v-if="isAdmin()"
                            :id="'modifyImage_' + image.id"
                            v-tooltip="'Modifier'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-edit"
                            style="position: absolute; right: 10px"
                            @click="uploadPrompt(image.id)"
                        />
                    </div>
                </vx-card>
            </div>
        </div>
        <!-- On crée une deuxième boucle pour bien séparer les lignes d'images,
  et on fait attention à n'afficher la photo planning que si le module est activé -->
        <div class="vx-row mt-10">
            <div
                v-for="image in images[0].slice(3)"
                :key="image.id"
                class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3"
            >
                <!-- <vx-card v-if="$store.state.planning || image !== images[0][3]"></vx-card> -->
                <!-- TODO - revoir l'affichage du planning -->
                <vx-card v-if="false || image !== images[0][3]">
                    <div slot="no-body">
                        <div
                            class="overlayed"
                            :style="image.exists ? image.custom : image.default"
                        />
                    </div>
                    <div style="display: inline-flex; align-items: center;">
                        <h2 class="mb-2">
                            {{ image.text }}
                        </h2>

                        <vs-button
                            v-if="isAdmin() && image.exists"
                            :id="'deleteImage_' + image.id"
                            v-tooltip="'Supprimer'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            style="position: absolute; right: 55px"
                            @click="deleteImage(image.id)"
                        />

                        <vs-button
                            v-if="isAdmin()"
                            :id="'modifyImage_' + image.id"
                            v-tooltip="'Modifier'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-edit"
                            style="position: absolute; right: 10px"
                            @click="uploadPrompt(image.id)"
                        />
                    </div>
                </vx-card>
            </div>

            <DialogPrompt
                :active.sync="active"
                :title="`Importer une nouvelle image`"
                cancel-text="Annuler"
                accept-text="Importer"
                :is-valid="true"
                @cancel="close()"
                @accept="startImport()"
            >
                <div class="con-exemple-prompt">
                    <div class="vx-row mb-6 ml-10">
                        <span>Sélectionner un fichier (.jpeg, .png...) : </span>
                    </div>
                    <div class="vx-row mb-6 ml-10">
                        <input
                            type="file"
                            accept="image/*"
                            name="file"
                            class="w-full"
                            @change="selectFile"
                        />
                    </div>
                </div>
            </DialogPrompt>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        api: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            image_id: 0,
            active: false,
            file: null,
            images: [],
            loading: true
        };
    },

    watch: {
        loading(newValue) {
            if (newValue) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        },
        items(value) {
            this.images = this.getServicesImage(value);
        }
    },

    methods: {
        storeImage(data) {
            this.loading = true;

            let form = new FormData();
            form.append('image', data.image);
            form.append("_method", "put");

            window.axios({
                method: 'post',
                url: `${this.api}/${data.id}`,
                data: form,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(res => {
                const body = res.data;
                if (body.status === "success") {
                    this.loadItems();
                    this.images = this.getServicesImage(this.items);
                    this.notifySuccess('Succès', 'Élement modifié avec succès !')
                }
            })
            .catch(err => {
                console.error(err);
                this.loading = false
                this.notifyError('Erreur', `Une erreur est survenue`)
            });
        },

        deleteImage(id) {
            this.apiDelete(id, () => this.loadItems());
        },

        uploadPrompt(id) {
            this.active = true;
            this.image_id = id;
        },

        selectFile(e) {
            if (e.target.files.length !== 0) {
                this.file = e.target.files[0];
            }
        },

        close() {
            this.active = false;
            this.file = null;
        },

        startImport() {
            this.loading = true;
            this.storeImage({
                image: this.file,
                id: this.image_id
            });
        },
        isAdmin() {
            return (
                this.isAnAdmin(this.user) ||
                this.isASuperAdmin(this.user) ||
                this.isADrh(this.user)
            );
        }
    },
    created() {
        this.images = this.servicesImage;
        this.loadItems();
    }
};
</script>

<style lang="scss" scoped>
.overlayed {
    min-height: 250px;
    background-size: cover;
}
</style>
