<template>
    <div>
        <vs-button
            id="addParams"
            color="primary"
            type="filled"
            icon-pack="feather"
            icon="icon-plus"
            @click="createParamsPrompt"
        >
            Ajouter un nouvel import
        </vs-button>

        <vs-prompt
            :active.sync="displayPrompt"
            title="Définition des paramètres de l'import"
            cancel-text="Annuler"
            accept-text="Enregistrer"
            @cancel="initResource"
            @accept="createResource"
        >
            <div class="con-exemple-prompt">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Protocol:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full" v-if="resource.fields.length">
                        <multiselect
                            v-model="resource.protocol"
                            name="protocol"
                            :options="protocols"
                            track-by="slug"
                            label="name"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Recherche ..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                        />
                    </div>
                    <div class="vx-col sm:w-2/3 w-full" v-else>
                        <vs-input
                            v-model="resource.protocol.name"
                            name="protocol"
                            class="w-full"
                            readonly="readonly"
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Cible *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            v-model="resource.target"
                            name="target"
                            :options="targets"
                            track-by="slug"
                            label="name"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Recherche ..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Type *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            v-model="resource.extension"
                            name="extension"
                            :options="fileTypes"
                            track-by="slug"
                            label="name"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Recherche ..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                        />
                    </div>
                </div>

                <div class="vx-row mb-6" v-if="resource.extension && resource.extension.slug != 'excel'">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Séparateur *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            v-model="resource.separator"
                            name="separator"
                            :options="separators"
                            track-by="slug"
                            label="name"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Recherche ..."
                            select-label=""
                            select-group-label=""
                            selected-label=""
                            deselect-label=""
                            deselect-group-label=""
                        />
                    </div>
                </div>

                <div v-if="resource.protocol && resource.protocol.slug !== 'upload'" class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Nom du fichier (path absolu) *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.filename"
                            name="filename"
                            class="w-full"
                        />
                    </div>
                </div>

                <div v-if="resource.protocol && resource.protocol.slug === 'upload'" class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Répertoire *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.folder"
                            name="folder"
                            class="w-full"
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Titres présents *:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-switch
                            v-model="resource.firstIsTitle"
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span><strong>Format du fichier:</strong></span>
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Charger un fichier exemple:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <input type="file" @change="onFileChange" />
                    </div>
                </div>

                <vs-table
                    v-if="fileRows.length"
                    style="width:100%"
                    :data="fileRows"
                >
                    <template slot-scope="{ data }">
                        <vs-tr>
                            <vs-td v-for="(cell, j) in data[0]" :key="'associate_cell-'+j">
                                <vx-tooltip :text="textComment(resource.fields[j])" position="top">
                                    <multiselect
                                        :id="'select-associate_cell-'+j"
                                        v-model="resource.fields[j]"
                                        :name="'field_'+j"
                                        :options="targetFields.filter(t => !t.hidden)"
                                        track-by="dbField"
                                        label="name"
                                        :multiple="false"
                                        :group-select="false"
                                        placeholder="Recherche ..."
                                        select-label=""
                                        select-group-label=""
                                        selected-label=""
                                        deselect-label=""
                                        deselect-group-label=""
                                        @input="changeField($event, j)"
                                        style="min-width: 144px;"
                                    />
                                </vx-tooltip>
                            </vs-td>
                        </vs-tr>
                        <vs-tr>
                            <vs-td v-for="(cell, j) in data[0]" :key="'mask_cell-'+j">
                                <vs-input
                                    v-if="resource.fields[j] && resource.fields[j].mask"
                                    v-model="resource.masks[j]"
                                    :placeholder="resource.fields[j].mask"
                                    :name="'mask_'+j"
                                    class="w-full"
                                />
                            </vs-td>
                        </vs-tr>
                        <vs-tr v-if="!resource.firstIsTitle">
                            <vs-td v-for="(cell, j) in data[0]" :key="'header_cell-'+j" class="font-bold">
                                Colonne {{ j+1 }}
                            </vs-td>
                        </vs-tr>
                        <vs-tr v-else>
                            <vs-td v-for="(cell, j) in data[0]" :key="'header_cell-'+j" class="font-bold">
                                {{ cell }}
                            </vs-td>
                        </vs-tr>
                        <vs-tr v-for="(row, i) in data.slice(resource.firstIsTitle ? 1 : 0)" :key="'row-'+i">
                            <vs-td v-for="(cell, k) in row" :key="'row-'+i+'_cell-'+k">{{ cell }}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>

                <vs-table
                    v-else-if="resource.headers.length"
                    style="width:100%"
                    :data="resource.headers"
                >
                    <template slot-scope="{ data }">
                        <vs-tr>
                            <vs-td v-for="(cell, j) in data" :key="'associate_cell-'+j">
                                <vx-tooltip :text="textComment(resource.fields[j])" position="top">
                                    <multiselect
                                        :id="'select-associate_cell-'+j"
                                        v-model="resource.fields[j]"
                                        :name="'field_'+j"
                                        :options="targetFields.filter(t => !t.hidden)"
                                        track-by="dbField"
                                        label="name"
                                        :multiple="false"
                                        :group-select="false"
                                        placeholder="Recherche ..."
                                        select-label=""
                                        select-group-label=""
                                        selected-label=""
                                        deselect-label=""
                                        deselect-group-label=""
                                        @input="changeField($event, j)"
                                        style="min-width: 144px;"
                                    />
                                </vx-tooltip>
                            </vs-td>
                        </vs-tr>
                        <vs-tr>
                            <vs-td v-for="(cell, j) in data" :key="'mask_cell-'+j">
                                <vs-input
                                    v-if="resource.fields[j] && resource.fields[j].mask"
                                    v-model="resource.masks[j]"
                                    :name="'mask_'+j"
                                    :placeholder="resource.fields[j].mask"
                                    class="w-full"
                                />
                            </vs-td>
                        </vs-tr>
                        <vs-tr>
                            <vs-td v-for="(cell, j) in data" :key="'header_cell-'+j" class="font-bold">
                                {{ cell }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>

            </div>
        </vs-prompt>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="items"
            :loading="true"
        >
            <template slot="thead">
                <vs-th>Protocol</vs-th>
                <vs-th>Cible</vs-th>
                <vs-th>Type</vs-th>
                <vs-th>Répertoire</vs-th>
                <vs-th>Nom du fichier</vs-th>
                <vs-th>Fichier exemple</vs-th>
                <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr v-for="(item, i) in data" :key="i">
                    <vs-td>
                        {{ get(item, ':value.protocol.name') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':value.target.name') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':value.extension.name') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':value.folder') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':value.filename') }}
                        <feather-icon
                            v-if="get(item, ':value.filename')"
                            icon="DownloadIcon"
                            svg-classes="h-6 w-6 icon-info vs-icon-primary"
                            class="ml-2"
                            v-tooltip="'Télécharger fichier distant utilisé pour l\'import'"
                            @click.stop="downloadExternalFile(item)"
                        />
                    </vs-td>
                    <vs-td v-if="get(item, ':value.uploaded_file')">
                        {{ get(item, ':value.uploaded_file') }}
                        <feather-icon
                            icon="TrashIcon"
                            svg-classes="h-6 w-6 icon-info vs-icon-primary"
                            class="ml-2"
                            v-tooltip="'Supprimer fichier'"
                            @click.stop="deleteFile(item)"
                        />
                        <feather-icon
                            icon="DownloadIcon"
                            svg-classes="h-6 w-6 icon-info vs-icon-primary"
                            class="ml-2"
                            v-tooltip="'Télécharger fichier d\'import'"
                            @click.stop="downloadFile(item)"
                        />
                    </vs-td>
                    <vs-td v-else>
                        <input type="file" @change="onUpdateFile($event, item.id)" />
                    </vs-td>
                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                id="updateParams"
                                v-tooltip="'Modifier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click.stop="updateParamsPrompt(item)"
                            />

                            <vs-button
                                id="downloadParamsFile"
                                v-tooltip="'Exécuter l\'import'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-upload"
                                @click.stop="importFromFile(item)"
                                :disabled="!get(item, ':value.uploaded_file')"
                            />

                            <vs-button
                                id="deleteParams"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click.stop="deleteResource(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>

        </vs-table>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import readXlsxFile from 'read-excel-file'
import { read, utils } from 'xlsx'
import objectToFormData from "object-to-formdata"
export default {
    name: "ImportsParams",
    components: {
        Multiselect,
        readXlsxFile,
        read,
        utils,
        objectToFormData,
    },
    props: {
        api: {
            type: String
        },
        config: {
            type: Object
        }
    },
    data() {
        return {
            resource: {
                protocol: {},
                target: null,
                extension: null,
                folder: null,
                filename: null,
                uploaded_file: null,
                separator: null,
                firstIsTitle : false,
                fields: [],
                headers: [],
                masks: [],
            },
            fileTypes: [
                {slug: 'csv', name: 'CSV'},
                {slug: 'excel', name: 'Excel'},
            ],
            separators: [
                {slug: 'comma', name: 'Virgule', value: ','},
                {slug: 'semicolon', name: 'Point-virgule', value: ';'},
                {slug: 'tab', name: 'Tabulation', value: '\t'}
            ],
            fileRows: [],
            loading: false,
            displayPrompt: false
        };
    },

    watch: {
        'resource': {
            handler (value) {
                console.log('Resource updated', window._.cloneDeep(this.resource))
            }, deep: true
        },
        headers: {
            handler (value) {
                console.log('Hearders updated', window._.cloneDeep(this.headers))
            }, deep: true
        }
    },

    computed: {
        headers() {
            if (this.fileRows.length) {
                if (this.resource.firstIsTitle) {
                    this.resource.headers = this.fileRows[0]
                    return this.fileRows[0]
                }
                else {
                    let headers = this.fillEmptyHeaders(this.fileRows[0])
                    this.resource.headers = headers
                    return headers
                }
            }
            else {
                if (this.resource.headers.length) {
                    return this.resource.headers
                }
                else return []
            }
        },
        targets() {
            return this.config['targets']
        },
        targetFields() {
            return this.config[this.resource.target?.slug]
        },
        protocols() {
            return this.config['protocols']
        }
    },

    methods: {
        createParamsPrompt() {
            this.initResource()
            this.displayPrompt = true
        },
        updateParamsPrompt(item) {
            this.resource = item.value
            this.resource.id = item.id
            this.resource.name = 'importParams'
            this.fileRows = []
            this.displayPrompt = true
            console.log('Resource', window._.cloneDeep(this.resource))
        },
        importFromFile(item) {
            window.axios({
                method: 'post',
                url: `${this.api}/${item.id}/import-file`,
            })
            .then(response => {
                this.notifySuccess('Import terminé')
            })
            .catch(err => {
                this.notifyError(err, 'Erreur !', true)
            })
        },
        deleteFile(item) {
            this.loading = true
            window.axios({
                method: 'post',
                url: `${this.api}/${item.id}/delete-file`,
            })
            .then(response => {
                this.loadItems()
                this.loading = false;
                this.notifySuccess()
            })
            .catch(err => {
                this.loading = false;
                this.notifyError(err, 'Erreur !', true)
            })
        },
        downloadFile(item) {
            window.axios({
                method: 'post',
                url: `${this.api}/${item.id}/download-local-file`,
                responseType: 'blob',
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', item.value.uploaded_file); // Spécifie le nom du fichier à télécharger
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                this.notifyError(err, `Une erreur est survenue`, true);
            })
        },
        downloadExternalFile(item) {
            console.log(item)
            window.axios({
                method: 'post',
                url: `${this.api}/${item.id}/download-distant-file`,
                responseType: 'blob',
            })
                .then(response => {
                    console.log('Download DONE !!')
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.value.filename); // Spécifie le nom du fichier à télécharger
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`, true);
                })
        },
        updateFile(item) {
            this.loading = true
            let form = new FormData();
            form.append('file', item.file);
            window.axios({
                method: 'post',
                data: form,
                url: `${this.api}/${item.id}/update-file`,
                headers: {'Content-Type': 'multipart/form-data'},
            })
            .then(response => {
                this.loadItems()
                this.loading = false;
                this.notifySuccess()
            })
            .catch(err => {
                this.loading = false;
                this.notifyError(err, 'Erreur !', true)
            })
        },

        initResource() {
            this.displayPrompt = false
            this.resource = {
                protocol: {
                    slug: 'upload',
                    name: 'Upload (local)'
                },
                name: 'importParams',
                target: null,
                extension: null,
                folder: null,
                filename: null,
                uploaded_file: null,
                separator: null,
                firstIsTitle: false,
                fields: [],
                headers: [],
                masks: [],
            }
            this.fileRows = []
        },
        createResource() {
            this.loading = true;
            console.log('this.resource', this.resource)
            let data = window._.cloneDeep(this.resource)
            this.targetFields.filter(t => t.hidden).forEach(f => {
                data.fields.push(f)
            })
            data.fields = JSON.stringify(data.fields)
            data.masks = JSON.stringify(data.masks)
            this.apiCreate(
                data,
                resp => {
                    this.loadItems()
                    this.loading = false;
                },
                err => {
                    this.displayPrompt = true
                    this.loading = false;
                },
                {'Content-Type': 'multipart/form-data'}
            );
        },

        updateResource(data) {
            this.loading = true;
            this.targetFields.filter(t => t.hidden).forEach(f => {
                data.fields.push(f)
            })
            data.fields = JSON.stringify(data.fields)
            data.masks = JSON.stringify(data.masks)

            this.apiUpdate(
                data,
                data.id,
                resp => {
                    this.loadItems()
                    this.loading = false;
                },
                err => {
                    this.displayPrompt = true
                    this.loading = false;
                },
                {'Content-Type': 'multipart/form-data'}
            );
        },

        deleteResource(item) {
            this.apiDelete(item.id, () => this.loadItems());
        },

        onFileChange(event) {
            let file = event.target.files ? event.target.files[0] : null
            if (file) {
                console.log('File', file, file.value, file.name)
                let extension = file.name?.split('.').pop().toLowerCase()
                switch (extension) {
                    case 'csv':
                        console.log('File is a CSV');
                        this.processCSVFile(file)
                        break;

                    case 'xls':
                        console.log('File is an Excel Xls');
                        this.processXlsFile(file)
                        break;
                    case 'xlsx':
                        console.log('File is an Excel Xlsx');
                        this.processExcelFile(file)
                        break;

                    default:
                        console.log('File is not a CSV nor an Excel');
                        break;
                }
                this.resource.file = file
                this.resource.uploaded_file = file.name
            }
        },
        onUpdateFile(event, settingId) {
            let file = event.target.files ? event.target.files[0] : null
            let item = {
                id: settingId,
                file: file,
            }
            this.updateFile(item)
        },

        processCSVFile(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                console.log('CSV content', e.target.result)
                let rows = e.target.result
                    .split(/(?:\r\n|\n)+/)
                    .filter(el => el.length !== 0)
                    .slice(0, 5)
                this.fileRows = rows.map(r => r.split(this.resource.separator?.value || ','))

            };
            reader.readAsText(file);
        },
        processExcelFile(file) {
            readXlsxFile(file).then((rows) => {
                console.log('Rows', rows)
                // `rows` is an array of rows
                // each row being an array of cells.
                //display only a part fo the file as example
                this.fileRows = rows.slice(0, 5)
            })
        },
        processXlsFile(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                let data = new Uint8Array(e.target.result)
                let workbook = read(data, {type: 'array'})
                let result = []
                workbook.SheetNames.forEach(function (sheetName) {
                    let roa = utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1})
                    if (roa.length) result.push(roa)
                })
                this.fileRows = result[0].slice(0, 5)
            };
            reader.readAsArrayBuffer(file);
        },

        changeField(event, index) {
            console.log('Select change', index, event)
            //this.resource.fields[index] = event
        },

        fillEmptyHeaders(cells) {
            let headers = []
            cells.forEach((cell, index) => {
                headers[index] = 'Colonne '+(index+1)
            })

            return headers
        },

        textComment(field) {
            return field?.comment
        }
    },

    created() {
        this.apiParams.filter = 'importParams';
        this.loadItems();
    },
}
</script>

<style scoped>

</style>
