var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        { staticClass: "mt-20" },
        [
          _c(
            "vs-col",
            {
              staticStyle: { padding: "0 0 !important" },
              attrs: { "vs-w": "2" }
            },
            [_c("h3", [_vm._v("Votre formule d’abonnement:")])]
          ),
          _vm._v(" "),
          _c("vs-col", { attrs: { "vs-w": "9" } }, [
            _c("h3", [_vm._v(_vm._s(_vm.plan.name))]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h5", { staticStyle: { "margin-top": "-15px" } }, [
              _vm._v(_vm._s(_vm.plan.details))
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h6", { staticStyle: { "margin-top": "-10px" } }, [
              _vm._v(
                _vm._s(
                  _vm.plan.nb_users
                    ? _vm.plan.nb_users + " utilisateurs"
                    : "Utilisateurs illimités"
                ) +
                  "\n                - " +
                  _vm._s(
                    _vm.plan.nb_licences_min
                      ? "à partir de " + _vm.plan.nb_licences_min + " licences"
                      : _vm.plan.nb_licences_max + " licences"
                  ) +
                  "\n            "
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("vs-row", { staticClass: "mt-20" }, [
        _c("h3", [_vm._v("Licences")]),
        _vm._v(" "),
        _c("table", { staticClass: "table", attrs: { id: "main-licence" } }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Nb total")]),
              _vm._v(" "),
              _c("th", [_vm._v("Nb de licences affectées")]),
              _vm._v(" "),
              _c("th", [_vm._v("Nb de licences restant à affecter")]),
              _vm._v(" "),
              _c("th", [_vm._v("Date anniversaire du contrat")])
            ])
          ]),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.licence.quantity))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.licence.affected))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.licence.still_to_affected))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.formatDate(_vm.licence.anniversary)))
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mt-10" },
        [
          _c("h3", [_vm._v("Liste de mes établissements")]),
          _vm._v(" "),
          _c(
            "vs-col",
            { attrs: { "vs-align": "left", "vs-w": "6" } },
            [
              _c(
                "vs-prompt",
                {
                  attrs: {
                    active: _vm.showPopup,
                    title: _vm.getDataPrompt.title,
                    "cancel-text": "Fermer",
                    "accept-text": "Enregistrer",
                    "button-accept": _vm.getDataPrompt.btnAccept,
                    "is-valid": !_vm.formError
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.showPopup = $event
                    },
                    cancel: _vm.resetResource,
                    accept: _vm.storeResource,
                    close: _vm.resetResource
                  }
                },
                [
                  _c("EstablishmentForm", {
                    attrs: { type: _vm.formType },
                    on: { error: _vm.setFormError },
                    model: {
                      value: _vm.resource,
                      callback: function($$v) {
                        _vm.resource = $$v
                      },
                      expression: "resource"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("vs-col", {
            attrs: { "vs-type": "flex", "vs-align": "left", "vs-w": "6" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full p-0" },
            [
              _vm.can("create", "establishments")
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        id: "btn-create",
                        color: "primary",
                        type: "filled",
                        "icon-pack": "feather",
                        icon: "icon-plus"
                      },
                      on: {
                        click: function($event) {
                          return _vm.createResource()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                Ajouter un nouvel établissement\n            "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            sst: true,
            search: "",
            "no-data-text": _vm.loading
              ? "Chargement de vos données..."
              : "Aucune donnée à afficher",
            data: _vm.items.data
          },
          on: { search: _vm.eventSearchItems, sort: _vm.eventSortByCol },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return _vm._l(data, function(item, i) {
                  return _c(
                    "vs-tr",
                    { key: i, attrs: { data: item } },
                    [
                      _c(
                        "vs-td",
                        [
                          item.logo
                            ? _c("img", {
                                attrs: {
                                  src: _vm.get(item, ":logo"),
                                  alt: "logo",
                                  width: "70",
                                  height: "70"
                                }
                              })
                            : _c("vs-avatar", {
                                attrs: {
                                  size: "70px",
                                  text: item.name,
                                  color: _vm.generateColor(item.name)
                                }
                              })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.get(item, ":name")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.get(item, ":type")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "vs-td",
                        [
                          _vm.canUpdateLicence()
                            ? _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  type: "text",
                                  value:
                                    _vm.items.data[i].establishment_licence
                                      .quantity
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changeLicenceQuantityHandle(
                                      item,
                                      $event
                                    )
                                  }
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.items.data[i].establishment_licence
                                      .quantity
                                  )
                                )
                              ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.get(item, ":establishment_licence.consumed")
                            ) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.get(item, ":establishment_licence.available")
                            ) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.types.data, function(type, index) {
                        return !_vm.types.loading
                          ? _c(
                              "vs-td",
                              { key: index, attrs: { align: "right" } },
                              [
                                _vm.licences[i]
                                  ? _c("vs-switch", {
                                      attrs: {
                                        disabled:
                                          !_vm.isASuperAdmin() ||
                                          type.slug == "rh"
                                            ? true
                                            : false
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.activeLicenceHandle(
                                            item,
                                            _vm.licences[i]
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.licences[i][type.slug].active,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.licences[i][type.slug],
                                            "active",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licences[i][type.slug].active"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c("vs-td", { staticClass: "action" }, [
                        _c(
                          "div",
                          { staticClass: "action-wrapper" },
                          [
                            _vm.isEditable(item)
                              ? _c("vs-button", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Modifier",
                                      expression: "'Modifier'"
                                    }
                                  ],
                                  attrs: {
                                    id: "btn-update-" + i,
                                    color: "primary",
                                    type: "border",
                                    "icon-pack": "feather",
                                    icon: "icon-edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateResource(item)
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isDeletable(item)
                              ? _c("vs-button", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Supprimer",
                                      expression: "'Supprimer'"
                                    }
                                  ],
                                  attrs: {
                                    id: "btn-delete-" + i,
                                    color: "primary",
                                    type: "border",
                                    "icon-pack": "feather",
                                    icon: "icon-trash"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteResource(item)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                })
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { attrs: { width: "160" } }),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "name" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Établissement")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "type" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Type")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Nb licences affectées")]),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Nb licences utilisées")]),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Nb licences disponibles")]),
              _vm._v(" "),
              _vm._l(_vm.types.data, function(type, index) {
                return !_vm.types.loading
                  ? _c("vs-th", { key: index, staticClass: "align-end" }, [
                      _vm._v(
                        "\n                Licence " +
                          _vm._s(type.name) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _c("vs-th")
            ],
            2
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _vm.items.total > 0
            ? _c(
                "vs-col",
                { staticClass: "mt-4" },
                [
                  _c("vs-pagination", {
                    attrs: {
                      total: Math.ceil(_vm.items.total / _vm.apiParams.perpage)
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }