var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-align": "left",
                "vs-w": "6",
                "vs-justify": "flex-end"
              }
            },
            [
              _vm.isAdminInterface() ||
              _vm.userType === "Evaluateur" ||
              _vm.can("create", "users")
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        id: "btn-create",
                        color: "primary",
                        type: "filled",
                        "icon-pack": "feather",
                        icon: "icon-plus"
                      },
                      on: {
                        click: function($event) {
                          return _vm.createResource(_vm.userType)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                Ajouter un nouvel " +
                          _vm._s(_vm.userType.toLowerCase()) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "DialogPrompt",
                {
                  attrs: {
                    active: _vm.showPopup,
                    title: _vm.getDataPrompt.title,
                    "cancel-text": "Fermer",
                    "accept-text": "Enregistrer",
                    "button-accept": _vm.getDataPrompt.btnAccept,
                    "is-valid": !_vm.formError
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.showPopup = $event
                    },
                    cancel: _vm.resetResource,
                    accept: _vm.storeResource,
                    close: _vm.resetResource
                  }
                },
                [
                  _c("UserForm", {
                    attrs: {
                      type: _vm.formType,
                      "user-type": _vm.userType,
                      "deleted-users-emails": _vm.deletedUsers.map(function(
                        user
                      ) {
                        return user.email
                      })
                    },
                    on: {
                      error: _vm.setFormError,
                      "email-in-deleted": _vm.emailInDeleted
                    },
                    model: {
                      value: _vm.resource,
                      callback: function($$v) {
                        _vm.resource = $$v
                      },
                      expression: "resource"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "DialogPrompt",
                {
                  staticStyle: { "z-index": "53000" },
                  attrs: {
                    active: _vm.showDeletedPopup,
                    title: "Utilisateur archivé",
                    "cancel-text": "Non",
                    "accept-text": "Oui",
                    "button-accept": _vm.getDataPrompt.btnAccept
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.showDeletedPopup = $event
                    },
                    accept: _vm.undeleteAndFillForm,
                    cancel: function($event) {
                      _vm.emailToUndelete = null
                    },
                    close: function($event) {
                      _vm.emailToUndelete = null
                    }
                  }
                },
                [
                  _c("p", [
                    _vm._v(
                      "Cet email est utilisé dans un compte utilisateur archivé, souhaitez-vous le réactiver ?"
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("vs-col", {
            attrs: { "vs-type": "flex", "vs-align": "left", "vs-w": "6" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            sst: true,
            search: "",
            "no-data-text": _vm.loading
              ? "Chargement de vos données..."
              : "Aucune donnée à afficher",
            data_: _vm.items.data.filter(function(user) {
              return !user.deleted_at
            }),
            data: _vm.items.data
          },
          on: { search: _vm.eventSearchItems, sort: _vm.eventSortByCol },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return _vm._l(data, function(item, i) {
                  return _c(
                    "vs-tr",
                    {
                      key: i,
                      class: item.deleted_at ? "deleted" : "",
                      attrs: { data: item }
                    },
                    [
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.get(item, ":id")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.get(item, ":last_name")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.get(item, ":first_name")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.get(item, ":email")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "vs-td",
                        { attrs: { data: item.name } },
                        [
                          _c("vs-chip", { attrs: { color: "primary" } }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  item.roles[0]
                                    ? item.roles[0].display_name
                                    : null
                                ) +
                                "\n                    "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-td",
                        [
                          _c(
                            "vs-chip",
                            {
                              attrs: {
                                color:
                                  item.reference === 1 ? "success" : "danger"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.reference === 1 ? "Oui" : "Non") +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-td",
                        _vm._l(item.establishments, function(establishment, j) {
                          return _c("div", { key: j }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(establishment.name) +
                                "\n                    "
                            )
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-td",
                        _vm._l(item.services, function(service, j) {
                          return _c("div", { key: j }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(service.title) +
                                "\n                    "
                            )
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.isASuperAdmin(_vm.user)
                        ? [
                            item.email_verified_at
                              ? _c("vs-td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.formatDate(item.email_verified_at)
                                      ) +
                                      "\n                    "
                                  )
                                ])
                              : !item.fresh_token
                              ? _c(
                                  "vs-td",
                                  { staticClass: "center" },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value:
                                              "Rafraîchir le token pour la réinitialisation du mot de passe",
                                            expression:
                                              "'Rafraîchir le token pour la réinitialisation du mot de passe'"
                                          }
                                        ],
                                        staticStyle: { margin: "0 0 0 5px" },
                                        attrs: {
                                          id: "refreshToken_" + i,
                                          color: "warning",
                                          type: "flat",
                                          text: "Rafraîchir",
                                          "icon-pack": "feather",
                                          icon: "icon-send"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.refreshToken(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Rafraîchir token\n                    "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "vs-td",
                                  { staticClass: "center text-center" },
                                  [
                                    _vm._v(
                                      "\n                        x\n                    "
                                    )
                                  ]
                                )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isASuperAdmin(_vm.user)
                        ? [
                            item.email_verified_at
                              ? _c("vs-td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.formatDate(item.email_verified_at)
                                      ) +
                                      "\n                    "
                                  )
                                ])
                              : item.fresh_token
                              ? _c(
                                  "vs-td",
                                  { staticClass: "center" },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value:
                                              "Envoyer l'email d'invitation",
                                            expression:
                                              "'Envoyer l\\'email d\\'invitation'"
                                          }
                                        ],
                                        staticStyle: { margin: "0 0 0 5px" },
                                        attrs: {
                                          id: "sendEmail_" + i,
                                          color: "warning",
                                          type: "flat",
                                          text: "Envoyer",
                                          "icon-pack": "feather",
                                          icon: "icon-send"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.sendInvitation(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Envoyer\n                        "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "vs-td",
                                  { staticClass: "center text-center" },
                                  [
                                    _vm._v(
                                      "\n                        x\n                    "
                                    )
                                  ]
                                )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isASuperAdmin(_vm.user)
                        ? [
                            item.email_verified_at
                              ? _c("vs-td", { staticClass: "center" }, [
                                  _vm._v(" ")
                                ])
                              : item.fresh_token
                              ? _c(
                                  "vs-td",
                                  { staticClass: "center" },
                                  [
                                    _c("vs-input", {
                                      attrs: {
                                        id: "resetUrl-hidden-" + item.id,
                                        type: "hidden"
                                      },
                                      model: {
                                        value: item.resetUrl,
                                        callback: function($$v) {
                                          _vm.$set(item, "resetUrl", $$v)
                                        },
                                        expression: "item.resetUrl"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "vs-button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value:
                                              "Copier le lien de réinitialisation",
                                            expression:
                                              "'Copier le lien de réinitialisation'"
                                          }
                                        ],
                                        staticStyle: { margin: "0 0 0 5px" },
                                        attrs: {
                                          id: "reinitUser_" + i,
                                          color: "warning",
                                          type: "flat",
                                          text: "Copier lien",
                                          "icon-pack": "feather",
                                          icon: "icon-send",
                                          "data-resetUrl": item.resetUrl
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.copyReinitLink(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Copier lien réinitialisation\n                        "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "vs-td",
                                  { staticClass: "center text-center" },
                                  [
                                    _vm._v(
                                      "\n                        x\n                    "
                                    )
                                  ]
                                )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("vs-td", { staticClass: "action" }, [
                        !item.deleted_at
                          ? _c(
                              "div",
                              { staticClass: "action-wrapper" },
                              [
                                _vm.isEditable(item)
                                  ? _c("vs-button", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: "Modifier",
                                          expression: "'Modifier'"
                                        }
                                      ],
                                      attrs: {
                                        id: "btn-update-" + i,
                                        color: "primary",
                                        type: "border",
                                        "icon-pack": "feather",
                                        icon: "icon-edit"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateResource(
                                            item,
                                            _vm.userType
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isDeletable(item)
                                  ? _c("vs-button", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: "Supprimer",
                                          expression: "'Supprimer'"
                                        }
                                      ],
                                      attrs: {
                                        id: "btn-delete-" + i,
                                        color: "primary",
                                        type: "border",
                                        "icon-pack": "feather",
                                        icon: "icon-trash"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteResource(item)
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "action-wrapper" },
                              [
                                _vm.isDeletable(item)
                                  ? _c("vs-button", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: "Restaurer",
                                          expression: "'Restaurer'"
                                        }
                                      ],
                                      attrs: {
                                        id: "btn-update-" + i,
                                        color: "primary",
                                        type: "border",
                                        "icon-pack": "feather",
                                        icon: "icon-rotate-ccw"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.restoreResource(item)
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                      ])
                    ],
                    2
                  )
                })
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    { attrs: { name: "id" }, on: { sort: _vm.eventSortByCol } },
                    [_vm._v("#")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "last_name" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Nom")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "first_name" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Prénom")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "email" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Email")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Rôle")]),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Référent")]),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Établissement(s)")]),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Service(s)")]),
              _vm._v(" "),
              _vm.isASuperAdmin(_vm.user)
                ? _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("Compte activé")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isASuperAdmin(_vm.user)
                ? _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("Email réinitialisation")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isASuperAdmin(_vm.user)
                ? _c("vs-th", [_vm._v("Lien réinitialisation")])
                : _vm._e(),
              _vm._v(" "),
              _c("vs-th", { attrs: { width: "160" } })
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _vm.items.total > 0
            ? _c(
                "vs-col",
                { staticClass: "mt-4" },
                [
                  _c("vs-pagination", {
                    attrs: {
                      total: Math.ceil(_vm.items.total / _vm.apiParams.perpage)
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }