var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { attrs: { title: "Gérer la notation" } }, [
        _c("div", { staticClass: "vx-row mb-6 mt-6" }, [
          _c("div", { staticClass: "vx-col w-full" }, [
            _c("h4", [
              _vm._v("Paramètres des " + _vm._s(_vm.projectNames.plural))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-10" }, [
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h5", [_vm._v("Compétences")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre 1 :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param1Note",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.params[1].note,
                    callback: function($$v) {
                      _vm.$set(_vm.params[1], "note", $$v)
                    },
                    expression: "params[1].note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param1Label",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.params[1].label,
                    callback: function($$v) {
                      _vm.$set(_vm.params[1], "label", $$v)
                    },
                    expression: "params[1].label"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre 2 :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param2Note",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.params[2].note,
                    callback: function($$v) {
                      _vm.$set(_vm.params[2], "note", $$v)
                    },
                    expression: "params[2].note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param2Label",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.params[2].label,
                    callback: function($$v) {
                      _vm.$set(_vm.params[2], "label", $$v)
                    },
                    expression: "params[2].label"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre 3 :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param3Note",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.params[3].note,
                    callback: function($$v) {
                      _vm.$set(_vm.params[3], "note", $$v)
                    },
                    expression: "params[3].note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param3Label",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.params[3].label,
                    callback: function($$v) {
                      _vm.$set(_vm.params[3], "label", $$v)
                    },
                    expression: "params[3].label"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre 4 :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param4Note",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.params[4].note,
                    callback: function($$v) {
                      _vm.$set(_vm.params[4], "note", $$v)
                    },
                    expression: "params[4].note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param4Label",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.params[4].label,
                    callback: function($$v) {
                      _vm.$set(_vm.params[4], "label", $$v)
                    },
                    expression: "params[4].label"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre Non mesuré :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "nonMesuredNote",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.NonMesuredLevel,
                    callback: function($$v) {
                      _vm.NonMesuredLevel = $$v
                    },
                    expression: "NonMesuredLevel"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre En cours :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "nonAcquiredNote",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.NonAcquiredLevel,
                    callback: function($$v) {
                      _vm.NonAcquiredLevel = $$v
                    },
                    expression: "NonAcquiredLevel"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-10" }, [
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h5", [_vm._v("Savoir-faire")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre 1 :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param1Note",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.paramsOccSkill[1].note,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[1], "note", $$v)
                    },
                    expression: "paramsOccSkill[1].note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param1Label",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.paramsOccSkill[1].label,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[1], "label", $$v)
                    },
                    expression: "paramsOccSkill[1].label"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param1Coef",
                    type: "number",
                    "label-placeholder": "Coefficient"
                  },
                  model: {
                    value: _vm.paramsOccSkill[1].coef,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[1], "coef", $$v)
                    },
                    expression: "paramsOccSkill[1].coef"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-5/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param1Description",
                    "label-placeholder": "Signification de la note par défaut"
                  },
                  model: {
                    value: _vm.paramsOccSkill[1].description,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[1], "description", $$v)
                    },
                    expression: "paramsOccSkill[1].description"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre 2 :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param2Note",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.paramsOccSkill[2].note,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[2], "note", $$v)
                    },
                    expression: "paramsOccSkill[2].note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param2Label",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.paramsOccSkill[2].label,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[2], "label", $$v)
                    },
                    expression: "paramsOccSkill[2].label"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param2Coef",
                    type: "number",
                    "label-placeholder": "Coefficient"
                  },
                  model: {
                    value: _vm.paramsOccSkill[2].coef,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[2], "coef", $$v)
                    },
                    expression: "paramsOccSkill[2].coef"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-5/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param2Description",
                    "label-placeholder": "Signification de la note par défaut"
                  },
                  model: {
                    value: _vm.paramsOccSkill[2].description,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[2], "description", $$v)
                    },
                    expression: "paramsOccSkill[2].description"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre 3 :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param3Note",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.paramsOccSkill[3].note,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[3], "note", $$v)
                    },
                    expression: "paramsOccSkill[3].note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param3Label",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.paramsOccSkill[3].label,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[3], "label", $$v)
                    },
                    expression: "paramsOccSkill[3].label"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param3Coef",
                    type: "number",
                    "label-placeholder": "Coefficient"
                  },
                  model: {
                    value: _vm.paramsOccSkill[3].coef,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[3], "coef", $$v)
                    },
                    expression: "paramsOccSkill[3].coef"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-5/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param3Description",
                    "label-placeholder": "Signification de la note par défaut"
                  },
                  model: {
                    value: _vm.paramsOccSkill[3].description,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[3], "description", $$v)
                    },
                    expression: "paramsOccSkill[3].description"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre 4 :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param4Note",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.paramsOccSkill[4].note,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[4], "note", $$v)
                    },
                    expression: "paramsOccSkill[4].note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param4Label",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.paramsOccSkill[4].label,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[4], "label", $$v)
                    },
                    expression: "paramsOccSkill[4].label"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param4Coef",
                    type: "number",
                    "label-placeholder": "Coefficient"
                  },
                  model: {
                    value: _vm.paramsOccSkill[4].coef,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[4], "coef", $$v)
                    },
                    expression: "paramsOccSkill[4].coef"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-5/12 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "param4Description",
                    "label-placeholder": "Signification de la note par défaut"
                  },
                  model: {
                    value: _vm.paramsOccSkill[4].description,
                    callback: function($$v) {
                      _vm.$set(_vm.paramsOccSkill[4], "description", $$v)
                    },
                    expression: "paramsOccSkill[4].description"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-10" }, [
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h4", [_vm._v("Paramètres de l'accessibilité")])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre Requis :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "requiredLevelNote",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.RequiredLevel.note,
                    callback: function($$v) {
                      _vm.$set(_vm.RequiredLevel, "note", $$v)
                    },
                    expression: "RequiredLevel.note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "requiredLevelCoef",
                    type: "number",
                    "label-placeholder": "Coefficient"
                  },
                  model: {
                    value: _vm.RequiredLevel.coef,
                    callback: function($$v) {
                      _vm.$set(_vm.RequiredLevel, "coef", $$v)
                    },
                    expression: "RequiredLevel.coef"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "requiredLevelLabel",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.RequiredLevel.label,
                    callback: function($$v) {
                      _vm.$set(_vm.RequiredLevel, "label", $$v)
                    },
                    expression: "RequiredLevel.label"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre Indispensable :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "indispensableLevelNote",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.IndispensableLevel.note,
                    callback: function($$v) {
                      _vm.$set(_vm.IndispensableLevel, "note", $$v)
                    },
                    expression: "IndispensableLevel.note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "indispensableLevelCoef",
                    type: "number",
                    "label-placeholder": "Coefficient"
                  },
                  model: {
                    value: _vm.IndispensableLevel.coef,
                    callback: function($$v) {
                      _vm.$set(_vm.IndispensableLevel, "coef", $$v)
                    },
                    expression: "IndispensableLevel.coef"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "indispensableLevelLabel",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.IndispensableLevel.label,
                    callback: function($$v) {
                      _vm.$set(_vm.IndispensableLevel, "label", $$v)
                    },
                    expression: "IndispensableLevel.label"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre Autonomie :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "autonomyLevelNote",
                    type: "number",
                    "label-placeholder": "Note"
                  },
                  model: {
                    value: _vm.AutonomyLevel.note,
                    callback: function($$v) {
                      _vm.$set(_vm.AutonomyLevel, "note", $$v)
                    },
                    expression: "AutonomyLevel.note"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "autonomyLevelCoef",
                    type: "number",
                    "label-placeholder": "Coefficient"
                  },
                  model: {
                    value: _vm.AutonomyLevel.coef,
                    callback: function($$v) {
                      _vm.$set(_vm.AutonomyLevel, "coef", $$v)
                    },
                    expression: "AutonomyLevel.coef"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    name: "autonomyLevelLabel",
                    "label-placeholder": "Libellé de la note"
                  },
                  model: {
                    value: _vm.AutonomyLevel.label,
                    callback: function($$v) {
                      _vm.$set(_vm.AutonomyLevel, "label", $$v)
                    },
                    expression: "AutonomyLevel.label"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Paramètre Centre-Radar :")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "radarCenter", type: "number" },
                  model: {
                    value: _vm.RadarCenter,
                    callback: function($$v) {
                      _vm.RadarCenter = $$v
                    },
                    expression: "RadarCenter"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mr-3 mb-2",
                  on: {
                    click: function($event) {
                      return _vm.setRatingSettings()
                    }
                  }
                },
                [_vm._v("\n                    Enregistrer\n                ")]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }