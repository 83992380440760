<template>
    <div>
        <LimitatedPlan v-if="!planAllow('publication-contenu-peda')" class="mb-10"/>
        
        <vs-row>
            <vs-col vs-align="left" vs-w="6">
                <vs-button
                    v-if="planAllow('publication-contenu-peda')"
                    id="addResource"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="showFormCreate"
                >
                    Ajouter une nouvelle ressource
                </vs-button>

                <div v-else style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                    <vs-button
                        id="addResource"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                        disabled="true"
                    >
                        Ajouter une nouvelle ressource
                    </vs-button>
                    <vs-icon class="button-bullet" bg="#FF9B3A" round >
                        <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                    </vs-icon>
                </div>

                <vs-prompt
                    :active.sync="showPopup"
                    :title="getDataPrompt.title"
                    cancel-text="Annuler"
                    accept-text="Enregistrer"
                    :button-accept="getDataPrompt.btnAccept"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @close="resetResource"
                    @accept="saveResource"
                >
                     <TrainingForm
                        v-model="resource"
                        :type="formType"
                        @error="setFormError"
                    />
                </vs-prompt>

            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />

            
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="itemsData"
            :loading="true"
            >
            <template slot="thead">
                <vs-th>
                    <Sort name="document_date" @sort="eventSortByCol">Date du document</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="thematic" @sort="eventSortByCol">Thématique</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="title" @sort="eventSortByCol">Titre</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="duration" @sort="eventSortByCol">Durée (min)</Sort>
                </vs-th>
                <vs-th>
                    Support
                </vs-th>
                <vs-th></vs-th>
            </template>

            <template>
                <vs-tr v-for="(item, i) in items.data" :key="i">
                    <vs-td>
                        {{ get(item, ':document_date') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':thematic') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':title') }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ':duration') }}
                    </vs-td>
                    <vs-td :data="get(item, ':original_filename')">
                        <a v-if="item.original_filename" href="#" @click="downloadClickHandle(item)">
                            <i class="fa fa-paperclip"></i>
                        </a>
                        <a v-if="item.link" href="#" @click="item.link" target="_blank">
                            <i class="fa fa-link"></i>
                        </a>
                    </vs-td>
                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="can('update', 'trainings', item.id) && planAllow('publication-contenu-peda')"
                                v-tooltip="'Modifier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click.stop="showFormUpdate(item)"
                            />

                            <vs-button
                                v-if="can('delete', 'trainings', item.id) && planAllow('publication-contenu-peda')"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click.stop="removeResource(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>

        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination :total="Math.ceil(items.total / apiParams.perpage)" v-model="currentPage"></vs-pagination>
            </vs-col>
        </vs-row>
    </div>

</template>

<script>

import TrainingForm from '@components/views/rh/referentiel/parameters/Training/TrainingForm';
import CrownIcon from '@components/svg/CrownIcon';
import LimitatedPlan from '@components/divers/LimitatedPlan';

export default {
    name: 'Training',
    components: {
        TrainingForm, CrownIcon, LimitatedPlan
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            config: [],
            currentPage: 1,
            loading: false,
            showPopup: false,
            isFormCreation: true,
            resource: {
                title: null,
                sector: null,
                establishment: null,
                referents: []
            },
            formError: true,
            formType: "create",
            prompt: {
                show: {
                    title: 'Consulter une ressource',
                    btnAccept: 'false'
                },
                create: {
                    title: 'Ajouter une ressource',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier une ressource existante',
                    btnAccept: 'filled'
                }
            }
        }
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },

    computed: {
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                    break;
                case 'create':
                    return this.prompt.create
                    break;
                case 'update':
                    return this.prompt.update
                    break;
                default:
                    return this.prompt.show
            }
        },
    },

    methods: {

        showFormCreate() {
            this.resetResource();
            this.formType = "create";
            this.showPopup = true
        },

        showFormUpdate(resource) {
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true
        },

        saveResource() {
            let data = JSON.parse(JSON.stringify(this.resource));
            data.file = this.resource.file;

            data.services = data.services.map(s => s.id);
            data.employees = data.employees.map(e => e.id);

            if (data.id) {
                this.apiUpdate(data, data.id,
                    (resp) => this.loadItems(),
                    (err)  => this.showPopup = true,
                    {'Content-Type': 'multipart/form-data'}
                );
            } else {
                this.apiCreate(data,
                    (resp) => this.loadItems(),
                    (err)  => this.showPopup = true,
                    {'Content-Type': 'multipart/form-data'}
                );
            }
        },

        removeResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            let currentDate = (new Date).toISOString().substring(0, 10);

            this.resource = {
                document_date: currentDate,
                thematic: null,
                title: null,
                emitter: null,
                duration: null,
                path: null,
                services: [],
                employees: []
            }
        },

        setFormError (value) {
            this.formError = value;
        },

        downloadClickHandle(item) {
            window.axios({
                method: "get",
                url: `${this.api}/download/${item.filename}`,
                responseType: 'blob',
            }).then((response) => {
                let blob = new Blob([response.data], { type: response.headers['content-type'] } );
                var link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = item.original_filename;
                link.dispatchEvent(new MouseEvent('click'));
            })
        }
    },
    created() {
        this.apiParams.include = ['employees', 'services'];
        this.loadItems();
    }
}
</script>
