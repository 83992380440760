<template>
    <div>
        <JobsOccupationsResume
            :jobs="value.jobs"
            :occupations="value.occupations"
            :displayWishes="false"
        />

        <div v-if="!loading && !resource.occupations_skills" class="not-data-table vs-table--not-data">Il n'y a aucun savoir-faire à évaluer</div>

        <vs-row>
            <vs-col vs-w="4" class="mt-8" vs-type="flex" vs-justify="flex-start" vs-align="center">
                <vs-checkbox v-model="showDeletedOccSkills">
                    Affichage des savoir-faire supprimés
                </vs-checkbox>
            </vs-col>
            <vs-col vs-w="2" class="mt-8">
                <vs-button 
                    type="border" 
                    class="ml-3"
                    icon-pack="feather"
                    icon="icon-refresh-cw"
                    v-tooltip="`Remplacer les données avec la dernière sauvegarde`"
                    @click="resetFromLastRatingClickHandle"
                >
                    Dernière sauvegarde
                </vs-button>
            </vs-col>
            <vs-col vs-w="6">
                <!-- <vs-row>
                    <vs-col class="font-weight-bold">
                        Comparer avec les évaluations ci-dessous sélectionnées à l'étape Périmètre
                    </vs-col>
                </vs-row> -->
                <vs-row class="mt-8">
                    <vs-col vs-w="6">
                        <vs-checkbox v-model="comparePortalPersonal" :disabled="!hasPersonals">
                            Auto-évaluation Portail
                        </vs-checkbox>
                    </vs-col>
                    <vs-col vs-w="6">
                        <vs-checkbox v-model="comparePortalExternal" :disabled="!hasExternals">
                            Évaluation(s) Portail
                        </vs-checkbox>
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>

        <Rating-Manager
            v-if="resource.occupations_skills || loading"
            name="occupation-skills"
            :type-form="typeForm"
            v-model="resource.occupations_skills"
            :show-deleted="showDeletedOccSkills"
            :col1="{label: 'Savoir-faire', value: 'title', title: 'description'}"
            :col2="{label: 'Poste / Métier', value: 'job_name', title: 'type'}"
            :col3="{label: 'Service / Filière', value: 'service_name'}"
            :loading="loading"
            :skill-review="skillReview"
        />
    </div>
</template>

<script>
import JobsOccupationsResume from "../JobsOccupationsResume";
import RatingManager from '../RatingManager';

export default {
    components: {
        JobsOccupationsResume, RatingManager
    },
    props: {
        typeForm: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        skillReview: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            resource: {
                occupations_skills: []
            },
            showDeletedOccSkills: false,
            comparePortalPersonal: false,
            comparePortalExternal: false,
            iconInfo: '<i class="vs-icon notranslate icon-scale feather icon-info small bg-small vs-icon-bg round" style="background: rgb(217, 217, 217); max-width: 50px;"></i>',
            loading: true,
            ratings: []
        };
    },
    created() {
        this.loading = true;
        this.initResource();
        this.loadDefaultRatings();
    },
    computed: {},
    watch: {
        resource: {
            handler: function(val) {

                let data = window._.cloneDeep(this.value);
                data.occupations_skills = val.occupations_skills

                this.$emit('input', data);
            },
            deep: true
        },
        comparePortalPersonal: function(value) {
            this.loadDefaultRatings();
        },
        comparePortalExternal: function(value) {
            this.loadDefaultRatings();
        }
    },
    computed: {
        hasExternals() {
            return this.resource.externals && this.resource.externals.length > 0
        },
        hasPersonals() {
            return this.resource.personals && this.resource.personals.length > 0
        }
    },
    methods: {
        initResource() {
            this.resource = window._.cloneDeep(this.value);
        },

        formatDefaultRatings(ratings) {
            let formatedRatings = [];

            if (ratings.length > 0) {
                ratings.forEach((item) => {
                    if (item.job_name) {
                        item.job_name += ' ' + this.iconInfo;
                    } else {
                        item.job_name = '--'
                    }
                    formatedRatings.push(item)
                })
            }

            return formatedRatings;
        },

        loadDefaultRatings() {

            let start = Date.now();
            var employee_rating = window._.cloneDeep(this.value);
            const occupations = employee_rating.occupations;

            employee_rating.occupations_skills = this.resource.occupations_skills;
            employee_rating.admin_occupations  = occupations.filter(o => o.admin_id != null).map(o => o.admin_id);
            employee_rating.occupations        = occupations.filter(o => o.admin_id == null).map(o => o.id);
            employee_rating.jobs               = employee_rating.jobs.map(o => o.id);
            employee_rating.externals          = employee_rating.externals?.map(o => o.id) || [];
            employee_rating.personals          = employee_rating.personals?.map(o => o.id) || [];
            employee_rating.skill_review       = this.skillReview

            window
                .axios({
                    method: "post",
                    url: `/api/gestion/employees/${this.value.employee.id}/ratings/default-occupation-skills`,
                    params: {
                        comparePortalPersonal: this.comparePortalPersonal, 
                        comparePortalExternal: this.comparePortalExternal
                    },
                    data: employee_rating,
                })
                .then(response => {
                    this.resource.occupations_skills = this.formatDefaultRatings(response.data);
                    this.loading = false;
                    console.info('=> Chargement des savoirs-faire depuis API : +' + (Date.now() - start) + 'ms');
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    this.loading = false;
                });
        },

        resetFromLastRatingClickHandle() {
            this.$swal({
                title: `Remplacer par la dernière sauvegarde ?`,
                text: "Attention, les courantes seront perdues.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Je confirme",
                cancelButtonText: "J'annule"
            }).then(result => {

                if (result.value) {
                    this.resource.occupations_skills = [];
                    this.comparePortalPersonal = false;
                    this.comparePortalExternal = false;
                    this.loadDefaultRatings();
                }
            });

        }
    }
};
</script>
