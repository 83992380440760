<template>
    <vs-row id="employeeForm" class="con-exemple-prompt">

        <vs-col>

            <div class="vx-row">
                <div class="vx-col w-full">
                    <h3 class="mb-8">
                        Informations :
                    </h3>
                </div>
            </div>

            <div class="vx-row mb-6">
                <!-- Profil picture -->
                <div class="vx-col sm:w-1/5 w-full">
                    <avatar-cropper
                        v-if="canUpdateEmployee"
                        id="cropper"
                        trigger="#pick-photo"
                        :labels="{ submit: 'Soumettre', cancel: 'Annuler'}"
                        :upload-handler="addPhoto"
                    />
                    <img
                        v-if="employee.photoUrl"
                        id="pick-photo"
                        :src="employee.photoUrl"
                        width="120px"
                        :style="canUpdateEmployee ? 'cursor: pointer;' : ''"
                    />
                    <div v-else id="pick-photo" style="display:flex; align-items:center;justify-content:center;text-align:center; font-size: 20px; background-color: grey; color: white; width: 120px; height: 67px; cursor:pointer;">
                        Photo
                    </div>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                    <div class="vx-row mb-2">
                        <!-- Firstname -->
                        <div class="vx-col sm:w-1/2 w-full">
                            <vs-input v-if="type != 'show'" v-model="employee.first_name" name="employeeFirstName" label="Prénom*" class="w-full" :disabled="!canUpdateEmployee"/>
                            <b v-else>{{ employee.first_name }}</b>
                        </div>
                        <!-- Lastname -->
                        <div class="vx-col sm:w-1/2 w-full">
                            <vs-input v-if="type != 'show'" v-model="employee.last_name" name="employeeLastName" label="Nom*" class="w-full" :disabled="!canUpdateEmployee"/>
                            <b v-else>{{ employee.last_name }}</b>
                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <!-- Birthdate -->
                        <div class="vx-col sm:w-1/2 w-full">
                            <!--datepicker v-if="type != 'show'" v-model="employee.birthday" name="employeeBirthday" class="w-full" :language="fr" monday-first :disabled="!canUpdateEmployee"/-->
                            <vs-input v-if="type != 'show'" type="date" v-model="employee.birthday" name="employeeBirthday" label="Date de naissance" class="w-full" :disabled="!canUpdateEmployee"/>
                            <b v-else>{{ employee.birthday }}</b>
                        </div>

                        <!-- Phone -->
                        <div class="vx-col sm:w-1/2 w-full">
                            <vs-input v-if="type != 'show'" v-model="employee.phone" name="employeePhone" label="Téléphone" class="w-full" :disabled="!canUpdateEmployee"/>
                            <b v-else>{{ employee.phone }}</b>
                        </div>
                    </div>
                    <div class="vx-row mb-1">
                        <!-- Mail -->
                        <div class="vx-col w-full">
                            <vs-input v-if="type != 'show'" v-model="employee.email" name="employeeMail" label="Adresse Email" class="w-full" :disabled="!canUpdateEmployee"/>
                            <b v-else>{{ employee.email }}</b>
                        </div>
                    </div>
                </div>

            </div>

            <div class="establishments-wrapper">

                <div class="establishment-form" v-for="(establishment, i) in establishmentList" :key="i">
                    <div v-if="establishmentList.length > 1" class="establishment-trash">
                        <vs-button
                            v-tooltip="'Supprimer'"
                            size="small"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click.stop="removeEstablishment(i)"
                        />
                    </div>
                    <vs-row>
                        <vs-col class="sm:w-3/5 w-full">
                            <label>Établissement*</label>
                            <Multiselect
                                v-model="employee.establishments[i]"
                                style="height: 10px;"
                                :options="availableEstablishments"
                                :group-select="false"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="name"
                                select-label
                                select-group-label
                                selected-label
                                deselect-label
                                deselect-group-label
                                @input="establishmentInputHandle"
                                :disabled="!canUpdateEmployee || !canUpdateEstablishment(employee.establishments[i])"
                                :loading="establishments.loading"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </Multiselect>
                        </vs-col>

                        <vs-col class="sm:w-2/5 w-full">
                            <vs-input
                                v-model="employee.establishments[i].start_date"
                                type="date"
                                name="employeeEstablishmentStart"
                                label="Date d'entrée*"
                                class="w-full"
                                :disabled="!canUpdateEmployee"
                            />
                        </vs-col>
                    </vs-row>

                    <template v-if="employee.establishments[i].id">
                        <vs-row v-if="!establishmentHasService(employee.establishments[i])" class="mt-3">
                            <vs-col>
                                <vs-alert color="warning" active="true">
                                    Pas de services disponibles pour cet établissement
                                </vs-alert>
                            </vs-col>
                        </vs-row>

                        <template v-else>
                            <vs-row class="mt-3 service-form" v-for="(service, j) in serviceList" :key="j">

                                <template v-if="service.establishment_id == establishment.id">

                                    <vs-col class="sm:w-3/5 w-full">
                                        <label>Service*</label>
                                        <Multiselect
                                            v-model="employee.services[j]"
                                            style="height: 10px;"
                                            :options="servicesFromEstablishmentId(establishment.id)"
                                            :group-select="false"
                                            placeholder="Recherche ..."
                                            track-by="id"
                                            label="title"
                                            select-label
                                            select-group-label
                                            selected-label
                                            deselect-label
                                            deselect-group-label
                                            :disabled="!canUpdateEmployee"
                                        >
                                            <span slot="noResult">Aucun résultat.</span>
                                        </Multiselect>
                                    </vs-col>

                                    <vs-col class="sm:w-2/5 w-full">
                                        <vs-input
                                            v-model="employee.services[j].etp"
                                            type="number"
                                            step="0.1"
                                            name="employeeServiceETP"
                                            label="ETP"
                                            class="w-full"
                                            :disabled="!canUpdateEmployee"
                                            :key="updateETP"
                                            @input="updateETP++"
                                        />
                                    </vs-col>

                                    <div v-if="canRemoveServices(establishment)" class="service-trash">
                                        <vs-button
                                            v-tooltip="'Supprimer'"
                                            size="small"
                                            color="primary"
                                            type="border"
                                            icon-pack="feather"
                                            icon="icon-trash"
                                            @click.stop="removeService(j)"
                                        />
                                    </div>
                                </template>
                            </vs-row>

                            <vs-row class="mt-5">
                                <vs-col class="text-right">
                                    <a href="#" class="link-myskilliz" @click.prevent="addServiceClickHandle(establishment.id)">+ Ajouter un service</a>
                                </vs-col>
                            </vs-row>
                        </template>

                    </template>

                </div>
            </div>

            <vs-row v-if="canAddEstablishment" class="mt-5">
                <vs-col class="text-right">
                    <a href="#" class="link-myskilliz" @click.prevent="addEstablishmentClickHandle()">+ Ajouter un établissement</a>
                </vs-col>
            </vs-row>

            <!-- Ligne des switchs -->
            <div class="vx-row mb-6 mt-5">
                <vs-col vs-w="3" vs-sm="12">
                    <span>Stagiaire / Hors effectif :</span>
                    <vs-switch
                        v-model="employee.not_in_staff"
                        :disabled="!canUpdateEmployee"
                    />
                </vs-col>

                <vs-col  vs-w="4" vs-sm="12">
                    <div v-if="displayOptions">
                        <span>Evaluation des savoir-faire uniquement :</span>
                        <vs-switch
                            v-model="employee.occupation_skills_rating_only"
                            :disabled="!canUpdateEmployee"
                        />
                    </div>
                </vs-col>

                <vs-col vs-w="4" vs-sm="12">
                    <div v-if="displayOptions">
                        <span>Auto-évaluation des savoir-faire :</span>
                        <vs-switch
                            v-model="employee.occupation_skills_self_rating"
                            :disabled="!canUpdateEmployee || employee.occupation_skills_rating_only == true"
                        />
                    </div>
                </vs-col>

                <vs-col vs-w="1" vs-sm="12">
                    <a class="opaver" @click="optionClickHandle">
                        <feather-icon icon="SettingsIcon" />
                    </a>
                </vs-col>
            </div>
        </vs-col>
    </vs-row>
</template>

<script>
import Multiselect from 'vue-multiselect';
import AvatarCropper from 'vue-avatar-cropper';
import EmployeeHelper from '@components/views/rh/gestion/employee/EmployeeHelper';

export default {
    name: 'employee-form',
    components: {
        Multiselect,
        AvatarCropper
    },
    mixins: [EmployeeHelper],
    props: {
        value: {
            required: false,
            type: Object
        },
        formType: {
            required: false,
            type: String,
            default: false
        },
        type: {
            required: false,
            type: String
        }
    },
    data () {
        return {
            log: console.log,
            employee: {
                services: [],
                establishments: []
            },
            establishments: {
                data: [],
                loading:false
            },
            updateETP: 0, // Pas très propre, mais pour éviter des bugs de rafraichissement ETP
            displayOptions: false,
        }
    },

    watch: {
        value: {
            handler(newValue, oldValue) {
                if (newValue === oldValue) return
                this.initEmployee()
            }, deep: true
        },
        'employee': {
            handler () {
                this.emitInput();
            }, deep: true
        },
        hasError (value) {
            this.$emit('error', value)
        },
        'employee.occupation_skills_rating_only': {
            handler (value) {
                if (value == true) {
                    this.employee.occupation_skills_self_rating = true;
                }
            }
        },
    },
    computed: {

        hasError() {
            // Check si service non renseigné
            let hasServiceEmpty = false;
            this.employee.services.forEach(item => {
                if (!item.id) {
                    hasServiceEmpty = true;
                }
            })

            // Check si etablissement non renseigné
            let hasEstablishmentEmpty = false;
            this.employee.establishments.forEach(item => {
                if (!item.id || !item.start_date) {
                    hasEstablishmentEmpty = true;
                }
            })

            let error = !this.employee.first_name || !this.employee.last_name || hasEstablishmentEmpty || hasServiceEmpty
            return !!error;
        },

        canUpdateEmployee () {
            if (this.formType == 'create') {
                return this.can('create', 'employees')
            }

            if (!this.employee.services.length) return true

            return this.canMulti('update', 'employees', this.employee.services.map(s => s.id))
        },

        availableEstablishments() {
            const currentIds = this.employee.establishments.map(e => e.id);

            return this.establishments.data.filter(e => !currentIds.includes(e.id));
        },

        canAddEstablishment() {
            return this.employee.establishments.length == 0 || !!this.employee.establishments[this.employee.establishments.length -1].id;
        },

        establishmentList() {
            return window._.cloneDeep(this.employee.establishments);
        },

        serviceList() {
            return window._.cloneDeep(this.employee.services);
        }
    },
    methods: {
        emitInput() {
            this.value.last_name = this.employee.last_name;
            this.value.first_name = this.employee.first_name;
            this.value.birthday = this.employee.birthday;
            this.value.phone = this.employee.phone;
            this.value.email = this.employee.email;
            this.value.photo = this.employee.photo;
            this.value.services = this.employee.services;
            this.value.establishments = this.employee.establishments;
            this.value.occupations = this.employee.occupations;
            this.value.jobs = this.employee.jobs;
            this.value.not_in_staff = this.employee.not_in_staff;
            this.value.occupation_skills_rating_only = this.employee.occupation_skills_rating_only;
            this.value.occupation_skills_self_rating = this.employee.occupation_skills_self_rating;

            this.$emit('input', this.value)
        },
        initEmployee() {

            let services = [];
            if (this.value.services) {
                services = this.value.services.map(e => {
                    if (e.pivot && !e.etp) {
                        e.etp = e.pivot.etp
                    }
                    return e;
                })
            }

            let establishments = [];
            if (this.value.establishments) {
                establishments = this.value.establishments.map(e => {
                    if (e.pivot) {
                        e.start_date = e.pivot.start_date
                    }
                    return e;
                })
            }

            this.employee = {
                last_name: this.value.last_name,
                first_name: this.value.first_name,
                birthday: this.value.birthday,
                phone: this.value.phone,
                email: this.value.email,
                photo: this.value.photo,
                services: services,
                establishments: establishments,
                occupations: this.value.current_occupations,
                jobs: this.value.current_jobs,
                id: this.value.id,
                not_in_staff: this.value.not_in_staff,
                occupation_skills_rating_only: this.value.occupation_skills_rating_only,
                occupation_skills_self_rating: this.value.occupation_skills_self_rating
            }

            if (!this.employee.photoUrl) {
                this.$set(this.employee, 'photoUrl', this.value.photoUrl);
            } else {
                this.employee.photoUrl = this.value.photoUrl
            }
        },

        async loadEstablishments() {
            return window.axios({
                method: 'get',
                url: '/api/referentiel/establishments',
                params: {perpage: 9999, include: ['services']},
            })
            .then(response => {
                this.establishments.data = response.data.data;
                this.establishments.loading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },

        addPhoto(ev) {
            ev.getCroppedCanvas({ width: 512, height: 512 }).toBlob(blob => {
                this.employee.photoUrl = URL.createObjectURL(blob)
                this.employee.photo = blob
            })
        },
        servicesFromEstablishmentId(establishmentId) {

            const currentIds = this.employee.services.map(e => e.id);

            const services = this.services.filter(s => this.listOfServicesUpdatableFor("employees").map(id => id).includes(s.id));
            return services
                        .filter(s => s.establishment_id == establishmentId)
                        .filter(e => !currentIds.includes(e.id));
        },

        establishmentInputHandle(value) {
            if (value.id && this.employee.services.filter(s => s.establishment_id == value.id).length == 0) {
                this.addServiceClickHandle(value.id)
            }

            if (!value.start_date) {
                value.start_date = (new Date()).toISOString().substring(0, 10);
            }

            this.removeOrphanServices();

            this.updateOccupationSkillsRatingOnly();
            this.updateOccupationSkillsSelfRating();
        },
        /**
         * Mise à jour du champ savoir-faire uniquement en fonction des établissements
         */
        updateOccupationSkillsRatingOnly() {
            if (!this.employee.occupation_skills_rating_only) {
                let value = true;
                this.employee.establishments.forEach(e => {
                    if (!e.occupation_skills_rating_only) {
                        value = false;
                    }
                })
                this.employee.occupation_skills_rating_only = value;
            }
        },
        /**
         * Mise à jour du champ autovaluation des savoir-faire en fonction des établissements
         */
        updateOccupationSkillsSelfRating() {
            if (!this.employee.occupation_skills_self_rating) {
                let value = true;
                this.employee.establishments.forEach(e => {
                    if (!e.portail_occupation_skill) {
                        value = false;
                    }
                })
                this.employee.occupation_skills_self_rating = value;
            }
        },
        removeOrphanServices() {
            const currentEstablishmentIds = this.employee.establishments.map(e => e.id);

            this.employee.services = this.employee.services.filter(s => currentEstablishmentIds.includes(s.establishment_id));
        },
        addEstablishmentClickHandle() {
            this.employee.establishments.push({
                //start_date: (new Date()).toISOString().substring(0, 10)
            });
        },

        addServiceClickHandle(establishmentId) {
            this.employee.services.push({establishment_id: establishmentId, etp: null})

        },

        removeEstablishment(index) {
            this.$swal({
                title: `Souhaitez-vous supprimer cet établissement ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
            }).then(response => {
                if (response.value) {
                    this.employee.services = this.employee.services.filter(s => s.establishment_id != this.employee.establishments[index].id)
                    this.employee.establishments = this.employee.establishments.filter((item, i) => i != index);
                }
            })
        },

        removeService(serviceIndex) {
            this.$swal({
                title: `Souhaitez-vous supprimer ce service ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
            }).then(response => {
                if (response.value) {
                    this.employee.services = this.employee.services.filter((item, i) => i != serviceIndex);
                }
            })
        },

        establishmentHasService(establishment) {
            return this.serviceList.filter(s => s.establishment_id == establishment.id).length > 0
        },

        canRemoveServices(establishment) {
            return this.serviceList.filter(s => s.establishment_id == establishment.id).length > 1
        },

        canUpdateEstablishment(establishment) {
            return !establishment.pivot?.id
        },
        optionClickHandle() {
            this.displayOptions = !this.displayOptions
        },
    },
    created() {
        this.initEmployee();
        this.$emit('error', this.hasError)
        this.loadEstablishments();

        if (this.employee.establishments.length == 0) {
            this.addEstablishmentClickHandle();
        }
    }
}
</script>

<style lang="scss" scoped>
#employeeForm {
    display: block;
    > div {
        padding: 1rem;
    }

    ::v-deep input {
        min-height: 43px;
        padding: 1rem 1.3rem;
        font-size:1.1rem;
    }
    .multiselect {
        min-height: 43px;
        &.multiselect--disabled {
            border-radius: 5px;
            ::v-deep .multiselect__select {
                border-radius: 0 4px 4px 0;
                height: 41px;
            }
        }
    }

    .establishment-form {
        border: 1px solid #a5a5a5;
        border-radius: 30px;
        padding: 30px 26px 20px 7px;
        margin-top: 15px;
        position: relative;
    }

    .service-form {
        position: relative;
    }

    .establishment-trash {
        position: absolute;
        top: 10px;
        right: 9px;
    }

    .service-trash {
        position: absolute;
        right: -18px;
        top: 24px;
    }
}
</style>
