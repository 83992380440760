<template>
    <div class="dashboard">
        <LimitatedPlan v-if="!planAllow('dashbords')" class="mb-10"/>
        <h4>Identifier la capacité d’une équipe à répondre aux besoins d'une activité</h4>
        <p>En sélectionnant un ou plusieurs savoir-faire, ce tableau de bord permet de vérifier les capacités à y répondre à l'échelle d’un ou plusieurs services.</p>

        <!-- Contexte -->
        <vx-card class="mb-4 mt-3">
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="4">
                    <label class="typo__label">Filières</label>
                    <Multiselect
                        v-model="filters.sectors"
                        :options="sectors.data"
                        :multiple="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="title"
                        select-label
                        select-group-label
                        selected-label
                        deselect-label
                        deselect-group-label
                        :disabled="!planAllow('dashbords')"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-w="6">
                    <label class="typo__label">Sélectionner un ou plusieurs savoir-faire</label>
                    <Multiselect
                        v-model="filters.occupationSkills"
                        :options="shownOccupationSkillList"
                        :multiple="true"
                        group-label="group"
                        group-values="items"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="rich_title"
                        select-label
                        select-group-label
                        selected-label
                        deselect-label
                        deselect-group-label
                        :loading="occupationSkills.loading"
                        :disabled="!planAllow('dashbords')"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
            </vs-row>
        </vx-card>

        <!-- Filtres -->
        <vx-card>
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-w="4">
                    <label class="typo__label">Sélectionner un ou plusieurs services</label>
                    <Multiselect
                        v-model="filters.services"
                        :options="groupedServicesByEstablishments"
                        :multiple="true"
                        group-values="services"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="title"
                        select-label
                        select-group-label
                        selected-label
                        deselect-label
                        deselect-group-label
                        :disabled="!planAllow('dashbords')"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-w="4">
                    <label class="typo__label">Sélectionner une ou plusieurs personnes</label>
                    <Multiselect
                        v-model="filters.employees"
                        :options="groupedEmployeesByEstablishmentsAndFilteredByServices"
                        :multiple="true"
                        group-values="employees"
                        group-label="establishmentService"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="name"
                        select-label
                        select-group-label
                        selected-label
                        deselect-label
                        deselect-group-label
                        :disabled="!planAllow('dashbords')"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </Multiselect>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-w="2" class="text-right pt-3">
                    <feather-icon icon="SettingsIcon" @click="showPopup = true" class="settings mt-5"/>
                </vs-col>
                <vs-prompt
                    :active.sync="showPopup"
                    title="Affichage du tableau"
                    cancel-text="Fermer"
                    :button-accept="''"
                    @close="showPopup = false"
                >
                    <vs-row class="mt-4">
                        <vs-col vs-w="4">
                            <vs-checkbox v-model="filters.levelOfMasteryEvaluated" :disabled="!planAllow('dashbords')">
                                Niveau de maîtrise évalué
                            </vs-checkbox>
                        </vs-col>
                        <vs-col vs-w="4">
                            <vs-checkbox v-model="filters.accessibilityPercentage" :disabled="!planAllow('dashbords')">
                                Poucentage d'accessibilité
                            </vs-checkbox>
                        </vs-col>
                        <vs-col vs-w="4">
                            <vs-checkbox v-model="filters.accessibleSafely" :disabled="!planAllow('dashbords')">
                                Accessible en sécurité
                            </vs-checkbox>
                        </vs-col>
                    </vs-row>
                </vs-prompt>
            </vs-row>

            <vs-row>
                <vs-col>
                    <div v-if="hasSelectedEmployees">
                        {{ selectedEmployees.length }} personne{{ selectedEmployees.length > 1 ? 's' : '' }} sélectionnée{{ selectedEmployees.length > 1 ? 's' : '' }}
                    </div>
                </vs-col>
            </vs-row>

            <vs-row v-if="can('read', 'skill_reviews') || can('read', 'ratings')" class="mt-5">
                <vs-col vs-justify="center" vs-align="center">
                    <vs-row>
                        <vs-col vs-align="flex-start" vs-w="6">
                            Résultats basés sur :
                            <vs-radio
                                v-model="typeCompute"
                                vs-name="radios_type_compute"
                                id="radios-type-compute"
                                class="mr-5 ml-2"
                                :vs-value="typeComputeValues.RATINGS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings')) || !planAllow('dashbords')"
                            >
                                {{ projectNames.plural }}
                            </vs-radio>
                            <vs-radio
                                v-model="typeCompute"
                                vs-name="radios_type_compute"
                                id="radios-type-compute"
                                class="mr-5"
                                :vs-value="typeComputeValues.REVIEWS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings')) || !planAllow('dashbords')"
                            >
                                {{ skillRatingNames.plural }}
                            </vs-radio>
                        </vs-col>

                        <vs-col vs-align="flex-start" vs-w="6">
                            <vs-radio
                                v-model="typeEval"
                                vs-name="radios_type_eval"
                                id="radios-type-eval"
                                class="mr-5 ml-2"
                                :vs-value="typeEvalValues.SKILLS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings')) || !planAllow('dashbords')"
                            >
                                Accessibilité
                            </vs-radio>
                            <vs-radio
                                v-model="typeEval"
                                vs-name="radios_type_eval"
                                id="radios-type-eval"
                                class="mr-5"
                                :vs-value="typeEvalValues.OCCUPATION_SKILLS"
                                :disabled="(can('read', 'skill_reviews') && !can('read', 'ratings')) || (!can('read', 'skill_reviews') && can('read', 'ratings')) || !planAllow('dashbords')"
                            >
                                Score de maîtrise
                            </vs-radio>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>

        <!-- Action -->
        <vs-row class="mt-4">
            <vs-col vs-type="flex"  vs-justify="flex-end">
                <vs-button
                    v-if="planAllow('dashbords')"
                    id="search-button"
                    color="primary"
                    :disabled="!canSearch"
                    @click="startResearchHandle"
                >
                    Lancer la recherche
                </vs-button>

                <div v-else style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                    <vs-button
                        id="search-button"
                        color="primary"
                        disabled="true"
                    >
                        Lancer la recherche
                    </vs-button>
                    <vs-icon class="button-bullet" bg="#FF9B3A" round >
                        <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                    </vs-icon>
                </div>
            </vs-col>
        </vs-row>

        <!-- Tableau -->
        <vx-card v-if="resourceLoaded && hasSelectedEmployees" class="mt-4">
            <vs-row>
                <vs-col>
                    <vs-table
                        class="dashboard-os-results"
                        :data="orderedOccupationSkills"
                        :no-data-text="`Aucune donnée à afficher`"
                    >
                        <template slot="thead">
                            <vs-th></vs-th>
                            <vs-th v-for="(employee, i) in orderedSelectedEmployees" :key="i" class="text-center eval-name">
                                {{ employee.last_name }} {{ employee.first_name }}
                            </vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr v-if="!isOccuSkillEvalType" style="border-top: 3px solid #c3c3c3; border-bottom: 1px solid #c3c3c3; height: 50px;">
                                <vs-th>Réponse au besoin</vs-th>
                                <vs-th v-for="(employee, i) in orderedSelectedEmployees" :key="i" class="text-center eval-response">
                                    <span v-if="filters.accessibilityPercentage">
                                        {{ getGlobalAccessibilityPercent(employee.id) }} %
                                    </span>
                                    <span v-if="filters.accessibleSafely" class="security ml-3">
                                        <i v-if="getGlobalSecurity(employee.id)" class="fa fa-check text-success"></i>
                                    </span>
                                </vs-th>
                            </vs-tr>

                            <vs-tr v-for="(occupationSkill, i) in data" :key="i">
                                <vs-th class="text-nowrap">{{ occupationSkill.title }}</vs-th>
                                <vs-td v-for="(employee, i) in orderedSelectedEmployees" :key="i" class="evaluation">
                                     <vs-chip
                                        v-if="filters.levelOfMasteryEvaluated"
                                        :color="getLevelColor(getEvaluatedOccupationSkillInfo(employee.id, occupationSkill.id, 'rating_level'))"
                                        class="level"
                                    >
                                        {{ levelLabelById(getEvaluatedOccupationSkillInfo(employee.id, occupationSkill.id, 'rating_level')) }}
                                    </vs-chip>
                                    <span v-if="filters.accessibilityPercentage && !isOccuSkillEvalType" class="percent ml-3">
                                        {{ Math.round(getEvaluatedOccupationSkillInfo(employee.id, occupationSkill.id, 'accessibility') * 100) }} %
                                    </span>
                                    <span v-if="filters.accessibleSafely && !isOccuSkillEvalType" class="security ml-3">
                                        <i v-if="getEvaluatedOccupationSkillInfo(employee.id, occupationSkill.id, 'security')" class="fa fa-check text-success"></i>
                                    </span>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>
        </vx-card>

    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import CrownIcon from '@components/svg/CrownIcon';
import LimitatedPlan from '@components/divers/LimitatedPlan';
export default {
    name: "DashboardOccupationSkills",
    components: {
        Multiselect, CrownIcon, LimitatedPlan
    },
    props: {},
    data() {
        return {
            log: console.log,
            employees: {
                data: [],
                loading: false
            },
            occupationSkills: {
                data: [],
                list: [],
                loading: false
            },
            establishments: {
                data: [],
                loading: false
            },
            sectors: {
                data: [],
                loading: false
            },
            filters: {
                services: [],
                employees: [],
                occupationSkills: [],
                sectors: [],
                levelOfMasteryEvaluated: true,
                accessibilityPercentage: true,
                accessibleSafely: true
            },
            resources: {},
            employeEvaluation: {},
            responseToNeed: [],
            resourceLoaded: false,
            searchLoading: false,
            showPopup: false,
            typeComputeValues: {
                RATINGS: 1,
                REVIEWS: 2,
            },
            typeCompute: 1,
            typeEval: 1,
            typeEvalValues: {
                SKILLS: 1,
                OCCUPATION_SKILLS: 2
            },
            orderedSelectedEmployees: []
        }
    },
    created() {

        const start_at = Date.now();

        if (this.can('read', 'skill_reviews') && !this.can('read', 'ratings')) {
            this.typeCompute = this.typeComputeValues.REVIEWS
        }

        Promise.all([
            this.loadEmployees(),
            this.loadOccupationskills(),
            this.loadEstablishments(),
            this.loadSectors(),
        ]).then(() => {
            console.log(`Chargement des listes +${Date.now() - start_at}ms`)
        })

    },
    computed: {

        hasSelectedOccupationSkills() {
            return this.filters.occupationSkills.length > 0;
        },

        hasSelectedEmployees() {
            return this.selectedEmployees.length > 0;
        },

        canSearch() {
            return this.hasSelectedOccupationSkills && this.hasSelectedEmployees;
        },

        selectedEmployees() {
            let selected = [];

            if (this.filters.employees.length > 0) {
                selected = this.filters.employees
            } else if (this.filters.services) {
                this.employees.data.filter(e => {
                    this.filters.services.every(fs => {
                        const hasService = e.services.map(s => s.id).includes(fs.id);

                        if (hasService) {
                            selected.push(e);
                            return false;
                        }
                        return true;
                    })
                })
            }

            return selected;
        },

        groupedServicesByEstablishments() {
            let toShow = [];
            let establishments = this.filterEstablishmentFormViewableService(this.establishments.data);

            establishments.forEach(e => {
                toShow.push({
                    establishment: e.name,
                    services: this.services ? this.services.filter(service =>
                                    service.establishment_id === e.id &&
                                    this.listOfServicesViewableFor("employees").includes(service.id))
                                : []
                });
            });
            return toShow;
        },

        groupedEmployeesByEstablishmentsAndFilteredByServices() {
            let toShow = [];

            const establishments = this.filterEstablishmentFormViewableService(this.establishments.data);

            establishments.forEach(establishment => {

                establishment.services.forEach(service => {

                    // Nom du groupe (etablissement / service)
                    const groupName = `${establishment.name} / ${service.title}`;

                    const filteredEmployees = this.employees.data.filter(e =>
                        e.services.length &&
                        e.services?.map(s => s.establishment_id).includes(establishment.id) &&
                        e.services.map(s => s.id).includes(service.id)
                    );

                    toShow.push({
                        establishmentService: groupName,
                        employees: filteredEmployees.map(employee => {
                            return {
                                ...employee,
                                name: `${employee.last_name.toUpperCase()} ${employee.first_name}`
                            };
                        })
                    });
                })
            });

            if (this.filters.services.length > 0) {
                const establishmentServiceNames = this.filters.services.map(s => `${s.establishment.name} / ${s.title}`);

                // Personne des services filtrés, en 1er
                toShow = toShow.sort((a, b) => establishmentServiceNames.includes(a.establishmentService) ? -1 : 1);
            }

            return toShow;
        },

        shownOccupationSkillList() {

            let occupationSkills = this.occupationSkills.data.map(os => ({...os, rich_title: os.title+(os.establishment ? ' ('+os.establishment.name+')' : '')}));

            let grouped = this.groupForMultiselect(occupationSkills, ['sector.title']);

            if (this.filters.sectors.length > 0) {
                const sectorNames = this.filters.sectors.map(s => s.title);

                // Savoir-faire des filières filtrées, en 1er
                grouped = grouped.sort((a, b) => sectorNames.includes(a.group) ? -1 : 1)
            }

            return grouped;
        },

        // orderedSelectedEmployees() {
        //     return this.selectedEmployees.sort((a, b) => a.last_name > b.last_name ? 1 : -1);
        // },

        employeeListFromResource() {
            return Object.values(this.resources)[0];
        },

        orderedOccupationSkills() {
            var occupationSkillEvaluated = [];

            for (var idEmployee in this.employeEvaluation) {

                this.employeEvaluation[idEmployee].forEach(evaluated => {
                    if (!occupationSkillEvaluated.map(ose => ose.id).includes(evaluated.occupation_skill.id)) {
                        occupationSkillEvaluated.push(evaluated.occupation_skill);
                    }
                })
            }

            return occupationSkillEvaluated.sort((a, b) => a.title > b.title ? 1 : -1);
        },

        isOccuSkillEvalType() {
            return this.typeEval === this.typeEvalValues.OCCUPATION_SKILLS
        }

    },
    watch: {
        searchLoading(value) {
            if (value) {
                this.$vs.loading({
                    background: this.backgroundLoading,
                    color: this.colorLoading,
                    container: document.getElementById('search-button'),
                    scale: 0.45
                })
            } else {
                setTimeout(() => {
                    this.$vs.loading.close(document.getElementById('search-button'))
                }, 100)
            }
        },
        'filters.services': {
            handler(value) {
                this.resourceLoaded = false;
                this.resetEvaluationData();
            }
        } ,
        'filters.employees': {
            handler(newEmployee, oldEmployee) {
                if (newEmployee.map(n => n.id) == oldEmployee.map(o => o.id)) {
                    this.resourceLoaded = false;
                }
            }
        },
        'filters.occupationSkills': {
            handler(value) {
                this.resourceLoaded = false;
                this.resetEvaluationData();
            }
        },
        typeEval(newValue) {
            this.resetEvaluationData();
        },
    },
    methods: {
        async loadEstablishments() {
            this.establishments.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ['services']
                    }
                })
                .then(response => {
                    this.establishments.data = response.data.data;
                    this.establishments.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadEmployees() {
            this.employees.loading = true
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: { perpage: 9999, include: ['services.establishment', 'currentJobs', 'currentOccupations'] }
                })
                .then(response => {
                    this.employees.data = response.data.data;
                    this.employees.loading = false
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        getEmployeeById(id) {
            return this.employees.data.find(e => e.id == id);
        },

        async loadOccupationskills() {
            this.occupationSkills.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills",
                    params: {
                        perpage: 9999,
                        include: ["sector", "jobs", "establishment"],
                    }
                })
                .then(response => {
                    let occupationSkills = response.data.data;
                    this.occupationSkills.data = occupationSkills;
                    this.occupationSkills.loading = false;
                })
                .catch(err => {this.groupForMultiselect(occupationSkills, ['sector.title']);
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        occupationskillById(id) {
            return this.occupationSkills.find(os => os.id == id);
        },

        async loadSectors() {
            this.sectors.loading = true;
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/sectors",
                    params: {
                        perpage: 9999,
                        include: []
                    }
                })
                .then(response => {
                    this.sectors.data = response.data.data;
                    this.sectors.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        startResearchHandle() {
            this.resourceLoaded = false;
            this.searchLoading = true;
            this.resources = {};
            this.employeEvaluation = {};
            const start = Date.now();
            // var toLoad = [];

            // this.selectedEmployees.forEach(e => {
            //     toLoad.push(this.loadEvaluatedOccupationSkills(e, this.filters.occupationSkills));
            // })

            // Promise.all(toLoad).then(() => {
            //     this.resourceLoaded = true
            //     this.searchLoading = false;
            //     console.info('=> Chargement des savoir-faire : +' + (Date.now() - start) + 'ms');
            // })

            // Chargement des résultas via API
            this.loadEmployeesEvaluatedOccupationSkills(this.selectedEmployees, this.filters.occupationSkills).then(response => {
                this.resourceLoaded = true
                this.searchLoading = false;
                console.info('=> Chargement des savoir-faire : +' + (Date.now() - start) + 'ms');
            });
        },

        getEvaluatedOccupationSkillInfo(employeeId, occupationSkillId, key) {
            const employeeEvaluation = this.employeEvaluation[employeeId];

            if (!employeeEvaluation) {
                return null;
            }

            const evalOccSkill = employeeEvaluation.find(e => e.occupation_skill.id == occupationSkillId);

            if (!evalOccSkill) {
                return 0
            }

            return evalOccSkill[key] ? evalOccSkill[key] : 0;
        },

        // loadEvaluatedOccupationSkills(employee, occupationSkills) {
        //     return window
        //         .axios({
        //             method: "get",
        //             url: `/api/gestion/employees/${employee.id}/ratings/evaluation/occupation-skills`,
        //             params: {
        //                 sort_by: 'accessibility',
        //                 occupation_skill_ids: occupationSkills.map(os => os.id)
        //             }
        //         })
        //         .then(response => {
        //             this.employeEvaluation[employee.id] = response.data.data.sort((a, b) => a.occupation_skill.title > b.occupation_skill.title ? 1 : -1);

        //             //this.employeEvaluation[employee.id] = response.data.data;
        //         })
        //         .catch(err => {
        //             console.log(err);
        //             this.notifyError(err, `Une erreur est survenue`);
        //         });
        // },

        loadEmployeesEvaluatedOccupationSkills(employees, occupationSkills) {
            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/-1/ratings/evaluation/occupation-skills`,
                    params: {
                        sort_by: 'accessibility',
                        occupationskills_only: this.typeEval === this.typeEvalValues.OCCUPATION_SKILLS,
                        employee_ids: employees.map(e => e.id).join('|'),
                        occupation_skill_ids: occupationSkills.map(os => os.id).join('|'),
                        skill_reviews: this.typeCompute === this.typeComputeValues.REVIEWS,
                    }
                })
                .then(response => {
                    response.data.data.forEach(d => {
                        
                        const employeeId = d.rating.employee_id;
                        this.employeEvaluation[employeeId] = d.data.sort((a, b) => a.occupation_skill.title > b.occupation_skill.title ? 1 : -1);
                        this.setOrderedSelectedEmployee();
                    });
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        getLevelColor(level) {
            if (level == 4) {
                return 'success';
            } else if (level == 3) {
                return 'warning';
            } else {
                return null;
            }
        },

        getGlobalAccessibilityPercent(employeeId) {
            let cumul = 0;
            const evaluatedOccupationSkills = this.employeEvaluation[employeeId];

            this.orderedOccupationSkills.forEach(oos => {
                const accessibility = this.getEvaluatedOccupationSkillInfo(employeeId, oos.id, 'accessibility') * 100
                cumul += accessibility;
            })

            return this.orderedOccupationSkills.length ? Math.round(cumul / this.orderedOccupationSkills.length) : 0;
        },

        getGlobalSecurity(employeeId)
        {
            let security = false;

            this.orderedOccupationSkills.forEach(oos => {
                this.getEvaluatedOccupationSkillInfo(employeeId, oos.id, 'security')
            })

            return security;
        },
        resetEvaluationData() {
            this.employeEvaluation = {};
        },

        setOrderedSelectedEmployee() {
            let orderedItems = _.cloneDeep(this.selectedEmployees.sort((a, b) => a.last_name > b.last_name ? 1 : -1));
            
            // Tri par l'accessibilité
            orderedItems = orderedItems.sort((a, b) => {
                const aAccessibility = this.getGlobalAccessibilityPercent(a.id);
                const bAccessibility = this.getGlobalAccessibilityPercent(b.id);

                if (aAccessibility == bAccessibility) {
                    return 0;
                } else if (aAccessibility > bAccessibility) {
                    return -1
                } else {
                    return 1;
                }
            });

            this.orderedSelectedEmployees = orderedItems;
        },
    }
}
</script>


<style lang="scss" scoped>
@import "@sass/variables.scss";

.dashboard::v-deep .multiselect {
    font-size: 14px;
    color: grey;
    .multiselect__single {
        font-size: 14px;
        color: grey;
    }
    .multiselect__placeholder {
        color: grey;
        font-size: 14px;
        margin-left: 10px;
    }

    .multiselect__content-wrapper {
        border: none;
    }

    .multiselect__input {
        background: $primary-light;
        font-size: 14px;
    }

    .multiselect__tag {
        background: $primary;
        font-size: 14px;
        &-icon:hover {
            background: rgb(151, 47, 95);
        }

        &-icon:after {
            color: $white;
        }
    }

    .multiselect__single {
        background: $primary-light;
        margin-top: 4px;
    }
    .multiselect__select:before {
        top: 45%;
    }
    .multiselect__tags {
        padding-top: 4px;
        min-height: 33px;
        max-height: 500px;
        background: $primary-light;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }

    .multiselect__content {
        background: $primary-light;
    }
    .multiselect__tag-icon::after {
        background: rgb(151, 47, 95);
    }
    .multiselect__option--highlight {
        background: $primary;
        outline: none;
        color: #fff;
        &::after {
            display: none;
            &:hover {
                background: rgb(151, 47, 95);
            }
        }
    }

}
.evaluation {
    line-height: 28px;
    min-width: 165px;
    .level {
        width: 80px;
    }
}

.checkboxes-container {
    padding: 10px 1em 0;
    border-top: 1px solid $grid-gray;
    z-index: 0;
}

.settings {
    opacity: 0.6
}

.settings:hover {
    opacity: 1
}

.dashboard-os-results::v-deep {
    .vs-table {
        width: initial !important;
    }
}

</style>

