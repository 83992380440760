<template>
    <div>
        <vs-row class="mb-6 mt-10">
            <vs-col class="sm:w-1/5 w-full">
                <span>Date :</span>
            </vs-col>
            <vs-col class="sm:w-1/5">
                <Datepicker
                    v-model="resource.rating_date"
                    name="ratingDate"
                    :language="fr"
                    :placeholder="'Date du '+projectNames.singular"
                    :disabled="typeForm === 'validation' || typeForm === 'consultation'"
                    :disabled-dates="disabledDates"
                    monday-first
                    @input="updateFormHandle()"
                />
            </vs-col>
            <vs-col class="sm:w-3/5"> </vs-col>
        </vs-row>
        <!--<vs-row
            vs-align="left"
            vs-type="flex"
            vs-justify="space-around"
            vs-w="12"
        >
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                <vs-divider position="left"><p class="bold">Périmètre des données à prendre en compte :</p></vs-divider>
            </vs-col>
        </vs-row>-->
        <vs-divider position="left" class="mt-20 font-bold"><p class="bold">Périmètre des données à prendre en compte :</p></vs-divider>
        <vs-alert color="#1f74ff">
            L'ajout ou la suppression d’un poste, d’un stage ou d’un métier mettra à jour le parcours de la personne.
        </vs-alert>
        <vs-row class="mb-6 mt-10">
            <vs-col class="sm:w-1/5 w-full">
                <span>Postes ou stages occupés :</span>
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <Multiselect
                    id="occupedJobs"
                    v-model="resource.jobs"
                    :options="jobs"
                    :multiple="true"
                    group-values="jobs"
                    group-label="establishmentService"
                    :group-select="true"
                    placeholder="Recherche ..."
                    track-by="id"
                    label="title"
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                    :disabled="typeForm === 'validation' || typeForm === 'consultation'"
                    :loading="jobs.length == 0"
                    @input="careerUpdatedHandle(); updateFormHandle()"
                >
                    <span slot="noResult">Aucun résultat.</span>
                </Multiselect>
            </vs-col>
        </vs-row>
        <vs-row class="mb-6 mt-10">
            <vs-col class="sm:w-1/5 w-full">
                <span>Métiers occupés ou en préparation :</span>
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <Multiselect
                    id="occupedOccupations"
                    v-model="resource.occupations"
                    :options="occupations"
                    :multiple="true"
                    group-values="occupations"
                    group-label="type"
                    :group-select="true"
                    placeholder="Recherche ..."
                    track-by="id"
                    label="title"
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                    :disabled="typeForm === 'validation' || typeForm === 'consultation'"
                    :loading="occupations.length == 0"
                    @input="careerUpdatedHandle(); updateFormHandle()"
                >
                    <span slot="noResult">Aucun résultat.</span>
                </Multiselect>
            </vs-col>
        </vs-row>

        <vs-divider position="left" class="mt-20 font-bold"><p class="bold">Périmètre des évaluations Portail à prendre en compte :</p></vs-divider>
        <vs-row class="mb-6 mt-10">
            <vs-col class="sm:w-1/5 w-full">
                <span>Auto-évaluation Portail :</span>
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <Multiselect
                    v-model="resource.personals"
                    :options="personalRatings"
                    multiple
                    placeholder="Recherche ..."
                    track-by="title"
                    label="title"
                    select-label
                    select-group-label
                    selected-label
                    deselect-label
                    deselect-group-label
                    @input="updateFormHandle()"
                >
                    <span slot="noOptions">Aucune auto-évaluation Portail effectuée</span>
                </Multiselect>
            </vs-col>
        </vs-row>
        <!--<vs-row
            v-if="!skillReview"
            vs-align="left"
            vs-type="flex"
            vs-justify="space-around"
            vs-w="12"
        >
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                Par défaut, les évaluations seront pré-remplies avec les données
                du projet personnalisé précédent.
            </vs-col>
        </vs-row>-->
        <vs-row
            vs-align="left"
            vs-type="flex"
            vs-justify="space-around"
            vs-w="12"
        >
            <vs-col v-if="false" vs-type="flex" vs-justify="left" vs-align="left" vs-w="7">
                <p class="bold">
                    Souhaitez-vous récupérer dans ce projet les évaluations
                    internes/externes saisies sur le Portail ?
                </p>
            </vs-col>
            <vs-col v-show="false" vs-type="flex" vs-justify="left" vs-align="left" vs-w="5">
                <vs-switch
                    color="primary"
                    v-model="showEvaluations"
                    :disabled="typeForm === 'validation' || typeForm === 'consultation'"
                >
                    <span slot="on">Oui</span>
                    <span slot="off">Non</span>
                </vs-switch>
            </vs-col>
        </vs-row>
        <vs-row class="mb-6 mt-10" v-if="showEvaluations">
            <vs-col class="sm:w-1/5 w-full">
                <span>Evaluation(s) Portail :</span>
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <Multiselect
                    id="externalRatings"
                    v-model="resource.externals"
                    :options="externalRatings"
                    :multiple="true"
                    placeholder="Recherche ..."
                    track-by="id"
                    label="title"
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                    :disabled="typeForm === 'validation' || typeForm === 'consultation'"
                    @input="updateFormHandle()"
                >
                    <span slot="noOptions">Aucune évaluation transmise</span>
                </Multiselect>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import { fr } from "vuejs-datepicker/dist/locale";
import ratingService from "@components/services/ratings";

export default {
    components: {
        Datepicker,
        Multiselect,
    },
    props: {
        typeForm: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        jobs: {
            type: Array,
            required: true
        },
        occupations: {
            type: Array,
            required: true
        },
        skillReview: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            fr: fr,
            resource: {
                rating_date: null,
                jobs: [],
                occupations: [],
                externals: [],
                personals: []
            },
            ratingsDates: [],
            showEvaluations: false,
            externalRatings: [],
            personalRatings: []
        };
    },
    async created() {
        this.initResource();

        await Promise.all([
            this.loadRatingsDates(),
            this.loadExternalRatings()
        ])

        //if (this.skillReview) this.showEvaluations = true
        this.showEvaluations = true
    },
    computed: {
        disabledDates() {
            if (!this.ratingsDates) return { dates: [] };
            let dates = this.ratingsDates.filter(r => r.status != 2 && r.status != 3 && r.skill_review === this.skillReview).map(r => new Date(r.rating_date));

            return {
                dates: this.typeForm == "creation" ? dates : []
            };
        },
    },
    watch: {
        // 'resource': {
        //     handler: function(val) {

        //         let data = window._.cloneDeep(this.value);
        //         data.rating_date = val.rating_date
        //         data.occupations = val.occupations
        //         data.jobs = val.jobs
        //         data.externals = val.externals

        //         this.$emit('input', data);
        //     },
        //     deep: true
        // },
        'value': {
            handler: function(val) {

                if (this.resource.rating_date != val.rating_date) {
                    this.resource.rating_date = window._.cloneDeep(val.rating_date);
                }

                if (this.resource.jobs != val.jobs) {
                    this.resource.jobs = window._.cloneDeep(val.jobs);
                }

                if (this.resource.occupations != val.occupations) {
                    this.resource.occupations = window._.cloneDeep(val.occupations);
                }
            },
            deep: true
        },
    },
    methods: {
        initResource() {
            this.resource.rating_date = this.value.rating_date;

            // if (this.typeForm == 'creation') {
            //     this.resource.jobs = window._.cloneDeep(this.value.employee.current_jobs);
            //     this.resource.occupations = window._.cloneDeep(this.value.employee.current_occupations);
            // } else {
                this.resource.jobs = window._.cloneDeep(this.value.jobs);
                this.resource.occupations = window._.cloneDeep(this.value.occupations);
            //}

            this.resource.externals = [];
            this.resource.personals = []
        },

        /**
         * Chargement des dates à exclure pour le datepicker
         */
        async loadRatingsDates() {
            return ratingService.getRatingsDates({
                employee_id: this.value.employee.id,
                withMobility: 1
            }).then(response => {
                this.ratingsDates = response;
            });
        },

        /**
         * Chargement des evaluations du portail
         */
         async loadExternalRatings() {
            let response = await ratingService.getRatingsByStatus({
                employee_id: this.value.employee.id,
                statuses: [2, 3],
                include: ['creator', 'occupationSkills', 'skills']
            });
            this.externalRatings = response.data.filter(rating => rating.status === 3).map(rating => {
                rating.title = `${rating.eval_type} du ${this.formatDate(rating.rating_date)}`;
                return rating;
            })
            this.personalRatings = response.data.filter(rating => rating.status === 2).map(rating => {
                rating.title = `Auto-évaluation Portail du ${this.formatDate(rating.rating_date)}`;
                return rating;
            })
            // Présélection de la dernière auto-évaluation du portail :
            if (this.personalRatings.length > 0) {
                this.$set(this.resource, 'personals', [this.personalRatings.pop()]);
                this.updateFormHandle() // ... and then propagate modifications to the parent component
            }
        },

        careerUpdatedHandle() {
            this.$emit('career-updated')
        },

        updateFormHandle() {

            let data = window._.cloneDeep(this.value);
            data.rating_date = this.resource.rating_date
            data.occupations = this.resource.occupations
            data.jobs = this.resource.jobs
            data.externals = this.resource.externals
            data.personals = this.resource.personals

            this.$emit('input', data);
        }
    }
};
</script>
