/*
 * Set of functions to check user's permissions
 *    - can('create', 'jobs', 1)         ==> has permission to create a job on service 1
 *    - can('create', 'jobs')                ==> has permission to create a job whatever the service
 *    - can('view', 'employees', 3)    ==> has the permissions to view (in listing) the employees from service 3
 *    - isAbleTo('create-jobs', 1)     ==> has permission to create a job on service 1
 *    - isAbleTo('create-jobs')            ==> has permission to create a job whatever the service
 *    - listOfServicesViewableFor('employees') ==> [1, 3] means the user can see the employees only from services 1 & 3
 */
import Vue from "vue";

Vue.mixin({
    methods: {
        canMulti(verb, object, services = [], user) {

            if (!user) user = this.user;
            let bool = !!this.isASuperAdmin(user)

            if (services) {
                services.forEach(service => {
                    if (this.can(verb, object, service, user)) {
                        bool = true;
                        return
                    }
                })
            }

            return bool;
        },
        can(verb, object, service, user) {

            if (!user) user = window.Laravel.user;

            //short circuit if user is superAdmin
            if (this.isASuperAdmin(user)) return true;

            if (verb === "create")
                return this.isAbleTo("create-" + object, undefined, user);

            if (object === "services") {
                if (verb === "view") verb = "read";

                return (
                    this.isAbleTo(verb + "-services", undefined, user) && //has the permission or the role with this permission
                    ((user.services.length &&
                        user.services.map(s => s.id).includes(service)) || //has services which include the considered service
                        (!user.services.length &&
                            (!user.establishments.length ||
                                this.userServices_from_userEstablishments(
                                    user
                                ).includes(service)))) //hasn't establishments or has some and they include the considered service
                );
            } else if (["establishments", "users"].includes(object)) {
                if (verb === "view") verb = "read";

                //In that case service should be an array : arr1.some(r=> arr2.includes(r))
                return (
                    this.isAbleTo(verb + "-" + object, undefined, user) &&
                    (service === undefined ||
                        !user.establishments.length ||
                        this.userServices_from_userEstablishments(
                            user
                        ).some(id => service.includes(id)))
                );
            } else if (["skills", "occupation_skills"].includes(object)) {
                if (verb === "view") verb = "read";

                return this.isAbleTo(verb + "-" + object, undefined, user);
            } else return this.isAbleTo(verb + "-" + object, service, user);
        },

        isAbleTo(permission, service, user) {
            if (this.isASuperAdmin(user)) return true;

            if (!user.permissions) return false;

            if (!user.permissions.length)
                return this.hasPermissionFromRoles(permission, user);

            if (Array.isArray(service)) {
                service.forEach(s => {
                    if (this.findPermissionForService(permission, s, user)) return true
                })
                return false
            }
            else return this.findPermissionForService(permission, service, user);
        },

        listOfServicesFor(action, object, user) {
            if (!user) user = this.user;

            if (this.isASuperAdmin(user)) return this.services.map(s => s.id);

            if (!user.permissions || !user.permissions.length) {
                if (user.roles[0].permissions.map(p => p.name).includes(action + "-" + object)) {
                    return this.services.map(s => s.id);
                } else {
                    return [];
                }
            }

            return user.permissions
                .filter(p => p.name === action + "-" + object)
                .map(p => p.pivot.service_id);
        },

        listOfServicesViewableFor(object, user) {
            return this.listOfServicesFor("view", object, user);
        },

        listOfServicesUpdatableFor(object, user) {
            return this.listOfServicesFor("update", object, user);
        },

        findPermissionForService(permission, service, user) {
            if (!user) user = this.user;

            return !!user.permissions.find(
                p =>
                    p.name === permission &&
                    (!service ||
                        p.pivot.service_id === service ||
                        !p.pivot.service_id)
            );
        },

        hasPermissionFromRoles(permission, user) {
            if (!user) {
                user = this.user
            }

            for (let role of user.roles) {
                if (role.permissions.find(p => p.name === permission)){
                    return true
                }
            }

            return false
        },

        isASuperAdmin(user) {
            if (!user) user = this.user;
            return user.roles.find(r => r.name === "superadministrator");
        },

        isAnAdmin(user) {
            if (!user) user = this.user;
            return user.roles.find(r => r.name === "administrator");
        },

        isADrh(user) {
            if (!user) user = this.user;
            return user.roles.find(r => r.name === "drh");
        },

        isAMonitor(user) {
            if (!user) user = this.user;
            return user.roles.find(r => r.name === "manager");
        },

        isAVisitor(user) {
            if (!user) user = this.user;
            return user.roles.find(r => r.name === "visitor");
        },

        isAnEvaluator(user) {
            if (!user) user = this.user;
            return user.roles?.find(r => r.name === "evaluator");
        },

        isAdminInterface() {
            const pathname = document.location.pathname;
            return pathname.startsWith('/admin');
        },

        userServices_from_userEstablishments(user) {
            let services = []
            //let userObject = typeof user === 'number' ? this.$store.state.users.find(u => u.id === user) : user
            //let userObject = user

            if (!user.establishments.length) //When superadmin or for some admin users
                return this.services.map(s => s.id)

                user.establishments.forEach(et => {
                et.services.forEach(s => {
                    services.push(s)
                })
            })

            return services.map(s => s.id)
        },
    }
});
