<template>
    <div class="con-exemple-prompt mb-6 scrollBug">
        <!-- <div v-if="type == 'create'" class="vx-row mb-6 w-full">
            <div class="vx-col sm:w-1/3 w-full">
                <span>A partir d'un modèle</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-switch
                    v-model="fromAdminOccupationSkill"
                    name="occupationSkillSwitch"
                />
            </div>
        </div>
        <div v-if="fromAdminOccupationSkill" class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Modèle :</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <Multiselect
                    v-model="adminOccupationSkillSector"
                    :options="sectors"
                    track-by="id"
                    label="title"
                    :multiple="false"
                    :group-select="false"
                    placeholder="Filière du savoir-faire ..."
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                />
            </div>

            <div class="vx-col sm:w-1/3 w-full">
                <Multiselect
                    v-model="adminOccupationSkill"
                    :options="shownAdminoccupationskills"
                    track-by="id"
                    label="title"
                    :multiple="false"
                    :group-select="false"
                    placeholder="Savoir-faire ..."
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                />
            </div>
        </div> -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Savoir-faire* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-if="type != 'show'"
                    v-model="resource.title"
                    name="resourceTitle"
                    class="w-full"
                />
                <b v-else>{{ resource.title }}</b>
            </div>
        </div>

        <div v-if="type != 'show'" class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Savoir-faire Référentiel :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Multiselect
                    v-model="adminOccupationSkill"
                    :options="groupedAdminOccupationSkills"
                    group-label="group"
                    group-values="items"
                    :group-select="false"
                    placeholder="Choisir..."
                    track-by="id"
                    label="title"
                    :multiple="false"
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                    :loading="adminOccupationSkillsLoading"
                    @select="adminOccupationSkillChangeHandle"
                />
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Spécifique à :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Multiselect
                    v-if="type != 'show'"
                    id="addSFEstablishment"
                    v-model="resource.establishment"
                    :options="establishments"
                    track-by="id"
                    :multiple="false"
                    :group-select="false"
                    name="establishments"
                    label="name"
                    placeholder="Recherche ..."
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                >
                    <span slot="noResult">Aucun résultat.</span>
                </Multiselect>
                <b v-else>{{ resource.establishment ? resource.establishment.name : '--' }}</b>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Description du Savoir-faire :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea
                    v-if="type != 'show'"
                    v-model="resource.description"
                    name="resourceDescription"
                />
                <span v-else>{{ resource.description }}</span>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Filière* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Multiselect
                    v-if="type != 'show'"
                    v-model="resource.sector"
                    :options="sectors"
                    track-by="id"
                    label="title"
                    :multiple="false"
                    :group-select="false"
                    placeholder="Recherche ..."
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                >
                    <span slot="noResult">Aucun résultat.</span>
                </Multiselect>
                <span v-else>{{ resource.sector.title }}</span>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Type* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">

                <span>{{ data_get(resource, 'admin_occupation_skill.type', '--') }}</span>
            </div>
        </div>

        <TransversalSkillsSelector
            :value="resource.skills"
            :type="formType"
            :skills="skills"
            :admin-os="resource.resource == 'AdminOccupationSkill' ? resource : resource.admin_occupation_skill"
            @input="setTranversalSkills"
            @needvalidation="needValidation = $event"
        />

        <div class="mb-10">
            <vs-collapse accordion class="mb-6 mt-10">
                <vs-collapse-item>
                    <div slot="header">
                        <h5>Signification des notes : </h5>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/5 w-full">
                            <b>{{ occSkillNoteById(1) }} - {{ occSkillLevelLabelById(1) }} : </b>
                        </div>
                        <div class="input-level vx-col sm:w-4/5 w-full">
                            <span v-if="type == 'show'">{{ resource.level.level_0 }}</span>
                            <vs-input 
                                v-else 
                                v-model="resource.level.level_0" 
                                name="modifyOccSkillLevel_O" 
                                class="w-full"
                                placeholder="0 : " 
                                type="text" 
                                @input="levelChangeHandle"
                            />
                        </div>
                    </div>
                    <div class="vx-row mt-3">
                        <div class="vx-col sm:w-1/5 w-full">
                            <b>{{ occSkillNoteById(2) }} - {{ occSkillLevelLabelById(2) }} : </b>
                        </div>
                        <div class="input-level vx-col sm:w-4/5 w-full">
                            <span v-if="type == 'show'">{{ resource.level.level_1 }}</span>
                            <vs-input 
                                v-else 
                                v-model="resource.level.level_1" 
                                name="modifyOccSkillLevel_1" 
                                class="w-full"
                                placeholder="1 : " 
                                type="text"
                                @input="levelChangeHandle"
                            />
                        </div>
                    </div>
                    <div class="vx-row mt-3">
                        <div class="vx-col sm:w-1/5 w-full">
                            <b>{{ occSkillNoteById(3) }} - {{ occSkillLevelLabelById(3) }} : </b>
                        </div>
                        <div class="input-level vx-col sm:w-4/5 w-full">
                            <span v-if="type == 'show'">{{ resource.level.level_2 }}</span>
                            <vs-input 
                                v-else 
                                v-model="resource.level.level_2" 
                                name="modifyOccSkillLevel_2" 
                                class="w-full"
                                placeholder="2 : " 
                                type="text" 
                                @input="levelChangeHandle"
                            />
                        </div>
                    </div>
                    <div class="vx-row mt-3">
                        <div class="vx-col sm:w-1/5 w-full">
                            <b>{{ occSkillNoteById(4) }} - {{ occSkillLevelLabelById(4) }} : </b>
                        </div>
                        <div class="input-level vx-col sm:w-4/5 w-full">
                            <span v-if="type == 'show'">{{ resource.level.level_3 }}</span>
                            <vs-input 
                                v-else 
                                v-model="resource.level.level_3" 
                                name="modifyOccSkillLevel_3" 
                                class="w-full"
                                placeholder=" 3 : " 
                                type="text" 
                                @input="levelChangeHandle"
                            />
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>

        </div>

        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full pl-12">
                <span>Image (.jpg, .png, .webp) :</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full pl-8 pr-12">
                <avatar-cropper
                    v-if="type != 'show'"
                    :id="'cropper'"
                    :trigger="'#edited-pick-photo'"
                    :labels="{ submit: 'Soumettre', cancel: 'Annuler' }"
                    :upload-handler="submitPhoto"
                    :cropper-options="{'aspectRatio': '16:9', 'autoCropArea': 1, 'viewMode': 1, 'movable': false, 'zoomable': false}"
                />
                <div :id="'edited-pick-photo'" style="text-align:center; font-size: 20px; background-color: grey; color: white; width: 470px; height: 36px;">
                    <small>Cliquez pour Modifier/Ajouter la photo avec un fichier</small>
                </div>
                <!--<vs-input
                    type="file"
                    name="file"
                    class="w-full"
                    @change="changeFileHandle"
                />-->
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full pl-12">
                <span>Lien vers une image du savoir-faire (lien public, libre de droits): </span>
            </div>
            <div class="vx-col sm:w-4/5 w-full pl-8 pr-12">
                <vs-input
                    v-if="type != 'show'"
                    v-model="resource.photo"
                    name="resourcePhoto"
                    class="w-full"
                />
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full pl-12">
                <span>Aperçu :</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full pl-8 pr-12">
                <img
                    v-if="!resourcePhotoIsVideo"
                    :id="'edited-pick-photo'"
                    :src="resource.photo"
                    width="120px"
                />
                <iframe
                    v-else
                    width="100%"
                    height="315"
                    :src="resource.photo"
                    title="Video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import TransversalSkillsSelector from "./partials/TransversallSkillsSelector";
import AvatarCropper from 'vue-avatar-cropper'

import {data_get} from '@assets/utils/helpers/data_get.js'

export default {
    name: "OccupationSkillForm",
    components: {
        Multiselect,
        TransversalSkillsSelector,
        AvatarCropper
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        type: {
            required: true,
            type: String
        },
    },
    data() {
        return {
            data_get: data_get,
            log: console.log,
            formType: null,
            resource: {},
            sectors: [],
            establishments: [],
            sectorsLoading: true,
            establishmentsLoading: true,
            fromAdminOccupationSkill: false,
            skills: [],
            skillsLoading: true,
            adminOccupationSkillSector: null,
            adminOccupationSkill: null,
            adminOccupationSkills: [],
            adminOccupationSkillsLoading: true,
            adminSkills: [],
            adminSkillsLoading: true,
            needValidation: false,
            levelChanged: false
        };
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue === oldValue) return;
            this.initResource();
        },
        resource: {
            handler() {
                this.value.title            = this.resource.title;
                this.value.description      = this.resource.description;
                this.value.sector           = this.resource.sector;
                this.value.establishment    = this.resource.establishment;
                this.value.skills           = this.resource.skills;
                this.value.photo            = this.resource.photo;
                //this.value.file             = this.resource.file;
                this.value.photoBlob        = this.resource.photoBlob
                this.value.version                   = this.resource.version;
                this.value.admin_occupation_skill_id = this.resource.admin_occupation_skill_id;

                if (this.levelChanged) {
                    this.value.level = this.resource.level;
                }

                this.$emit("input", this.value);
            },
            deep: true
        },
        hasError(value) {
            this.$emit("error", value);
        },

        async adminOccupationSkill(value) {
            if (!value) {
                return
            }

            // Fix - pas de photo depuis un AdminOccupationSkill
            //this.resource.photo = value.photo;

//             this.resource = {
//                 title: value.title,
//                 description: value.description,
//                 sector: this.sectors.find(el => el.id === value.sector_id),
//                 establishment: this.establishments.find(el => el.id === value.establishment_id),
//                 level: value.level,
//                 skills: {},
//                 photo: value.photo,
//                 resource: value.resource
//             };
//
//             // Préparation des compétences transversales
//             let skills = {};
//             value.adminskills.forEach(adminskill => {
//                 let skill = this.getSkillByAdminSkillId(adminskill.id);
//                 skills[skill.id] = adminskill.pivot.required_level;
//             });
//             this.resource.skills = skills;

            this.fromAdminOccupationSkill = false;
        }
    },
    computed: {
        hasError() {

            let error =
                !this.resource.title ||
                //!this.resource.description ||
                !this.resource.sector ||
                this.needValidation;

            return error;
        },

        shownAdminoccupationskills() {
            if (this.adminOccupationSkillSector) {
                return this.adminOccupationSkills.filter(adminoccupationskill => adminoccupationskill.sector_id == this.adminOccupationSkillSector.id)
            } else {
                return this.adminOccupationSkills
            }
        },

        groupedAdminOccupationSkills() {
            const sortedBySector = this.adminOccupationSkills.sort((a, b) => a.sector?.title < b.sector?.title ? -1 : 1);
            return this.groupForMultiselect(sortedBySector, ['sector.title'], 'title')
        },

        resourcePhotoIsVideo() {
            
            if (!this.resource.photo) {
                return false;
            }
            
            if (this.resource.photo.indexOf('http') === -1) return false

            return this.resource.photo.indexOf('.jpg') === -1
                && this.resource.photo.indexOf('.jpeg') === -1
                && this.resource.photo.indexOf('.png') === -1
                && this.resource.photo.indexOf('.gif') === -1
                && this.resource.photo.indexOf('.svg') === -1
                && this.resource.photo.indexOf('.webp') === -1
                && this.resource.photo.indexOf('.bmp') === -1
                && this.resource.photo.indexOf('.tiff') === -1
                && this.resource.photo.indexOf('.tif') === -1
                && this.resource.photo.indexOf('.ico') === -1
                && this.resource.photo.indexOf('.psd') === -1
                && this.resource.photo.indexOf('.eps') === -1
                && this.resource.photo.indexOf('.raw') === -1
                && this.resource.photo.indexOf('.heic') === -1
                && this.resource.photo.indexOf('.heif') === -1;
        }
    },
    methods: {
        submitPhoto(ev) {
            ev.getCroppedCanvas({ width: 512, height: 512 }).toBlob(blob => {
                    this.resource.photo = URL.createObjectURL(blob)
                    this.resource.photoBlob = blob
                },
                'image/jpeg',
                0.9
            )
        },
        async loadSectors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/sectors",
                    params: { perpage: 9999, sortBy: {"title": "asc"}, include: [] }
                })
                .then(response => {
                    this.sectors = response.data.data;
                    this.sectorsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadEstablishments() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                    }
                })
                .then(response => {
                    let establishments = response.data.data
                    //Ajout d'un élément en tête de tableau pour réinitialiser l'établissement spécifique à NULL
                    establishments.unshift({id: null, name: 'Tronc commun'})
                    this.establishments = establishments;
                    this.establishmentsLoading = false
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadAdminSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/admin-skills",
                    params: {
                        perpage: 9999,
                        include: ['category', 'admin_level'],
                        withTrash: 'true',
                    }
                })
                .then(response => {
                    let adminSkills = response.data.data
                    this.adminSkills = adminSkills
                    this.adminSkillsLoading = false;
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    console.error(err)
                });
        },

        getAdminSkillById(id) {
            return this.adminSkills.find(as => as.id === id);
        },

        async loadAdminOccupationSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/occupation-skills",
                    //params: { perpage: 9999, sortBy: {"title": "asc"}, include: this.type == 'create' ? ['adminskills'] : null }
                    params: {
                        perpage: 9999,
                        sortBy: {"title": "asc"},
                        include: ['adminskill_adminoccupationskills', 'sector'],
                        hidden: ['created_at', 'created_by', 'updated_at', 'updated_by', 'validated_by']
                    }
                })
                .then(response => {
                    this.adminOccupationSkills = this.reconstuctAdminOccupationSkills(response.data.data);
                    this.adminOccupationSkillsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadAdminOccupationSkillRelation(id) {
            return window
                .axios({
                    method: "get",
                    url: `/api/admin/occupation-skills/${id}`,
                    params: { include: ['adminskills'] }
                })
                .then(response => {
                    this.resource.admin_occupation_skill = response.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        reconstuctAdminOccupationSkills(aos) {
            return aos.map(item => {

                item.adminskills = [];

                if (item.adminskill_adminoccupationskills) {

                    item.adminskill_adminoccupationskills.forEach(as_aos => {
                        let adminskill = this.getAdminSkillById(as_aos.admin_skill_id);
                        adminskill.pivot = as_aos;
                        item.adminskills.push(adminskill);
                    })
                }

                return item;
            })
        },

        async loadSkills() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/skills",
                    //params: { perpage: 9999, include: ['level', 'admin_skill.category', 'admin_skill.admin_level'] }
                    params: { perpage: 9999, include: ['level'] }
                })
                .then(response => {
                    this.skills = this.reconstructSkills(response.data.data);
                    this.skillsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        reconstructSkills(skills) {
            return skills.map(s => {
                s.admin_skill = this.getAdminSkillById(s.admin_skill_id);

                return s;
            })
        },

        getSkillByAdminSkillId(adminSkillId) {
            return this.skills.find(el => el.admin_skill_id == adminSkillId)
        },

        setTranversalSkills(skills) {
            this.resource.skills = skills
        },

        initResource() {
            let skills = {};

            if (this.value.skills.length && this.value.skills.length > 0) {
                this.value.skills.forEach(el => {
                    skills[el.id] = el.pivot.required_level
                })
            }

            this.resource = {
                title: this.value.title,
                description: this.value.description,
                sector: this.value.sector,
                type: this.value.type,
                establishment: this.value.establishment,
                skills: skills,
                admin_occupation_skill: this.value.admin_occupation_skill ? this.value.admin_occupation_skill : {},
                photo: this.value.photo,
                photoBlob: this.value.photoBlob,
                //file: this.value.file,
                admin_occupation_skill_id: this.value.admin_occupation_skill_id,
                level: {
                    level_0: this.value.level ? this.value.level.level_0 : this.occSkillDescriptionById(1),
                    level_1: this.value.level ? this.value.level.level_1 : this.occSkillDescriptionById(2),
                    level_2: this.value.level ? this.value.level.level_2 : this.occSkillDescriptionById(3),
                    level_3: this.value.level ? this.value.level.level_3 : this.occSkillDescriptionById(4)
                }
            };

            if (this.type == 'update-from-admin') {
                this.resource.version = this.value.admin_occupation_skill.version;
            }

            this.adminOccupationSkill = this.value.admin_occupation_skill;
        },

        async adminOccupationSkillChangeHandle(value) {

            // Laisser le temps au Multiselect de se fermer. Sinon il se rouvre ensuite.
            await setTimeout(() => {}, 100);

            if (!value) {
                return
            }

            if (this.type == 'create') {
                // this.resource = {
                //     title: this.resource.title,
                //     description: value.description,
                //     sector: this.sectors.find(el => el.id === value.sector_id),
                //     establishment: this.establishments.find(el => el.id === value.establishment_id),
                //     resource: this.resource.resource,
                //     admin_occupation_skill_id: value.id,
                //     admin_occupation_skill: value,
                //     skills: this.resource.skills
                // };

                this.resource['description'] = value.description;
                this.resource['sector'] = this.sectors.find(el => el.id === value.sector_id);
            }

            this.resource['admin_occupation_skill_id'] = value.id;
            this.resource['admin_occupation_skill'] = value;


            // Si au moins une compétence est déjà appelée
            if (Object.values(this.resource.skills).length > 0) {

                const result = await this.$swal({
                    title: `Souhaitez-vous écraser les compétences déjà appelées ?`,
                    text: '',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non'
                })

                // Ne pas écraser les compétences -> validation
                if (!result.value) {
                    // await this.loadAdminOccupationSkillRelation(value.id);
                    // this.formType = 'update-from-admin';
                    return;
                }
            }

            // Préparation des compétences transversales
            let skills = {};
            value.adminskills.forEach(adminskill => {
                let skill = this.getSkillByAdminSkillId(adminskill.id);
                skills[skill.id] = adminskill.pivot.required_level;
            });

            this.resource.skills = skills;
        },

        levelChangeHandle() {
            this.levelChanged = true;
        },

        //DEPRECATED
        changeFileHandle(event) {
            this.resource.file = event.target.files[0];
        },
    },
    async created() {
        this.initResource();
        this.localLoading();

        await this.loadAdminSkills();

        await Promise.all([
            this.loadSectors(),
            this.loadEstablishments(),
            this.loadAdminOccupationSkills(),
            this.loadSkills()
        ]);

        this.formType = this.type

        this.endLocalLoading();

        if (this.type === 'update-from-admin') {
            this.loadAdminOccupationSkillRelation(this.value.admin_occupation_skill_id)
        }

    }
};
</script>

<style lang="scss" scoped></style>
