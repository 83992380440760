<template>
    <div>
        <div class="badge-action">
            <vs-button
                v-if="planAllow('acquis-badges')"
                color="success"
                type="filled"
                icon-pack="feather"
                class="font-weight-bold"
                @click="showPopupUpdate = true"
            >
                Badges
            </vs-button>

            <div v-else style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                <vs-button
                    color="success"
                    type="filled"
                    icon-pack="feather"
                    class="font-weight-bold"
                    disabled="true"
                >
                    Badges
                </vs-button>
                <vs-icon class="button-bullet" bg="#FF9B3A" round >
                    <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                </vs-icon>
            </div>
        </div>

        <vs-prompt
            :active.sync="showPopupUpdate"
            title="Gestion des badges"
            cancel-text="Fermer"
            button-accept="false"
            @cancel="showPopupUpdate = false"
            @close="showPopupUpdate = false"
        >
            <vs-row>
                <vs-col>
                    <EmployeeBadgesAvailable
                        :api="`/api/gestion/employees/${this.employee.id}/badges/available-skills`"
                        :employee="employee"
                        @asked="refreshAsked++"
                    />
                </vs-col>
            </vs-row>

            <vs-row class="mt-20">
                <vs-col>
                    <EmployeeBadgesAsked
                        :api="`/api/gestion/employees/${this.employee.id}/badges`"
                        :employee="employee"
                        :refresh="refreshAsked"
                        :accepted="false"
                    />
                </vs-col>
            </vs-row>

            <vs-row class="mt-20">
                <vs-col>
                    <EmployeeBadgesAsked
                        :api="`/api/gestion/employees/${this.employee.id}/badges`"
                        :employee="employee"
                        :refresh="refreshAsked"
                        :accepted="true"
                    />
                </vs-col>
            </vs-row>
        </vs-prompt>
    </div>
</template>

<script>
import EmployeeBadgesAvailable from './EmployeeBadgesAvailable.vue';
import CrownIcon from '@components/svg/CrownIcon';
import EmployeeBadgesAsked from './EmployeeBadgesAsked.vue';
export default {
    components: {
        EmployeeBadgesAvailable, EmployeeBadgesAsked, CrownIcon
    },
    props: {
        employee: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            log: console.log,
            showPopupUpdate: false,
            availableSkillBadges: {
                data: [],
                loading: false
            },
            askedBadge: {
                date: [],
                loading: false
            },
            refreshAsked: 0
        }
    },
    created() {

    },
    watch: {
        showPopupUpdate(value) {
            if (value) {
                //this.loadAvailableSkillBadges()
            }
        }
    },
    methods: {
        loadAvailableSkillBadges() {
            this.availableSkillBadges.loading = true;

            return window
                .axios({
                    method: "get",
                    url: `/api/gestion/employees/${this.employee.id}/badges/available-skills`,
                    params: {}
                })
                .then(response => {
                    this.availableSkillBadges.data = response.data;
                    this.availableSkillBadges.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        loadAskedBadges() {

        },

        createResource() {

        }
    }
}
</script>

<style scoped>
.badge-title {
    font-size: 1.4em;
    font-weight: 600;
    color: #7f7f7f;
}
</style>
