<template>
    <div class="selector-container">
        <!-- Search bar -->
        <vs-row v-if="hasSkills" class="mb-6">
            <vs-col vs-w="12">
                <vs-input
                    v-model="search"
                    name="JobFilterOccupationSkills"
                    class="w-full"
                    placeholder="Filtrer les Savoir-faire affichés..."
                />
            </vs-col>
        </vs-row>

        <vs-row>
            <span class="vs-col">
                Glissez-déposez les Savoir-faire que vous souhaitez ajouter :
            </span>
        </vs-row>

        <vs-row class="mt-5" v-if="hasSkills">
            <!-- Skills left (not selected) -->
            <vs-col vs-w="5.5">
                <vs-list>
                    <vs-list-header
                        title="Savoir-faire disponibles"
                        color="primary"
                    />
                    <vs-list class="scrollable_list">
                        <template v-for="(listItem, i) in sectorsSkills">
                            <vs-list-header
                                color="primary"
                                :title="i"
                                :key="i"
                            ></vs-list-header>
                            <draggable
                                :value="sectorsSkills[i]"
                                group="skills"
                                class="p-2 cursor-move"
                                :key="'drag-' + i"
                            >
                                <vs-list-item
                                    v-for="(skill, j) in listItem"
                                    :id="'occupationSkill_' + skill.id"
                                    :key="i + '-' + j"
                                    :title="skill.title+(skill.establishment? ' - '+skill.establishment.name : '')"
                                />
                            </draggable>
                        </template>
                        <template v-if="!Object.keys(sectorsSkills).length">
                            <draggable
                                :value="[]"
                                class="p-2 cursor-move"
                                group="skills"
                                :emptyInsertThreshold="250"
                            />
                        </template>
                    </vs-list>
                </vs-list>
            </vs-col>

            <!-- Icons >> -->
            <template>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                    <chevrons-right-icon
                        size="3x"
                        stroke="rgb(var(--vs-primary))"
                        class="custom-class"
                    />
                </vs-col>
            </template>

            <!-- Skills right (selected) -->
            <vs-col vs-w="5.5">
                <vs-list>
                    <vs-list-header
                        :title="`Savoir-faire choisis`"
                        color="primary"
                    />

                    <draggable
                        v-model="selected"
                        group="skills"
                        class="p-2 cursor-move scrollable_list"
                        @input="onSelectedSkillsChange"
                        :emptyInsertThreshold="250"
                        direction="horizontal"
                    >
                        <vs-list-item
                            v-for="(listItem, i) in selected"
                            :id="'occupationSkillSelected_' + listItem.id"
                            :key="listItem.id + '-' + i"
                            :title="listItem.title+(listItem.establishment? ' - '+listItem.establishment.name : '')"
                        />
                    </draggable>
                </vs-list>
            </vs-col>
            <!-- End Skills right (selected) -->
        </vs-row>
        <vs-row v-else-if="occupationSkillsLoading || establishmentsLoading" class="selector-container empty">
            <p>Chargement en cours...</p>
        </vs-row>
        <vs-row v-else class="selector-container empty">
            <p>Aucun savoir-faire n'a encore été créé</p>
            <a @click="redirect">Ajouter un nouveau savoir-faire</a>
        </vs-row>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import { ChevronsRightIcon } from "vue-feather-icons";

import Fuse from "fuse.js";
const fuseOptions = {
    keys: ["title", "description"],
    isCaseSensitive: false,
    threshold: 0.4,
    location: 0,
    distance: 100
};
const fuse = new Fuse([], fuseOptions);

export default {
    name: "skillsSelector",
    components: {
        draggable,
        ChevronsRightIcon
    },
    props: {
        value: {
            type: Array,
            required: false
        },
        service: {
            type: [Object, null],
            required: false,
            default: null
        },
        sector: {
            type: [Object, null],
            required: false,
            default: null
        },
        filterBySector: {
            type: Boolean,
            required: false,
            default: false
        },
        filterByUserRelated: {
            type: Boolean,
            required: false,
            default: false
        },
        objectName: {
            type: String,
            required: false,
            default: "Poste"
        }
    },
    data() {

        return {
            occupationSkills: [],
            occupationSkillsLoading: true,
            adminOccupationSkills: [],
            establishments: [],
            establishmentsLoading: true,
            selected: [],
            search: null
        };
    },
    async created() {

        this.$vs.loading();
        await Promise.all([
            this.loadOccupationSkills(),
            this.loadAdminOccupationSkills(),
            this.loadEstablishments()
        ]);
        this.$vs.loading.close();

        if (this.value) {
            this.selected = this.value.slice().sort((a, b) => {
                return a.pivot.order - b.pivot.order
            });

            this.selected.map((el, i) => {
                el.order = i;
                return el;
            })
        }

        // Savoir faire par défaut depuis ID dans l'URL
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('os')) {
            searchParams.get('os').split(',').forEach(id => {
                this.selected.push(this.occupationSkills.find(os => os.id == id));
            })

            // On force la propagation
            this.onSelectedSkillsChange();
        }
    },
    watch: {
        value(value) {
            this.selected = value.slice()
        }
    },
    computed: {

        skills() {
            if (!this.occupationSkillsLoading) {

                if (this.is_admin_interface) {
                    return this.adminOccupationSkills;
                } else {
                    return this.occupationSkills;
                }

            }
            return [];
        },

        /**
         * Check if skills have already been created
         */
        hasSkills() {
            let value = !!(this.skills && this.skills.length);
            this.$emit("hasSkills", value);

            return value;
        },

        establishmentsServices() {
            if (this.isASuperAdmin(this.user) || (this.isAnAdmin(this.user) && this.user.establishments?.length == 0)) {
                return this.establishments.flatMap(e => e.services);
            } else if (this.user && this.user.establishments?.length) {
                return this.user.establishments.flatMap(e => e.services);
            }
            return [];
        },
        establishmentsServicesIds() {
            if (!this.establishmentsLoading) {
                return this.establishmentsServices.flatMap(s => s.id);
            }
            return [];
        },

        /**
         * List of skills
         * Filtered by service
         * Filtered by search
         * @returns Array
         */
        skillsList() {
            if (!this.skills.length) return [];

            let servicesIds = this.establishmentsServicesIds;
            let skills = this.skills.filter(item => {
                // Creation
                if (!this.selected?.length) {
                    if (this.filterByUserRelated) {
                        let jobs = item.jobs.filter(j => {
                            return servicesIds.includes(j.service_id);
                        });
                        if (jobs.length && this.filterBySector) {
                            if (this.service)
                                return item.sector_id == this.service.sector_id;
                            if (this.sector)
                                return item.sector_id == this.sector.id;
                        }
                        return !!jobs.length;
                    }
                    if (this.filterBySector) {
                        if (this.service)
                            return item.sector.id === this.service.sector_id;
                        if (this.sector)
                            return item.sector.id === this.sector.id;
                    }
                    return true;
                }

                // Editionskills
                if (this.selected?.length) {
                    if (this.selected.find(el => el.id === item.id)) {
                        return false;
                    }
                    if (this.filterByUserRelated) {
                        let jobs = item.jobs.filter(j => {
                            return servicesIds.includes(j.service_id);
                        });
                        if (jobs.length && this.filterBySector) {
                            if (this.service)
                                return item.sector_id == this.service.sector_id;
                            if (this.sector)
                                return item.sector_id == this.sector.id;
                        }
                        return !!jobs.length;
                    }
                    if (this.filterBySector) {
                        if (this.service)
                            return item.sector.id === this.service.sector_id;
                        if (this.sector)
                            return item.sector.id === this.sector.id;
                    }
                    return true;
                }
                return true;
            });

            if (this.search) {
                fuse.setCollection(skills);
                skills = fuse.search(this.search);
            }

            return skills;
        },

        /**
         * List of skills grouped by sector
         * Ordered by alphabetique order
         * @returns Array
         */
        sectorsSkills() {
            let skills = this.skillsList;
            if (!skills?.length) return {};

            skills.sort((a, b) => {
                if (a.sector.title != b.sector.title) {
                    return a.sector.title > b.sector.title ? 1 : -1;
                } else {
                    return a.title > b.title ? 1 : -1;
                }
            });

            let sectorsSkills = {};
            skills.forEach(skill => {
                if (!sectorsSkills[skill.sector.title]) {
                    sectorsSkills[skill.sector.title] = [];
                }
                sectorsSkills[skill.sector.title].push(skill);
            });
            return sectorsSkills;
        }
    },
    methods: {
        async loadOccupationSkills() {
            if (this.is_admin_interface) {
                return;
            }

            return window.axios({
                method: 'get',
                url: '/api/referentiel/occupation-skills',
                params: {perpage: 9999, include: ['sector', 'jobs', 'establishment', 'skills']},
            })
            .then(response => {
                this.occupationSkills = response.data.data;
                this.occupationSkillsLoading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })

        },
        async loadAdminOccupationSkills() {
            if (!this.is_admin_interface) {
                return;
            }

            return window.axios({
                method: 'get',
                url: '/api/admin/occupation-skills',
                params: {perpage: 9999, include: ['sector']},
            })
            .then(response => {
                this.adminOccupationSkills = response.data.data;
                this.occupationSkillsLoading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        async loadEstablishments() {
            if (this.is_admin_interface) {
                return;
            }

            return window.axios({
                method: 'get',
                url: '/api/referentiel/establishments',
                params: {perpage: 9999, include: ['services']},
            })
            .then(response => {
                this.establishments = response.data.data;
                this.establishmentsLoading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        onSelectedSkillsChange() {
            let selected = this.selected.map(function(item, key) {
                item.order = key;
                return item;
            })
            this.$emit("input", selected);
        },
        redirect() {
            this.$emit("close");
            this.$router.push({ name: "Savoir-faire" });
        }
    }
};
</script>

<style lang="scss" scoped>
.selector-container {
    min-height: 200px;
    &.empty {
        background-color: #f8f8f8;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        a {
            cursor: pointer;
        }
        > * {
            font-size: 1.1em;
        }
    }
    p.title {
        font-size: 1.05em;
    }
    .vs-col {
        padding: 0;
    }
    .scrollable_list {
        height: 250px;
        min-height: 250px;
        overflow-y: auto;
    }
}
</style>
