 
// import { loadUsers as loadUsersService } from "@components/services/resources.js";
export const loadUsers = async ()  => {
    return window
        .axios({
            method: "get",
            url: "/api/referentiel/users",
            params: {
                perpage: 9999,
                include: []
            }
        })
        .then(response => {
            let users = response.data.data;

            if (users.length == 0) return;

            users.slice().map(u => {
                u.label = `${u.name} (${u.email})`;
                return u;
            });

            return users;
        })
        .catch(err => {
            console.log(err);
            this.notifyError(err, `Une erreur est survenue`);
        });
}

// import { loadRatingQuestionnaires } from "@components/services/resources.js";
export const loadRatingQuestionnaires = async ()  => {
    return window
        .axios({
            method: "get",
            url: "/api/referentiel/rating-questionnaires",
            params: {
                perpage: 9999,
                include: []
            }
        })
        .catch(err => {
            console.log(err);
            this.notifyError(err, `Une erreur est survenue`);
        });
}

// import { reloadAppUser as reloadAppUserService } from "@components/services/resources.js";
export const reloadAppUser = async () => {
    return window
        .axios({
            method: "get",
            url: `/api/referentiel/users/profile`,
            params: {}
        })
        .then(response => {
            window.Laravel.user = response.data;
        })
        .catch(err => {
            console.log(err);
            this.notifyError(err, `Une erreur est survenue`);
        });
}

// import { loadOccupationSkills } from "@components/services/resources.js";
export const loadOccupationSkills = async ({ perpage = 9999, include = [] })  => {
    return window
        .axios({
            method: "get",
            url: "/api/referentiel/occupation-skills",
            params: { perpage, include }
        })
        .catch(err => {
            console.log(err);
            this.notifyError(err, `Une erreur est survenue`);
        });
}
