var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "experience" },
    [
      _c("h3", { staticClass: "mb-8" }, [
        _vm._v("Métiers occupés ou en préparation :")
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.canUpdate()
            ? _c(
                "vs-button",
                {
                  staticStyle: { "margin-bottom": "2em" },
                  attrs: {
                    id: "addTraining",
                    color: "primary",
                    type: "filled",
                    "icon-pack": "feather",
                    icon: "icon-plus"
                  },
                  on: {
                    click: function($event) {
                      return _vm.createResource()
                    }
                  }
                },
                [_vm._v("\n            Ajouter un métier\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isEvaluable.length &&
          _vm.canUpdate() &&
          _vm.planAllow("eval-tiers-croise")
            ? _c(
                "vs-button",
                {
                  staticClass: "mb-2 ml-2",
                  attrs: {
                    id: "inviteTiers",
                    color: "primary",
                    type: "border"
                  },
                  on: { click: _vm.inviteTiers }
                },
                [_vm._v("\n            Inviter un tiers à évaluer\n        ")]
              )
            : !_vm.planAllow("eval-tiers-croise") && !_vm.shouldHideOptions()
            ? _c(
                "div",
                {
                  staticStyle: { position: "relative", float: "left" },
                  attrs: {
                    title: "Votre formule n'inclut pas cette fonctionnalité"
                  }
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-2 ml-2",
                      attrs: {
                        id: "inviteTiers",
                        color: "primary",
                        type: "border",
                        disabled: "true"
                      }
                    },
                    [
                      _vm._v(
                        "\n                Inviter un tiers à évaluer\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "vs-icon",
                    {
                      staticClass: "button-bullet",
                      attrs: { bg: "#FF9B3A", round: "" }
                    },
                    [
                      _c("CrownIcon", {
                        staticStyle: {
                          width: "16px",
                          height: "16px",
                          padding: "3px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "DialogPrompt",
        {
          attrs: {
            active: _vm.showPopup,
            title: _vm.getDataPrompt.title,
            "cancel-text": "Annuler",
            "accept-text": "Enregistrer",
            "button-accept": _vm.getDataPrompt.btnAccept,
            "is-valid": !_vm.hasError
          },
          on: {
            "update:active": function($event) {
              _vm.showPopup = $event
            },
            cancel: _vm.resetResource,
            close: _vm.resetResource,
            accept: _vm.storeResource
          }
        },
        [
          _c("div", { staticClass: "con-exemple-prompt" }, [
            this.formType == "create" || _vm.resource.occupation
              ? _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                    _c("span", [_vm._v("Métier déjà existant :")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-2/3 w-full" },
                    [
                      _vm.occupations.length > 0
                        ? _c(
                            "multiselect",
                            {
                              attrs: {
                                name: "occupationTitle",
                                options: _vm.occupations,
                                multiple: false,
                                "group-label": "group",
                                "group-values": "items",
                                placeholder: "Recherche ...",
                                "track-by": "id",
                                label: "title",
                                "select-label": "",
                                "selected-label": "",
                                "deselect-label": "",
                                loading: _vm.occupationsLoading
                              },
                              model: {
                                value: _vm.resource.occupation,
                                callback: function($$v) {
                                  _vm.$set(_vm.resource, "occupation", $$v)
                                },
                                expression: "resource.occupation"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.resource.occupation
              ? _c("div", [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                      _c("span", [_vm._v("Libellé du métier* :")])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-2/3 w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: { name: "title" },
                          model: {
                            value: _vm.resource.title,
                            callback: function($$v) {
                              _vm.$set(_vm.resource, "title", $$v)
                            },
                            expression: "resource.title"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("Date de début* :")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "date", name: "start_date" },
                    model: {
                      value: _vm.resource.start_date,
                      callback: function($$v) {
                        _vm.$set(_vm.resource, "start_date", $$v)
                      },
                      expression: "resource.start_date"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("Date de fin ")]),
                !_vm.resource.occupation ? _c("span", [_vm._v("*")]) : _vm._e(),
                _c("span", [_vm._v(":")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "date",
                      name: "end_date",
                      "description-text":
                        "Un métier est évaluable (y compris par un tiers) et auto-évaluable jusqu'à sa date de fin"
                    },
                    model: {
                      value: _vm.resource.end_date,
                      callback: function($$v) {
                        _vm.$set(_vm.resource, "end_date", $$v)
                      },
                      expression: "resource.end_date"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  _c(
                    "span",
                    { staticStyle: { float: "left", "line-height": "26px" } },
                    [
                      _vm._v(
                        "\n                        Pièce jointe  \n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.documentLicence
                    ? _c("vs-chip", { attrs: { color: "secondary" } }, [
                        _c("b", [
                          _vm._v(
                            "Gratuit jusqu'à " +
                              _vm._s(_vm.postMaxSizePerPerson) +
                              " Mo (PDF uniquement)"
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.resource.doc_path
                ? _c(
                    "div",
                    { staticClass: "vx-col sm:w-2/3 w-full" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "inline mr-1 float-left cursor-pointer"
                        },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.download(_vm.resource.doc_path)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.resource.doc_path.split("/").pop())
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("vs-button", {
                        staticStyle: {
                          display: "inline",
                          "margin-top": "-0.5rem"
                        },
                        attrs: {
                          id: "deleteDoc_" + _vm.resource.id,
                          color: "primary",
                          type: "border",
                          size: "small",
                          "icon-pack": "feather",
                          icon: "icon-trash"
                        },
                        on: {
                          click: function($event) {
                            return _vm.removeDocPath(_vm.resource)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "vx-col sm:w-2/3 w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          type: "file",
                          accept: _vm.acceptExtensions(
                            _vm.documentLicence ? "all" : "pdf"
                          ),
                          name: "doc_path",
                          danger: this.fileError.message,
                          "danger-text": this.fileError.message
                        },
                        on: { change: _vm.selectFile }
                      })
                    ],
                    1
                  )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "DialogPrompt",
        {
          attrs: {
            active: _vm.showPopupFullStorage,
            title: "Votre espace de stockage est plein",
            "cancel-text": "Annuler",
            "accept-text": "OK"
          },
          on: {
            "update:active": function($event) {
              _vm.showPopupFullStorage = $event
            },
            close: function($event) {
              _vm.showPopupFullStorage = false
            },
            accept: function($event) {
              _vm.showPopupFullStorage = false
            }
          }
        },
        [
          _c("div", { staticClass: "con-exemple-prompt" }, [
            _c("p", [_vm._v(_vm._s(_vm.popupFullStorageMessage))])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "vs-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            sst: true,
            data: _vm.items,
            "no-data-text": "Aucune donnée à afficher"
          },
          on: { sort: _vm.eventSortByCol },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return _vm._l(data, function(item, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr, attrs: { data: item } },
                    [
                      _c("vs-td", { attrs: { data: item.title } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.title) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", { attrs: { data: item.start_date } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("date")(item.start_date || "-")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", { attrs: { data: item.end_date } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("date")(item.end_date || "-")) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-td", [
                        item.doc_path
                          ? _c("i", {
                              class:
                                "fa fa-file" +
                                (item.doc_path.split(".").pop() === "pdf"
                                  ? "-pdf"
                                  : "-o"),
                              staticStyle: {
                                "font-size": "2rem",
                                color: "rgba(var(--vs-primary),1)"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.download(item.doc_path)
                                }
                              }
                            })
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.canUpdate()
                        ? _c(
                            "vs-td",
                            [
                              _vm.can("update", "employees")
                                ? _c("vs-button", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: "Modifier",
                                        expression: "'Modifier'"
                                      }
                                    ],
                                    attrs: {
                                      id: "modifyJob_" + indextr,
                                      color: "primary",
                                      type: "border",
                                      "icon-pack": "feather",
                                      icon: "icon-edit"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateResource(item)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.can("update", "employees")
                                ? _c("vs-button", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: "Supprimer",
                                        expression: "'Supprimer'"
                                      }
                                    ],
                                    staticStyle: { "margin-left": "1rem" },
                                    attrs: {
                                      id: "deleteJob_" + indextr,
                                      color: "primary",
                                      type: "border",
                                      "icon-pack": "feather",
                                      icon: "icon-trash"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteResource(item)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                })
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "title" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Libellé")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "start_date" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Date de début")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "end_date" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Date de fin")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-th", [_vm._v("\n                Document\n            ")]),
              _vm._v(" "),
              _vm.canUpdate()
                ? _c("vs-th", [
                    _vm._v("\n                Actions\n            ")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }