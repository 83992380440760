<template>
    <div>
        <vs-row>
            <vs-col vs-align="left" vs-w="6" vs-justify="flex-end">
                <vs-button
                    v-if="isAdminInterface()
                        || userType === 'Evaluateur'
                        || can('create', 'users')
                    "
                    id="btn-create"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="createResource(userType)"
                >
                    Ajouter un nouvel {{ userType.toLowerCase() }}
                </vs-button>
                <DialogPrompt
                    :active.sync="showPopup"
                    :title="getDataPrompt.title"
                    cancel-text="Fermer"
                    accept-text="Enregistrer"
                    :button-accept="getDataPrompt.btnAccept"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @accept="storeResource"
                    @close="resetResource"
                >
                    <UserForm
                        v-model="resource"
                        :type="formType"
                        :user-type="userType"
                        :deleted-users-emails="deletedUsers.map(user => user.email)"
                        @error="setFormError"
                        @email-in-deleted="emailInDeleted"
                    />
                </DialogPrompt>

                <DialogPrompt
                    :active.sync="showDeletedPopup"
                    title="Utilisateur archivé"
                    cancel-text="Non"
                    accept-text="Oui"
                    :button-accept="getDataPrompt.btnAccept"
                    @accept="undeleteAndFillForm"
                    @cancel="emailToUndelete = null"
                    @close="emailToUndelete = null"
                    style="z-index: 53000;"
                >
                    <p>Cet email est utilisé dans un compte utilisateur archivé, souhaitez-vous le réactiver ?</p>
                </DialogPrompt>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data_="items.data.filter(user => !user.deleted_at)"
            :data="items.data"
        >
            <template slot="thead">
                <vs-th>
                    <Sort name="id" @sort="eventSortByCol">#</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="last_name" @sort="eventSortByCol">Nom</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="first_name" @sort="eventSortByCol">Prénom</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="email" @sort="eventSortByCol">Email</Sort>
                </vs-th>
                <vs-th>Rôle</vs-th>
                <vs-th>Référent</vs-th>
                <vs-th>Établissement(s)</vs-th>
                <vs-th>Service(s)</vs-th>
                <vs-th v-if="isASuperAdmin(user)" class="text-center">Compte activé</vs-th>
                <vs-th v-if="isASuperAdmin(user)" class="text-center">Email réinitialisation</vs-th>
                <vs-th v-if="isASuperAdmin(user)">Lien réinitialisation</vs-th>
                <vs-th width="160"></vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr v-for="(item, i) in data" :key="i" :data="item" :class="item.deleted_at ? 'deleted' : ''">
                    <vs-td>
                        {{ get(item, ":id") }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":last_name") }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":first_name") }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":email") }}
                    </vs-td>
                    <vs-td :data="item.name">
                        <vs-chip color="primary">
                            {{
                                item.roles[0]
                                    ? item.roles[0].display_name
                                    : null
                            }}
                        </vs-chip>
                    </vs-td>
                    <vs-td>
                        <vs-chip :color="item.reference === 1 ? 'success' : 'danger'">
                            {{ item.reference === 1 ? "Oui" : "Non" }}
                        </vs-chip>
                    </vs-td>
                    <vs-td>
                        <div v-for="(establishment, j) in item.establishments" :key="j">
                            {{ establishment.name }}
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-for="(service, j) in item.services" :key="j">
                            {{ service.title }}
                        </div>
                    </vs-td>
                    <template v-if="isASuperAdmin(user)">
                        <vs-td v-if="item.email_verified_at" class="text-center">
                        {{ formatDate(item.email_verified_at) }}
                        </vs-td>
                        <vs-td v-else-if="!item.fresh_token" class="center">
                        <vs-button
                            :id="'refreshToken_' + i"
                            v-tooltip="'Rafraîchir le token pour la réinitialisation du mot de passe'"
                            color="warning"
                            type="flat"
                            text="Rafraîchir"
                            icon-pack="feather"
                            icon="icon-send"
                            style="margin: 0 0 0 5px;"
                            @click="refreshToken(item)"
                        >
                            Rafraîchir token
                        </vs-button>
                        </vs-td>
                        <vs-td v-else class="center text-center">
                            x
                        </vs-td>
                    </template>
                    <template v-if="isASuperAdmin(user)">
                        <vs-td v-if="item.email_verified_at" class="text-center">
                            {{ formatDate(item.email_verified_at) }}
                        </vs-td>
                        <vs-td v-else-if="item.fresh_token" class="center">
                            <vs-button
                                :id="'sendEmail_' + i"
                                v-tooltip="'Envoyer l\'email d\'invitation'"
                                color="warning"
                                type="flat"
                                text="Envoyer"
                                icon-pack="feather"
                                icon="icon-send"
                                style="margin: 0 0 0 5px;"
                                @click="sendInvitation(item)"
                            >
                                Envoyer
                            </vs-button>
                        </vs-td>
                        <vs-td v-else class="center text-center">
                            x
                        </vs-td>
                    </template>
                    <template v-if="isASuperAdmin(user)">
                        <vs-td v-if="item.email_verified_at" class="center">&nbsp;</vs-td>
                        <vs-td v-else-if="item.fresh_token" class="center">
                            <vs-input
                                :id="'resetUrl-hidden-'+item.id"
                                type="hidden"
                                v-model="item.resetUrl"
                            />
                            <vs-button
                                :id="'reinitUser_' + i"
                                v-tooltip="'Copier le lien de réinitialisation'"
                                color="warning"
                                type="flat"
                                text="Copier lien"
                                icon-pack="feather"
                                icon="icon-send"
                                :data-resetUrl="item.resetUrl"
                                style="margin: 0 0 0 5px;"
                                @click="copyReinitLink(item)"
                            >
                                Copier lien réinitialisation
                            </vs-button>
                        </vs-td>
                        <vs-td v-else class="center text-center">
                            x
                        </vs-td>
                    </template>
                    <vs-td class="action">
                        <div v-if="!item.deleted_at" class="action-wrapper">
                            <vs-button
                                v-if="isEditable(item)"
                                :id="'btn-update-' + i"
                                v-tooltip="'Modifier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="updateResource(item, userType)"
                            />

                            <vs-button
                                v-if="isDeletable(item)"
                                :id="'btn-delete-' + i"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click="deleteResource(item)"
                            />
                        </div>
                        <div v-else class="action-wrapper">
                            <vs-button
                                v-if="isDeletable(item)"
                                :id="'btn-update-' + i"
                                v-tooltip="'Restaurer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-rotate-ccw"
                                @click="restoreResource(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination
                    :total="Math.ceil(items.total / apiParams.perpage)"
                    v-model="currentPage"
                >
                </vs-pagination>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import UserForm from "@components/views/rh/referentiel/user/UserForm";

export default {
    name: "UserTab",
    components: {
        UserForm
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        },
        userType: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            currentPage: 1,
            loading: false,
            showPopup: false,
            showDeletedPopup: false,
            resourceReadonly: true,
            resourceType: "Utilisateur",
            resource: {},
            formError: true,
            formType: "show",
            prompt: {
                show: {
                    title: "Consulter un ",
                    btnAccept: "false"
                },
                create: {
                    title: "Ajouter un ",
                    btnAccept: "filled"
                },
                update: {
                    title: "Modifier un ",
                    btnAccept: "filled"
                }
            },
            deletedUsers: [],
            emailToUndelete: null
        };
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },

    computed: {
        getDataPrompt() {
            switch (this.formType) {
                case "show":
                    this.prompt.show.title = "Consulter un " + this.resourceType;
                    return this.prompt.show;
                case "create":
                    this.prompt.create.title = "Ajouter un " + this.resourceType;
                    return this.prompt.create;
                case "update":
                    this.prompt.update.title = "Modifier un " + this.resourceType;
                    return this.prompt.update;
                default:
                    return this.prompt.show;
            }
        },
    },

    methods: {
        showResource(resource) {
            this.resourceReadonly = true;
            this.resetResource();
            this.setResource(resource);
            this.formType = "show";
            this.showPopup = true;
        },

        createResource(userType) {
            this.resourceReadonly = false;
            this.resourceType = userType;
            this.resetResource();
            this.formType = "create";
            this.showPopup = true;
        },

        updateResource(resource, userType) {
            this.resourceReadonly = false;
            this.resourceType = userType;
            this.resetResource();
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true;
        },

        storeResource() {
            let data = JSON.parse(JSON.stringify(this.resource));

            data.role = data.roles[0].id;

            data.establishments = data.establishments.map(e => e.id);
            data.services = data.services.map(s => s.id);

            if (data.evaluatorScope) {
                data.evaluatorScope = data.evaluatorScope.employees;

                if (!this.isAnEvaluator(this.resource)) {
                    data.authorizations.employees.read = data.authorizations.employees.read.map(s => s.id)
                    data.authorizations.employees.update = data.authorizations.employees.update.map(s => s.id)
                    data.authorizations.jobs.read = data.authorizations.jobs.read.map(s => s.id)
                    data.authorizations.jobs.update = data.authorizations.jobs.update.map(s => s.id)
                    data.authorizations.validation.update = data.authorizations.validation.update.map(s => s.id)
                    data.authorizations.skill_review_validation.update = data.authorizations.skill_review_validation.update.map(s => s.id)
                }
            }

            if (data.id) {
                this.apiUpdate(
                    data,
                    this.resource.id,
                    resp => this.loadItems(),
                    err => {
                        this.showPopup = true
                        if (err.status === 419) {
                            err.code = 419
                            err.message = err.data.message
                        }
                        this.notifyError(err, 'Erreur !', true)
                    },
                    null,
                    true
                );
            } else {
                this.apiCreate(
                    data,
                    resp => this.loadItems(),
                    err => {
                        this.showPopup = true
                        if (err.status === 419) {
                            err.code = 419
                            err.message = err.data.message
                        }
                        this.notifyError(err, 'Erreur !', true)
                    },
                    null,
                    true
                );
            }
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => {
                this.loadItems()
                this.loadDeletedUsers()
            });
        },

        restoreResource(resource) {
            this.apiRestore(resource.id, () => {
                this.loadItems()
            });
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                last_name: null,
                first_name: null,
                email: null,
                roles: [],
                establishments: [],
                services: [],
                sendEmail: false
            };
        },

        setFormError(value) {
            this.formError = value;
        },

        isEditable(item) {
            //on peut modifier son propre profil même sans avoir les droits de modification (et du coup seuls certains champs seront visibles)
            return (item.id === this.user.id) || this.can('update', 'users')
        },

        isDeletable(item) {
            return this.can("delete", "users");
        },

        sendInvitation (user) {
            window.axios.post(this.api + '/' + user.id + '/send-invitation', user)
                .then((res) => {
                    this.notifySuccess(`L'invitation a été envoyée à ${user.email}`)
                })
                .catch(err => {
                    this.notifyError(err, 'Erreur !', true)
                })
        },

        refreshToken (user) {
            window.axios.post(this.api + '/' + user.id + '/refresh-token')
                .then((res) => {
                    let index = this.items.data.map(u => u.id).indexOf(res.data.user_id)
                    this.$set(this.items.data[index], 'fresh_token', res.data.fresh_token)
                    this.$set(this.items.data[index], 'resetUrl', res.data.resetUrl)
                    this.notifySuccess(`Token rafraîchi pour l\'utilisateur ${user.email}`)
                })
                .catch(err => {
                    this.notifyError(err, 'Erreur !', true)
                })
        },

        copyReinitLink (user) {
            this.copyToClipboard('resetUrl-hidden-' + user.id)
        },
        async copyToClipboard(nodeId){
            let copyText = document.getElementById(nodeId)
            await this.writeText(copyText.value)
            await this.readText()
            this.notifySuccess(`Lien URL copié avec succès !`)
        },
        async writeText(text) {
            navigator.clipboard.writeText(text)
                .then(() => {})
                .catch(err => {
                    console.log('Something went wrong when try to write Text to Clipboard', err);
                });
        },
        async readText() {
            navigator.clipboard.readText()
                .then(text => {})
                .catch(err => {
                    // maybe user didn't grant access to read from clipboard
                    console.log('Something went wrong when try to read Text from Clipboard', err);
                });
        },

        async loadDeletedUsers() {
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/users",
                    params: {
                        perpage: 999,
                        include: [
                            'roles.permissions',
                            'permissions',
                            'establishments.services',
                            'services',
                            'viewableEmployees',
                            'updatableEmployees'
                        ],
                        onlyTrash: 'true',
                        filter: 'deleted_at|!=|null'
                    }
                })
                .then(response => {
                    this.deletedUsers = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        emailInDeleted(email) {
            this.showDeletedPopup = true
            this.emailToUndelete = email
        },
        undeleteAndFillForm() {
            let user_id = this.deletedUsers.find(user => user.email === this.emailToUndelete)?.id
            window.axios({
                method: 'patch',
                url: `/api/referentiel/users/${user_id}/restore`,
                params: {
                    with: [
                        'roles.permissions',
                        'permissions',
                        'establishments.services',
                        'services',
                        'viewableEmployees',
                        'updatableEmployees'
                    ]
                }
            })
            .then(response => {
                this.setResource(response.data.user)
                this.showDeletedPopup = false
                this.emailToUndelete = null
            })
            .catch(err => {
                this.notifyError(err, 'Erreur !', true)
            })
        },
    },
    created() {

        this.apiParams.perpage = 10;
        this.apiParams.withTrash = 'false';
        this.apiParams.include = [
            'roles.permissions',
            'permissions',
            'establishments.services',
            'services',
            'viewableEmployees',
            'updatableEmployees'
        ];
        if (this.userType === 'Utilisateur') {
            this.apiParams.filter = 'roles.name|!=|evaluator';
        } else {
            this.apiParams.filter = 'roles.name|=|evaluator';
        }
        this.resetResource();
        this.loadItems();
        this.loadDeletedUsers();
    }
};
</script>

<style lang="scss" scoped>
.text-center .vs-table-text {
    display: block;
    text-align: center;
}
.deleted td:not(.action) {
    opacity: 0.3;
}
</style>
