<template>
    <div>
        <JobsOccupationsResume
            :jobs="value.jobs"
            :occupations="value.occupations"
            :displayWishes="false"
        />

        <vs-row v-if="resource.occupations_skills" class="checkboxes-container mt-10">
            <vs-col vs-type="flex" vs-align="center" class="sm:w-4/5 w-full sm:w-">
                <div class="text-white">_______________________________--</div>
                <template v-if="typeForm !== 'consultation' && typeForm !== 'validation'">
                    <label class="ml-20">Préremplir avec :</label>
                    <vs-radio
                        v-model="resource.self_occupation_skills_source"
                        vs-name="radios1"
                        id="radio-last-auto-eval"
                        class="ml-2"
                        :vs-value="selfRatingSourceValues.LAST_SELF"
                        @change="setDefaultHandle(selfRatingSourceValues.LAST_SELF)"
                    >
                        Dernière auto-évaluation
                    </vs-radio>
                    <vs-radio
                        v-model="resource.self_occupation_skills_source"
                        vs-name="radios1"
                        id="radio-declaration-competence"
                        class="ml-2"
                        :vs-value="selfRatingSourceValues.MOBILITY"
                        :disabled="!mobilityRating"
                        @change="setDefaultHandle(selfRatingSourceValues.MOBILITY)"
                    >
                        Auto-évaluation Portail
                        {{ !!mobilityRating ? `du ${this.$moment(mobilityRating.rating_date).format("l")}` : "" }}
                    </vs-radio>
                </template>
                <template v-if="typeForm !== 'creation' && typeForm !== 'poursuite'">
                    <label class="ml-20">Affichage :</label>
                    <vs-radio
                        v-model="displayMode"
                        vs-name="radios-occSkill-display"
                        id="radio-occSkill-display-table"
                        class="ml-2"
                        vs-value="table"
                    >
                        Tableau
                    </vs-radio>
                    <vs-radio
                        v-model="displayMode"
                        vs-name="radios-occSkill-display"
                        id="radio-occSkill-display-graphic"
                        class="ml-2"
                        vs-value="graphic"
                    >
                        Graphique
                    </vs-radio>
                </template>
            </vs-col>
            <vs-col v-if="typeForm !== 'consultation' && typeForm !== 'validation'"
                    vs-type="flex"
                    vs-align="center"
                    class="sm:w-4/5 w-full sm:w-"
            >
                <div class="text-white">_______________________________--</div>
                <label class="ml-20">
                    En cas de nouvelle auto-évaluation Portail transmise depuis la date de création,
                    veuillez sélectionner l’auto-évaluation Portail
                </label>
            </vs-col>
        </vs-row>
        <div v-else-if="!loading" class="not-data-table vs-table--not-data">Il n'y a aucun savoir-faire à évaluer</div>

        <Rating-Manager
            v-if="resource.occupations_skills || loading"
            name="self-occupation-skills"
            :type-form="typeForm"
            v-model="resource.self_occupation_skills"
            :show-deleted="showDeletedOccSkills"
            :col1="{label: 'Savoir-faire', value: 'title', title: 'description'}"
            :col2="{label: 'Poste / Métier', value: 'job_name', title: 'type'}"
            :col3="{label: 'Service / Filière', value: 'service_name'}"
            :loading="loading"
            :skill-review="skillReview"
        >
            <!-- <vs-checkbox v-model="showDeletedOccSkills">
                Affichage des savoir-faire supprimés
            </vs-checkbox> -->
        </Rating-Manager>
    </div>
</template>

<script>
import JobsOccupationsResume from "../JobsOccupationsResume";
import RatingManager from '../RatingManager';
import ratingService from "@components/services/ratings";

export default {
    components: {
        JobsOccupationsResume, RatingManager
    },
    props: {
        typeForm: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        skillReview: {
            type: Boolean,
            required: true
        },
        selfRatingSourceValues: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            resource: {
                self_occupation_skills: [],
                self_occupation_skills_source: null,
            },
            showDeletedOccSkills: false,
            iconInfo: '<i class="vs-icon notranslate icon-scale feather icon-info small bg-small vs-icon-bg round" style="background: rgb(217, 217, 217); max-width: 50px;"></i>',
            loading: true,
            ratings: [],
            mobilityRating: null,
        };
    },
    created() {
        this.loading = true;
        this.initResource();
        this.resource.self_occupation_skills_source = this.value.personals.length ? this.selfRatingSourceValues.MOBILITY : this.selfRatingSourceValues.LAST_SELF;
        this.getLastMobilitySelfRating();
        this.loadDefaultRatings(this.value.personals.length ? true : false);
    },
    computed: {},
    watch: {
        resource: {
            handler: function(val) {

                let data = window._.cloneDeep(this.value);
                data.self_occupation_skills = val.self_occupation_skills
                this.$emit('input', data);
            },
            deep: true
        }
    },
    methods: {
        initResource() {
            this.resource = window._.cloneDeep(this.value);
        },

        formatDefaultRatings(ratings) {
            let formatedRatings = [];

            if (ratings.length > 0) {
                ratings.forEach((item) => {
                    item.job_name += ' ' + this.iconInfo;
                    formatedRatings.push(item)
                })
            }

            return formatedRatings;
        },

        loadDefaultRatings(setDefault) {
            let start = Date.now();
            var employee_rating = window._.cloneDeep(this.value);
            const occupations = employee_rating.occupations;

            employee_rating.self_occupation_skills = this.resource.self_occupation_skills;
            employee_rating.admin_occupations  = occupations.filter(o => o.admin_id != null).map(o => o.admin_id);
            employee_rating.occupations        = occupations.filter(o => o.admin_id == null).map(o => o.id);
            employee_rating.jobs               = employee_rating.jobs.map(o => o.id);
            employee_rating.externals          = employee_rating.externals?.map(o => o.id) || [];
            employee_rating.personals          = employee_rating.personals?.map(o => o.id) || [];
            employee_rating.skill_review       = this.skillReview;
            employee_rating.self_rating_source = this.resource.self_occupation_skills_source;
            employee_rating.self_rating_set_default = setDefault;

            window
                .axios({
                    method: "post",
                    url: `/api/gestion/employees/${this.value.employee.id}/ratings/default-self-occupation-skills`,
                    params: {},
                    data: employee_rating,
                })
                .then(response => {
                    this.resource.self_occupation_skills = this.formatDefaultRatings(response.data);
                    this.loading = false;
                    console.info('=> Chargement des auto-évaluations de savoir-faire depuis API : +' + (Date.now() - start) + 'ms');
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    this.loading = false;
                });
        },

        /**
         * Récupération de la dernière auto-évaluation transmise du portail
         */
        getLastMobilitySelfRating() {
            ratingService.getRatingsByStatus({
                employee_id: this.value.employee.id,
                statuses: [2],
                limit: 1,
                orderBy: "rating_date",
                direction: "desc",
            })
                .then(response => {

                    if (response.data && response.data.length > 0) {
                        this.mobilityRating = response.data[0];

                    }
                })
        },

        setDefaultHandle(selfOccupationSkillsSource) {

            if (selfOccupationSkillsSource == this.selfRatingSourceValues.MOBILITY && !this.mobilityRating) {
                return;
            }

            // On force le rechargement
            this.resource.self_skills = this.resource.self_skills.map(s => {
                s.level = 1;
                return s;
            });

            this.loadDefaultRatings(true);
        },
    }
};
</script>
