<template>
    <div>
        <vs-row>
            <vs-col vs-align="left" vs-w="6">
                <vs-button
                    v-if="can('create', 'occupations')"
                    id="btn-create"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="createResource"
                >
                    Ajouter un nouveau métier
                </vs-button>

                <DialogPrompt
                    :active.sync="showPopup"
                    :title="getDataPrompt.title"
                    cancel-text="Fermer"
                    accept-text="Enregistrer"
                    :button-accept="getDataPrompt.btnAccept"
                    :is-valid="!formError"
                    @cancel="resetResource(); resetSearchParams()"
                    @accept="storeResource"
                    @close="resetResource(); resetSearchParams()"
                >
                    <OccupationForm
                        v-model="resource"
                        :type="formType"
                        @error="setFormError"
                    />
                </DialogPrompt>

                <DialogPrompt
                    :active.sync="showPopupOccupationSkill"
                    title="Consulter un savoir faire"
                    cancel-text="Fermer"
                    accept-text="Enregistrer"
                    button-accept="false"
                    @cancel="resetResource"
                    @close="resetResource"
                >
                    <OccupationSkillForm
                        v-model="resourceOccupationSkill"
                        type="show"
                    />
                </DialogPrompt>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="items.data"
        >
            <template slot="thead">
                <vs-th>
                    <Sort name="title" @sort="eventSortByCol"></Sort>Métier
                </vs-th>
                <vs-th>
                    <Sort name="sector,title" @sort="eventSortByCol"></Sort>Filière
                </vs-th>
                <vs-th>Type</vs-th>
                <vs-th><Sort name="updated_at" @sort="eventSortByCol" :value="apiParams.sortBy.updated_at">Modifié le</Sort></vs-th>
                <vs-th>Savoir-faire</vs-th>
                <vs-th width="160"></vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr v-for="(item, i) in data" :key="i" :data="item">
                    <vs-td
                        v-tooltip="{
                            content: item.description,
                            placement: 'top-start',
                            classes: ['job-description']
                        }"
                    >
                        <div style="text-wrap: nowrap; display: flex">
                            <div class="float-left mr-5" style="line-height: 25px;">{{ get(item, ":title") }}</div>
                            <vs-chip v-if="item.is_updated" color="primary" class="bg-chip-transparent">
                                Mis à jour
                            </vs-chip>
                            <vs-chip v-if="item.is_new" color="warning" class="bg-chip-transparent">
                                Nouveau
                            </vs-chip>
                        </div>
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":sector.title") }}
                    </vs-td>
                    <vs-td>
                        {{ get(item, ":type") }}
                    </vs-td>
                    <vs-td>
                        {{ localeDateTime(item.updated_at) }}
                    </vs-td>
                    <vs-td>

                        <vs-chip v-for="(aos, index) in get(item, ':admin_occupation_skills')" :key="index" color="secondary">
                            <span name="zone" style="cursor: pointer !important; min-height: 26px;min-width: 26px; padding-top: 6px;" @click="showAdminOccupationSkill(aos)" v-tooltip="tooltipContent(aos)">
                                {{ aos.title }}
                            </span>
                        </vs-chip>

                        <vs-chip v-for="(os, index) in get(item, ':occupation_skills')" :key="index" color="primary">
                            <span name="zone" style="cursor: pointer !important; min-height: 26px;min-width: 26px; padding-top: 6px;" @click="showOccupationSkill(os)" v-tooltip="tooltipContent(os)">
                                {{ os.title }}
                            </span>
                        </vs-chip>
                    </vs-td>
                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="can('read', 'occupations', item.id)"
                                :id="'showSkill_' + i"
                                v-tooltip="'Consulter'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-list"
                                @click="showResource(item)"
                            />

                            <vs-button
                                v-if="isEditable(item)"
                                :id="'btn-update-' + i"
                                v-tooltip="'Modifier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="updateResource(item)"
                            />

                            <vs-button
                                v-if="isDeletable(item)"
                                :id="'btn-delete-' + i"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click="deleteResource(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination
                    :total="Math.ceil(items.total / apiParams.perpage)"
                    v-model="currentPage"
                >
                </vs-pagination>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import OccupationForm from "@components/views/rh/referentiel/occupation/OccupationForm";
import OccupationSkillForm from "@components/views/rh/referentiel/occupation-skill/OccupationSkillForm";
import { localeDateTime } from "@assets/utils/helpers/helpers.js";

export default {
    name: "Occupation",
    components: {
        OccupationForm, OccupationSkillForm
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        },
        freshImport: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            localeDateTime,
            log: console.log,
            currentPage: 1,
            loading: false,
            showPopup: false,
            showPopupOccupationSkill: false,
            resourceReadonly: true,
            resource: {},
            resourceOccupationSkill: {},
            formError: true,
            formType: 'show',
            prompt: {
                show: {
                    title: 'Consulter un métier',
                    btnAccept: 'false'
                },
                create: {
                    title: 'Ajouter un métier',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier un métier',
                    btnAccept: 'filled'
                }
            }
        };
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        },
        freshImport(value) {
            if (value) {
                this.loadItems()
                this.$emit('data-refreshed')
            }
        }
    },

    computed: {
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                case 'create':
                    return this.prompt.create
                case 'update':
                    return this.prompt.update
                default:
                    return this.prompt.show
            }
        },
        // occupationTypes() {
        //     let types = [];
        //     this.items.data.forEach(item => {
        //         if (item.admin_id == null) {
        //             types.push(item.type);
        //         }
        //     })

        //     return types;
        // }
    },

    methods: {
        showResource(resource) {
            this.resourceReadonly = true;
            this.resetResource();
            this.setResource(resource);
            this.formType = 'show';
            this.showPopup = true;
        },

        createResource() {
            this.resourceReadonly = false;
            this.resetResource();
            this.formType = 'create';
            this.showPopup = true;
        },

        updateResource(resource) {
            this.resourceReadonly = false;
            this.resetResource();
            this.setResource(resource);
            this.formType = 'update';
            this.showPopup = true;
        },

        storeResource() {
            let data = JSON.parse(JSON.stringify(this.resource));
            data.sector_id = data.sector.id;

            if (data.id) {
                this.apiUpdate(
                    data,
                    this.resource.id,
                    resp => this.loadItems(),
                    err => (this.showPopup = true)
                );
            } else {
                this.apiCreate(
                    data,
                    resp => this.loadItems(),
                    err => (this.showPopup = true)
                );
            }
        },

        duplicateResource(resource) {
            this.apiDuplicate(resource.id, () => this.loadItems());
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                title: null,
                type: null,
                description: null,
                sector: {},
                occupation_skills: []
            };
        },

        resetSearchParams() {
            const searchParams = new URLSearchParams(window.location.search);

            if (searchParams.has('create') || searchParams.has('os')) {
                searchParams.delete('create');
                searchParams.delete('os');
                window.location.search = '?' + searchParams.toString();
            }
        },

        setFormError(value) {
            this.formError = value;
        },

        showAdminOccupationSkill(item) {
            this.resourceOccupationSkill = JSON.parse(JSON.stringify(item));
            this.resourceOccupationSkill.skills = this.resourceOccupationSkill.adminskills

            this.showPopupOccupationSkill = true;
        },

        showOccupationSkill(item) {
            this.resourceOccupationSkill = JSON.parse(JSON.stringify(item));
            this.showPopupOccupationSkill = true;
        },

        tooltipContent(item) {
            return {
                content: item.description,
                placement: 'top-start',
                classes: ['job-description']
            }
        },

        isEditable (occupationSkill) {
            if (occupationSkill.admin_id) return false
            return this.can('update', 'occupations')
        },

        isDeletable (occupationSkill) {
            //if (occupationSkill.admin_id) return false
            return this.can('delete', 'occupations')
        },
    },
    created() {
        this.apiParams.include = [
            "sector",
            "occupation_skills.jobs.service.establishment",
            "occupation_skills.sector",
            "occupation_skills.skills",
            'admin_occupation_skills.adminskills',
            'admin_occupation_skills.sector'
        ];
        this.apiParams.append = [
            'is_updated', 'is_new'
        ];
        this.apiParams.sortBy = {updated_at: "desc"};
        this.resetResource();
        this.loadItems();

        // Ouvrir le formulaire de création au chargement de la page si argument create
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('create')) {
            this.createResource();
        }
    }
};
</script>
