<template>
    <section class="experience">
        <h3 class="mb-8">RAE / VAE :</h3>

        <div>
            <vs-button
                v-if="canUpdate()"
                id="addRAE"
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
                @click="createResource()"
                style="margin-bottom: 2em;"
            >
                Ajouter une nouvelle RAE / VAE
            </vs-button>
        </div>

        <DialogPrompt
            :active.sync="showPopup"
            :title="getDataPrompt.title"
            cancel-text="Annuler"
            accept-text="Enregistrer"
            :button-accept="getDataPrompt.btnAccept"
            :is-valid="!hasError"
            @cancel="resetResource"
            @close="resetResource"
            @accept="storeResource"
        >
            <div class="con-exemple-prompt">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Type* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            v-model="resource.experience_type"
                            name="raeTypes"
                            :options="trainingTypes"
                            :multiple="false"
                            :group-select="false"
                            placeholder="Recherche ..."
                            track-by="id"
                            label="name"
                            select-label
                            selected-label
                            deselect-label
                            :loading="trainingTypesLoading"
                        >
                            <span slot="noResult">Aucun résultat.</span>
                        </multiselect>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Libellé* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.name"
                            name="raeName"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Organisme* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.establishment_name"
                            name="raeEstablishment"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Diplômant :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full mb-2">
                        <vs-checkbox
                            class="w-full"
                            v-model="resource.is_graduate"
                            name="isGraduate"
                        ></vs-checkbox>
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Date* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            type="date"
                            v-model="resource.start_date"
                            name="raeEndDate"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Commentaire :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            v-model="resource.comment"
                            name="raeComment"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span style="float: left;line-height: 26px;">
                            Pièce jointe &nbsp;
                        </span>
                        <vs-chip v-if="!documentLicence" color="secondary">
                            <b>Gratuit jusqu'à {{ postMaxSizePerPerson }} Mo (PDF uniquement)</b>
                        </vs-chip>
                    </div>
                    <div v-if="resource.doc_path" class="vx-col sm:w-2/3 w-full">
                        <div
                            class="inline mr-1 float-left cursor-pointer"
                        >
                            <a @click.prevent="download(resource.doc_path)">{{ resource.doc_path.split('/').pop() }}</a>
                        </div>
                        <vs-button
                            :id="'deleteDoc_' + resource.id"
                            color="primary"
                            type="border"
                            size="small"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="removeDocPath(resource)"
                            style="display: inline; margin-top: -0.5rem;"
                        />
                    </div>
                    <div v-else class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            type="file"
                            :accept="acceptExtensions(documentLicence ? 'all' : 'pdf')"
                            name="doc_path"
                            class="w-full"
                            @change="selectFile"
                            :danger="this.fileError.message"
                            :danger-text="this.fileError.message"
                        />
                    </div>
                </div>
            </div>
        </DialogPrompt>

        <DialogPrompt
            :active.sync="showPopupFullStorage"
            title="Votre espace de stockage est plein"
            cancel-text="Annuler"
            accept-text="OK"
            @close="showPopupFullStorage = false"
            @accept="showPopupFullStorage = false"
        >
            <div class="con-exemple-prompt">
                <p>{{ popupFullStorageMessage }}</p>
            </div>
        </DialogPrompt>

        <vs-table
            :sst="true"
            :data="items.data"
            no-data-text="Aucune donnée à afficher"
            @sort="eventSortByCol"
            style="width:100%;"
        >
            <template slot="thead">
                <vs-th>
                    <Sort name="experience_type.name" @sort="eventSortByCol">Type</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="name" @sort="eventSortByCol">Libellé</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="establishment_name" @sort="eventSortByCol">Organisme</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="end_date" @sort="eventSortByCol">Date</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="comment" @sort="eventSortByCol">Commentaire</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="is_graduate" @sort="eventSortByCol">Diplômant</Sort>
                </vs-th>
                <vs-th>
                    Document
                </vs-th>
                <vs-th v-if="canUpdate()">
                    Actions
                </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr
                    v-for="(item, indextr) in data"
                    :key="indextr"
                    :data="item"
                >
                    <vs-td :data="item.type">
                        {{ item.experience_type.name }}
                    </vs-td>
                    <vs-td :data="item.name">
                        {{ item.name }}
                    </vs-td>
                    <vs-td :data="item.establishment_name">
                        {{ item.establishment_name }}
                    </vs-td>
                    <vs-td :data="item.start_date">
                        {{ item.start_date | date }}
                    </vs-td>
                    <vs-td :data="item.comment">
                        {{ item.comment }}
                    </vs-td>
                    <vs-td :data="item.is_graduate">
                        <feather-icon
                            v-if="item.is_graduate"
                            icon="CheckSquareIcon"
                            svg-classes="h-6 w-6 icon-info vs-icon-primary"
                            class="ml-2"
                            v-tooltip="'Formation diplômante'"
                        />
                        <feather-icon
                            v-else
                            icon="SquareIcon"
                            svg-classes="h-6 w-6 icon-info"
                            class="ml-2"
                        />
                    </vs-td>
                    <vs-td>
                        <i
                            v-if="item.doc_path"
                            :class="'fa fa-file'+(item.doc_path.split('.').pop() === 'pdf' ? '-pdf' : '-o')"
                            style="font-size: 2rem; color: rgba(var(--vs-primary),1)"
                            @click="download(item.doc_path)"
                        ></i>
                    </vs-td>
                    <vs-td v-if="canUpdate()">
                        <vs-button
                            v-if="can('update', 'employees')"
                            :id="'modifyRAE_' + indextr"
                            v-tooltip="'Modifier'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-edit"
                            @click="updateResource(item)"
                        />
                        <vs-button
                            v-if="can('update', 'employees')"
                            :id="'deleteRAE_' + indextr"
                            v-tooltip="'Supprimer'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            style="margin-left: 1rem;"
                            @click="deleteResource(item)"
                        />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </section>
</template>

<script>
import Multiselect from "vue-multiselect";
//import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import { format } from 'date-fns'
export default {
    name: "EmployeeRAE",
    components: {
        Multiselect,
        //Datepicker
    },
    props: {
        api: {
            type: String
        },
        employee: {
            required: false,
            type: Object
        },
        documentLicence: {
            required: false,
            type: Boolean
        }
    },
    data() {
        return {
            fr: fr,
            resource: {},
            showPopup: false,
            showPopupFullStorage: false,
            popupFullStorageMessage: null,
            loading: true,
            formError: true,
            formType: "create",
            trainingTypes: [],
            trainingTypesLoading: true,
            prompt: {
                show: {
                    title: "Consulter une RAE/VAE",
                    btnAccept: "false"
                },
                create: {
                    title: "Ajouter une RAE/VAE",
                    btnAccept: "filled"
                },
                update: {
                    title: "Modifier une RAE/VAE",
                    btnAccept: "filled"
                }
            },
            file: null,
            fileError: {
                message: null,
            },
            postMaxSizePerPerson: process.env.MIX_TOTAL_SPACE_WITHOUT_LICENCE,
            maxFileSize: this.documentLicence ? process.env.MIX_PHP_MAX_FILESIZE : process.env.MIX_CAREER_FILE_MAX_FILESIZE_WITHOUT_LICENCE
        };
    },
    watch: {},
    computed: {
        hasError() {
            let error =
                !this.resource.experience_type ||
                !this.resource.name ||
                !this.resource.start_date ||
                !this.resource.establishment_name;
            return error;
        }
    },
    methods: {
        selectFile(e) {
            this.fileError.message = null
            if (e.target.files.length !== 0) {
                if (e.target.files[0].size >= this.maxFileSize*1024*1024) {
                    this.fileError.message = 'La taille du fichier de '+this.getReadableFileSizeString(e.target.files[0].size)+' est supérieure à la valeur autorisée de '+this.maxFileSize+' Mo'
                }
                else this.file = e.target.files[0]
            }
        },
        removeDocPath(resource) {
            let jobClass = 'Experience'
            window
                .axios({
                    method: "post",
                    url: "/api/gestion/employees/career/doc/delete",
                    params: { path: resource.doc_path, class: jobClass },
                })
                .then(response => {
                    this.loadItems()
                    this.$emit('change')
                    this.resource.doc_path = null
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`, true);
                });
        },
        download(path) {
            window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees/career/doc",
                    params: { path: path },
                    responseType: 'blob', // Indique que la réponse sera un fichier
                })
                .then(response => {
                    console.log('Download DONE !!')
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', path.split('/').pop()); // Spécifie le nom du fichier à télécharger
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(async (err) => {
                    let errorResponse = await err.response.data.text();
                    const errorObj = JSON.parse(errorResponse);
                    err.response.status = err.code
                    err.message = errorObj.message
                    this.notifyError(err, `Une erreur est survenue`, true);
                });
        },
        loadTrainingTypes() {
            window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees/experience-types",
                    params: { perpage: 100, filter: "category|=|2" }
                })
                .then(response => {
                    this.trainingTypes = response.data.data;
                    this.trainingTypesLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        canUpdate() {
            return this.canMulti("update", "employees", this.employee.services.map(s => s.id));
        },

        createResource() {
            this.resourceReadonly = false;
            this.resetResource();
            this.formType = "create";
            this.showPopup = true;
        },

        updateResource(resource) {
            this.resourceReadonly = false;
            this.resetResource();
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true;
        },

        storeResource() {
            let data = JSON.parse(JSON.stringify(this.resource));
            data.experience_type_id = this.resource.experience_type.id;
            data.end_date = data.start_date;
            data.file = this.file

            this.localLoading()
            if (data.id) {
                this.apiUpdate(
                    data,
                    this.resource.id,
                    resp => {
                        if (!resp.data.availableSizeValidation) {
                            this.showPopupFullStorage = true
                            this.popupFullStorageMessage = resp.data.availableSizeValidationMessages
                        }
                        this.loadItems(),
                        this.$emit('change')
                        this.endLocalLoading()
                    },
                    (err) => {
                        this.showPopup = true
                        this.endLocalLoading()
                    },
                    {'Content-Type': 'multipart/form-data'}
                );
            } else {
                this.apiCreate(
                    data,
                    resp => {
                        if (!resp.data.availableSizeValidation) {
                            this.showPopupFullStorage = true
                            this.popupFullStorageMessage = resp.data.availableSizeValidationMessages
                        }
                        this.loadItems(),
                        this.$emit('change')
                        this.endLocalLoading()
                    },
                    (err) => {
                        this.showPopup = true
                        this.endLocalLoading()
                    },
                    {'Content-Type': 'multipart/form-data'}
                );
            }
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => {
                this.loadItems(),
                this.$emit('change')
            });
        },

        setResource(data) {
            let dataTemp = JSON.parse(JSON.stringify(data));
            dataTemp.start_date = this.getDate(dataTemp.start_date);
            dataTemp.end_date = dataTemp.end_date ? this.getDate(dataTemp.end_date) : dataTemp.end_date;
            this.resource = dataTemp;
        },

        resetResource() {
            this.resource = {
                experienceType: null,
                name: null,
                is_graduate: null,
                start_date: format(new Date(), "yyyy-MM-dd"),
                establishment: null,
                comment: null,
                doc_path: null,
            };
        }
    },
    created() {
        this.apiParams = {
            sortBy: {"end_date": "asc"},
            perpage: 100,
            filter: "experienceType.category|=|2",
            include: []
        };
        this.resetResource();
        this.loadItems();
        this.loadTrainingTypes();
    }
};
</script>

<style lang="scss" scoped></style>
