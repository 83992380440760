var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        { attrs: { "vs-justify": "space-between" } },
        [
          _c("vs-col", { staticClass: "mb-10 flex" }, [
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "float-right",
                    attrs: {
                      id: "goBack",
                      color: "primary",
                      type: "border",
                      "icon-pack": "feather",
                      icon: "icon-arrow-left",
                      href: _vm.backUrl
                    }
                  },
                  [_vm._v("\n                Retour\n            ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.typeForm !== "validation" && _vm.typeForm !== "consultation"
                  ? _c(
                      "vs-button",
                      {
                        attrs: {
                          id: "saveQuit",
                          color: "primary",
                          type: "filled",
                          "icon-pack": "feather",
                          icon: "icon-save"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.saveProjectClickHandle.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [_vm._v("\n                Sauvegarder\n            ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.employee
        ? _c(
            "div",
            [
              _c(
                "form-wizard",
                {
                  ref: "formWizard",
                  attrs: {
                    color: "rgba(var(--vs-primary), 1)",
                    "error-color": "rgba(var(--vs-danger), 1)",
                    "start-index": _vm.currentStep,
                    title: _vm.title,
                    subtitle: null
                  },
                  on: {
                    "on-complete": _vm.endFormHandle,
                    "on-validate": _vm.validateFormHandle,
                    "on-error": _vm.errorFormHandle,
                    "on-change": _vm.changePageHandle
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "footer",
                        fn: function(props) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "wizard-footer-left",
                                attrs: { onclick: "window.scrollTo(0,0)" }
                              },
                              [
                                props.activeTabIndex > 0
                                  ? _c(
                                      "vs-button",
                                      {
                                        attrs: {
                                          id: "prev",
                                          color: "primary",
                                          type: "filled",
                                          "icon-pack": "feather",
                                          icon: "icon-chevron-left"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return props.prevTab()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Précédent\n                    "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "wizard-footer-right",
                                attrs: { onclick: "window.scrollTo(0,0)" }
                              },
                              [
                                !props.isLastStep
                                  ? _c(
                                      "vs-button",
                                      {
                                        staticClass: "wizard-footer-right",
                                        attrs: {
                                          id: "next",
                                          color: "primary",
                                          type: "filled",
                                          "icon-pack": "feather",
                                          icon: "icon-chevron-right"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return props.nextTab()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Suivant\n                    "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "vs-button",
                                      {
                                        staticClass:
                                          "wizard-footer-right finish-button",
                                        attrs: {
                                          id: "submit",
                                          color: "primary",
                                          type: "filled",
                                          "icon-pack": "feather",
                                          icon: "icon-check"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return props.nextTab()
                                          }
                                        }
                                      },
                                      [
                                        _vm.typeForm === "consultation"
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                            Retour\n                        "
                                              )
                                            ])
                                          : _vm.skillReview
                                          ? _c(
                                              "span",
                                              [
                                                _vm.canValidateSkillReview
                                                  ? [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n                                    Valider le " +
                                                            _vm._s(
                                                              _vm.skillRatingNames.singular.toLowerCase()
                                                            ) +
                                                            "\n                                "
                                                        )
                                                      ])
                                                    ]
                                                  : [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n                                    Soumettre pour validation\n                                "
                                                        )
                                                      ])
                                                    ]
                                              ],
                                              2
                                            )
                                          : _c(
                                              "span",
                                              [
                                                _vm.canValidateProject
                                                  ? [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n                                    Valider le " +
                                                            _vm._s(
                                                              _vm.projectNames.singular.toLowerCase()
                                                            ) +
                                                            "\n                                "
                                                        )
                                                      ])
                                                    ]
                                                  : [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n                                    Soumettre pour validation\n                                "
                                                        )
                                                      ])
                                                    ]
                                              ],
                                              2
                                            )
                                      ]
                                    )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3413951057
                  )
                },
                [
                  _vm.errorValidateMessage
                    ? _c(
                        "div",
                        { staticClass: "mb-10" },
                        [
                          _c(
                            "vs-alert",
                            { attrs: { color: "danger", active: "true" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.errorValidateMessage) +
                                  "\n                "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: "Périmètre",
                        icon: "feather icon-crosshair",
                        "before-change": _vm.validatePerimeter
                      }
                    },
                    [
                      _c(
                        "form",
                        {
                          attrs: {
                            "data-vv-scope":
                              "step-" + _vm.getStepFromName("perimeter")
                          }
                        },
                        [
                          _c("Perimeter-Tab", {
                            attrs: {
                              "type-form": _vm.typeForm,
                              "skill-review": _vm.skillReview,
                              jobs: _vm.orderedJobs,
                              occupations: _vm.orderedOccupations
                            },
                            on: {
                              "career-updated": function($event) {
                                _vm.careerUpdated = true
                              }
                            },
                            model: {
                              value: _vm.resource,
                              callback: function($$v) {
                                _vm.resource = $$v
                              },
                              expression: "resource"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: "Évaluation des savoir-faire",
                        icon: "feather icon-aperture",
                        "before-change": _vm.validateOccupationSkills
                      }
                    },
                    [
                      _c(
                        "form",
                        {
                          attrs: {
                            "data-vv-scope":
                              "step-" + _vm.getStepFromName("occupation-skills")
                          }
                        },
                        [
                          _vm.currentStep ==
                          _vm.getStepFromName("occupation-skills")
                            ? _c("Occupation-Skills-Rating-Tab", {
                                attrs: {
                                  "type-form": _vm.typeForm,
                                  "skill-review": _vm.skillReview
                                },
                                model: {
                                  value: _vm.resource,
                                  callback: function($$v) {
                                    _vm.resource = $$v
                                  },
                                  expression: "resource"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: "Évaluation des compétences",
                        icon: "feather icon-award",
                        "before-change": _vm.validateSkills
                      }
                    },
                    [
                      _c(
                        "form",
                        {
                          attrs: {
                            "data-vv-scope":
                              "step-" + _vm.getStepFromName("skills")
                          }
                        },
                        [
                          _vm.currentStep == _vm.getStepFromName("skills") &&
                          !_vm.occupationSkillOnly
                            ? _c("Skills-Rating-Tab", {
                                attrs: {
                                  "type-form": _vm.typeForm,
                                  "skill-review": _vm.skillReview,
                                  "admin-skills": _vm.adminSkills,
                                  colors: _vm.colors.data
                                },
                                model: {
                                  value: _vm.resource,
                                  callback: function($$v) {
                                    _vm.resource = $$v
                                  },
                                  expression: "resource"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.skillReview && _vm.occupationSkillsSeftRating
                    ? _c(
                        "tab-content",
                        {
                          staticClass: "mb-5",
                          attrs: {
                            title: "Auto-évaluation savoir-faire",
                            icon: "feather icon-check-square"
                          }
                        },
                        [
                          _c(
                            "form",
                            {
                              attrs: {
                                "data-vv-scope":
                                  "step-" +
                                  _vm.getStepFromName("self-occupation-skills")
                              }
                            },
                            [
                              _vm.currentStep ==
                              _vm.getStepFromName("self-occupation-skills")
                                ? _c("SelfOccupationSkillsRatingTab", {
                                    attrs: {
                                      "type-form": _vm.typeForm,
                                      "skill-review": _vm.skillReview,
                                      "self-rating-source-values":
                                        _vm.selfRatingSourceValues
                                    },
                                    model: {
                                      value: _vm.resource,
                                      callback: function($$v) {
                                        _vm.resource = $$v
                                      },
                                      expression: "resource"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.skillReview
                    ? _c(
                        "tab-content",
                        {
                          staticClass: "mb-5",
                          attrs: {
                            title: "Auto-évaluation compétence",
                            icon: "feather icon-user-check",
                            "before-change": _vm.validateSelfSkills
                          }
                        },
                        [
                          _c(
                            "form",
                            {
                              attrs: {
                                "data-vv-scope":
                                  "step-" + _vm.getStepFromName("self-skills")
                              }
                            },
                            [
                              !_vm.occupationSkillOnly &&
                              _vm.currentStep ==
                                _vm.getStepFromName("self-skills")
                                ? _c("Self-Rating-Tab", {
                                    attrs: {
                                      "type-form": _vm.typeForm,
                                      "skill-review": _vm.skillReview,
                                      "self-rating-source-values":
                                        _vm.selfRatingSourceValues,
                                      "admin-skills": _vm.adminSkills,
                                      colors: _vm.colors.data
                                    },
                                    model: {
                                      value: _vm.resource,
                                      callback: function($$v) {
                                        _vm.resource = $$v
                                      },
                                      expression: "resource"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: "Objectifs",
                        icon: "feather icon-briefcase",
                        "before-change": _vm.validateGoals
                      }
                    },
                    [
                      _c(
                        "form",
                        {
                          attrs: {
                            "data-vv-scope":
                              "step-" + _vm.getStepFromName("goals")
                          }
                        },
                        [
                          _vm.currentStep == _vm.getStepFromName("goals")
                            ? _c("Goals-Tab", {
                                attrs: {
                                  "type-form": _vm.typeForm,
                                  jobs: _vm.orderedJobs,
                                  occupations: _vm.orderedOccupations
                                },
                                model: {
                                  value: _vm.resource,
                                  callback: function($$v) {
                                    _vm.resource = $$v
                                  },
                                  expression: "resource"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: "Synthèse",
                        icon: "feather icon-align-justify",
                        "before-change": _vm.validateSynthesis
                      }
                    },
                    [
                      _c(
                        "form",
                        {
                          attrs: {
                            "data-vv-scope":
                              "" + _vm.getStepFromName("synthesis")
                          }
                        },
                        [
                          _vm.currentStep == _vm.getStepFromName("synthesis")
                            ? _c("Synthesis-Tab", {
                                attrs: { "type-form": _vm.typeForm },
                                model: {
                                  value: _vm.resource,
                                  callback: function($$v) {
                                    _vm.resource = $$v
                                  },
                                  expression: "resource"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }