<template>
    <div>

        <vs-row>
            <vs-col>
                  <DialogPrompt
                    :active.sync="showPopup"
                    title="Réactiver une personne"
                    cancel-text="Annuler"
                    accept-text="Enregistrer & activer"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @close="resetResource"
                    @accept="saveResource"
                >
                    <EmployeeForm
                        v-model="resource"
                        formType="create"
                        @error="setFormError"
                    />
                </DialogPrompt>

            </vs-col>
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="itemsData"
            >
            <template slot="thead">
                <vs-th><Sort v-model="apiParams.sortBy.id" name="id" @sort="eventSortByCol">ID</Sort></vs-th>
                <vs-th><Sort v-model="apiParams.sortBy.last_name" name="last_name" @sort="eventSortByCol">Nom</Sort></vs-th>
                <vs-th><Sort v-model="apiParams.sortBy.first_name" name="first_name" @sort="eventSortByCol">Prénom</Sort></vs-th>
                <vs-th>Dernier établissement</vs-th>
                <vs-th>Dernier service</vs-th>
                <vs-th><Sort v-model="apiParams.sortBy.archived_at" name="archived_at" @sort="eventSortByCol">Date d'archivage</Sort></vs-th>
                <vs-th></vs-th>
            </template>

            <template>
                <vs-tr v-for="(item, i) in items.data" :key="i">
                    <vs-td>
                        {{ item.id }}
                    </vs-td>
                    <vs-td>
                        {{ item.last_name }}
                    </vs-td>
                    <vs-td>
                        {{ item.first_name }}
                    </vs-td>
                    <vs-td>
                        {{ getLastEstablishmentName(item) }}
                    </vs-td>
                    <vs-td>
                        {{ getLastService(item) }}
                    </vs-td>
                    <vs-td>
                        {{ formatDate(item.archived_at) }}
                    </vs-td>
                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="canReactivate"
                                @click="unarchiveClickHandle(item)"
                                v-tooltip="'Réactiver'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-rotate-ccw"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>

        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination :total="Math.ceil(items.total / apiParams.perpage)" v-model="currentPage"></vs-pagination>
            </vs-col>
        </vs-row>
    </div>

</template>

<script>
import { formatDate } from "@assets/utils/helpers/helpers.js";
import objectToFormData from "object-to-formdata";
import EmployeeForm from '@components/views/rh/gestion/employee/EmployeeForm';

export default {
    name: 'Employees',
    components: {
        EmployeeForm
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            currentPage: 1,
            loading: false,
            showPopup: false,
            resource: {
                last_name: null,
                first_name: null,
                birthday: null,
                photo: null,
                services: [],
                occupations: [],
                jobs: []
            },
            formError: true
        }
    },

    computed: {
        canReactivate() {
            return this.isASuperAdmin() || this.isAnAdmin() || this.isADrh();
        }
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },

    methods: {
        getLastEstablishmentName(employee) {
            if (!employee.establishments) {
                return 'N/A'
            }

            const establishments = window._.cloneDeep(employee.establishments)

            let sortedEstablishments = establishments.sort((a, b) => {
                if (a.pivot.end_date < b.pivot.end_date) {
                    return 1;
                }
                if (a.pivot.end_date > b.pivot.end_date) {
                    return -1;
                }
                return 0
            });

            return sortedEstablishments[0].name;
        },
        getLastService(employee) {
            if (!employee.services) {
                return 'N/A'
            }

            const services = window._.cloneDeep(employee.services)

            let sortedServices = services.sort((a, b) => {
                if (a.pivot.end_date < b.pivot.end_date) {
                    return 1;
                }
                if (a.pivot.end_date > b.pivot.end_date) {
                    return -1;
                }
                return 0
            });

            return sortedServices[0].name;
        },
        unarchiveClickHandle(employee) {
            this.setResource(employee);
            this.showPopup = true;
            // this.$swal({
            //     title: `Êtes-vous sûr de vouloir réactiver ${employee.first_name} ${employee.last_name}?`,
            //     text: '',
            //     type: 'warning',
            //     showCancelButton: true,
            //     confirmButtonText: 'Ok',
            //     cancelButtonText: 'Annuler'
            // }).then(async result => {
            //     if (result.value) {

            //         window.axios({
            //             method: 'patch',
            //             url: `/api/gestion/employees/${employee.id}/unarchive`,
            //         })
            //         .then(response => {

            //             if (response.status === 200 && response.data != '') {
            //                 this.notifySuccess('Succès', 'Personne réactivé avec succès !')
            //                 this.loadItems();
            //             } else {
            //                 this.notifyError('Erreur', `Une erreur est survenue lors de la réactivation`)
            //             }

            //         })
            //         .catch((err, data) => {
            //             this.notifyErrorValidation(err)
            //             this.showPopup = true

            //         })
            //     }
            // })

        },

        saveResource() {

            let services = {};
            this.resource.services.forEach((item, i) => {
                services[i] = {id: item.id, etp: item.etp};
            });

            let establishments = {};
            this.resource.establishments.forEach((item, i) => {
                establishments[i] = {id: item.id, start_date: item.start_date}
            });

            let data                = window._.cloneDeep(this.resource);
                data.occupations    = data.occupations ? data.occupations.map(el => el.id) : null;
                data.jobs           = data.jobs ? data.jobs.map(el => el.id) : null;
                data.services       = services;
                data.establishments = establishments;

            if (data.id) {
                this.apiUpdate(
                    data, 
                    data.id, 
                    () => this.loadItems(), //Callback
                    () => this.showPopup = true // Callback error
                );
            }
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
            this.resource.establishments = this.resource.establishments.filter(e => e.pivot.end_date === null);
            this.resource.services = this.resource.services.filter(e => e.pivot.end_date === null);
            this.resource.archived_at = null;
        },

        resetResource() {
            this.resource = {
                last_name: null,
                first_name: null,
                birthday: null,
                photo: null,
                services: [],
                occupations: [],
                jobs: []
            }
        },

        setFormError (value) {
            this.formError = value;
        }
    },
    created() {
        this.apiParams.include = [
            'establishments',
            'services.establishment',
        ];
        this.apiParams.sortBy = {'archived_at': 'desc'};
        this.apiParams.archived = 'true';
        this.loadItems();
    }
}
</script>
<style lang="css">
.swal2-title {
    display:block !important;
}
.swal2-popup {
    width: 35em !important;
}

</style>
