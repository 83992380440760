var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.planAllow("indicateurs-activite")
        ? _c("LimitatedPlan", { staticClass: "mb-10" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vx-row mt-10" },
        [
          _c(
            "vx-card",
            [
              _c("h3", [_vm._v("Suivi des usages")]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-10" }, [
                _c("p", [_c("b", [_vm._v("Périmètre:")])]),
                _vm._v(" "),
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-1/2 w-full" },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "establishments",
                            options: _vm.establishments,
                            multiple: true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "name",
                            "select-label": "",
                            "selected-label": "",
                            "deselect-label": "",
                            loading: _vm.establishmentsLoading,
                            disabled: !_vm.planAllow("indicateurs-activite")
                          },
                          model: {
                            value: _vm.establishmentsFilter,
                            callback: function($$v) {
                              _vm.establishmentsFilter = $$v
                            },
                            expression: "establishmentsFilter"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-2/12 w-full pt-2" },
                        [_c("span", [_vm._v("Du :")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-10/12 w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "date",
                              name: "start_date",
                              disabled: !_vm.planAllow("indicateurs-activite")
                            },
                            model: {
                              value: _vm.startDateFilter,
                              callback: function($$v) {
                                _vm.startDateFilter = $$v
                              },
                              expression: "startDateFilter"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-2/12 w-full pt-2" },
                        [_c("span", [_vm._v("au :")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-10/12 w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "date",
                              name: "start_date",
                              disabled: !_vm.planAllow("indicateurs-activite")
                            },
                            model: {
                              value: _vm.endDateFilter,
                              callback: function($$v) {
                                _vm.endDateFilter = $$v
                              },
                              expression: "endDateFilter"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vx-row mt-5" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/2 w-full" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-1/12 w-full" },
                        [
                          _c("vs-switch", {
                            attrs: {
                              disabled: !_vm.planAllow("indicateurs-activite")
                            },
                            model: {
                              value: _vm.includeEmployees,
                              callback: function($$v) {
                                _vm.includeEmployees = $$v
                              },
                              expression: "includeEmployees"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                        _vm._v(
                          "\n                                Personnes\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-1/12 w-full" },
                        [
                          _c("vs-switch", {
                            attrs: {
                              disabled: !_vm.planAllow("indicateurs-activite")
                            },
                            model: {
                              value: _vm.includeNotInStaff,
                              callback: function($$v) {
                                _vm.includeNotInStaff = $$v
                              },
                              expression: "includeNotInStaff"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                        _vm._v(
                          "\n                                Stagiaires / Hors effectif\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-1/12 w-full" },
                        [
                          _c("vs-switch", {
                            attrs: {
                              disabled: !_vm.planAllow("indicateurs-activite")
                            },
                            model: {
                              value: _vm.perService,
                              callback: function($$v) {
                                _vm.perService = $$v
                              },
                              expression: "perService"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                        _vm._v(
                          "\n                                Détail par service\n                            "
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mt-5" }, [
                  _c("b", [_vm._v("Sélectionner l'indicateur à utiliser:")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-1/2 w-full" },
                    [
                      _c("multiselect", {
                        attrs: {
                          name: "modes",
                          options: _vm.modes,
                          multiple: false,
                          placeholder: "Recherche ...",
                          "track-by": "id",
                          label: "title",
                          "select-label": "",
                          "selected-label": "",
                          "deselect-label": "",
                          disabled: !_vm.planAllow("indicateurs-activite")
                        },
                        model: {
                          value: _vm.mode,
                          callback: function($$v) {
                            _vm.mode = $$v
                          },
                          expression: "mode"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "vs-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { search: "", data: _vm.usages },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return _vm._l(data, function(item, i) {
                          return _c(
                            "vs-tr",
                            { key: i, attrs: { data: item } },
                            [
                              _c(
                                "vs-td",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: item.description,
                                        placement: "top-start"
                                      },
                                      expression:
                                        "{content: item.description, placement: 'top-start'}"
                                    }
                                  ]
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.get(item, ":title")) +
                                      "\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("vs-td", [
                                _vm.mode.title === "Nombre"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.get(item, ":value") || 0)
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.mode.title === "Pourcentage"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.get(item, ":percentageValue") || 0
                                        ) + "%"
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              !_vm.perService
                                ? _vm._l(_vm.establishmentsFilter, function(
                                    establishment,
                                    index
                                  ) {
                                    return _c(
                                      "vs-td",
                                      {
                                        key:
                                          "usage_" +
                                          i +
                                          "_establishment_" +
                                          index
                                      },
                                      [
                                        _vm.mode.title === "Nombre"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.get(
                                                    item,
                                                    ":establishments"
                                                  )[index].value
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.mode.title === "Pourcentage"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.get(
                                                    item,
                                                    ":establishments"
                                                  )[index].percentageValue
                                                ) + "%"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  })
                                : [
                                    _vm._l(_vm.establishmentsFilter, function(
                                      establishment,
                                      index
                                    ) {
                                      return [
                                        _c("vs-td", [
                                          _vm.mode.title === "Nombre"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.get(
                                                      item,
                                                      ":establishments"
                                                    )[index].value
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.mode.title === "Pourcentage"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.get(
                                                      item,
                                                      ":establishments"
                                                    )[index].percentageValue
                                                  ) + "%"
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(establishment.services, function(
                                          service,
                                          service_index
                                        ) {
                                          return _c(
                                            "vs-td",
                                            {
                                              key:
                                                "usage_" +
                                                i +
                                                "_establishment_" +
                                                index +
                                                "_service_" +
                                                service_index
                                            },
                                            [
                                              _vm.mode.title === "Nombre"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.get(
                                                          item,
                                                          ":establishments"
                                                        )[index].services[
                                                          service_index
                                                        ].value
                                                      )
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.mode.title === "Pourcentage"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.get(
                                                          item,
                                                          ":establishments"
                                                        )[index].services[
                                                          service_index
                                                        ].percentageValue
                                                      ) + "%"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        })
                                      ]
                                    })
                                  ]
                            ],
                            2
                          )
                        })
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c(
                        "vs-th",
                        { staticStyle: { width: "40rem" } },
                        [
                          _c("Sort", { attrs: { name: "title" } }, [
                            _vm._v("Usage")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-th",
                        { staticStyle: { width: "40rem" } },
                        [
                          _c(
                            "Sort",
                            {
                              attrs: {
                                name:
                                  _vm.mode.title === "Nombre"
                                    ? "value"
                                    : "percentageValue"
                              }
                            },
                            [_vm._v("Cumul périmètre")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.perService
                        ? _vm._l(_vm.establishmentsFilter, function(
                            establishment,
                            index
                          ) {
                            return _c(
                              "vs-th",
                              {
                                key: "establishment_" + index,
                                staticStyle: { width: "40rem" }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(establishment.name) +
                                    "\n                        "
                                )
                              ]
                            )
                          })
                        : [
                            _vm._l(_vm.establishmentsFilter, function(
                              establishment,
                              index
                            ) {
                              return [
                                _c(
                                  "vs-th",
                                  {
                                    key: "establishment_" + index,
                                    staticStyle: { width: "40rem" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(establishment.name) +
                                        "\n                            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(establishment.services, function(
                                  service,
                                  service_index
                                ) {
                                  return _c(
                                    "vs-th",
                                    {
                                      key:
                                        "establishment_" +
                                        index +
                                        "_service_" +
                                        service_index,
                                      staticStyle: {
                                        width: "40rem",
                                        color: "rgb(160, 160, 160)"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(service.title) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                })
                              ]
                            })
                          ]
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }