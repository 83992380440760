<template>
    <div class="form-authorizations-container vx-row mb-6">
        <div class="vx-col w-full ml-4">
            <div class="vx-row mb-6" v-show="!reduced">
                <b v-if="evaluator">Périmètre de l'évaluateur externe</b>
                <b v-else>Personne(s) additionnelle(s) au périmètre de l'évaluateur tiers</b>
            </div>

            <!-- Source de l'évaluation -->
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Source de l'évaluation :</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full px-0">
                    <vs-input
                        v-model="value.eval_source"
                        name="userEmail"
                        type="email"
                        class="w-full"
                    />
                </div>
            </div>

            <div class="vx-row mb-6" v-show="!reduced">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Personnes :</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <multiselect
                        v-if="(evaluator && groupedEmployeesByEstablishmentsForEvaluators.length > 0) || (!evaluator && groupedEmployeesByEstablishmentsForNoNEvaluators.length > 0)"
                        v-model="value.employees"
                        :options="evaluator ? groupedEmployeesByEstablishmentsForEvaluators : groupedEmployeesByEstablishmentsForNoNEvaluators"
                        :multiple="true"
                        group-values="employees"
                        group-label="establishment"
                        :group-select="true"
                        placeholder="Recherche ..."
                        track-by="id"
                        label="name"
                        select-label=""
                        select-group-label="L'établissement"
                        selected-label=""
                        deselect-label=""
                        deselect-group-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
            </div>

            <!-- <div class="vx-row mb-6" v-if="value.employees.length">
                <div class="vx-col sm:w-1/5 w-full" >
                    <b>{{ !reduced ? '' : 'Périmètre de l\'évaluateur*' }}</b>
                </div>
                <div class="vx-col sm:w-1/3 w-full" v-if="!reduced || reducedType === 'experience'">
                    <span>Poste(s)</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full" v-if="!reduced || reducedType === 'occupation'">
                    <span>Métier(s)</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full" >
                    <span>Compétences</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full" >
                    <span>Questionnaire</span>
                </div>
            </div> -->
            <div
                class="vx-row mb-6"
                v-for="(employee, index) in value.employees"
                :key="index"
            >
                <div class="vx-col sm:w-3/12 w-full">
                    <div v-show="!reduced">
                        <span>({{ employee.id }}) {{ employee.name }}</span>
                    </div>
                    <!-- Type d'évaluation -->
                    <div>
                        <label>Évaluation demandée*</label>
                        <multiselect
                            v-model="employee.eval_types"
                            :options="evalTypes"
                            :multiple="true"
                            name="eval_types"
                            track-by="slug"
                            label="title"
                            placeholder="Recherche ..."
                            select-label=""
                            selected-label=""
                            deselect-label=""
                        />
                    </div>
                </div>
                <div class="vx-col sm:w-4/12 w-full" v-if="!reduced || reducedType === 'experience'">
                    <label>Poste(s)</label>
                    <multiselect
                        v-model="employee.jobsToEvaluate"
                        :name="'employee.' + employee.id + '.jobs'"
                        :options="employee.jobs || []"
                        :multiple="true"
                        placeholder="Postes à considérer ..."
                        track-by="id"
                        label="title"
                        select-label=""
                        selected-label=""
                        deselect-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>
                <div class="vx-col sm:w-4/12 w-full" v-if="!reduced || reducedType === 'occupation'">
                    <label>Métier(s)</label>
                    <multiselect
                        v-model="employee.occupationsToEvaluate"
                        :name="'employee.' + employee.id + '.occupations'"
                        :options="employee.occupations || []"
                        :multiple="true"
                        placeholder="Métiers à considérer ..."
                        track-by="id"
                        label="title"
                        select-label=""
                        selected-label=""
                        deselect-label=""
                    >
                        <span slot="noResult">Aucun résultat.</span>
                    </multiselect>
                </div>

                <div class="vx-col sm:w-2/12 w-full">
                    <label>Compétences</label>
                    <vs-switch
                        v-model="employee.eval_skills"
                        name="eval_skills"
                    />
                </div>
                <div class="vx-col sm:w-3/12 w-full">
                    <label>Questionnaire</label>
                    <br>
                    <multiselect
                        v-model="employee.questionnaire"
                        name="questionnaire"
                        :options="questionnaires.data"
                        placeholder="Choisir..."
                        track-by="id"
                        label="name"
                        select-label=""
                        selected-label=""
                        deselect-label=""
                        :loading="questionnaires.loading"
                    >
                        <span slot="noResult">Aucun résultat.</span>
                        <span slot="noOptions">La liste est vide.</span>
                    </multiselect>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { loadRatingQuestionnaires } from "@components/services/resources.js";

export default {
    name: "UserEvaluatorForm",
    components: {
        Multiselect
    },
    props: {
        value: {
            required: true,
            type: Object
        },
        establishmentsList: {
            required: false,
            default: () => []
        },
        servicesList: {
            required: false,
            default: () => []
        },
        evaluator: {
            required: true,
        },
        reduced: {
            required: false,
            type: Boolean,
            default: false,
        },
        reducedFor: {
            required: false,
        },
        reducedType: {
            required: false,
            type: String,
        }
    },
    data() {
        return {
            log: console.log,
            loadRatingQuestionnaires,
            employees: [],
            employeesLoading: true,
            evalTypes: [
                { slug: "intern", title: "Evaluation interne" },
                { slug: "client", title: "Evaluation client" },
                { slug: "rae", title: "RAE" },
                { slug: "mixed", title: "Evaluation croisée" },
                { slug: "stage", title: "Evaluation Stage" }
            ],
            questionnaires: {
                data: [],
                loading: false
            }
        };
    },
    async created() {
        this.$vs.loading();
        this.questionnaires.loading = true;
        await Promise.all([
            this.loadEmployees(),
            this.loadRatingQuestionnaires().then((response) => {
                this.questionnaires.data = response.data;
                this.questionnaires.loading = false;

                this.value.employees.forEach((employee) => {
                    if (employee.questionnaire_id) {
                        employee.questionnaire = this.questionnaires.data.find(q => q.id == employee.questionnaire_id);
                    }
                    else {
                        employee.questionnaire = this.questionnaires.data[0]
                    }
                })
            })
        ]);
        this.$vs.loading.close();
    },
    computed: {
        groupedEmployeesByEstablishmentsForEvaluators() {
            let toShow = [];
            let servs = [];

            this.establishmentsList.forEach(e => {
                servs = this.servicesList ? this.servicesList.filter(service => service.establishment_id === e.id) : [];
                toShow.push({
                    establishment: e.name,
                    employees: this.employees
                        ? this.employees
                            .filter(em => servs.map(s => s.id).includes(em.services[0].id))
                            .map(employee => {
                                    return {
                                        id: employee.id,
                                        mobility: employee?.mobility,
                                        mobility_id: employee?.mobility_id,
                                        first_name: employee?.first_name,
                                        last_name: employee?.last_name,
                                        jobs: employee.jobs,
                                        occupations: employee.occupations,
                                        name: `${employee.last_name.toUpperCase()} ${
                                            employee.first_name
                                        }`,
                                        jobsToEvaluate: (!this.reduced || this.reducedType === 'experience') ? employee.jobs.map(j => ({
                                            id: j.id,
                                            title: j.title
                                        })) : [],
                                        occupationsToEvaluate: (!this.reduced || this.reducedType === 'occupation') ? employee.occupations.map(
                                            o => ({ id: o.id, admin_id: (employee?.occupations?.find(occ => o.id === occ.id)?.admin_id || null), title: o.title })
                                        ) : [],
                                        eval_types: employee.eval_types,
                                        eval_skills: true,
                                        questionnaire: employee.questionnaire_id ? this.questionnaires.data.find(q => q.id == employee.questionnaire_id) : this.questionnaires.data[0],
                                    };
                            })
                        : []
                });
            });

            return toShow;
        },

        groupedEmployeesByEstablishmentsForNoNEvaluators() {
            let toShow = [];
            let servs = [];

            let servicesNotInPerimeter = this.servicesList ? this.services.filter(s => !this.servicesList.map(sL => sL.id).includes(s.id)) : this.services

            this.establishmentsList.forEach(e => {
                servs = servicesNotInPerimeter ? servicesNotInPerimeter.filter(service => service.establishment_id === e.id) : [];

                toShow.push({
                    establishment: e.name,
                    employees: this.employees
                        ? this.employees
                            .filter(e => servs.map(s => s.id).includes(e.services[0]))
                            .map(employee => {
                                return {
                                    id: employee.id,
                                    mobility: employee?.mobility,
                                    mobility_id: employee?.mobility_id,
                                    first_name: employee?.first_name,
                                    last_name: employee?.last_name,
                                    jobs: employee.jobs,
                                    occupations: employee.occupations,
                                    name: `${employee.last_name.toUpperCase()} ${
                                        employee.first_name
                                    }`,
                                    jobsToEvaluate: (!this.reduced || this.reducedType === 'experience') ? employee.jobs.map(j => ({
                                        id: j.id,
                                        title: j.title
                                    })) : [],
                                    occupationsToEvaluate: (!this.reduced || this.reducedType === 'occupation') ? employee.occupations.map(
                                        o => ({ id: o.id, admin_id: (employee?.occupations?.find(occ => o.id === occ.id)?.admin_id || null), title: o.title })
                                    ) : [],
                                    eval_types: employee.eval_types,
                                    questionnaire: employee.questionnaire_id ? this.questionnaires.data.find(q => q.id == employee.questionnaire_id) : this.questionnaires.data[0],
                                };
                            })
                        : []
                });
            });

            return toShow;
        }
    },

    watch: {
        value: {
            handler() {
                this.$emit("input", this.value);
            },
            deep: true
        }
    },

    methods: {
        async loadEmployees() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: {
                        perpage: 9999,
                        include: ['currentOccupations', 'currentJobs','services.establishment'],
                        filter: this.reducedFor ? 'id|=|'+this.reducedFor : undefined,
                        updatable: 'true', //only ones auth user has permissions to update
                    }
                })
                .then(response => {
                    this.employees = response.data.data;
                    this.employees.forEach(e => {
                        e.occupations = e.current_occupations
                        e.jobs = e.current_jobs
                    })
                    if (this.reduced) {
                        let employee = this.employees.find(e => e.id === +this.reducedFor)

                        this.value.employees = [{
                            id: employee.id,
                            mobility: employee?.mobility,
                            mobility_id: employee?.mobility_id,
                            first_name: employee?.first_name,
                            last_name: employee?.last_name,
                            jobs: employee.jobs,
                            occupations: employee.occupations,
                            name: `${employee.last_name.toUpperCase()} ${
                                employee.first_name
                            }`,
                            jobsToEvaluate: (!this.reduced || this.reducedType === 'experience') ? employee.jobs.map(j => ({
                                id: j.id,
                                title: j.title
                            })) : [],
                            occupationsToEvaluate: (!this.reduced || this.reducedType === 'occupation') ? employee.occupations.map(
                                o => ({ id: o.id, admin_id: (employee?.occupations?.find(occ => o.id === occ.id)?.admin_id || null), title: o.title })
                            ) : [],
                            eval_types: employee.eval_types,
                            eval_skills: true,
                            questionnaire: this.questionnaires.data.find(q => q.id == 1)
                        }];
                    }
                    this.employeesLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        }
    }
};
</script>

<style lang="scss" scoped></style>
