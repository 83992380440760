var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      !_vm.planAllow("dashbords")
        ? _c("LimitatedPlan", { staticClass: "mb-10" })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [
        _vm._v(
          "\n        Identifier les personnes en capacité de répondre aux attendus d’un\n        métier, d’un poste ou d’un savoir-faire\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n        Pour chaque métier, poste ou savoir-faire sélectionné, ce tableau de\n        bord permet d’identifier les personnes en capacité d’y accéder en\n        sécurité.\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mb-4 mt-3" },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "12"
                  }
                },
                [
                  _c(
                    "vs-row",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un métier")
                          ]),
                          _vm._v(" "),
                          _c(
                            "Multiselect",
                            {
                              attrs: {
                                options: _vm.occupationsList,
                                multiple: false,
                                "group-values": "occupations",
                                "group-label": "type",
                                "group-select": false,
                                placeholder: "Recherche ...",
                                "track-by": "id",
                                label: "title",
                                "select-label": "",
                                "selected-label": "",
                                "deselect-label": "",
                                loading: _vm.occupations.loading,
                                disabled: !_vm.planAllow("dashbords")
                              },
                              on: { input: _vm.filterOccupationChangeHandle },
                              model: {
                                value: _vm.filters.occupation,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "occupation", $$v)
                                },
                                expression: "filters.occupation"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noOptions" },
                                  slot: "noOptions"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un poste")
                          ]),
                          _vm._v(" "),
                          _c(
                            "Multiselect",
                            {
                              attrs: {
                                options: _vm.groupedJobsByService,
                                multiple: false,
                                "group-values": "jobs",
                                "group-label": "service",
                                "group-select": false,
                                placeholder: "Recherche ...",
                                "track-by": "id",
                                label: "title",
                                "select-label": "",
                                "select-group-label": "Etablissement/service",
                                "selected-label": "",
                                "deselect-label": "",
                                "deselect-group-label": "",
                                loading: _vm.jobs.loading,
                                disabled: !_vm.planAllow("dashbords")
                              },
                              on: { input: _vm.filterJobChangeHandle },
                              model: {
                                value: _vm.filters.job,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "job", $$v)
                                },
                                expression: "filters.job"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noOptions" },
                                  slot: "noOptions"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un savoir-faire")
                          ]),
                          _vm._v(" "),
                          _c(
                            "Multiselect",
                            {
                              attrs: {
                                options: _vm.shownOccupationSkills,
                                multiple: false,
                                placeholder: "Recherche ...",
                                "track-by": "id",
                                label: "titleEstablishment",
                                "select-label": "",
                                "selected-label": "",
                                "deselect-label": "",
                                "deselect-group-label": "",
                                loading: _vm.occupation_skills.loading,
                                disabled: !_vm.planAllow("dashbords")
                              },
                              on: {
                                input: _vm.filterOccupationskillChangeHandle
                              },
                              model: {
                                value: _vm.filters.occupationskill,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "occupationskill", $$v)
                                },
                                expression: "filters.occupationskill"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noOptions" },
                                  slot: "noOptions"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "vs-row",
                    { staticClass: "checkboxes-container" },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "flex-start",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              attrs: { disabled: !_vm.planAllow("dashbords") },
                              model: {
                                value: _vm.filters.wishlistOnly,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "wishlistOnly", $$v)
                                },
                                expression: "filters.wishlistOnly"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Souhaités uniquement\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "flex-start",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              attrs: { disabled: !_vm.planAllow("dashbords") },
                              model: {
                                value: _vm.filters.securityOnly,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "securityOnly", $$v)
                                },
                                expression: "filters.securityOnly"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Accessibles en sécurité\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "flex-start",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              attrs: {
                                disabled:
                                  _vm.disabledMineOnly ||
                                  !_vm.planAllow("dashbords")
                              },
                              model: {
                                value: _vm.filters.mineOnly,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "mineOnly", $$v)
                                },
                                expression: "filters.mineOnly"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Mes savoir-faire uniquement\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.can("read", "skill_reviews") || _vm.can("read", "ratings")
            ? _c(
                "vs-row",
                { staticClass: "mt-5" },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-justify": "center", "vs-align": "center" } },
                    [
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-align": "flex-start", "vs-w": "6" }
                            },
                            [
                              _vm._v(
                                "\n                        Résultats basés sur :\n                        "
                              ),
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5 ml-2",
                                  attrs: {
                                    "vs-name": "radios_type_compute",
                                    id: "radios-type-compute",
                                    "vs-value": _vm.typeComputeValues.RATINGS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeCompute,
                                    callback: function($$v) {
                                      _vm.typeCompute = $$v
                                    },
                                    expression: "typeCompute"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.projectNames.plural) +
                                      "\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5",
                                  attrs: {
                                    "vs-name": "radios_type_compute",
                                    id: "radios-type-compute",
                                    "vs-value": _vm.typeComputeValues.REVIEWS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeCompute,
                                    callback: function($$v) {
                                      _vm.typeCompute = $$v
                                    },
                                    expression: "typeCompute"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.skillRatingNames.plural) +
                                      "\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-align": "flex-start", "vs-w": "6" }
                            },
                            [
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5 ml-2",
                                  attrs: {
                                    "vs-name": "radios_type_eval",
                                    id: "radios-type-eval",
                                    "vs-value": _vm.typeEvalValues.SKILLS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeEval,
                                    callback: function($$v) {
                                      _vm.typeEval = $$v
                                    },
                                    expression: "typeEval"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Accessibilité\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5",
                                  attrs: {
                                    "vs-name": "radios_type_eval",
                                    id: "radios-type-eval",
                                    "vs-value":
                                      _vm.typeEvalValues.OCCUPATION_SKILLS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeEval,
                                    callback: function($$v) {
                                      _vm.typeEval = $$v
                                    },
                                    expression: "typeEval"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Score de maîtrise\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "12"
                  }
                },
                [
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner un service")
                          ]),
                          _vm._v(" "),
                          _c(
                            "Multiselect",
                            {
                              attrs: {
                                options: _vm.groupedServicesByEstablishments,
                                multiple: true,
                                "group-values": "services",
                                "group-label": "establishment",
                                "group-select": true,
                                placeholder: "Recherche ...",
                                "track-by": "id",
                                label: "title",
                                "select-label": "",
                                "select-group-label": "L'établissement",
                                "selected-label": "",
                                "deselect-label": "",
                                "deselect-group-label": "",
                                disabled: _vm.disabledServices,
                                loading: _vm.establishments.loading
                              },
                              on: { input: _vm.filterServicesChangeHandle },
                              model: {
                                value: _vm.filters.services,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "services", $$v)
                                },
                                expression: "filters.services"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("label", { staticClass: "typo__label" }, [
                            _vm._v("Sélectionner une personne")
                          ]),
                          _vm._v(" "),
                          _c(
                            "Multiselect",
                            {
                              attrs: {
                                options:
                                  _vm.groupedEmployeesByEstablishmentsAndFilteredByServices,
                                multiple: true,
                                "group-values": "employees",
                                "group-label": "establishment",
                                "group-select": true,
                                placeholder: "Recherche ...",
                                "track-by": "id",
                                label: "name",
                                "select-label": "",
                                "select-group-label": "L'établissement",
                                "selected-label": "",
                                "deselect-label": "",
                                "deselect-group-label": "",
                                disabled: _vm.disabledEmployees,
                                loading: _vm.employees.loading
                              },
                              on: { input: _vm.filterEmployeesChangeHandle },
                              model: {
                                value: _vm.filters.employees,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "employees", $$v)
                                },
                                expression: "filters.employees"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Aucun résultat.")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
            [
              _vm.planAllow("dashbords")
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        id: "search-button",
                        color: "primary",
                        disabled: !_vm.canSearch
                      },
                      on: { click: _vm.searchClick }
                    },
                    [
                      _vm._v(
                        "\n                Lancer la recherche\n            "
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticStyle: { position: "relative", float: "left" },
                      attrs: {
                        title: "Votre formule n'inclut pas cette fonctionnalité"
                      }
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            id: "search-button",
                            color: "primary",
                            disabled: "true"
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Lancer la recherche\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-icon",
                        {
                          staticClass: "button-bullet",
                          attrs: { bg: "#FF9B3A", round: "" }
                        },
                        [
                          _c("CrownIcon", {
                            staticStyle: {
                              width: "16px",
                              height: "16px",
                              padding: "3px"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("vx-card", { staticClass: "mt-6" }, [
        _vm.evaluationForChart.length > 0
          ? _c(
              "div",
              [
                _c("vs-row", [
                  _c(
                    "ul",
                    {
                      staticStyle: {
                        columns: "4",
                        "-webkit-columns": "4",
                        "-moz-columns": "4"
                      },
                      attrs: { id: "employee-skills-show" }
                    },
                    _vm._l(_vm.evaluationForChart, function(chart, i) {
                      return _c("li", { key: i + "-" + chart.job.id }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.scrollTo(
                                  "chart-" + i + "-" + chart.job.id
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: { content: chart.job.description },
                                    expression:
                                      "{ content: chart.job.description }"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      chart.options.title.text.split(":")[0]
                                    ) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n                            :\n                            "
                            ),
                            _c("b", { staticClass: "text-primary" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.round(chart.scoreNumber * 100, 0) + "% "
                                  ) +
                                  "\n                            "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        chart.check
                          ? _c("b", { staticStyle: { color: "green" } }, [
                              _vm._v("✓")
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c(
                  "vs-row",
                  { staticClass: "mt-10" },
                  [
                    _c(
                      "vs-col",
                      { attrs: { "vs-type": "flex", "vs-justify": "center" } },
                      [
                        _vm.hasMorePage
                          ? _c(
                              "vs-button",
                              {
                                attrs: {
                                  id: "more-button",
                                  color: "primary",
                                  type: "border"
                                },
                                on: { click: _vm.loadEvaluationsPage }
                              },
                              [
                                _vm._v(
                                  "\n                        Charger plus...\n                    "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm.evaluation.loaded
          ? _c("div", [
              _vm._v("\n            Aucune donnée à afficher\n        ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mt-6" },
        [
          _vm.evaluationForChart.length > 0
            ? _c(
                "vs-row",
                _vm._l(_vm.evaluationForChart, function(chart, i) {
                  return _c(
                    "vs-col",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: { content: chart.job.description },
                          expression: "{ content: chart.job.description }"
                        }
                      ],
                      key:
                        "chart-" + i + "-" + chart.score + "-" + chart.job.id,
                      staticClass: "mt-6",
                      attrs: {
                        id: "chart-" + i + "-" + chart.job.id,
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-lg": "6",
                        "vs-sm": "12"
                      }
                    },
                    [
                      _c("RatingChart", {
                        staticClass: "rating",
                        attrs: {
                          employee: _vm.filters.employee,
                          options: chart.options,
                          "chart-data": chart.data
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mt-10" },
        [
          _c(
            "vs-col",
            { attrs: { "vs-type": "flex", "vs-justify": "center" } },
            [
              _vm.hasMorePage
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        id: "more-button",
                        color: "primary",
                        type: "border"
                      },
                      on: { click: _vm.loadEvaluationsPage }
                    },
                    [_vm._v("\n                Charger plus...\n            ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }