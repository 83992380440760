var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      !_vm.planAllow("dashbords")
        ? _c("LimitatedPlan", { staticClass: "mb-10" })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [
        _vm._v(
          "Identifier la capacité d’une équipe à répondre aux besoins d'une activité"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "En sélectionnant un ou plusieurs savoir-faire, ce tableau de bord permet de vérifier les capacités à y répondre à l'échelle d’un ou plusieurs services."
        )
      ]),
      _vm._v(" "),
      _c(
        "vx-card",
        { staticClass: "mb-4 mt-3" },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "4"
                  }
                },
                [
                  _c("label", { staticClass: "typo__label" }, [
                    _vm._v("Filières")
                  ]),
                  _vm._v(" "),
                  _c(
                    "Multiselect",
                    {
                      attrs: {
                        options: _vm.sectors.data,
                        multiple: true,
                        placeholder: "Recherche ...",
                        "track-by": "id",
                        label: "title",
                        "select-label": "",
                        "select-group-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        "deselect-group-label": "",
                        disabled: !_vm.planAllow("dashbords")
                      },
                      model: {
                        value: _vm.filters.sectors,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "sectors", $$v)
                        },
                        expression: "filters.sectors"
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Aucun résultat.")]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "6"
                  }
                },
                [
                  _c("label", { staticClass: "typo__label" }, [
                    _vm._v("Sélectionner un ou plusieurs savoir-faire")
                  ]),
                  _vm._v(" "),
                  _c(
                    "Multiselect",
                    {
                      attrs: {
                        options: _vm.shownOccupationSkillList,
                        multiple: true,
                        "group-label": "group",
                        "group-values": "items",
                        "group-select": true,
                        placeholder: "Recherche ...",
                        "track-by": "id",
                        label: "rich_title",
                        "select-label": "",
                        "select-group-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        "deselect-group-label": "",
                        loading: _vm.occupationSkills.loading,
                        disabled: !_vm.planAllow("dashbords")
                      },
                      model: {
                        value: _vm.filters.occupationSkills,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "occupationSkills", $$v)
                        },
                        expression: "filters.occupationSkills"
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Aucun résultat.")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-card",
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "4"
                  }
                },
                [
                  _c("label", { staticClass: "typo__label" }, [
                    _vm._v("Sélectionner un ou plusieurs services")
                  ]),
                  _vm._v(" "),
                  _c(
                    "Multiselect",
                    {
                      attrs: {
                        options: _vm.groupedServicesByEstablishments,
                        multiple: true,
                        "group-values": "services",
                        "group-label": "establishment",
                        "group-select": true,
                        placeholder: "Recherche ...",
                        "track-by": "id",
                        label: "title",
                        "select-label": "",
                        "select-group-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        "deselect-group-label": "",
                        disabled: !_vm.planAllow("dashbords")
                      },
                      model: {
                        value: _vm.filters.services,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "services", $$v)
                        },
                        expression: "filters.services"
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Aucun résultat.")]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "4"
                  }
                },
                [
                  _c("label", { staticClass: "typo__label" }, [
                    _vm._v("Sélectionner une ou plusieurs personnes")
                  ]),
                  _vm._v(" "),
                  _c(
                    "Multiselect",
                    {
                      attrs: {
                        options:
                          _vm.groupedEmployeesByEstablishmentsAndFilteredByServices,
                        multiple: true,
                        "group-values": "employees",
                        "group-label": "establishmentService",
                        "group-select": true,
                        placeholder: "Recherche ...",
                        "track-by": "id",
                        label: "name",
                        "select-label": "",
                        "select-group-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        "deselect-group-label": "",
                        disabled: !_vm.planAllow("dashbords")
                      },
                      model: {
                        value: _vm.filters.employees,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "employees", $$v)
                        },
                        expression: "filters.employees"
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Aucun résultat.")]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-col",
                {
                  staticClass: "text-right pt-3",
                  attrs: {
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "2"
                  }
                },
                [
                  _c("feather-icon", {
                    staticClass: "settings mt-5",
                    attrs: { icon: "SettingsIcon" },
                    on: {
                      click: function($event) {
                        _vm.showPopup = true
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "DialogPrompt",
                {
                  attrs: {
                    active: _vm.showPopup,
                    title: "Affichage du tableau",
                    "cancel-text": "Fermer",
                    "button-accept": ""
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.showPopup = $event
                    },
                    close: function($event) {
                      _vm.showPopup = false
                    }
                  }
                },
                [
                  _c(
                    "vs-row",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-w": "4" } },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              attrs: { disabled: !_vm.planAllow("dashbords") },
                              model: {
                                value: _vm.filters.levelOfMasteryEvaluated,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.filters,
                                    "levelOfMasteryEvaluated",
                                    $$v
                                  )
                                },
                                expression: "filters.levelOfMasteryEvaluated"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Niveau de maîtrise évalué\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        { attrs: { "vs-w": "4" } },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              attrs: { disabled: !_vm.planAllow("dashbords") },
                              model: {
                                value: _vm.filters.accessibilityPercentage,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.filters,
                                    "accessibilityPercentage",
                                    $$v
                                  )
                                },
                                expression: "filters.accessibilityPercentage"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Poucentage d'accessibilité\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-col",
                        { attrs: { "vs-w": "4" } },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              attrs: { disabled: !_vm.planAllow("dashbords") },
                              model: {
                                value: _vm.filters.accessibleSafely,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "accessibleSafely", $$v)
                                },
                                expression: "filters.accessibleSafely"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Accessible en sécurité\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vs-row",
            [
              _c("vs-col", [
                _vm.hasSelectedEmployees
                  ? _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.selectedEmployees.length) +
                          " personne" +
                          _vm._s(_vm.selectedEmployees.length > 1 ? "s" : "") +
                          " sélectionnée" +
                          _vm._s(_vm.selectedEmployees.length > 1 ? "s" : "") +
                          "\n                "
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.can("read", "skill_reviews") || _vm.can("read", "ratings")
            ? _c(
                "vs-row",
                { staticClass: "mt-5" },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-justify": "center", "vs-align": "center" } },
                    [
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-align": "flex-start", "vs-w": "6" }
                            },
                            [
                              _vm._v(
                                "\n                        Résultats basés sur :\n                        "
                              ),
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5 ml-2",
                                  attrs: {
                                    "vs-name": "radios_type_compute",
                                    id: "radios-type-compute",
                                    "vs-value": _vm.typeComputeValues.RATINGS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeCompute,
                                    callback: function($$v) {
                                      _vm.typeCompute = $$v
                                    },
                                    expression: "typeCompute"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.projectNames.plural) +
                                      "\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5",
                                  attrs: {
                                    "vs-name": "radios_type_compute",
                                    id: "radios-type-compute",
                                    "vs-value": _vm.typeComputeValues.REVIEWS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeCompute,
                                    callback: function($$v) {
                                      _vm.typeCompute = $$v
                                    },
                                    expression: "typeCompute"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.skillRatingNames.plural) +
                                      "\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-align": "flex-start", "vs-w": "6" }
                            },
                            [
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5 ml-2",
                                  attrs: {
                                    "vs-name": "radios_type_eval",
                                    id: "radios-type-eval",
                                    "vs-value": _vm.typeEvalValues.SKILLS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeEval,
                                    callback: function($$v) {
                                      _vm.typeEval = $$v
                                    },
                                    expression: "typeEval"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Accessibilité\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "vs-radio",
                                {
                                  staticClass: "mr-5",
                                  attrs: {
                                    "vs-name": "radios_type_eval",
                                    id: "radios-type-eval",
                                    "vs-value":
                                      _vm.typeEvalValues.OCCUPATION_SKILLS,
                                    disabled:
                                      (_vm.can("read", "skill_reviews") &&
                                        !_vm.can("read", "ratings")) ||
                                      (!_vm.can("read", "skill_reviews") &&
                                        _vm.can("read", "ratings")) ||
                                      !_vm.planAllow("dashbords")
                                  },
                                  model: {
                                    value: _vm.typeEval,
                                    callback: function($$v) {
                                      _vm.typeEval = $$v
                                    },
                                    expression: "typeEval"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Score de maîtrise\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        { staticClass: "mt-4" },
        [
          _c(
            "vs-col",
            { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
            [
              _vm.planAllow("dashbords")
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        id: "search-button",
                        color: "primary",
                        disabled: !_vm.canSearch
                      },
                      on: { click: _vm.startResearchHandle }
                    },
                    [
                      _vm._v(
                        "\n                Lancer la recherche\n            "
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticStyle: { position: "relative", float: "left" },
                      attrs: {
                        title: "Votre formule n'inclut pas cette fonctionnalité"
                      }
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            id: "search-button",
                            color: "primary",
                            disabled: "true"
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Lancer la recherche\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-icon",
                        {
                          staticClass: "button-bullet",
                          attrs: { bg: "#FF9B3A", round: "" }
                        },
                        [
                          _c("CrownIcon", {
                            staticStyle: {
                              width: "16px",
                              height: "16px",
                              padding: "3px"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.resourceLoaded && _vm.hasSelectedEmployees
        ? _c(
            "vx-card",
            { staticClass: "mt-4" },
            [
              _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    [
                      _c(
                        "vs-table",
                        {
                          staticClass: "dashboard-os-results",
                          attrs: {
                            data: _vm.orderedOccupationSkills,
                            "no-data-text": "Aucune donnée à afficher"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  return [
                                    _c(
                                      "vs-tr",
                                      {
                                        staticStyle: {
                                          "border-top": "3px solid #c3c3c3",
                                          "border-bottom": "1px solid #c3c3c3",
                                          height: "50px"
                                        }
                                      },
                                      [
                                        _c("vs-th", [
                                          _vm._v("Réponse au besoin")
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.orderedSelectedEmployees,
                                          function(employee, i) {
                                            return _c(
                                              "vs-th",
                                              {
                                                key: i,
                                                staticClass:
                                                  "text-center eval-response"
                                              },
                                              [
                                                _vm.filters
                                                  .accessibilityPercentage
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm.getGlobalAccessibilityPercent(
                                                              employee.id
                                                            )
                                                          ) +
                                                          " %\n                                "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.filters.accessibleSafely
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "security ml-3"
                                                      },
                                                      [
                                                        _vm.getGlobalSecurity(
                                                          employee.id
                                                        )
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-check text-success"
                                                            })
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm._l(data, function(occupationSkill, i) {
                                      return _c(
                                        "vs-tr",
                                        { key: i },
                                        [
                                          _c(
                                            "vs-th",
                                            { staticClass: "text-nowrap" },
                                            [
                                              _vm._v(
                                                _vm._s(occupationSkill.title)
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.orderedSelectedEmployees,
                                            function(employee, i) {
                                              return _c(
                                                "vs-td",
                                                {
                                                  key: i,
                                                  staticClass: "evaluation"
                                                },
                                                [
                                                  _vm.filters
                                                    .levelOfMasteryEvaluated
                                                    ? _c(
                                                        "vs-chip",
                                                        {
                                                          staticClass: "level",
                                                          attrs: {
                                                            color: _vm.getLevelColor(
                                                              _vm.getEvaluatedOccupationSkillInfo(
                                                                employee.id,
                                                                occupationSkill.id,
                                                                "rating_level"
                                                              )
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                    " +
                                                              _vm._s(
                                                                _vm.levelLabelById(
                                                                  _vm.getEvaluatedOccupationSkillInfo(
                                                                    employee.id,
                                                                    occupationSkill.id,
                                                                    "rating_level"
                                                                  )
                                                                )
                                                              ) +
                                                              "\n                                "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.filters
                                                    .accessibilityPercentage &&
                                                  !_vm.isOccuSkillEvalType
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "percent ml-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                    " +
                                                              _vm._s(
                                                                Math.round(
                                                                  _vm.getEvaluatedOccupationSkillInfo(
                                                                    employee.id,
                                                                    occupationSkill.id,
                                                                    "accessibility"
                                                                  ) * 100
                                                                )
                                                              ) +
                                                              " %\n                                "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.filters
                                                    .accessibleSafely &&
                                                  !_vm.isOccuSkillEvalType
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "security ml-3"
                                                        },
                                                        [
                                                          _vm.getEvaluatedOccupationSkillInfo(
                                                            employee.id,
                                                            occupationSkill.id,
                                                            "security"
                                                          )
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "fa fa-check text-success"
                                                              })
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1684600204
                          )
                        },
                        [
                          _c(
                            "template",
                            { slot: "thead" },
                            [
                              _c("vs-th"),
                              _vm._v(" "),
                              _vm._l(_vm.orderedSelectedEmployees, function(
                                employee,
                                i
                              ) {
                                return _c(
                                  "vs-th",
                                  {
                                    key: i,
                                    staticClass: "text-center eval-name"
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(employee.last_name) +
                                        " " +
                                        _vm._s(employee.first_name) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }