<template>
    <div>
        <div style="text-align: right;">
            <br />
            <button class="btn btn-outline-primary vs-component vs-button vs-button-primary vs-button-filled" @click="displayManageWidget()">
                <span>Gérer le tableau de bord </span>
            </button>
            <div class="cardTdbord" v-if="manageWidgets">
                <p style="text-align: center;">
                <b>Afficher/cacher les cartes</b><br />
                10 cartes maximum
                <table>
                    <thead>
                        <tr>
                            <th>Mes suivis</th>
                            <th>Mes liens</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <vs-checkbox
                                    @click="clickCheckbox('ratings')"
                                    v-model="ratings"
                                    style="flex: auto;"
                                >
                                    {{ projectNames.plural }} à réaliser
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('ratingsInProgress')"
                                    v-model="ratingsInProgress"
                                    style="flex: auto;"
                                >
                                    {{ projectNames.plural }} en cours
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('ratingsToValidate')"
                                    v-model="ratingsToValidate"
                                    style="flex: auto;"
                                >
                                    {{ projectNames.plural }} à valider
                                </vs-checkbox>

                                <vs-checkbox
                                    @click="clickCheckbox('skillRatingsInProgress')"
                                    v-model="skillRatingsInProgress"
                                    style="flex: auto;"
                                >
                                    {{ skillRatingNames.plural }} en cours
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('skillRatingsToValidate')"
                                    v-model="skillRatingsToValidate"
                                    style="flex: auto;"
                                >
                                    {{ skillRatingNames.plural }} à valider
                                </vs-checkbox>


                                <vs-checkbox
                                    @click="clickCheckbox('employeesCreatedByUser')"
                                    v-model="employeesCreatedByUser"
                                    style="flex: auto;"
                                >
                                    Mes personnes créées
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('employeesCreated')"
                                    v-model="employeesCreated"
                                    style="flex: auto;"
                                >
                                    Personnes créées
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('jobsCreatedByUser')"
                                    v-model="jobsCreatedByUser"
                                    style="flex: auto;"
                                >
                                    Mes postes/stages créés
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('jobsCreated')"
                                    v-model="jobsCreated"
                                    style="flex: auto;"
                                >
                                    Postes/stages créés
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('occupationskillsModifiedByUser')"
                                    v-model="occupationskillsModifiedByUser"
                                    style="flex: auto;"
                                >
                                    Mes savoir-faire modifiés
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('occupationskillsModified')"
                                    v-model="occupationskillsModified"
                                    style="flex: auto;"
                                >
                                    Savoir-faire modifiés
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('lastSelfRatingsExternal')"
                                    v-model="lastSelfRatingsExternal"
                                    style="flex: auto;"
                                >
                                    Dernières auto-évaluations transmises
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('externalRatings')"
                                    v-model="externalRatings"
                                    style="flex: auto;"
                                >
                                    Dernières évaluations transmises
                                </vs-checkbox>
                            </td>
                            <td>
                                <vs-checkbox
                                    @click="clickCheckbox('employees')"
                                    v-model="personnes"
                                    style="flex: auto;"
                                >
                                    Personnes
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('jobs')"
                                    v-model="metiers"
                                    style="flex: auto;"
                                >
                                    Métiers & postes
                                </vs-checkbox>
                                <vs-checkbox
                                    @click="clickCheckbox('skills')"
                                    v-model="competences"
                                    style="flex: auto;"
                                >
                                    Compétences
                                </vs-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </p>
                <p style="text-align: left;"><small>
                    <i class="fa fa-arrows mr-2"></i>Utiliser le coin supérieur droit des cartes pour les déplacer sur le tableau <br>
                    <i class="fa fa-arrows-h mr-2"></i>Utiliser le coin inférieur droit des cartes pour les redimensionner sur le tableau
                </small></p>
                <div class="action mt-3">
                    <button class="btn btn-outline-primary vs-component vs-button vs-button-primary vs-button-filled" @click="saveManagedWidgets()">
                        <span> Enregistrer </span>
                    </button>
                </div>
            </div>
        </div>
        <div style="width:100%;margin-top: 10px;height:100%;">
            <grid-layout :layout.sync="getResources"
                :colWidth="300"
                :col-num="6"
                :row-height="30"
                :is-draggable="isDraggable"
                :is-resizable="isResizable"
                :responsive="isResponsive"
                :is-mirrored="false"
                :vertical-compact="true"
                :prevent-collision="true"
                :margin="[5, 5]"
                :cols="{ lg: 6, md: 6, sm: 6, xs: 4, xxs: 1 }"
                :use-css-transforms="true"
                :key="refresh"
            >

                <grid-item v-for="(item, index) in getResources" :key="index"
                    :class="{ 'editMode' : manageWidgets }"
                    :autoSize="true"
                    :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i"
                    :static="isStatic" drag-allow-from=".item-drag"
                    style="border: 2px solid rgba(var(--vs-primary), 0.3) !important; border-radius: 5px;"
                    @moved="movedEvent"
                    @resized="resizeEvent">
                <!-- :style="{'transform': 'translate3d('+ item.x +'* 20px, 75px, 0px);'}" -->
                    <div class="item-drag" title="Cliquer pour déplacer la carte"></div>

                    <EmployeesWidget v-if="item.type == 'employeesWidget'" :api="'/api/referentiel/settings/images'"/>

                    <JobsWidget v-if="item.type == 'jobsWidget'" :api="'/api/referentiel/settings/images'"/>

                    <SkillsWidget v-if="item.type == 'skillsWidget'" :api="'/api/referentiel/settings/images'"/>

                    <RatingsWidget v-if="item.type == 'ratingsWidget'"/>

                    <LastSelfRatingsExternalWidget v-if="item.type == 'lastSelfRatingsExternalWidget'"/>

                    <ExternalRatingsWidget v-if="item.type == 'externalRatingsWidget'"/>

                    <EmployeesCreatedWidget v-if="item.type == 'employeesCreatedWidget'"/>

                    <EmployeesCreatedByUserWidget v-if="item.type == 'employeesCreatedByUserWidget'"/>

                    <JobsCreatedWidget v-if="item.type == 'jobsCreatedWidget'"/>

                    <JobsCreatedByUserWidget v-if="item.type == 'jobsCreatedByUserWidget'"/>

                    <OccupationskillsModifiedWidget v-if="item.type == 'occupationskillsModifiedWidget'"/>

                    <OccupationskillsModifiedByUserWidget v-if="item.type == 'occupationskillsModifiedByUserWidget'"/>

                    <RatingsInProgressWidget v-if="item.type == 'ratingsInProgressWidget'"/>

                    <RatingsToValidateWidget v-if="item.type == 'ratingsToValidateWidget'"/>

                    <SkillRatingsInProgressWidget v-if="item.type == 'skillRatingsInProgressWidget'" />

                    <SkillRatingsToValidateWidget v-if="item.type == 'skillRatingsToValidateWidget'" />
                </grid-item>
            </grid-layout>
        </div>
    </div>
</template>
<script>
// https://jbaysolutions.github.io/vue-grid-layout/


import EmployeesWidget from './widgets/EmployeesWidget';
import EmployeesCreatedWidget from './widgets/EmployeesCreatedWidget';
import EmployeesCreatedByUserWidget from './widgets/EmployeesCreatedByUserWidget';
import JobsCreatedWidget from './widgets/JobsCreatedWidget';
import JobsCreatedByUserWidget from './widgets/JobsCreatedByUserWidget';
import RatingsToValidateWidget from './widgets/RatingsToValidateWidget';
import LastSelfRatingsExternalWidget from './widgets/LastSelfRatingsExternalWidget';
import ExternalRatingsWidget from './widgets/ExternalRatingsWidget';
import RatingsInProgressWidget from './widgets/RatingsInProgressWidget';
import OccupationskillsModifiedWidget from './widgets/OccupationskillsModifiedWidget';
import OccupationskillsModifiedByUserWidget from './widgets/OccupationskillsModifiedByUserWidget';
import SkillRatingsInProgressWidget from './widgets/SkillRatingsInProgressWidget';
import SkillRatingsToValidateWidget from './widgets/SkillRatingsToValidateWidget';
import RatingsWidget from './widgets/RatingsWidget';
import JobsWidget from './widgets/JobsWidget';
import SkillsWidget from './widgets/SkillsWidget';
import { GridLayout, GridItem } from 'vue-grid-layout';

export default {
    name: 'gridview',
    components: {
        GridLayout,
        EmployeesCreatedByUserWidget,
        JobsCreatedByUserWidget,
        LastSelfRatingsExternalWidget,
        ExternalRatingsWidget,
        OccupationskillsModifiedByUserWidget,
        GridItem,
        OccupationskillsModifiedWidget,
        RatingsWidget,
        RatingsInProgressWidget,
        JobsCreatedWidget,
        RatingsToValidateWidget,
        EmployeesCreatedWidget,
        EmployeesWidget,
        SkillsWidget,
        JobsWidget,
        SkillRatingsInProgressWidget,
        SkillRatingsToValidateWidget
    },
    data () {
        return {
            dashboardId: 0,
            gotDashboard: false,
            manageWidgets: false,
            contenteditable: false,
            ratings: false,
            ratingsInProgress: false,
            ratingsToValidate: false,
            skillRatingsInProgress: false,
            skillRatingsToValidate: false,
            employeesCreated: false,
            lastSelfRatingsExternal: false,
            externalRatings: false,
            jobsCreated: false,
            occupationskillsModified: false,
            employeesCreatedByUser: false,
            jobsCreatedByUser: false,
            occupationskillsModifiedByUser: false,
            personnes: false,
            metiers: false,
            competences: false,
            isDraggable: true,
            isResizable: true,
            isResponsive: true,
            isStatic: false,
            getResources:[],
            refresh: 0,
            log: console.log
        }
    },
    created() {
        this.loadDashboard();
    },
    updated() {
        if(document.getElementById('ratingsCard')){
            this.ratings = true
        }
        if(document.getElementById('ratingsInProgressCard')){
            this.ratingsInProgress = true
        }
        if(document.getElementById('ratingsToValidateCard')){
            this.ratingsToValidate = true
        }
        if(document.getElementById('skillRatingsInProgressCard')){
            this.skillRatingsInProgress = true
        }
        if(document.getElementById('skillRatingsToValidateCard')){
            this.skillRatingsToValidate = true
        }
        if(document.getElementById('employeesCreatedByUserCard')){
            this.employeesCreatedByUser = true
        }
        if(document.getElementById('employeesCreatedCard')){
            this.employeesCreated = true
        }
        if(document.getElementById('jobsCreatedByUserCard')){
            this.jobsCreatedByUser = true
        }
        if(document.getElementById('jobsCreatedCard')){
            this.jobsCreated = true
        }
        if(document.getElementById('occupationskillsModifiedByUserCard')){
            this.occupationskillsModifiedByUser = true
        }
        if(document.getElementById('occupationskillsModifiedCard')){
            this.occupationskillsModified = true
        }
        if(document.getElementById('lastSelfRatingsExternalCard')){
            this.lastSelfRatingsExternal = true
        }
        if(document.getElementById('externalRatingsCard')){
            this.externalRatings = true
        }
        if(document.getElementById('employeesCard')){
            this.personnes = true
        }
        if(document.getElementById('jobsCard')){
            this.metiers = true
        }
        if(document.getElementById('skillsCard')){
            this.competences = true
        }
    },
    methods: {
        loadDashboard() {
            window.axios({
                method: 'get',
                url: '/api/dashboard'
            })
            .then(response => {

                let json = [];
                if(response.data[0]){
                    if(response.data[0].dashboard != null){
                        json = response.data[0].dashboard;
                        this.gotDashboard = true;
                        this.dashboardId = response.data[0].id;
                    }
                } else {
                    json = [
                        "{\"x\":0,\"y\":0,\"w\":2,\"h\":9,\"i\":1,\"moved\":false,\"type\":\"employeesWidget\"}",
                        "{\"x\":2,\"y\":0,\"w\":2,\"h\":9,\"i\":2,\"moved\":false,\"type\":\"jobsWidget\"}",
                        "{\"x\":4,\"y\":0,\"w\":2,\"h\":9,\"i\":3,\"moved\":false,\"type\":\"skillsWidget\"}",
                        "{\"x\":0,\"y\":10,\"w\":3,\"h\":9,\"i\":4,\"moved\":false,\"type\":\"ratingsWidget\"}",
                        "{\"x\":3,\"y\":10,\"w\":3,\"h\":9,\"i\":5,\"moved\":false,\"type\":\"ratingsInProgressWidget\"}",
                        "{\"x\":0,\"y\":20,\"w\":3,\"h\":9,\"i\":6,\"moved\":false,\"type\":\"lastSelfRatingsExternalWidget\"}",
                        "{\"x\":3,\"y\":20,\"w\":3,\"h\":9,\"i\":7,\"moved\":false,\"type\":\"externalRatingsWidget\"}"
                    ];
                }

                this.getResources = [];
                json.forEach(element => this.getResources.push(JSON.parse(element)));
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        clickCheckbox(nameCard){
            // Retrait de la carte
            if(document.getElementById(nameCard+'Card')) {
                let i = 0

                this.getResources.forEach(element => {
                    let name = element['type'].split("Widget");
                    if(name[0] == nameCard){
                        this.getResources.splice(i,1)
                    }
                    i = i + 1
                });
            }
            // Ajout d'une carte
            else {
                let width = 3;
                let height = 9;
                if (nameCard == 'employees' || nameCard == 'jobs' || nameCard == 'skills' ) {
                    width = 2;
                }
                let g = Object.keys(this.getResources).length + 1;
                let k = { 'x': 0, 'y': this.getResourceCurrentY() + 1, 'w': width, 'h': height, 'i': g, "moved":true, 'type': nameCard + 'Widget' }
                this.getResources.push(k);
            }
        },
        getResourceCurrentY() {
            let currentY = 0;
            for (var r of this.getResources) {
                if (r.y > currentY) {
                    currentY = r.y;
                }
            }
            return currentY;
        },
        displayManageWidget() {
            this.manageWidgets = !this.manageWidgets
        },
        saveManagedWidgets() {
            this.manageWidgets = false
            this.save();
        },
        movedEvent() {
            this.save();
        },
        resizeEvent(i, newH, newW, newHPx, newWPx) {
            setTimeout(() => {
                this.getResources.find(r => r.i == i).h = 9;
                this.save();
            }, 5)
        },
        save() {
            if(!this.gotDashboard){
                window.axios({
                    method: 'post',
                    url: '/api/dashboard',
                    params: { dashboard: this.getResources }
                })
                .then(response => {
                    this.notifySuccess('Succès', 'Dashboard enregistré')
                })
                .catch((err) => {
                    console.log(err)
                    this.notifyError(err, `Une erreur est survenue`)
                })
                }
            else{
                window.axios({
                    method: 'patch',
                    url: '/api/dashboard/'+this.dashboardId,
                    params: { dashboard: this.getResources }
                })
                .then(response => {
                    this.notifySuccess('Succès', 'Dashboard enregistré')
                })
                .catch((err) => {
                    console.log(err)
                    this.notifyError(err, `Une erreur est survenue`)
                })
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.editMode {
    background-color: #fafafa;
    border-radius: 5px;
    opacity: 0.5;
}
.cardTdbord{
    position: absolute;
    background:white;
    z-index: 200;
    padding: 25px;
    border-radius: 6px;
    top: 125px;
    right: 4%;
    box-shadow: 0 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.item-drag {
	width: 15px;
	height: 26px;
	top: 8px;
	right: 8px;
	position: absolute;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGUlEQVQI12NgAIJz507/Z4AxVqxYAuEgAwD26QnAz+WLlgAAAABJRU5ErkJggg==);
	cursor: grabbing;
    z-index: 999;
    opacity: 0.5;
    background-color: rgba(255, 255, 255, 0.2);
}
.item-drag:hover {
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}
</style>

<style>
.vue-grid-layout .vs-con-table td,
.vue-grid-layout .vs-table--tbody-table .tr-values td {
    padding: 2px 8px;
}
</style>
