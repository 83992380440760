<template>
    <section class="experience">
        <h3 class="mb-8">Métiers occupés ou en préparation :</h3>

        <div>
            <vs-button
                v-if="canUpdate()"
                id="addTraining"
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
                @click="createResource()"
                style="margin-bottom: 2em;"
            >
                Ajouter un métier
            </vs-button>
            <vs-button
                v-if="isEvaluable.length && canUpdate() && planAllow('eval-tiers-croise')"
                id="inviteTiers"
                color="primary"
                type="border"
                @click="inviteTiers"
                class="mb-2 ml-2"
            >
                Inviter un tiers à évaluer
            </vs-button>

            <div v-else-if="!planAllow('eval-tiers-croise') && !shouldHideOptions()" style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                <vs-button
                    id="inviteTiers"
                    color="primary"
                    type="border"
                    class="mb-2 ml-2"
                    disabled="true"
                >
                    Inviter un tiers à évaluer
                </vs-button>
                <vs-icon class="button-bullet" bg="#FF9B3A" round >
                    <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                </vs-icon>
            </div>
        </div>

        <DialogPrompt
            :active.sync="showPopup"
            :title="getDataPrompt.title"
            cancel-text="Annuler"
            accept-text="Enregistrer"
            :button-accept="getDataPrompt.btnAccept"
            :is-valid="!hasError"
            @cancel="resetResource"
            @close="resetResource"
            @accept="storeResource"
        >
            <div class="con-exemple-prompt">
                <div
                    v-if="this.formType == 'create' || resource.occupation"
                    class="vx-row mb-6"
                >
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Métier déjà existant :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect
                            v-if="occupations.length > 0"
                            v-model="resource.occupation"
                            name="occupationTitle"
                            :options="occupations"
                            :multiple="false"
                            group-label="group"
                            group-values="items"
                            placeholder="Recherche ..."
                            track-by="id"
                            label="title"
                            select-label
                            selected-label
                            deselect-label
                            :loading="occupationsLoading"
                        >
                            <span slot="noResult">Aucun résultat.</span>
                        </multiselect>
                    </div>
                </div>
                <div v-if="!resource.occupation">
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full">
                            <span>Libellé du métier* :</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <vs-input
                                v-model="resource.title"
                                name="title"
                                class="w-full"
                            />
                        </div>
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Date de début* :</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!--datepicker
                            v-model="resource.start_date"
                            name="start_date"
                            class="w-full"
                            :language="fr"
                            monday-first
                        /-->
                        <vs-input
                            type="date"
                            v-model="resource.start_date"
                            name="start_date"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Date de fin </span><span v-if="!resource.occupation">*</span><span>:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!--datepicker
                            v-model="resource.end_date"
                            name="end_date"
                            class="w-full"
                            :language="fr"
                            monday-first
                        /-->
                        <vs-input
                            type="date"
                            v-model="resource.end_date"
                            name="end_date"
                            class="w-full"
                            description-text="Un métier est évaluable (y compris par un tiers) et auto-évaluable jusqu'à sa date de fin"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span style="float: left;line-height: 26px;">
                            Pièce jointe &nbsp;
                        </span>
                        <vs-chip v-if="!documentLicence" color="secondary">
                            <b>Gratuit jusqu'à {{ postMaxSizePerPerson }} Mo (PDF uniquement)</b>
                        </vs-chip>
                    </div>
                    <div v-if="resource.doc_path" class="vx-col sm:w-2/3 w-full">
                        <div
                            class="inline mr-1 float-left cursor-pointer"
                        >
                            <a @click.prevent="download(resource.doc_path)">{{ resource.doc_path.split('/').pop() }}</a>
                        </div>
                        <vs-button
                            :id="'deleteDoc_' + resource.id"
                            color="primary"
                            type="border"
                            size="small"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="removeDocPath(resource)"
                            style="display: inline; margin-top: -0.5rem;"
                        />
                    </div>
                    <div v-else class="vx-col sm:w-2/3 w-full">
                        <vs-input
                            type="file"
                            :accept="acceptExtensions(documentLicence ? 'all' : 'pdf')"
                            name="doc_path"
                            class="w-full"
                            @change="selectFile"
                            :danger="this.fileError.message"
                            :danger-text="this.fileError.message"
                        />
                    </div>
                </div>
            </div>
        </DialogPrompt>

        <DialogPrompt
            :active.sync="showPopupFullStorage"
            title="Votre espace de stockage est plein"
            cancel-text="Annuler"
            accept-text="OK"
            @close="showPopupFullStorage = false"
            @accept="showPopupFullStorage = false"
        >
            <div class="con-exemple-prompt">
                <p>{{ popupFullStorageMessage }}</p>
            </div>
        </DialogPrompt>

        <vs-table
            :sst="true"
            @sort="eventSortByCol"
            :data="items"
            no-data-text="Aucune donnée à afficher"
            style="width:100%;"
        >
            <template slot="thead">
                <vs-th>
                    <Sort name="title" @sort="eventSortByCol">Libellé</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="start_date" @sort="eventSortByCol">Date de début</Sort>
                </vs-th>
                <vs-th>
                    <Sort name="end_date" @sort="eventSortByCol">Date de fin</Sort>
                </vs-th>
                <vs-th>
                    Document
                </vs-th>
                <vs-th v-if="canUpdate()">
                    Actions
                </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr
                    v-for="(item, indextr) in data"
                    :key="indextr"
                    :data="item"
                >
                    <vs-td :data="item.title">
                        {{ item.title }}
                    </vs-td>
                    <vs-td :data="item.start_date">
                        {{ item.start_date || "-" | date }}
                    </vs-td>
                    <vs-td :data="item.end_date">
                        {{ item.end_date || "-" | date }}
                    </vs-td>
                    <vs-td>
                        <i
                            v-if="item.doc_path"
                            :class="'fa fa-file'+(item.doc_path.split('.').pop() === 'pdf' ? '-pdf' : '-o')"
                            style="font-size: 2rem; color: rgba(var(--vs-primary),1)"
                            @click="download(item.doc_path)"
                        ></i>
                    </vs-td>
                    <vs-td v-if="canUpdate()">
                        <vs-button
                            v-if="can('update', 'employees')"
                            :id="'modifyJob_' + indextr"
                            v-tooltip="'Modifier'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-edit"
                            @click="updateResource(item)"
                        />
                        <vs-button
                            v-if="can('update', 'employees')"
                            :id="'deleteJob_' + indextr"
                            v-tooltip="'Supprimer'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            style="margin-left: 1rem;"
                            @click="deleteResource(item)"
                        />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </section>
</template>

<script>
import Multiselect from "vue-multiselect";
//import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import { format } from 'date-fns'
import CrownIcon from '@components/svg/CrownIcon';
export default {
    name: "EmployeeOccupations",
    components: {
        Multiselect,
        //Datepicker,
        CrownIcon
    },
    props: {
        api: {
            type: String
        },
        employee: {
            required: false,
            type: Object
        },
        documentLicence: {
            required: false,
            type: Boolean
        }
    },
    data() {
        return {
            fr: fr,
            log: console.log,
            resource: {},
            showPopup: false,
            showPopupFullStorage: false,
            popupFullStorageMessage: null,
            loading: true,
            formError: true,
            formType: "create",
            occupations: [],
            occupationsLoading: true,
            prompt: {
                show: {
                    title: "Consulter un métier",
                    btnAccept: "false"
                },
                create: {
                    title: "Ajouter un métier",
                    btnAccept: "filled"
                },
                update: {
                    title: "Modifier un métier",
                    btnAccept: "filled"
                }
            },
            file: null,
            fileError: {
                message: null,
            },
            postMaxSizePerPerson: process.env.MIX_TOTAL_SPACE_WITHOUT_LICENCE,
            maxFileSize: this.documentLicence ? process.env.MIX_PHP_MAX_FILESIZE : process.env.MIX_CAREER_FILE_MAX_FILESIZE_WITHOUT_LICENCE
        };
    },
    watch: {},
    computed: {
        hasError() {
            let error = true;

            if (this.resource.occupation) {
                error = !this.resource.start_date;
            } else {
                error =
                    !this.resource.title ||
                    !this.resource.start_date ||
                    !this.resource.end_date;
            }

            return error;
        },
        isEvaluable() {
            // Seuls les métiers en cours sont proposés dans la form d'invitation
            let current = [];
            if (!this.loading) {
                this.items.forEach(occupation => {
                    if (occupation.end_date == null || (new Date(occupation.end_date)).getTime() > Date.now()) {
                        current.push(occupation)
                    }
                });
            }
            return current;
        }
    },
    methods: {
        selectFile(e) {
            this.fileError.message = null
            if (e.target.files.length !== 0) {
                if (e.target.files[0].size >= this.maxFileSize*1024*1024) {
                    this.fileError.message = 'La taille du fichier de '+this.getReadableFileSizeString(e.target.files[0].size)+' est supérieure à la valeur autorisée de '+this.maxFileSize+' Mo'
                }
                else this.file = e.target.files[0]
            }
        },
        removeDocPath(resource) {
            let jobClass = 'EmployeeOccupation'
            if (!resource.occupation) jobClass = 'OldOccupation'
            window
                .axios({
                    method: "post",
                    url: "/api/gestion/employees/career/doc/delete",
                    params: { path: resource.doc_path, class: jobClass },
                })
                .then(response => {
                    this.loadItems()
                    this.$emit('change')
                    this.resource.doc_path = null
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`, true);
                });
        },
        download(path) {
            window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees/career/doc",
                    params: { path: path },
                    responseType: 'blob', // Indique que la réponse sera un fichier
                })
                .then(response => {
                    console.log('Download DONE !!')
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', path.split('/').pop()); // Spécifie le nom du fichier à télécharger
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(async (err) => {
                    let errorResponse = await err.response.data.text();
                    const errorObj = JSON.parse(errorResponse);
                    err.response.status = err.code
                    err.message = errorObj.message
                    this.notifyError(err, `Une erreur est survenue`, true);
                });
        },
        loadOccupations() {
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: { perpage: 999, sortBy: {"title": "asc"}, include: []}
                })
                .then(response => {
                    this.occupations = this.groupForMultiselect(response.data.data, ['type']);
                    this.occupationsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        canUpdate() {
            return this.canMulti("update", "employees", this.employee.services.map(s => s.id));
        },

        createResource() {
            this.resourceReadonly = false;
            this.resetResource();
            console.log(this.resource);
            this.formType = "create";
            this.showPopup = true;
        },

        updateResource(resource) {
            this.resourceReadonly = false;
            this.resetResource();
            this.setResource(resource);
            console.log(resource);
            this.formType = "update";
            this.showPopup = true;
        },

        storeResource() {
            let data = JSON.parse(JSON.stringify(this.resource));
            data.occupation_id = this.resource.occupation?.id;
            data.end_date = this.resource.end_date ? this.getDate(this.resource.end_date) : this.resource.end_date;
            data.file = this.file

            this.localLoading()
            if (data.id) {
                this.apiUpdate(
                    data,
                    this.resource.id,
                    resp => {
                        if (!resp.data.availableSizeValidation) {
                            this.showPopupFullStorage = true
                            this.popupFullStorageMessage = resp.data.availableSizeValidationMessages
                        }
                        this.loadItems(),
                        this.$emit('change')
                        this.endLocalLoading()
                    },
                    (err) => {
                        this.showPopup = true
                        this.endLocalLoading()
                    },
                    {'Content-Type': 'multipart/form-data'}
                );
            } else {
                this.apiCreate(
                    data,
                    resp => {
                        if (!resp.data.availableSizeValidation) {
                            this.showPopupFullStorage = true
                            this.popupFullStorageMessage = resp.data.availableSizeValidationMessages
                        }
                        this.loadItems(),
                        this.$emit('change')
                        this.endLocalLoading()
                    },
                    (err) => {
                        this.showPopup = true
                        this.endLocalLoading()
                    },
                    {'Content-Type': 'multipart/form-data'}
                );
            }
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => {
                this.loadItems(),
                this.$emit('change')
            });
        },

        setResource(data) {
            let dataTemp = JSON.parse(JSON.stringify(data));
            dataTemp.start_date = this.getDate(dataTemp.start_date);
            dataTemp.end_date = dataTemp.end_date ? this.getDate(dataTemp.end_date) : dataTemp.end_date;
            this.resource = dataTemp;
        },

        resetResource() {
            this.resource = {
                occupation: null,
                start_date: format(new Date(), "yyyy-MM-dd"),
                end_date: null,
                title: null,
                doc_path: null,
            };
        },
        inviteTiers() {
            this.$emit('invite-tiers');
        }
    },
    created() {
        this.apiParams = {
            sortBy: {"end_date": "desc"}
        };
        this.resetResource();
        this.loadItems();
        this.loadOccupations();
    }
};
</script>

<style lang="scss" scoped>
::v-deep .con-text-validation {
    font-size: 0.8rem;
}
</style>
