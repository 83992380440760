var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.planAllow("publication-contenu-peda")
        ? _c("LimitatedPlan", { staticClass: "mb-10" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { attrs: { "vs-align": "left", "vs-w": "6" } },
            [
              _vm.planAllow("publication-contenu-peda")
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        id: "addResource",
                        color: "primary",
                        type: "filled",
                        "icon-pack": "feather",
                        icon: "icon-plus"
                      },
                      on: { click: _vm.showFormCreate }
                    },
                    [
                      _vm._v(
                        "\n                Ajouter une nouvelle ressource\n            "
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticStyle: { position: "relative", float: "left" },
                      attrs: {
                        title: "Votre formule n'inclut pas cette fonctionnalité"
                      }
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            id: "addResource",
                            color: "primary",
                            type: "filled",
                            "icon-pack": "feather",
                            icon: "icon-plus",
                            disabled: "true"
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Ajouter une nouvelle ressource\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-icon",
                        {
                          staticClass: "button-bullet",
                          attrs: { bg: "#FF9B3A", round: "" }
                        },
                        [
                          _c("CrownIcon", {
                            staticStyle: {
                              width: "16px",
                              height: "16px",
                              padding: "3px"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "vs-prompt",
                {
                  attrs: {
                    active: _vm.showPopup,
                    title: _vm.getDataPrompt.title,
                    "cancel-text": "Annuler",
                    "accept-text": "Enregistrer",
                    "button-accept": _vm.getDataPrompt.btnAccept,
                    "is-valid": !_vm.formError
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.showPopup = $event
                    },
                    cancel: _vm.resetResource,
                    close: _vm.resetResource,
                    accept: _vm.saveResource
                  }
                },
                [
                  _c("TrainingForm", {
                    attrs: { type: _vm.formType },
                    on: { error: _vm.setFormError },
                    model: {
                      value: _vm.resource,
                      callback: function($$v) {
                        _vm.resource = $$v
                      },
                      expression: "resource"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("vs-col", {
            attrs: { "vs-type": "flex", "vs-align": "left", "vs-w": "6" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            sst: true,
            search: "",
            "no-data-text": _vm.loading
              ? "Chargement de vos données..."
              : "Aucune donnée à afficher",
            data: _vm.itemsData,
            loading: true
          },
          on: { search: _vm.eventSearchItems, sort: _vm.eventSortByCol }
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "document_date" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Date du document")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "thematic" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Thématique")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "title" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Titre")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "duration" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Durée (min)")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-th", [_vm._v("\n                Support\n            ")]),
              _vm._v(" "),
              _c("vs-th")
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.items.data, function(item, i) {
            return _c(
              "vs-tr",
              { key: i },
              [
                _c("vs-td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.get(item, ":document_date")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("vs-td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.get(item, ":thematic")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("vs-td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.get(item, ":title")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("vs-td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.get(item, ":duration")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "vs-td",
                  { attrs: { data: _vm.get(item, ":original_filename") } },
                  [
                    item.original_filename
                      ? _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.downloadClickHandle(item)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-paperclip" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.link
                      ? _c(
                          "a",
                          {
                            attrs: { href: "#", target: "_blank" },
                            on: { click: item.link }
                          },
                          [_c("i", { staticClass: "fa fa-link" })]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("vs-td", { staticClass: "action" }, [
                  _c(
                    "div",
                    { staticClass: "action-wrapper" },
                    [
                      _vm.can("update", "trainings", item.id) &&
                      _vm.planAllow("publication-contenu-peda")
                        ? _c("vs-button", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Modifier",
                                expression: "'Modifier'"
                              }
                            ],
                            attrs: {
                              color: "primary",
                              type: "border",
                              "icon-pack": "feather",
                              icon: "icon-edit"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.showFormUpdate(item)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.can("delete", "trainings", item.id) &&
                      _vm.planAllow("publication-contenu-peda")
                        ? _c("vs-button", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Supprimer",
                                expression: "'Supprimer'"
                              }
                            ],
                            attrs: {
                              color: "primary",
                              type: "border",
                              "icon-pack": "feather",
                              icon: "icon-trash"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.removeResource(item)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _vm.items.total > 0
            ? _c(
                "vs-col",
                { staticClass: "mt-4" },
                [
                  _c("vs-pagination", {
                    attrs: {
                      total: Math.ceil(_vm.items.total / _vm.apiParams.perpage)
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }