<template>
    <div v-if="isInit && employee">
        <vs-row class="employee_info">
            <vs-alert
                :active="!currentRating.length && !currentSkillReviews.length"
                class="mb-8"
                icon-pack="feather"
                icon="icon-alert-triangle"
                color="warning"
            >
                Vous n'avez pas encore évalué cette personne.
            </vs-alert>

            <vs-col>
                <!-- Header -->
                <vs-row class="mb-10">
                    <!-- Info employee -->
                    <vs-col v-if="employee" vs-lg="4">
                        <ul class="employee-info">
                            <li class="employee-name">
                                <p>
                                    Nom :
                                    <b class="uppercase">{{ employee.last_name }}</b>
                                </p>
                                <p>
                                    Prénom : <b>{{ employee.first_name }}</b>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Service(s) de référence :
                                    <b>{{ employee.services.map((s) => s.title).join(", ") }}</b>
                                </p>
                            </li>
                        </ul>
                    </vs-col>

                    <!-- Action buttons -->
                    <vs-col vs-lg="8" class="action-buttons">
                        <vs-row vs-justify="flex-end">
                            <!-- Action sur Bilan -->
                            <vs-button
                                v-if="canContinueSkillReview"
                                id="addBilan"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-check-square"
                                @click="redirectSkillReviewContinue"
                            >
                                Poursuivre le {{ skillRatingNames.singular.toLowerCase() }} en cours
                            </vs-button>

                            <vs-button
                                v-else-if="canAddSkillReview"
                                id="addBilan"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-check-square"
                                @click="redirectSkillReviewCreation"
                            >
                                Créer un {{ skillRatingNames.singular.toLowerCase() }}
                            </vs-button>

                            <vs-button
                                v-if="canModifySkillReview"
                                id="modifyRating"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="editSkillRatingPrompt = true"
                            >
                                Modifier un {{ skillRatingNames.singular.toLowerCase() }}
                            </vs-button>
                            <DialogPrompt
                                :active.sync="editSkillRatingPrompt"
                                :title="`Modifier un ${skillRatingNames.singular} non validé`"
                                cancel-text="Annuler"
                                accept-text="Modifier"
                                :is-valid="true"
                                @cancel="cancelEditSkillRating"
                                @close="cancelEditSkillRating"
                                @accept="redirectSkillReviewEdition"
                            >
                                <div class="con-exemple-prompt">
                                    <multiselect
                                        v-model="editedSkillRating"
                                        label="rating_date"
                                        :options="unvalidatedSkillReview"
                                        placeholder="Recherche ..."
                                        select-label
                                        select-group-label
                                        selected-label
                                        deselect-label
                                        deselect-group-label
                                    />
                                </div>
                            </DialogPrompt>

                            <vs-button
                                v-if="canDeleteSkillReview"
                                @click="deleteSkillRatingPrompt = true"
                                id="deleteSkillReview"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                            >
                                Supprimer un {{ skillRatingNames.singular }}
                            </vs-button>
                            <DialogPrompt
                                :active.sync="deleteSkillRatingPrompt"
                                :title="`Supprimer un ${skillRatingNames.singular}`"
                                cancel-text="Annuler"
                                accept-text="Supprimer"
                                :is-valid="true"
                                @cancel="cancelDeleteSkillRating"
                                @close="cancelDeleteSkillRating"
                                @accept="deleteSkillRating"
                            >
                                <div class="con-exemple-prompt">
                                    <multiselect
                                        v-model="deletedSkillRating"
                                        label="rating_date"
                                        :options="deletableSkillReview"
                                        placeholder="Recherche ..."
                                        select-label
                                        select-group-label
                                        selected-label
                                        deselect-label
                                        deselect-group-label
                                    />
                                </div>
                            </DialogPrompt>

                            <!-- Action sur projet personnalisé -->
                            <vs-button
                                v-if="canContinueRating"
                                @click="redirectPursue"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-check-square"
                            >
                                Poursuivre le {{ projectNames.singular.toLowerCase() }} en cours
                            </vs-button>

                            <!-- Add rating -->
                            <vs-button
                                v-else-if="canAddRating"
                                id="addRating"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-check-square"
                                @click="redirectCreation"
                            >
                                Ajouter un {{ projectNames.singular.toLowerCase() }}
                            </vs-button>

                            <vs-button
                                v-if="canModifyRating"
                                id="modifyRating"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="editRatingPrompt = true"
                            >
                                Modifier un {{ projectNames.singular.toLowerCase() }}
                            </vs-button>
                            <DialogPrompt
                                :active.sync="editRatingPrompt"
                                :title="`Modifier un ${projectNames.singular} non validé`"
                                cancel-text="Annuler"
                                accept-text="Modifier"
                                :is-valid="true"
                                @cancel="cancelEditRating"
                                @close="cancelEditRating"
                                @accept="redirectEdition"
                            >
                                <div class="con-exemple-prompt">
                                    <multiselect
                                        v-model="editedRating"
                                        label="rating_date"
                                        :options="unvalidatedRatings"
                                        placeholder="Recherche ..."
                                        select-label
                                        select-group-label
                                        selected-label
                                        deselect-label
                                        deselect-group-label
                                    />
                                </div>
                            </DialogPrompt>

                            <vs-button
                                v-if="canDeleteRating"
                                @click="deleteRatingPrompt = true"
                                id="deleteRating"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                            >
                                Supprimer un {{ projectNames.singular }}
                            </vs-button>
                            <DialogPrompt
                                :active.sync="deleteRatingPrompt"
                                :title="`Supprimer un ${projectNames.singular}`"
                                cancel-text="Annuler"
                                accept-text="Supprimer"
                                :is-valid="true"
                                @cancel="cancelDeleteRating"
                                @close="cancelDeleteRating"
                                @accept="deleteRating"
                            >
                                <div class="con-exemple-prompt">
                                    <multiselect
                                        v-model="deletedRating"
                                        label="rating_date"
                                        :options="deletableRating"
                                        placeholder="Recherche ..."
                                        select-label
                                        select-group-label
                                        selected-label
                                        deselect-label
                                        deselect-group-label
                                    />
                                </div>
                            </DialogPrompt>

                            <!-- Export rating -->
                            <vs-button
                                v-if="canExportRating"
                                @click="exportRatingPrompt = true"
                                id="export-pdf-button"
                                :class="['vs-con-loading__container', { exportPDF: 'vs-con-loading__container' }]"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-download"
                            >
                                Export PDF
                            </vs-button>
                            <DialogPrompt
                                :active.sync="exportRatingPrompt"
                                :title="`Exporter en PDF`"
                                cancel-text="Annuler"
                                accept-text="Exporter"
                                :is-valid="true"
                                @cancel="cancelExportRating"
                                @close="cancelExportRating"
                                @accept="initExport()"
                            >
                                <div class="con-exemple-prompt">
                                    <multiselect
                                        v-model="exportRatingDate"
                                        label="title"
                                        :options="exportRating"
                                        placeholder="Recherche ..."
                                        select-label
                                        select-group-label
                                        selected-label
                                        deselect-label
                                        deselect-group-label
                                    >
                                        <span slot="noOptions">Aucun résultat</span>
                                    </multiselect>
                                </div>
                            </DialogPrompt>
                        </vs-row>
                    </vs-col>
                </vs-row>

                <!-- Main -->
                <vs-row>
                    <!-- Select ratings -->
                    <vs-row class="mb-10" v-if="can('read', 'ratings')">
                        <vs-col vs-lg="2" vs-sm="12" class="mb-5">
                            <strong>{{ projectNames.plural }}</strong> :
                        </vs-col>
                        <vs-col vs-lg="10" vs-sm="12">
                            <multiselect
                                v-model="selectedRatings"
                                :options="currentRating"
                                multiple
                                placeholder="Recherche ..."
                                track-by="title"
                                label="title"
                                select-label
                                select-group-label
                                selected-label
                                deselect-label
                                deselect-group-label
                            >
                                <span slot="noOptions">Aucun résultat</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mb-10" v-if="can('read', 'ratings')">
                        <vs-col vs-lg="2" vs-sm="12" class="mb-5">
                            <strong>Auto-évaluation du {{ projectNames.singular }}</strong>
                            :
                        </vs-col>
                        <vs-col vs-lg="10" vs-sm="12">
                            <multiselect
                                v-model="selectedAutoRating"
                                :options="selfRatingList"
                                placeholder="Recherche ..."
                                track-by="title"
                                label="title"
                                select-label
                                select-group-label
                                selected-label
                                deselect-label
                                deselect-group-label
                            >
                                <span slot="noOptions">Aucun résultat</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mb-10" v-if="can('read', 'skill_reviews')">
                        <vs-col vs-lg="2" vs-sm="12" class="mb-5">
                            <strong>{{ skillRatingNames.singular }}</strong> :
                        </vs-col>
                        <vs-col vs-lg="10" vs-sm="12">
                            <multiselect
                                v-model="selectedSkillReview"
                                :options="currentSkillReviews"
                                multiple
                                placeholder="Recherche ..."
                                track-by="title"
                                label="title"
                                select-label
                                select-group-label
                                selected-label
                                deselect-label
                                deselect-group-label
                            >
                                <span slot="noOptions">Aucun résultat</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mb-10">
                        <vs-col vs-lg="2" vs-sm="12" class="mb-5"> <strong>Auto-évaluation Portail</strong> : </vs-col>
                        <vs-col vs-lg="10" vs-sm="12">
                            <multiselect
                                v-model="selectedMobilityRatings"
                                :options="mobilityRatings"
                                multiple
                                placeholder="Recherche ..."
                                track-by="title"
                                label="title"
                                select-label
                                select-group-label
                                selected-label
                                deselect-label
                                deselect-group-label
                            >
                                <span slot="noOptions">Aucun résultat</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mb-10">
                        <vs-col vs-lg="2" vs-sm="12" class="mb-5"> <strong>Evaluation(s) Portail</strong> : </vs-col>
                        <vs-col vs-lg="10" vs-sm="12">
                            <multiselect
                                v-model="selectedExternalRatings"
                                :options="externalRatings"
                                multiple
                                placeholder="Recherche ..."
                                track-by="title"
                                label="title"
                                select-label
                                select-group-label
                                selected-label
                                deselect-label
                                deselect-group-label
                            >
                                <span slot="noOptions">Aucun résultat</span>
                            </multiselect>
                        </vs-col>
                    </vs-row>

                    <!-- Skills charts (Main chart) -->
                    <vs-row v-if="!employeeOccupationSkillRatingsOnly" class="mb-6" style="position: relative">
                        <div class="vx-col w-full">
                            <div class="chart main" id="skills">
                                <rating-chart
                                    :employee="employee"
                                    class="main-chart"
                                    :options="skillRatingChart.options"
                                    :chart-data="skillRatingChart.data"
                                />
                            </div>
                        </div>
                    </vs-row>
                </vs-row>

            </vs-col>
        </vs-row>

        <!-- Collapse items -->
        <vs-row class="charts text-primary">
            <vs-col vs-w="12" style="position: relative">
                <vs-collapse>
                    <!-- Occupations -->
                    <vs-collapse-item
                        v-if="showRatingsHaveOccupations"
                        :open="employeeOccupationSkillRatingsOnly"
                        id="employee-occupations-charts"
                        icon-arrow="arrow_downward"
                    >
                        <div slot="header" class="collapse-header">
                            <p class="bold text-primary">Métiers occupés :&nbsp;</p>
                        </div>

                        <div v-if="showRatings.length">
                            <!-- Switch Occupation (skills) vs OccupationSkills (skills) vs Occupation (occupationSkills) -->
                            <vs-row class="mb-5" vs-type="flex" vs-w="12">
                                <vs-col vs-lg="2" vs-sm="4">
                                    <vs-radio
                                        v-model="switchOccupationsRadar"
                                        id="switchOccupationRadio_occupations"
                                        vs-name="switchOccupationRadio"
                                        vs-value="occupations"
                                        :disabled="employeeOccupationSkillRatingsOnly"
                                        >Métier</vs-radio
                                    >
                                </vs-col>
                                <vs-col vs-lg="2" vs-sm="4">
                                    <vs-radio
                                        v-model="switchOccupationsRadar"
                                        id="switchOccupationRadio_occupationSkills"
                                        vs-name="switchOccupationRadio"
                                        vs-value="occupationSkills"
                                        :disabled="employeeOccupationSkillRatingsOnly"
                                        >Savoir-faire</vs-radio
                                    >
                                </vs-col>
                                <vs-col vs-lg="8" vs-sm="4" class="flex">
                                    <vs-radio
                                        v-model="switchOccupationsRadar"
                                        id="switchOccupationRadio_occupationSkillsLevels"
                                        vs-name="switchOccupationRadio"
                                        vs-value="occupationSkillsLevels"
                                    >
                                        Savoir-faire des métiers
                                    </vs-radio>
                                    <div>
                                        <ms-tooltip :text="'Le score de maîtrise est la moyenne des savoir-faire maîtrisés pondérée suivant le niveau de maîtrise'" position="right">
                                            <vs-button
                                                radius
                                                color="primary"
                                                type="flat"
                                                icon="info"
                                                class="large-icon"
                                            ></vs-button>
                                        </ms-tooltip>
                                    </div>
                                </vs-col>
                            </vs-row>

                            <!-- Occupation - List of occupation name + score -->
                            <vs-row v-if="switchOccupationsRadar === 'occupations'">
                                <vs-col>
                                    <ul class="columns">
                                        <li class="text-primary" v-for="(chart, i) in occupationCharts" :key="`occupation-${i}`">
                                            <div
                                                v-html="formatHtmlTitle(chart)"
                                                v-tooltip.top-start="{
                                                    content: tooltipDescOccupations[i],
                                                }"
                                            ></div>
                                        </li>
                                    </ul>
                                </vs-col>
                            </vs-row>
                        </div>
                        <!-- No last rating occupations -->
                        <div v-else>
                            <ul class="columns">
                                <li class="text-primary" v-for="(occupation, i) in employee.occupations" :key="`occTitle-${i}`">
                                    - {{ occupation.title }}
                                </li>
                            </ul>
                        </div>

                        <vs-row
                            v-if="showRatingsHaveOccupations && switchOccupationsRadar === 'occupations'"
                            class="chart-container"
                        >
                            <template v-for="(chart, i) in occupationCharts">
                                <vs-col
                                    vs-lg="6"
                                    vs-sm="12"
                                    class="mt-6 chart"
                                    v-tooltip="{ content: tooltipDescOccupations[i] }"
                                >
                                    <rating-chart
                                        :employee="employee"
                                        :options="chart.options"
                                        :chart-data="chart.data"
                                        :id="'chart-occupation-' + i"
                                        class="rating"
                                    />
                                </vs-col>
                            </template>
                        </vs-row>
                        <!-- Occupation_skill charts of Skills -->
                        <div v-else-if="showRatingsHaveOccupations && switchOccupationsRadar === 'occupationSkills'">
                            <vs-row
                                v-for="(occupationChart, i) in occupationCharts"
                                :key="`${i}-occupationChart.occupationId`"
                            >
                                <vs-col>
                                    <div v-html="formatHtmlTitle(occupationChart, false)"></div>
                                </vs-col>

                                <template
                                    v-for="(osChart, i) in occupationSkillsOccupationCharts[
                                        occupationChart.occupationId
                                    ]"
                                >
                                    <vs-col vs-lg="6" vs-sm="12" class="mt-6 chart chart-container">
                                        <rating-chart
                                            :employee="employee"
                                            :options="osChart.options"
                                            :chart-data="osChart.data"
                                            :id="
                                                'chart-occupation-' +
                                                occupationChart.occupationId +
                                                '-OccSkillIndex-' +
                                                i
                                            "
                                            class="rating"
                                        />
                                    </vs-col>
                                </template>
                            </vs-row>
                        </div>
                        <!-- Occupation charts of OccupationSkills -->
                        <vs-row
                            v-if="showRatingsHaveOccupations && switchOccupationsRadar === 'occupationSkillsLevels'"
                            class="chart-container"
                        >
                            <vs-col
                                v-for="(chart, i) in occupationChartsOfOccskills"
                                :key="`chart-occupation-${i}`"
                                vs-lg="6"
                                vs-sm="12"
                                class="mt-6 chart"
                                v-tooltip="{ content: tooltipDescJobs[i] }"
                            >
                                <rating-chart
                                    :employee="employee"
                                    :options="chart.options"
                                    :chart-data="chart.data"
                                    :id="'chart-occupationSkill-' + i"
                                    class="rating"
                                />
                            </vs-col>
                        </vs-row>
                    </vs-collapse-item>

                    <!-- Postes / Savoir-faire -->
                    <vs-collapse-item
                        v-if="showRatingsHaveJobs"
                        :open="employeeOccupationSkillRatingsOnly"
                        id="employee-jobs-charts"
                        icon-arrow="arrow_downward"
                    >
                        <div slot="header" class="collapse-header">
                            <p class="bold text-primary">Postes occupés :&nbsp;</p>
                        </div>

                        <div v-if="showRatings.length">
                            <!-- Switch Poste vs Savoir-faire -->
                            <vs-row class="mb-5" vs-type="flex" vs-w="12">
                                <vs-col vs-lg="2" vs-sm="4">
                                    <vs-radio
                                        v-model="switchJobsRadar"
                                        id="switchJobRadio_jobs"
                                        vs-name="switchJobRadio"
                                        vs-value="jobs"
                                        :disabled="employeeOccupationSkillRatingsOnly"
                                        >Poste</vs-radio
                                    >
                                </vs-col>
                                <vs-col vs-lg="2" vs-sm="4">
                                    <vs-radio
                                        v-model="switchJobsRadar"
                                        id="switchJobRadio_occupationSkills"
                                        vs-name="switchJobRadio"
                                        vs-value="occupationSkills"
                                        :disabled="employeeOccupationSkillRatingsOnly"
                                        >Savoir-faire</vs-radio
                                    >
                                </vs-col>
                                <vs-col vs-lg="8" vs-sm="4" class="flex">
                                    <vs-radio
                                        v-model="switchJobsRadar"
                                        id="switchJobRadio_occupationSkillsLevels"
                                        vs-name="switchJobRadio"
                                        vs-value="occupationSkillsLevels"
                                        >Savoir-faire des postes</vs-radio
                                    >
                                    <div>
                                        <ms-tooltip :text="'Le score de maîtrise est la moyenne des savoir-faire maîtrisés pondérée suivant le niveau de maîtrise'" position="right">
                                            <vs-button
                                                radius
                                                color="primary"
                                                type="flat"
                                                icon="info"
                                                class="large-icon"
                                            ></vs-button>
                                        </ms-tooltip>
                                    </div>
                                </vs-col>
                            </vs-row>

                            <!-- Job - List of jobs name + score -->
                            <vs-row v-if="switchJobsRadar === 'jobs'">
                                <vs-col>
                                    <ul class="columns">
                                        <li class="text-primary" v-for="(chart, i) in jobCharts" :key="`title-job-${i}`">
                                            <div
                                                v-html="formatHtmlTitle(chart)"
                                                v-tooltip.top-start="{
                                                    content: tooltipDescJobs[i],
                                                }"
                                            ></div>
                                        </li>
                                    </ul>
                                </vs-col>
                            </vs-row>
                        </div>

                        <!-- No last rating job -->
                        <div v-else>
                            <ul class="columns">
                                <li class="text-primary" v-for="(job, i) in employee.jobs" :key="`jobTitle-${i}`">- {{ job.title }}</li>
                            </ul>
                        </div>

                        <!-- Jobs charts of Skills -->
                        <vs-row v-if="showRatingsHaveJobs && switchJobsRadar === 'jobs'" class="chart-container">
                            <vs-col
                                v-for="(chart, i) in jobCharts"
                                :key="`chart-job-${i}`"
                                vs-lg="6"
                                vs-sm="12"
                                class="mt-6 chart"
                                v-tooltip="{ content: tooltipDescJobs[i] }"
                            >
                                <rating-chart
                                    :employee="employee"
                                    :options="chart.options"
                                    :chart-data="chart.data"
                                    class="rating"
                                />
                            </vs-col>
                        </vs-row>

                        <!-- Occupation_skill charts of Skills -->
                        <div v-else-if="showRatingsHaveJobs && switchJobsRadar === 'occupationSkills'">
                            <vs-row v-for="(jobChart, i) in jobCharts" :key="`${i}-jobChart.jobId`">
                                <vs-col>
                                    <div v-html="formatHtmlTitle(jobChart)"></div>
                                </vs-col>

                                <vs-col
                                    v-for="(osChart, i) in occupationSkillsJobCharts[jobChart.jobId]"
                                    :key="`chart-os-${i}-${jobChart.jobId}`"
                                    vs-lg="6"
                                    vs-sm="12"
                                    class="mt-6 chart chart-container"
                                >
                                    <rating-chart
                                        :employee="employee"
                                        :options="osChart.options"
                                        :chart-data="osChart.data"
                                        class="rating"
                                    />
                                </vs-col>
                            </vs-row>
                        </div>

                        <!-- Jobs charts of OccupationSkills -->
                        <vs-row
                            v-if="showRatingsHaveJobs && switchJobsRadar === 'occupationSkillsLevels'"
                            class="chart-container"
                        >
                            <vs-col
                                v-for="(chart, i) in jobChartsOfOccskills"
                                :key="`chart-job-${i}`"
                                vs-lg="6"
                                vs-sm="12"
                                class="mt-6 chart"
                                v-tooltip="{ content: tooltipDescJobs[i] }"
                            >
                                <rating-chart
                                    :employee="employee"
                                    :options="chart.options"
                                    :chart-data="chart.data"
                                    class="rating"
                                />
                            </vs-col>
                        </vs-row>
                    </vs-collapse-item>

                    <!-- Occupations wishlist -->
                    <vs-collapse-item
                        v-if="wishOccupationCharts && wishOccupationCharts.length"
                        :open="false"
                        id="employee-wishlist-occupations-charts"
                        icon-arrow="arrow_downward"
                    >
                        <div slot="header" class="collapse-header">
                            <p class="bold text-primary">Métiers souhaités :&nbsp;</p>
                        </div>

                        <ul class="columns">
                            <li class="text-primary" v-for="(chart, i) in wishOccupationCharts" :key="`title-wish-occupation-${i}`">
                                <div
                                    v-html="formatHtmlTitle(chart)"
                                    v-tooltip.top-start="{
                                        content: tooltipDescWishOccupations[i],
                                    }"
                                ></div>
                            </li>
                        </ul>

                        <vs-row v-if="wishOccupationCharts">
                            <vs-col
                                v-for="(chart, i) in wishOccupationCharts"
                                :key="`chart-wish-occupation-${i}`"
                                vs-lg="6"
                                vs-sm="12"
                                class="mt-6 chart"
                                v-tooltip="{
                                    content: tooltipDescWishOccupations[i],
                                }"
                            >
                                <rating-chart
                                    :employee="employee"
                                    :options="chart.options"
                                    :chart-data="chart.data"
                                    class="rating"
                                />
                            </vs-col>
                        </vs-row>
                    </vs-collapse-item>

                    <!-- Jobs wishlist -->
                    <vs-collapse-item
                        v-if="wishJobCharts && wishJobCharts.length"
                        :open="false"
                        id="employee-wishlist-jobs-charts"
                        icon-arrow="arrow_downward"
                    >
                        <div slot="header" class="collapse-header">
                            <p class="bold text-primary">Postes souhaités :&nbsp;</p>
                        </div>

                        <ul class="columns">
                            <li class="text-primary" v-for="(chart, i) in wishJobCharts" :key="`title-wish-job-${i}`">
                                <div
                                    v-html="formatHtmlTitle(chart)"
                                    v-tooltip.top-start="{
                                        content: tooltipDescWishJobs[i],
                                    }"
                                ></div>
                            </li>
                        </ul>

                        <vs-row v-if="wishJobCharts">
                            <vs-col
                                v-for="(chart, i) in wishJobCharts"
                                :key="`chart-wjob-${i}`"
                                vs-lg="6"
                                vs-sm="12"
                                class="mt-6 chart"
                                v-tooltip="{ content: tooltipDescWishJobs[i] }"
                            >
                                <rating-chart
                                    :employee="employee"
                                    :options="chart.options"
                                    :chart-data="chart.data"
                                    class="rating"
                                />
                            </vs-col>
                        </vs-row>
                    </vs-collapse-item>

                    <!-- Comments des évaluations du portail -->
                    <vs-collapse-item
                        v-if="selectedExternalRatings.length > 0"
                        id="employee-comments"
                        :open="false"
                        icon-arrow="arrow_downward"
                    >
                        <div slot="header" class="collapse-header pl-0">
                            <p class="bold text-primary">Commentaires des évaluations portail :</p>
                        </div>

                        <template v-for="(ser, i) in selectedExternalRatings">

                            <h4 :class="['font-weight-bold', i != 0 ? 'mt-5' : '']">{{ ser.title }}</h4>
                            <div class="pl-5">
                                <template v-if="Object.keys(ser.comment).length > 0">
                                    <div v-for="(commentName, j) in Object.keys(ser.comment)">
                                        <h5 class="mt-3 text-primary">{{ commentName }}</h5>
                                        <p class="pl-3 text-dark">
                                            <span v-if="ser.comment[commentName]">{{ ser.comment[commentName] }}</span>
                                            <span v-else class="text-dark" style="opacity: 0.6">
                                                Aucun commentaire disponible.
                                            </span>
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <p class="text-dark" style="opacity: 0.6">Aucun commentaire disponible.</p>
                                </template>
                            </div>
                        </template>

                    </vs-collapse-item>
                </vs-collapse>
            </vs-col>
        </vs-row>
        <!-- End Collapse items -->

        <!-- Export rating PDF -->
        <export-ratings-pdf
            v-if="exportPDF"
            @exported="closeExport"
            :employee="employee"
            :skillRatingChart="skillRatingChartForExport"
            :autoEvalChart="autoEvalChartForExport"
            :occupationCharts="occupationChartsForExport"
            :jobCharts="jobChartsForExport"
            :jobs="jobs"
            :wishOccupationCharts="wishOccupationChartsForExport"
            :wishJobCharts="wishJobChartsForExport"
            :concernedRating="exportRatings[0]"
        >
        </export-ratings-pdf>
    </div>
</template>

<script>
import RatingChart from "@components/app/RatingChart";
import ExportRatingsPDF from "@components/app/ExportRatingsPDF";
import Multiselect from "vue-multiselect";
import ratingChartsHelper from "@/mixins/ratingChartsHelper";
import hexToRgba from "hex-to-rgba";
import ratingService from "@components/services/ratings";
import { formatDate } from "@assets/utils/helpers/helpers.js";

const randomColor = require("randomcolor");

export default {
    components: {
        Multiselect,
        RatingChart,
        "export-ratings-pdf": ExportRatingsPDF,
    },

    mixins: [ratingChartsHelper],

    props: {
        employee: {
            type: Object,
            require: true,
        },
        skills: {
            type: Array,
            require: true,
        },
        adminSkills: {
            type: Array,
            require: true,
        },
        skill_occupationSkill: {
            type: Array,
            require: true,
        },
        adminSkill_adminOccupationSkill: {
            type: Array,
            require: true,
        },
        baseUrl: {
            type: String,
            require: true,
        },
    },

    data() {
        return {
            log: console.log,
            formatDateFr: formatDate,
            descrJobs: [],
            employeeId: null,
            chartOptions: this.getChartOptions(this.employee),
            ratingsDates: null,
            selectedRatings: null,
            selectedSkillReview: null,
            selectedAutoRating: null,
            selectedExternalRatings: null,
            selectedMobilityRatings: null,
            lastRatings: null,
            showRatings: null,
            showAutoRatings: null,
            editRatingPrompt: false,
            editedRating: null,

            editSkillRatingPrompt: false,
            editedSkillRating: false,
            deleteSkillRatingPrompt: false,
            deletedSkillRating: false,

            currentOnly: false, // TODO - N'afficher que les compétences notées
            wishlistOnly: false, // TODO
            showSelfRating: false,
            deleteRatingPrompt: false,
            deletedRating: null,
            loading: false,
            deuxcolonnes: true,
            deuxcolonnesJobs: true,
            switchJobsRadar: "jobs",
            switchOccupationsRadar: "occupations",
            exportPDF: false,
            exportRatingPrompt: false,
            exportRatingDate: null,
            exportRatings: null,
            fontSizeRadarExport: 11.5,
            mainGraphColors: {},

            colors: [],
            employees: [],
            jobs: [],
            occupations: [],
        };
    },

    async created() {
        this.loading = true;
        this.employeeId = this.employee.id;

        await Promise.all([this.loadColors(), this.loadEmployees(), this.loadJobs(), this.loadOccupations()]);

        let ratingsDates = await ratingService.getRatingsDates({
            employee_id: this.employeeId,
            withMobility: 1,
        });

        this.ratingsDates = ratingsDates.map(this.formatRating);
        let tempRatings = await ratingService.getLastRatings({
            employee_ids: [this.employeeId],
            count: 1,
        });

        let start = Date.now();
        tempRatings[this.employeeId]?.forEach((tempRating) => {
            this.reconstructRating(tempRating);
        });
        let end = Date.now();

        this.lastRatings = window._.cloneDeep(tempRatings);
        if (Object.values(tempRatings).length && tempRatings[this.employeeId]) {
            this.showRatings = tempRatings[this.employeeId];
        } else {
            this.showRatings = [];
        }

        this.selectedRatings = [];
        if (this.can("read", "ratings")) {
            this.selectedRatings = this.ratingsDates
                .filter((r) => r.status !== 2 && r.status !== 3 && !r.skill_review)
                .slice(0, 1);
        }

        this.selectedSkillReview = [];
        if (this.can("read", "skill_reviews")) {
            this.selectedSkillReview = this.ratingsDates.filter((r) => {
                return [-1, 1].includes(r.status) && !!r.skill_review
            }).slice(0, 1);

        }

        // Si pas de projet personnalisé -> sélection par défaut d'une évaluation portail (externe ou déclaration de compétences) v-if="can('read', 'skill_reviews')"
        let defaultFromMobility = false;
        if (this.selectedRatings.length === 0) {
            if (this.can("read", "skill_reviews")) {
                if (this.selectedSkillReview.length) {
                    this.selectedSkillReview = this.ratingsDates.filter((r) => [-1, 1].includes(r.status) && !!r.skill_review).slice(0, 1);
                } else {
                    defaultFromMobility = true;
                }
            } else {
                defaultFromMobility = true;
            }
        }

        this.selectedMobilityRatings = [];
        this.selectedExternalRatings = [];
        if (defaultFromMobility) {
            this.selectedMobilityRatings = this.ratingsDates.filter((r) => r.status === 2).slice(0, 1);
            this.selectedExternalRatings = this.ratingsDates.filter((r) => r.status === 3).slice(0, 1);
        }

        // Pour les utilisateurs qui n'ont pas les habilitations sur les bilans, si pas de projet personnalisé et pas d'évaluation portail -> redirection vers la création d'un projet personnalisé
        if (
            !this.can("read", "skill_reviews") &&
            this.canMulti(
                "update",
                "ratings",
                this.employee.services.map((s) => s.id)
            )
        ) {
            if (
                this.selectedRatings.length === 0 &&
                this.selectedMobilityRatings.length === 0 &&
                this.selectedExternalRatings.length === 0 &&
                this.canAddRating &&
                this.pendingRating.length == 0
            ) {
                this.redirectCreation();
            }
        }

        if (this.employeeOccupationSkillRatingsOnly) {
            this.switchOccupationsRadar = 'occupationSkillsLevels';
            this.switchJobsRadar = 'occupationSkillsLevels'
        }
    },

    watch: {
        loading(newValue) {
            if (newValue) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        },

        isInit(newValue) {
            if (newValue) {
                this.loading = false;
            } else {
                this.loading = true;
            }
        },

        selectedRatings() {
            let ratingsIds = [];
            if (!this.showRatings) return;

            this.showRatings = this.showRatings.filter(
                (r) =>
                    r.status === 3 ||
                    r.status === 2 ||
                    this.selectedRatings?.find((selected) => selected.id === r.id) ||
                    this.selectedSkillReview?.find((selected) => selected.id === r.id)
            );
            this.selectedRatings.forEach((selected) => {
                let exists = this.showRatings.find((r) => r.id === selected.id);
                if (!exists) {
                    ratingsIds.push(selected.id);
                }
            });

            if (ratingsIds.length) {
                ratingService.getRatings({ ratingsIds }).then((res) => {
                    res.forEach((tempRating) => {
                        this.reconstructRating(tempRating);
                    });
                    this.showRatings = [...this.showRatings, ...res].sort((a, b) => {
                        if (a.status === 3 || b.status === 3) {
                            if (a.status < b.status) return -1;
                            else if (a.status > b.status) return 1;
                            else return new Date(b.rating_date) - new Date(a.rating_date);
                        } else {
                            return new Date(b.rating_date) - new Date(a.rating_date);
                        }
                    });
                });
            }
        },

        selectedSkillReview() {
            let ratingsIds = [];
            if (!this.showRatings) return;

            this.showRatings = this.showRatings.filter(
                (r) =>
                    r.status === 3 ||
                    this.selectedRatings?.find((selected) => selected.id === r.id) ||
                    this.selectedSkillReview?.find((selected) => selected.id === r.id)
            );
            this.selectedSkillReview.forEach((selected) => {
                let exists = this.showRatings.find((r) => r.id === selected.id);
                if (!exists) {
                    ratingsIds.push(selected.id);
                }
            });

            if (ratingsIds.length) {
                ratingService.getRatings({ ratingsIds }).then((res) => {
                    res.forEach((tempRating) => {
                        this.reconstructRating(tempRating);
                    });
                    this.showRatings = [...this.showRatings, ...res].sort((a, b) => {
                        if (a.status === 3 || b.status === 3) {
                            if (a.status < b.status) return -1;
                            else if (a.status > b.status) return 1;
                            else return new Date(b.rating_date) - new Date(a.rating_date);
                        } else {
                            return new Date(b.rating_date) - new Date(a.rating_date);
                        }
                    });
                });
            }
        },

        selectedMobilityRatings() {
            let ratingsIds = [];
            if (!this.showRatings) return;

            if (!this.selectedMobilityRatings.length) {
                this.showRatings = this.showRatings.filter((r) => r.status !== 2);
                return;
            }

            this.showRatings = this.showRatings.filter(
                (r) => r.status !== 2 || this.selectedMobilityRatings.find((selected) => selected.id == r.id)
            );
            this.selectedMobilityRatings.forEach((selected) => {
                let exists = this.showRatings.find((r) => r.id == selected.id);
                if (!exists) {
                    ratingsIds.push(selected.id);
                }
            });

            if (ratingsIds.length) {
                ratingService.getRatings({ ratingsIds, withMobility: 1 }).then((res) => {
                    res.forEach((tempRating) => {
                        this.reconstructRating(tempRating);
                    });
                    this.showRatings = [...this.showRatings, ...res].sort((a, b) => {
                        if (a.status === 2 || b.status === 2) {
                            if (a.status < b.status) return -1;
                            else if (a.status > b.status) return 1;
                            else return new Date(b.rating_date) - new Date(a.rating_date);
                        } else {
                            return new Date(b.rating_date) - new Date(a.rating_date);
                        }
                    });
                });
            }
        },

        selectedExternalRatings() {
            let ratingsIds = [];
            if (!this.showRatings) return;

            if (!this.selectedExternalRatings.length) {
                this.showRatings = this.showRatings.filter((r) => r.status !== 3);
                return;
            }

            this.showRatings = this.showRatings.filter(
                (r) => r.status !== 3 || this.selectedExternalRatings.find((selected) => selected.id == r.id)
            );
            this.selectedExternalRatings.forEach((selected) => {
                let exists = this.showRatings.find((r) => r.id == selected.id);
                if (!exists) {
                    ratingsIds.push(selected.id);
                }
            });

            if (ratingsIds.length) {
                ratingService.getRatings({ ratingsIds, withMobility: 1 }).then((res) => {
                    res.forEach((tempRating) => {
                        this.reconstructRating(tempRating);
                    });
                    this.showRatings = [...this.showRatings, ...res].sort((a, b) => {
                        if (a.status === 3 || b.status === 3) {
                            if (a.status < b.status) return -1;
                            else if (a.status > b.status) return 1;
                            else return new Date(b.rating_date) - new Date(a.rating_date);
                        } else {
                            return new Date(b.rating_date) - new Date(a.rating_date);
                        }
                    });
                });
            }
        },

        selectedAutoRating() {
            if (!this.selectedAutoRating?.id) {
                this.showSelfRating = false;
                this.showAutoRatings = null;
            } else {
                this.showAutoRatings = this.showRatings.find((r) => r.id === this.selectedAutoRating.id);
                if (this.showAutoRatings) {
                    this.showSelfRating = true;
                } else {
                    ratingService.getRating(this.selectedAutoRating.id).then((res) => {
                        this.reconstructRating(res);
                        this.showAutoRatings = res;
                        this.showSelfRating = true;
                    });
                }
            }
        },

        occupationCharts(value) {

            // Patch pour permettre aux couleurs de se charger. A revoir
            setTimeout(() => {
                if ((!value || value.length == 0) && this.occupationChartsOfOccskills.length > 0) {
                    this.switchOccupationsRadar = 'occupationSkillsLevels';
                }
            }, 200)
        }
    },

    computed: {
        isInit() {
            return !!(this.employee && this.showRatings);
        },

        unvalidatedRatings() {
            return this.ratingsDates.filter((r) => r.status === 0 && !r.skill_review);
        },
        pendingRating() {
            return this.ratingsDates.filter((r) => r.status === -1 && !r.skill_review)[0];
        },
        pendingSkillReview() {
            return this.ratingsDates.filter(r => r.status === -1 && r.skill_review)[0];
        },
        unvalidatedSkillReview() {
            return this.currentSkillReviews.filter(r => r.status === 0 && r.skill_review);
        },
        currentSkillReviews() {
            return this.ratingsDates.filter(r => r.status !== 2 && r.status !== 3 && r.skill_review);
        },
        deletableSkillReview() {
            return this.ratingsDates.filter(r => r.status !== -1 && r.status !== 2 && r.status !== 3 && r.skill_review);
        },
        currentRating() {
            return this.ratingsDates.filter((r) => r.status !== 2 && r.status !== 3 && !r.skill_review);
        },
        otherRating() {
            return this.ratingsDates.filter((r) => r.status === 2 || r.status === 3);
        },
        deletableRating() {
            return this.ratingsDates.filter(r => r.status !== -1 && r.status !== 2 && r.status !== 3 && !r.skill_review);
        },
        exportRating() {
            return this.ratingsDates.filter(
                (r) =>
                    r.status !== 2 &&
                    r.status !== 3 &&
                    ((r.skill_review && this.can("read", "skill_reviews")) ||
                        (!r.skill_review && this.can("read", "ratings")))
            );
        },
        selfRatingList() {
            return this.ratingsDates.filter((r) => r.status !== 2 && r.status !== 3 && !r.skill_review);
        },
        selfMobilityRatingList() {
            return this.ratingsDates.filter((r) => r.status === 2);
        },
        notPendingRatings() {
            return this.ratingsDates.filter(
                (r) => r.status !== -1 && r.status !== 2 && r.status !== 3 && !r.skill_review
            );
        },
        latestRating() {
            return this.showRatings[0];
        },
        lastRating() {
            let trueShowRatings = this.showRatings.filter((r) => r.status < 2 && !r.skill_review);
            return trueShowRatings.length ? trueShowRatings[0] : null;
        },
        mobilityRating() {
            return this.ratingsDates.find((r) => r.status === 2);
        },
        mobilityRatings() {
            return this.ratingsDates.filter((r) => r.status === 2);
        },
        externalRatings() {
            return this.ratingsDates.filter((r) => r.status === 3);
        },
        skillReviews() {
            return this.ratingsDates.filter((r) => r.status === 0 && !!r.skill_review);
        },
        canAddSkillReview() {
            return !!this.canMulti("update", "skill_reviews", this.employee.services.map(s => s.id));
        },
        canContinueSkillReview() {
            return this.pendingSkillReview && this.canMulti("update", "skill_reviews", this.employee.services.map(s => s.id));
        },
        canModifySkillReview() {
            return this.unvalidatedSkillReview.length > 0 && this.canMulti("update", "skill_reviews", this.employee.services.map(s => s.id));
        },
        canDeleteSkillReview() {
            return this.currentSkillReviews.length > 0 && this.canMulti("update", "skill_reviews", this.employee.services.map(s => s.id));

            // return (
            //     !!this.canMulti(
            //         "update",
            //         "skill_reviews",
            //         this.employee.services.map((s) => s.id)
            //     ) && !this.skillReviews.length
            // );
        },
        canUpdateSkillReview() {
            return (
                !!this.canMulti(
                    "update",
                    "skill_reviews",
                    this.employee.services.map((s) => s.id)
                ) && this.skillReviews.length
            );
        },
        canAddRating() {
            return !!this.canMulti(
                "update",
                "ratings",
                this.employee.services.map((s) => s.id)
            );
        },
        canContinueRating() {
            return !!(
                this.pendingRating &&
                this.canMulti(
                    "update",
                    "ratings",
                    this.employee.services.map((s) => s.id)
                )
            );
        },
        canModifyRating() {
            return !!(
                this.unvalidatedRatings.length &&
                this.canMulti(
                    "update",
                    "ratings",
                    this.employee.services.map((s) => s.id)
                )
            );
        },
        canDeleteRating() {
            return (
                !!(
                    this.mobilityRating &&
                    this.ratingsDates.length > 1 &&
                    this.canMulti(
                        "update",
                        "ratings",
                        this.employee.services.map((s) => s.id)
                    )
                ) ||
                (this.pendingRating &&
                    this.ratingsDates.length > 1 &&
                    this.canMulti(
                        "update",
                        "ratings",
                        this.employee.services.map((s) => s.id)
                    )) ||
                (!this.mobilityRating &&
                    !this.pendingRating &&
                    this.ratingsDates.length > 0 &&
                    this.canMulti(
                        "update",
                        "ratings",
                        this.employee.services.map((s) => s.id)
                    ))
            );
        },
        canExportRating() {
            // TODO gérer la permission
            return true;
            return !!(this.ratingsDates.length > 0 && this.$acl.check("manager"));
        },

        showRatingsHaveJobs() {
            if (!this.showRatings) return false;

            let have = false;
            this.showRatings.forEach((r) => {
                if (r.jobs.length) {
                    have = true;
                    return;
                }
            });

            return have;
        },
        showRatingsHaveOccupations() {
            if (!this.showRatings) return false;

            let have = false;
            this.showRatings.forEach((r) => {
                if (r.occupations.length) {
                    have = true;
                    return;
                }
            });

            return have;
        },

        skillRatingChart() {
            return {
                data: {
                    labels: this.showedSkills(this.showRatings, this.employee, this.currentOnly, this.wishlistOnly).map(
                        (s) => s.admin_skill.title
                    ),
                    datasets: this.generateDatasets(this.showRatings),
                },
                options: this.chartOptions,
            };
        },
        skillRatingChartForExport() {
            let options = window._.cloneDeep(this.chartOptions);
            options.scale.pointLabels.fontSize = this.fontSizeRadarExport;
            return {
                data: {
                    labels: this.showedSkills(
                        this.exportRatings,
                        this.employee,
                        this.currentOnly, // Export de toutes les compétences systématiquement
                        this.wishlistOnly,
                        true
                    ).map((s) => s.admin_skill.title),
                    datasets: this.generateDatasets(this.exportRatings, false, true),
                },
                options: options,
            };
        },

        autoEvalChartForExport() {
            if (!this.exportRatings) return {};

            let options = window._.cloneDeep(this.chartOptions);
            options.scale.pointLabels.fontSize = this.fontSizeRadarExport;

            return {
                data: {
                    labels: this.showedSkills(
                        this.exportRatings,
                        this.employee,
                        this.currentOnly, // Export de toutes les compétences systématiquement
                        this.wishlistOnly,
                        true
                    ).map((s) => s.admin_skill.title),
                    datasets: this.generateDatasets([this.exportRatings[0]], true, true),
                },
                options: options,
            };
        },

        occupationCharts() {
            const charts = [];

            if (!this.showRatings || (!this.showRatings.length && !this.showAutoRatings)) return null;

            let ratingOccupations = this.showRatingsOccupations;

            // On filtre les métiers avec tous les savoirs-faire sans compétence
            ratingOccupations = ratingOccupations.filter(
                (o) => !this.occupationWithOnlyOccupationSkillsWithoutSkill(o)
            );

            let uniqueOccupations = [...new Set(ratingOccupations.map((o) => o.id))];

            uniqueOccupations.forEach((uniqueId) => {
                let ratings = [];
                let entity = {};
                this.showRatingsOccupations
                    .filter((o) => o.id === uniqueId)
                    .forEach((occupation, idx) => {
                        ratings.push(this.showRatings.find((r) => r.id === occupation.pivot.employee_rating_id));
                        if (!idx) entity = occupation;
                    });
                //let chart = this.generateChart({ rating: ratings, entity: entity, type: 'occupation', params: { occupationId: uniqueId }, scoreRating: this.lastRating });

                let chart = this.generateChart({
                    rating: this.showRatings,
                    entity: entity,
                    type: "occupation",
                    params: { occupationId: uniqueId },
                    scoreRating: this.lastRating,
                });
                charts.push(chart);
            });

            return this.sortCharts(charts);
        },
        occupationSkillsOccupationCharts() {
            const charts = [];
            let uniqueOccupations = [...new Set(this.showRatingsOccupations.map((j) => j.id))];

            uniqueOccupations.forEach((uniqueId) => {
                let ratings = [];
                this.showRatingsOccupations
                    .filter((o) => o.id === uniqueId)
                    .forEach((occupation) => {
                        ratings.push(this.showRatings.find((r) => r.id === occupation.pivot.employee_rating_id));
                    });
                charts[uniqueId] = [];
                this.occupations
                    .find((o) => o.id === uniqueId && !o.admin_id)
                    ?.occupation_skills.forEach((occSkill) => {
                        const occSkillRatingValue =
                            this.showRatings.length && !!this.showRatings[0]?.occupations_skills?.length
                                ? this.showRatings[0].occupations_skills
                                      .find((rOs) => rOs.id === occSkill.id)
                                      ?.pivots.find((p) => p.occupation_skill_id === occSkill.id)?.level
                                : null;
                        const occSkillRatingText = this.ratingParams.find((param) => +param.id === occSkillRatingValue)
                            ?.label;
                        let chart = this.generateChart({
                            rating: this.showRatings,
                            entity: occSkill,
                            type: "occupation_skill",
                            params: {},
                            scoreRating: this.lastRating,
                            colors: this.mainGraphColors,
                            OSLabel: occSkillRatingText,
                        });
                        charts[uniqueId].push(chart);
                    });

                this.occupations
                    .find((o) => o.id === uniqueId && o.admin_id)
                    ?.admin_occupation_skills.forEach((occSkill) => {
                        const occSkillRatingValue =
                            this.showRatings.length && !!this.showRatings[0]?.admin_occupation_skills?.length
                                ? this.showRatings[0].admin_occupation_skills.find(
                                      (rAOs) =>
                                          rAOs.admin_occupation_skill_id === occSkill.pivot?.admin_occupation_skill_id
                                  )?.level
                                : null;
                        const occSkillRatingText = this.ratingParams.find((param) => +param.id === occSkillRatingValue)
                            ?.label;
                        let chart = this.generateChart({
                            rating: this.showRatings,
                            entity: occSkill,
                            type: "admin_occupation_skill",
                            params: {},
                            scoreRating: this.lastRating,
                            colors: this.mainGraphColors,
                            OSLabel: occSkillRatingText,
                        });
                        charts[uniqueId].push(chart);
                    });
            });

            return charts;
        },
        occupationChartsOfOccskills() {
            const charts = [];

            if (!this.showRatings.length && !this.showAutoRatings) return null;

            let uniqueOccupations = [...new Set(this.showRatingsOccupations.map((j) => j.id))];

            uniqueOccupations.forEach((uniqueId) => {
                let ratings = [];
                let entity = {};
                let type = null;
                this.showRatingsOccupations
                    .filter((o) => o.id === uniqueId)
                    .forEach((occupation, idx) => {
                        ratings.push(this.showRatings.find((r) => r.id === occupation.pivot.employee_rating_id));
                        if (!idx) entity = occupation;
                        type = occupation.admin_id ? "admin_occupation" : "occupation";
                    });

                this.generateDatasets(ratings);
             
                let chart = this.generateChartOfOccupationSkills({
                    rating: ratings,
                    entity: entity,
                    type: type,
                    params: { occupationId: uniqueId },
                    scoreRating: this.lastRating,
                    colors: this.mainGraphColors,
                });
                charts.push(chart);
            });

            return this.sortCharts(charts);
        },
        occupationChartsForExport() {
            let charts = [];

            if (!this.exportRatings?.length || !this.exportRatings[0].occupations.length) return charts;

            this.exportRatings[0].occupations.forEach((occ) => {
                let chart = this.generateChart({
                    rating: this.exportRatings[0],
                    entity: occ,
                    type: "occupation",
                });
                chart.options.scale.pointLabels.fontSize = this.fontSizeRadarExport;
                charts.push(chart);
            });

            return this.sortCharts(charts);
        },

        showRatingsJobs() {
            return (
                this.showRatings
                    .map((r) => r.jobs)
                    ?.flatMap((j) => j)
                    ?.sort((a, b) => a.id - b.id) || []
            );
        },
        showRatingsOccupations() {
            return (
                this.showRatings
                    .map((r) => r.occupations)
                    ?.flatMap((o) => o)
                    ?.sort((a, b) => a.id - b.id) || []
            );
        },
        showRatingsWishOccupations() {
            return (
                this.showRatings
                    .map((r) => r.wishlist_occupations)
                    ?.flatMap((wo) => wo)
                    ?.sort((a, b) => a.id - b.id) || []
            );
        },
        showRatingsWishJobs() {
            return (
                this.showRatings
                    .map((r) => r.wishlist_jobs)
                    ?.flatMap((wj) => wj)
                    ?.sort((a, b) => a.id - b.id) || []
            );
        },

        jobCharts() {
            const charts = [];
            //const jobs = this.employee.jobs

            //if (!this.latestRating) return null
            if (!this.showRatings.length && !this.showAutoRatings) return null;

            //if (this.showRatings.jobs && !this.showRatings.jobs.length) return null

            //const jobs = this.latestRating.jobs || []
            //let jobs = this.showRatings.map(r => r.jobs).flatMap(j => j).sort((a, b) => a.id - b.id)
            let uniqueJobs = [...new Set(this.showRatingsJobs.map((j) => j.id))];

            uniqueJobs.forEach((uniqueId) => {
                let ratings = [];
                let entity = {};
                this.showRatingsJobs
                    .filter((j) => j.id === uniqueId)
                    .forEach((job, idx) => {
                        ratings.push(this.showRatings.find((r) => r.id === job.pivot.employee_rating_id));
                        if (!idx) entity = job;
                    });
                //let chart = this.generateChart({ rating: ratings, entity: entity, type: 'job', params: { jobId: uniqueId }, scoreRating: this.lastRating, colors: this.mainGraphColors });
                let chart = this.generateChart({
                    rating: this.showRatings,
                    entity: entity,
                    type: "job",
                    params: { jobId: uniqueId },
                    scoreRating: this.lastRating,
                    colors: this.mainGraphColors,
                });
                charts.push(chart);
            });

            /*jobs.forEach(job => {
                let chart = this.generateChart({ rating: this.latestRating, entity: job, type: 'job', params: { jobId: job.id } });
                charts.push(chart);
              })*/
            return this.sortCharts(charts);
        },
        jobChartsOfOccskills() {
            const charts = [];

            if (!this.showRatings.length && !this.showAutoRatings) return null;

            let uniqueJobs = [...new Set(this.showRatingsJobs.map((j) => j.id))];

            uniqueJobs.forEach((uniqueId) => {
                let ratings = [];
                let entity = {};
                this.showRatingsJobs
                    .filter((j) => j.id === uniqueId)
                    .forEach((job, idx) => {
                        ratings.push(this.showRatings.find((r) => r.id === job.pivot.employee_rating_id));
                        if (!idx) entity = job;
                    });

                this.generateDatasets(ratings);

                let chart = this.generateChartOfOccupationSkills({
                    rating: ratings,
                    entity: entity,
                    type: "job",
                    params: { jobId: uniqueId },
                    scoreRating: this.lastRating,
                    colors: this.mainGraphColors,
                });
                charts.push(chart);
            });

            return this.sortCharts(charts);
        },
        occupationSkillsJobCharts() {
            const charts = [];
            let uniqueJobs = [...new Set(this.showRatingsJobs.map((j) => j.id))];

            uniqueJobs.forEach((uniqueId) => {
                let ratings = [];
                let entity = {};
                this.showRatingsJobs
                    .filter((j) => j.id === uniqueId)
                    .forEach((job, idx) => {
                        ratings.push(this.showRatings.find((r) => r.id === job.pivot.employee_rating_id));
                        //if (!idx) entity = job
                    });

                charts[uniqueId] = [];
                this.jobs
                    .find((j) => j.id === uniqueId)
                    .occupation_skills.forEach((occSkill) => {
                        const occSkillRatingValue =
                            this.showRatings.length && !!this.showRatings[0]?.occupations_skills?.length
                                ? this.showRatings[0].occupations_skills
                                      .find((rOs) => rOs.id === occSkill.id)
                                      ?.pivots.find((p) => p.occupation_skill_id === occSkill.id)?.level
                                : null;
                        const occSkillRatingText = this.ratingParams.find((param) => +param.id === occSkillRatingValue)
                            ?.label;
                        let chart = this.generateChart({
                            rating: this.showRatings,
                            entity: occSkill,
                            type: "occupation_skill",
                            params: {},
                            scoreRating: this.lastRating,
                            colors: this.mainGraphColors,
                            OSLabel: occSkillRatingText,
                        });
                        charts[uniqueId].push(chart);
                    });
            });

            return charts;
        },
        jobChartsForExport() {
            let charts = [];

            if (!this.exportRatings?.length || !this.exportRatings[0].jobs.length) return charts;

            this.exportRatings[0].jobs.forEach((job) => {
                let chart = this.generateChart({
                    rating: this.exportRatings[0],
                    entity: job,
                    type: "job",
                    params: { jobId: job.id },
                });
                chart.options.scale.pointLabels.fontSize = this.fontSizeRadarExport;
                charts.push(chart);
            });
            return this.sortCharts(charts);
        },

        wishJobCharts() {
            const charts = [];

            if (!this.showRatings.length && !this.showAutoRatings) return null;

            let uniqueWishjobs = [...new Set(this.showRatingsWishJobs.map((j) => j.id))];

            uniqueWishjobs.forEach((uniqueId) => {
                let ratings = [];
                let entity = {};
                this.showRatingsWishJobs
                    .filter((j) => j.id === uniqueId)
                    .forEach((job, idx) => {
                        ratings.push(this.showRatings.find((r) => r.id === job.pivot.employee_rating_id));
                        if (!idx) entity = job;
                    });
                //let chart = this.generateChart({ rating: ratings, entity: entity, type: 'job', params: { occupationId: uniqueId }, scoreRating: this.lastRating });
                let chart = this.generateChart({
                    rating: this.showRatings,
                    entity: entity,
                    type: "job",
                    params: { occupationId: uniqueId },
                    scoreRating: this.lastRating,
                });
                charts.push(chart);
            });

            return this.sortCharts(charts);
        },
        wishJobChartsForExport() {
            const charts = [];

            if (!this.exportRatings?.length || !this.exportRatings[0].wishlist_jobs.length) return null;

            this.exportRatings[0].wishlist_jobs.forEach((wishJob) => {
                let chart = this.generateChart({
                    rating: this.exportRatings[0],
                    entity: wishJob,
                    type: "job",
                });
                chart.options.scale.pointLabels.fontSize = this.fontSizeRadarExport;
                charts.push(chart);
            });

            return charts;
        },
        wishOccupationCharts() {
            const charts = [];

            if (!this.showRatings.length && !this.showAutoRatings) return null;

            let uniqueWishOccupations = [...new Set(this.showRatingsWishOccupations.map((o) => o.id))];

            uniqueWishOccupations.forEach((uniqueId) => {
                let ratings = [];
                let entity = {};
                this.showRatingsWishOccupations
                    .filter((o) => o.id === uniqueId)
                    .forEach((occupation, idx) => {
                        ratings.push(this.showRatings.find((r) => r.id === occupation.pivot.employee_rating_id));
                        if (!idx) entity = occupation;
                    });
                //let chart = this.generateChart({ rating: ratings, entity: entity, type: 'occupation', params: { occupationId: uniqueId }, scoreRating: this.lastRating });
                let chart = this.generateChart({
                    rating: this.showRatings,
                    entity: entity,
                    type: "occupation",
                    params: { occupationId: uniqueId },
                    scoreRating: this.lastRating,
                });
                charts.push(chart);
            });

            return this.sortCharts(charts);
        },
        wishOccupationChartsForExport() {
            const charts = [];

            if (!this.exportRatings?.length || !this.exportRatings[0].wishlist_occupations.length) return null;

            this.exportRatings[0].wishlist_occupations.forEach((wishOccupation) => {
                let chart = this.generateChart({
                    rating: this.exportRatings[0],
                    entity: wishOccupation,
                    type: "occupation",
                });
                chart.options.scale.pointLabels.fontSize = this.fontSizeRadarExport;
                charts.push(chart);
            });

            return this.sortCharts(charts);
        },

        tooltipDescWishJobs() {
            const provJobs = [];

            this.wishJobCharts.forEach((wishJob) => {
                provJobs.push(wishJob.description);
            });
            return provJobs;
        },
        tooltipDescWishOccupations() {
            const provOccupations = [];

            this.wishOccupationCharts.forEach((wishOccupation) => {
                provOccupations.push(wishOccupation.description);
            });
            return provOccupations;
        },
        tooltipDescOccupations() {
            const occOccupations = [];

            this.occupationCharts.forEach((occ) => {
                occOccupations.push(occ.description);
            });
            return occOccupations;
        },
        tooltipDescJobs() {
            const occJobs = [];

            this.jobCharts.forEach((occ) => {
                occJobs.push(occ.description);
            });
            return occJobs;
        },

        listColors() {
            let sortedValues = this.colors;
            let listColors = [];
            sortedValues.forEach((color) => {
                listColors.push(color.rgb);
            });
            return listColors.length ? listColors : ["#000"];
        },

        ratingParams() {
            if (this.ratingSettings && this.ratingSettings.params) {
                const params = Object.keys(this.ratingSettings.params).map((id) => {
                    var param = this.ratingSettings.params[id];
                    param.id = id;
                    return param;
                });
                return params;
            }
            return [];
        },
        employeeOccupationSkillRatingsOnly() {
            return this.employee.occupation_skills_rating_only;
        }
    },

    methods: {
        async loadColors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/settings/colors",
                    params: { perpage: 9999 },
                })
                .then((response) => {
                    this.colors = response.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadEmployees() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: { perpage: 9999 },
                })
                .then((response) => {
                    this.employees = response.data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadJobs() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/jobs",
                    params: {
                        perpage: 9999,
                        include: ["service.establishment", "occupation_skills"],
                        withTrash: "true",
                    },
                })
                .then((response) => {
                    let jobs = response.data.data;
                    jobs.forEach((j) => {
                        j.occupation_skills.forEach((jos) => {
                            this.reconstructSkillOccupationSkillRelation(jos, this.skill_occupationSkill);
                        });
                    });
                    this.jobs = jobs;
                })
                .catch((err) => {
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: {
                        perpage: 9999,
                        include: ["sector", "occupation_skills", "admin_occupation_skills"],
                        withTrash: "true",
                    },
                })
                .then((response) => {
                    let occupations = response.data.data;
                    occupations.forEach((o) => {
                        o.occupation_skills.forEach((oos) => {
                            this.reconstructSkillOccupationSkillRelation(oos, this.skill_occupationSkill, false);
                        });
                        o.admin_occupation_skills.forEach((oos) => {
                            this.reconstructSkillOccupationSkillRelation(
                                oos,
                                this.adminSkill_adminOccupationSkill,
                                true
                            );
                        });
                    });
                    this.occupations = occupations;
                })
                .catch((err) => {
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        occupationById(id) {
            return this.occupations.find((o) => o.id == id);
        },

        getChartOptions(employee) {
            return {
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: `Compétences de ${employee.first_name + " " + employee.last_name}`,
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        },
                        label: (tooltipItem, data) => {
                            const label = data.datasets[tooltipItem.datasetIndex].label;
                            const value =
                                label != "Montée en compétences"
                                    ? data.datasets[tooltipItem.datasetIndex].levels[tooltipItem.index]
                                    : null;
                            return value ? `${label}: ${value} ` : `${label}`;
                        },
                    },
                    filter: (x) => x.yLabel >= 0,
                },
                scale: {
                    pointLabels: {
                        fontSize: 10,
                        callback: function (value, index, values) {
                            // if more than 25 values, display the top label with space under and bottom label with space above
                            if (values.length < 25) {
                                return value;
                            } else {
                                if (index == 0) {
                                    return [value, [" "]];
                                } else if (values.length % 2 == 0 && index == values.length / 2) {
                                    return [" ", [value]];
                                } else {
                                    return value;
                                }
                            }
                        },
                    },
                    ticks: {
                        min: this.ratingSettings ? this.ratingSettings.RadarCenter : -1,
                        max: 3,
                        stepSize: 1,
                    },
                },
            };
        },
        generateChart({ rating, entity, type, params = {}, scoreRating = null, colors = null, OSLabel = null }) {
            let options = window._.cloneDeep(this.chartOptions);
            if (!Array.isArray(rating)) rating = [rating];

            if (!scoreRating) {
                scoreRating = rating[0];
            }
            const score = this.employeeAccessibility(scoreRating, entity, type);
            let selfRating = this.showSelfRating ? this.showAutoRatings : null;

            return {
                score: this.formatScore(score),
                scoreNumber: score[0],
                check: score[1],
                ...params,
                options: {
                    ...options,
                    title: {
                        ...this.chartOptions.title,
                        text: `${entity.title} ${OSLabel ? "(" + OSLabel + ")" : ""} : ${this.formatScore(score)} (accessibilité)`,
                    },
                },
                data: this.employeeAccessibilityChartData(rating, entity, type, true, colors, selfRating),
                description: entity.description,
            };
        },
        generateChartOfOccupationSkills({ rating, entity, type, params = {}, scoreRating = null, colors = null }) {
            let options = window._.cloneDeep(this.chartOptions);
            if (!Array.isArray(rating)) rating = [rating];

            if (!scoreRating) {
                scoreRating = rating[0];
            }

            let onlyOccSkills = !rating.skills || rating.skills.length === 0;

            // Mode de l'accessibilité
            if (type == 'admin_occupation' || type == 'occupation') {
                onlyOccSkills = this.switchOccupationsRadar == 'occupationSkillsLevels';
            } else if (type == 'job') {
                onlyOccSkills = this.switchJobsRadar == 'occupationSkillsLevels'
            }
            
            const score = this.employeeAccessibility(scoreRating, entity, type, onlyOccSkills);
            
            return {
                score: this.formatScore(score),
                scoreNumber: score[0],
                check: score[1],
                ...params,
                options: {
                    ...options,
                    title: {
                        ...this.chartOptions.title,
                        text: `${entity.title} : ${this.formatScore(score)} ${onlyOccSkills ? '(score de maîtrise)' : null}`,
                    },
                },
                data: this.employeeAccessibilityChartDataOfOccupationSkills(rating, entity, type, true, colors, this.showAutoRatings),
                description: entity.description,
            };
        },

        redirectCreation() {
            document.location.pathname = `${this.baseUrl}/creer`;
        },

        redirectSkillReviewCreation() {
            document.location.href = `${this.baseUrl}/creer?skill-review=true`;
        },
        redirectSkillReviewEdition() {
            document.location.href = `${this.baseUrl}/modifier/${this.skillReviews[0]?.id}?skill-review=true`;
        },

        redirectEdition() {
            document.location.pathname = `${this.baseUrl}/modifier/${this.editedRating.id}`;
        },
        redirectPursue() {
            document.location.pathname = `${this.baseUrl}/poursuivre/${this.pendingRating.id}`;
        },
        redirectSkillReviewCreation() {
            document.location.href = `${this.baseUrl}/creer?skill-review=true`
        },
        redirectSkillReviewEdition() {
            document.location.href = `${this.baseUrl}/modifier/${this.editedSkillRating.id}?skill-review=true`
        },
        redirectSkillReviewContinue() {
            document.location.href = `${this.baseUrl}/poursuivre/${this.pendingSkillReview.id}?skill-review=true`
        },

        formatRating(rating) {
            let prefix =
                rating.status === 2
                    ? "Auto-évaluation Portail"
                    : rating.status === 3
                    ? rating.eval_type
                    : rating.skill_review
                    ? this.skillRatingNames.singular
                    : this.projectNames.singular;

            let suffix = '';

            if (rating.status === 0) {
                suffix = "(En attente de validation)";
            } else if (rating.status === -1) {
                suffix = "(En cours)";
            } else if (rating.status === 1) {
                suffix = "(Validé)";
            }

            const date = this.formatDateFr(rating.rating_date);

            return {
                ...rating,
                rating_date: date,
                title: `${prefix} du ${date} ${suffix}`,
            };
        },

        formatScore(score) {
            return this.round(score[0] * 100, 0) + "% " + (score[1] ? "✓" : "");
        },

        sortCharts(charts) {
            return charts.sort((a, b) =>
                a.scoreNumber == b.scoreNumber ? (b.check ? 1 : -1) : b.scoreNumber - a.scoreNumber
            );
        },

        cancelEditRating() {
            this.editRatingPrompt = false;
            this.editedRating = null;
        },
        cancelDeleteRating() {
            this.deleteRatingPrompt = false;
            this.deletedRating = null;
        },
        cancelExportRating() {
            this.exportRatingPrompt = false;
            this.exportRatingId = null;
            this.exportRatings = null;
        },
        cancelEditSkillRating() {
            this.editRatingPrompt = false;
            this.editedSkillRating = null
        },
        cancelDeleteSkillRating() {
            this.deleteSkillRatingPrompt = false;
            this.deletedSkillRating = null
        },

        deleteRating(skillReview = false) {
            this.loading = true;
            let object = skillReview ? this.skillRatingNames.singular : this.projectNames.singular;
            ratingService
                .deleteRating(this.deletedRating.id)
                .then(() => {
                    this.ratingsDates = this.ratingsDates.filter((r) => r.id != this.deletedRating.id);
                    this.selectedRatings = [];
                    this.selectedAutoRating = [];
                    this.selectedMobilityRatings = [];
                    this.selectedExternalRatings = [];
                    this.selectedSkillReview = [];
                    this.$swal(`${object} supprimé !`, ``, "success");
                    this.loading = false;
                })
                .catch(() => {
                    this.$swal(`Erreur!`, ``, "error");
                    this.loading = false;
                })
                .finally(() => {
                    this.deletedRating = null;
                });
        },

        deleteSkillRating() {
            this.$swal({
                title: `Êtes-vous sûr de vouloir supprimer le ${this.skillRatingNames.singular} ?`,
                text: "",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Ok",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if (result.value) {
                    this.deletedRating = this.deletedSkillRating;
                    this.deleteRating(true);
                    this.deletedSkillRating = null;
                }
            });
        },

        // getRatingColors(idx) {
        //     let borderColor = "";
        //     let backgroundColor = "";
        //     if (idx < this.listColors.slice(1, 16).length - 1) {
        //         backgroundColor = hexToRgba(this.listColors.slice(1, 16)[idx], "0.2");
        //         borderColor = hexToRgba(this.listColors.slice(1, 16)[idx]);
        //     } else {
        //         const seed = this.$moment().unix();
        //         borderColor = randomColor({
        //             format: "rgba",
        //             alpha: 1,
        //             seed: seed,
        //         });
        //         backgroundColor = randomColor({
        //             format: "rgba",
        //             alpha: 0.2,
        //             seed: seed,
        //         });
        //     }
        //     return {
        //         backgroundColor: backgroundColor,
        //         borderColor: borderColor,
        //         pointBackgroundColor: backgroundColor,
        //     };
        // },

        /**
         * Retourne une couleur depuis son slug
         * @param slug
         */
        getColorBySlug(slug) {
            return this.colors.find(c => c.slug == slug);
        },

        /**
         * Retourne une configuration de couleur à partir d'un slug de couleur
         * @param slug
         */
        getRatingColorsBySlug(slug) {
            let borderColor = null;
            let backgroundColor = null;
            const color = this.getColorBySlug(slug);

            if (color) {
                borderColor = hexToRgba(color.rgb);
                backgroundColor = hexToRgba(color.rgb, "0.2");
            } else {
                const seed = this.$moment().unix();
                borderColor = randomColor({
                    format: "rgba",
                    alpha: 1,
                    seed: seed,
                });
                backgroundColor = randomColor({
                    format: "rgba",
                    alpha: 0.2,
                    seed: seed,
                });
            }

            return {
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                pointBackgroundColor: backgroundColor,
            };
        },

        generateDatasets(ratings, forceShowSelfRating = false, isPDF = false) {
            let selfRating = (this.showSelfRating && !isPDF) || forceShowSelfRating;
            let NonMesuredLevel = 0;
            let datasets = [];
            if (this.ratingSettings.NonMesuredLevel !== null) {
                NonMesuredLevel = this.ratingSettings.NonMesuredLevel;
            }
            if (selfRating) {
                let rating = isPDF ? this.exportRatings[0] : this.showAutoRatings;

                datasets.push({
                    label: `Auto-évaluation`,
                    fill: true,
                    pointBorderColor: "#fff",
                    //...this.getRatingColors(0),
                    ...this.getRatingColorsBySlug('auto-eval-pp-0'),
                    data: this.showedSkillsNotes(
                        rating,
                        NonMesuredLevel,
                        isPDF && !forceShowSelfRating ? false : true,
                        "note",
                        isPDF
                    ),
                    levels: this.showedSkillsNotes(
                        rating,
                        NonMesuredLevel,
                        isPDF && !forceShowSelfRating ? false : true,
                        "label",
                        isPDF
                    ),
                    order: 1,
                    status: 2,
                    skillReview: 0,
                });
            }

            let skillsGoalsData = [];

            let nbAutoEvalMobility = 0, nbEvalTier = 0, nbSkillRevview = 0, nbPP = 0;

            datasets.push(
                ...ratings.map((r, idx) => {

                    let prefix, suffix, color;

                    // Traitement couleur et préfixe
                    if (r.status === 2) {
                        prefix = "Auto-évaluation Portail";
                        color = this.getRatingColorsBySlug(`auto-eval-mobility-${nbAutoEvalMobility}`);
                        nbAutoEvalMobility++;
                    } else if (r.status === 3) {
                        prefix = r.eval_type;
                        color = this.getRatingColorsBySlug(`eval-tiers-${nbEvalTier}`);
                        nbEvalTier++;
                    } else if (r.skill_review) {
                        prefix = this.skillRatingNames.singular;
                        color = this.getRatingColorsBySlug(`skill-review-${nbSkillRevview}`);
                        nbSkillRevview++;
                    } else {
                        prefix = this.projectNames.singular;
                        color = this.getRatingColorsBySlug(`pp-${nbPP}`);
                        nbPP++;
                    }

                    // Traitement du suffixe
                    switch (r.status) {
                        case -1:
                            suffix = '(En cours)';
                            break;
                        case 0:
                            suffix = '(En attente de validation)';
                            break;
                        default:
                            suffix = '';
                            break;
                    }

                    this.mainGraphColors[r.id] = color;

                    // Si il y a des objectifs de compétences
                    if (r.skills_goals.length > 0) {
                        skillsGoalsData.push({
                            rating: r,
                            values: this.showedSkillsGoalsNotes(r, NonMesuredLevel, false, "note", isPDF),
                        });
                    }

                    return {
                        label: `${prefix} du ${this.formatDateFr(r.rating_date)} ${suffix}`,
                        fill: true,
                        ...color,
                        pointBorderColor: "#fff",
                        data: this.showedSkillsNotes(r, NonMesuredLevel, false, "note", isPDF),
                        levels: this.showedSkillsNotes(r, NonMesuredLevel, false, "label", isPDF),
                        status: r.status,
                        skillReview: r.skill_review,
                    };
                })
            );

            // Traitement des objectifs de compétences
            if (skillsGoalsData.length > 0) {
                let goalsColor = this.getColorBySlug('goals') ? this.getColorBySlug('goals').rgb : "#4377df";

                skillsGoalsData.forEach((data) => {
                    datasets.push({
                        label: `Objectifs (${formatDate(data.rating.rating_date)})`,
                        // backgroundColor: hexToRgba(this.listColors[19] ? this.listColors[19] : "#4377df", "0.4"),
                        // pointBorderColor: hexToRgba(this.listColors[19] ? this.listColors[19] : "#4377df", "0.7"),
                        // borderColor: hexToRgba(this.listColors[19] ? this.listColors[19] : "#4377df", "0.4"),
                        backgroundColor: hexToRgba(goalsColor, "0.4"),
                        pointBorderColor: hexToRgba(goalsColor, "0.7"),
                        borderColor: hexToRgba(goalsColor, "0.4"),
                        pointBorderWidth: 5,
                        borderWidth: 0,
                        fill: false,
                        data: data.values,
                        showLines: false,
                        pointRadius: data.values.map((v) => (v > NonMesuredLevel ? 5 : 0)),
                        levels: data.values.map((levelNote) => this.levelLabelByNote(levelNote)),
                    });
                });
            }

            // Traitement des montées en compétences
            if (datasets.filter((d) => d.status < 2).length >= 2) {

                const skillGrowingColor = this.getColorBySlug('skill-growing').rgb;

                // Atleast two ratings are selected (Self rating not included)
                let data = selfRating
                    ? datasets[1].data
                          .map((v, i) => v - datasets[2].data[i])
                          .map((diff, i) => {
                              return diff > 0 ? datasets[1].data[i] : null;
                          })
                    : datasets[0].data
                          .map((v, i) => v - datasets[1].data[i])
                          .map((diff, i) => {
                              return diff > 0 ? datasets[0].data[i] : null;
                          });
                let evolutiondataset = {
                    label: "Montée en compétences",
                    backgroundColor: hexToRgba(skillGrowingColor, "0.4"),
                    pointBorderColor: hexToRgba(skillGrowingColor, "0.7"),
                    pointBorderWidth: 5,
                    borderColor: hexToRgba(skillGrowingColor, "0.4"),
                    borderWidth: 0,
                    fill: false,
                    data: data,
                    showLines: false,
                    pointRadius: data.map((v) => (v > NonMesuredLevel ? 5 : 0)),
                };
                datasets.push(evolutiondataset);
            }

            // Affichage des objectifs

            return datasets;
        },

        generateColor(str) {
            return randomColor({ seed: str });
        },

        ratedSkillNote(s, r, NonMesuredLevel, self, type) {
            let skills = self ? r.self_skills : r.skills;

            const ratedSkill = r
                ? skills.find((skill) =>
                      skill.admin_skill
                          ? skill.admin_skill.title === s.title
                          : skill.admin_skill_id === s.admin_skill_id
                  )
                : NonMesuredLevel;

            let level = ratedSkill && ratedSkill.pivot ? ratedSkill.pivot.level : ratedSkill ? ratedSkill.level : null;

            if (type === "note") return ratedSkill ? this.noteById(level) : NonMesuredLevel;
            else return ratedSkill ? this.levelLabelById(level) : "Non mesuré";
        },

        showedSkillsNotes(r, NonMesuredLevel, self, type, isPDF = false) {
            return this.showedSkills(
                isPDF ? this.exportRatings : this.showRatings,
                this.employee,
                this.currentOnly,
                this.wishlistOnly,
                isPDF
            ).map((s) => this.ratedSkillNote(s, r, NonMesuredLevel, self, type));
        },

        /**
         * Constuction des données de notes pour affichage des objsectifs de compétences
         * @param {Object} r
         * @param {Integer} NonMesuredLevel
         * @param {Boolean} self
         * @param {String} type
         * @param {Boolean} isPDF
         */
        showedSkillsGoalsNotes(r, NonMesuredLevel, self, type, isPDF = false) {
            var data = this.showedSkills(
                isPDF ? this.exportRatings : this.showRatings,
                this.employee,
                this.currentOnly,
                this.wishlistOnly,
                isPDF
            ).map((s) => {
                const goal = r.skills_goals.find((sg) => sg.id == s.id);
                return goal ? goal.pivot.level : null;
            });

            return data;
        },

        ratingStatus(rating) {
            if (rating.status === 0 && rating.skill_review) {
                return " (En attente de validation)";
            } else if (rating.status === 2) {
                return "(Auto-évaluation Portail)";
            } else if (rating.status === 3) {
                return "(" + rating.eval_type + ")";
            }
            return "";
        },

        formatHtmlTitle(chart) {
            let check = "";
            if (chart.check) {
                check = `<b style="color: green;">✓</b>`;
            }
            return (
                `<span>${chart.options.title.text.split(":")[0]}</span> :
        <b class="text-primary">${this.round(chart.scoreNumber * 100, 0) + "% "}</b>` + check
            );
        },

        async initExport() {
            if (!this.exportRatingDate) {
                return;
            }

            this.$vs.loading({
                background: "primary",
                color: "#FFF",
                container: "#export-pdf-button",
                scale: 0.45,
            });

            let index = this.notPendingRatings.findIndex((r) => r.id == this.exportRatingDate.id);
            let ratingsIds = [this.exportRatingDate.id];

            // Get data of previous rating
            if (this.notPendingRatings[index + 1] && !this.exportRatingDate.skill_review) {
                ratingsIds = [...ratingsIds, this.notPendingRatings[index + 1].id];
            }

            // TODO: reprendre la data de la vue si elle existe
            let tempExportRatings = await ratingService.getRatings({ ratingsIds });

            // Chargement des relations
            tempExportRatings?.forEach((tempRating) => {
                this.reconstructRating(tempRating);
            });
            this.exportRatings = tempExportRatings;

            // Lock scrollbar to avoid issue on pdf rendering
            document.querySelectorAll(".scroll-area--main-sidebar").forEach(function (e) {
                e.classList.add("hide-scrollbar");
            });
            document.querySelector("#content-area").classList.add("hide-scrollbar");
            this.exportPDF = true;
        },
        closeExport() {
            this.exportPDF = false;
            this.$vs.loading.close("#export-pdf-button > .con-vs-loading");

            // Unlock scrollbar
            document.querySelectorAll(".scroll-area--main-sidebar").forEach(function (e) {
                e.classList.remove("hide-scrollbar");
            });
            document.querySelector("#content-area").classList.remove("hide-scrollbar");
        },
    },
};
</script>
<style lang="scss">
.hide-scrollbar {
    overflow: -moz-hidden-unscrollable !important;
    overflow: hidden !important;
}
</style>
<style lang="scss" scoped>
.vs-col {
    @media screen and (max-width: 900px) {
        padding: 0;
    }
}

.deuxLignes {
    width: 70% !important;
}

#comments {
    font-variant: normal;

    > * {
        font-variant: normal !important;
        font-size: 12px;
    }
}

.chart {
    &.main {
        height: 750px;
        position: relative;
        max-width: 750px;
        margin-left: auto !important;
        margin-right: auto !important;

        @media screen and (max-width: 750px) {
            width: 100%;
            height: 400px;
        }
    }

    .main-chart {
        height: 100%;

        > #radar-chart {
            height: 100%;
        }
    }

    .rating {
        height: 32vw;

        > #radar-chart {
            height: 100%;
        }
    }

    .rating2 {
        height: 750px;
        width: 750px;
        margin-left: 25%;

        > #radar-chart {
            height: 100%;
        }
    }
}

.rating {
    padding: 10px;
    position: relative;
}

.columns {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    li {
        color: rgb(151, 47, 95);
    }
}

.employee-info {
    li {
        margin-bottom: 1rem;

        &.employee-name {
            display: flex;
            flex-wrap: wrap;

            p:first-child {
                margin-right: 1em;
            }
        }
    }
}

.action-buttons {
    > div.vs-row > * {
        margin-left: 15px;
        display: flex;
        margin-bottom: 15px;
    }
}

.employee_info {
    > div.vs-col {
        padding: 0;
    }
}

.vs-collapse-item {
    padding-right: 5px;
    padding-left: 5px;
}

div[id^="employee"] {
    ::v-deep > header {
        padding-left: 0;
    }
}

.filters {
    .toggles > div {
        .filter-container {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            @media screen and (max-width: 600px) {
                width: 100%;
                margin-right: 0;
            }
        }

        .filter-container:not(:last-child) {
            margin-right: 25px;
        }

        button + label {
            margin-left: 15px;
        }
    }
}

.charts {
    .vs-collapse,
    ::v-deep .con-content--item {
        padding-left: 0 !important;
        padding-right: 0 !important;
        opacity: 1;
    }
}

@media only screen and (max-width: 1350px) {
    .vs-sm-12 {
        width: 100% !important;
    }

    .chart .rating {
        height: 55vw;
    }

    @media screen and (orientation: portrait) {
        .chart .rating {
            height: 60vh;
        }
    }
}

#employee-occupations-charts.open-item ::v-deep .vs-collapse-item--content {
    max-height: inherit !important;
}

#employee-jobs-charts.open-item ::v-deep .vs-collapse-item--content {
    max-height: inherit !important;
}

#employee-wishlist-occupations-charts.open-item ::v-deep .vs-collapse-item--content {
    max-height: inherit !important;
}

#employee-wishlist-jobs-charts.open-item ::v-deep .vs-collapse-item--content {
    max-height: inherit !important;
}

.collapse-header {
    color: rgb(151, 47, 95);
    display: flex;
    font-weight: bold;
}
</style>
