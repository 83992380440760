<template>
    <div>
        <vx-card class="mb-5" v-if="isASuperAdmin() || isAnAdmin()" title="Déposer des fichiers">
            <!-- Permission désactivé pour le moment -->
            <vs-row v-if="can('import', 'imports') || true">
                <input type="file" @change="onUpdateFile($event)" />
            </vs-row>
            <vs-table
                :data="uploadedFiles"
                no-data-text="Aucune donnée à afficher"
                style="width:100%;"
            >
                <template slot="thead">
                    <vs-th>Fichier</vs-th>
                    <vs-th>Taille (en Ko)</vs-th>
                    <vs-th>Date de dépôt</vs-th>
                    <vs-th>Déposé par</vs-th>
                    <vs-th>
                        Actions
                    </vs-th>
                </template>
                <template slot-scope="{ data }">
                    <vs-tr
                        v-for="(item, indextr) in data"
                        :key="indextr"
                        :data="item"
                    >
                        <vs-td>{{ item.frontName }}</vs-td>
                        <vs-td>{{ Math.round(item.size / 1024) }}</vs-td>
                        <vs-td>{{ item.at }}</vs-td>
                        <vs-td>{{ item.by }}</vs-td>
                        <vs-td>
                            <vs-button
                                :id="'downloadUpload_' + indextr"
                                v-tooltip="'Télécharger fichier'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-download"
                                @click.stop="downloadUploadedFile(item)"
                            />

                            <vs-button
                                v-if="can('import', 'imports')"
                                :id="'deleteUpload_' + indextr"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                style="margin-left: 1rem;"
                                @click="deleteUploadedFile(item)"
                            />
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>

        <vx-card v-if="can('create', 'imports')" title="Paramétrer les imports">
            <ImportsParams
                :api="this.api"
                :config="config"
            />
        </vx-card>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import ImportsParams from '@components/views/rh/referentiel/parameters/ImportsParams'

export default {
    name: "Imports",
    components: {
        Multiselect,
        ImportsParams,
    },
    props: {
        api: {
            type: String
        },
        config: {
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            displayPrompt: false,
            uploadedFiles: [],
        };
    },

    created() {
        this.loadUploadedFiles()
    },

    computed: {},

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        },
    },

    methods: {
        onUpdateFile(event) {
            let file = event.target.files ? event.target.files[0] : null
            let item = {
                file: file,
            }
            this.updateFile(item)
        },
        updateFile(item) {
            this.loading = true
            let form = new FormData();
            form.append('pilote_file', item.file);
            window.axios({
                method: 'post',
                data: form,
                url: `${this.api}/upload-file`,
                headers: {'Content-Type': 'multipart/form-data'},
            })
            .then(response => {
                this.loadUploadedFiles()
                this.loading = false;
                this.notifySuccess()
            })
            .catch(err => {
                this.loading = false;
                this.notifyError(err, 'Erreur !', true)
            })
        },

        loadUploadedFiles() {
            window.axios({
                method: 'post',
                url: `${this.api}/index-upload-file`,
            })
            .then(response => {
                this.uploadedFiles = response.data
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.notifyError(err, 'Erreur !', true)
            })
        },

        deleteUploadedFile(item) {
            window.axios({
                method: 'post',
                data: {
                    fileName: item.fullName
                },
                url: `${this.api}/delete-upload-file`,
            })
            .then(response => {
                this.loadUploadedFiles()
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.notifyError(err, 'Erreur !', true)
            })
        },
        downloadUploadedFile(item) {
            window.axios({
                method: 'post',
                params: { fileName: item.fullName },
                url: `${this.api}/download-upload-file`,
                responseType: 'blob', // Indique que la réponse sera un fichier
            })
            .then(response => {
                console.log('Download DONE !!')
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', item.frontName); // Spécifie le nom du fichier à télécharger
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                this.notifyError(err, `Une erreur est survenue`, true);
            })
        },

    }
};
</script>
