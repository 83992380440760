<template>
    <div class="filters-container">
        <h3>Filtres</h3>
        <br />

        <!-- Employees filter -->
        <div class="selects-row">
            <div class="tier">
                <label>Personnes</label>
                <div class="select">
                    <multiselect
                        v-model="personsValue"
                        :custom-label="employeeName"
                        track-by="id"
                        :options="personsOptions"
                        :multiple="true"
                        :placeholder="personsPlaceholder"
                        group-values="pers"
                        group-label="establishmentsServices"
                        @open="customPlaceholder(personsPlaceholder)"
                        @close="customPlaceholder(personsPlaceholder)"
                    >
                        <span slot="noResult"
                            >Aucun résultat ne correspond à votre
                            recherche</span
                        >
                        <span slot="noOptions">Aucun résultat</span>
                    </multiselect>
                </div>
            </div>

            <!-- Services filter -->
            <div class="tier">
                <label class="typo__label">Services</label>
                <div class="select">
                    <multiselect
                        v-model="servicesValue"
                        :placeholder="servicesPlaceholder"
                        label="title"
                        track-by="id"
                        :multiple="true"
                        :options="servicesOptions"
                        group-values="services"
                        group-label="establishment"
                        @open="customPlaceholder(servicesPlaceholder)"
                        @close="customPlaceholder(servicesPlaceholder)"
                    >
                        <span slot="noResult"
                            >Aucun résultat ne correspond à votre
                            recherche</span
                        >
                        <span slot="noOptions">Aucun résultat</span>
                    </multiselect>
                </div>
            </div>

            <!-- Establishment filter -->
            <div class="tier">
                <label class="typo__label">Établissements</label>
                <div class="select">
                    <multiselect
                        v-model="establishmentValue"
                        label="name"
                        track-by="name"
                        :options="establishmentOptions"
                        :multiple="true"
                        :placeholder="establishmentPlaceHolder"
                        @open="customPlaceholder(establishmentPlaceHolder)"
                        @close="customPlaceholder(establishmentPlaceHolder)"
                    >
                        <span slot="noResult"
                            >Aucun résultat ne correspond à votre
                            recherche</span
                        >
                        <span slot="noOptions">Aucun résultat</span>
                    </multiselect>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { uniqBy } from "lodash";
import ratingService from "@components/services/ratings";
import { getQueryParam, fixDate } from "@assets/utils/helpers/helpers";

export default {
    name: "Filters",

    components: {
        Multiselect
    },

    props: {
        setEmployeesList: Function
    },

    data() {
        return {
            log: console.log,
            personsValue: [],
            personsPlaceholder: "Personnes",

            establishmentValue: [],
            establishmentPlaceHolder: "Établissements",
            selectedEstablishments: null,

            servicesValue: [],
            servicesPlaceholder: "Services",

            ratings: {},

            establishments: [],
            establishmentsLoading: true,
            employees: [],
            employeesLoading: true
        };
    },

    async created() {
        this.setEmployeesList([]);

        await this.loadEstablishment();

        await this.loadEmployees();

        let employeeId = getQueryParam("employee");
        if (employeeId) {
            this.filterEmployees(employeeId, this.personsOptions);
        }
    },

    computed: {
        //...mapState(["services", "establishments", "employees"]),
        //...mapGetters(["serviceById", "sectorById"]),

        isFiltered() {
            return !!(
                this.personsValue.length ||
                this.establishmentValue.length ||
                this.servicesValue.length
            );
        },

        employeesList() {
            let data = [];
            let employees = this.employees;

            for (let e of employees) {
                var object = {};
                var employee = {};
                employee._id = String(e.id);
                employee.first_name = e.first_name;
                employee.last_name = e.last_name;
                employee.birthday = e.birthday;
                employee.photoUrl = e.photoUrl;
                employee.proficiencyLevel = Math.random() * 100;
                employee.establishment = this.arrayUnique([e.services.map(s => s.establishment.name)]);
                employee.jobs = e.jobs.map(j => j.title);
                employee.services = e.services?.map(s => s.title);
                employee.occupations = e.occupations.map(o => o.title);
                object.employee = employee;

                var career = [];

                let establishments = []
                e.services.forEach(service => {
                    var establishment = {};
                    establishment._id = String(service.establishment.id);
                    establishment.category = "establishments";
                    establishment.name = service.establishment.name;
                    establishment.adress = service.establishment.address;
                    establishment.type = service.establishment.type;
                    establishment.start = fixDate(e.created_at.split(" ")[0]);
                    establishment.end = new Date();
                    establishments.push(establishment)
                })
                establishments = this.arrayUnique(establishments)
                establishments.forEach(ets => {
                    career.unshift(ets);
                })

                for (let ex of e.experiences) {
                    var experience = {};
                    experience.category =
                        ex.experience_type.category === 0
                            ? "trainings"
                            : (ex.experience_type.category === 3 ? "jobs" : "Immersions");
                    experience._id = String(ex.id);
                    experience.name = ex.name;
                    experience.type = ex.experience_type.name;
                    experience.organisation = ex.establishment ? ex.establishment.name : ex.establishment_name;
                    experience.start = fixDate(ex.start_date);
                    experience.end = fixDate(ex.end_date);
                    experience.title = ex.title;
                    experience.comments = [ex.comment];
                    experience.isGraduate = ex.is_graduate == 1 ? true : false;
                    career.unshift(experience);
                }

                e.jobs.forEach(j => {
                    var job = {};
                    job.category = "jobs";
                    job._id = String(j.id);
                    job.establishment = j.service?.establishment.name;
                    job.start = fixDate(j.start_date);
                    job.end = new Date();
                    job.name = j.title;
                    job.service = j.service.title;
                    career.unshift(job);
                });
                e.deletedJobs.forEach(j => {
                    var job = {};
                    job.category = "jobs";
                    job._id = String(j.id);
                    job.establishment = j.establishment_name;
                    job.start = fixDate(j.start_date);
                    job.end = fixDate(j.end_date);
                    job.name = j.title;
                    job.service = j.service_title;
                    career.unshift(job);
                });

                e.occupations.forEach(o => {
                    var occupation = {};
                    occupation.category = "occupations";
                    occupation._id = String(o.id);
                    occupation.establishment = null; //Pas d'établissement associé
                    occupation.start = fixDate(o.start_date);
                    occupation.end = new Date();
                    occupation.name = o.title;
                    occupation.intern = true;
                    career.unshift(occupation);
                });
                e.deletedOccupations.forEach(o => {
                    var occupation = {};
                    occupation.category = "occupations";
                    occupation._id = String(o.id);
                    occupation.establishment = null; //Pas d'établissement associé
                    occupation.start = fixDate(o.start_date);
                    occupation.end = fixDate(o.end_date);
                    occupation.name = o.title;
                    occupation.intern = o.intern;
                    career.unshift(occupation);
                });

                let employeeRatings = this.getEmployeeRatings(e.id);

                // Sélection uniquement des projets personnalisés en attente de validation et des projets personnalisés validés
                employeeRatings = employeeRatings
                    ? employeeRatings.filter(
                          r => r.status === 0 || r.status === 1
                      )
                    : [];
                if (employeeRatings && employeeRatings.length) {
                    employeeRatings.sort(
                        (a, b) =>
                            fixDate(b.rating_date) - fixDate(a.rating_date)
                    );
                    if (employeeRatings[0].wishlist_jobs) {
                        employeeRatings[0].wishlist_jobs.forEach(j => {
                            var job = {};
                            job.category = "employee_rating_job_wishlists";
                            job._id = String(j.id);
                            job.name = j.title;
                            job.date = fixDate(employeeRatings[0].rating_date);
                            job.sector = j.service.sector?.title;
                            career.unshift(job);
                        });
                    }
                    if (employeeRatings[0].wishlist_occupations) {
                        employeeRatings[0].wishlist_occupations.forEach(o => {
                            var occupation = {};
                            occupation.category =
                                "employee_rating_occupation_wishlists";
                            occupation._id = String(o.id);
                            occupation.name = o.title;
                            occupation.date = fixDate(
                                employeeRatings[0].rating_date
                            );
                            career.unshift(occupation);
                        });
                    }
                    employeeRatings.forEach(r => {
                        var rating = {};
                        rating.category = "employee_ratings";
                        rating.job_occupationSkills = this.getAcquiredOccupationSkillsByJobs(
                            r.occupations_skills
                        );
                        rating._id = String(r.id);
                        rating.skills = r.skills;
                        rating.date = fixDate(r.rating_date);
                        rating.status = r.status;
                        career.unshift(rating);
                    });
                }
                object.career = career;
                object.employeeModel = e;
                data.unshift(object);
            }

            this.setEmployeesList([]);
            return data;
        },

        servicesOptions() {
            let establishments;
            let establishmentsIds = this.establishmentValue.map(e => e.id).concat(this.personsValue?.services?.map(s => s.establishment_id) || [])
            if (!establishmentsIds.length) {
                establishments = this.establishments;
            } else {
                establishments = this.establishments.filter(e =>
                    establishmentsIds.includes(e.id)
                ); // Supprime les doublons
            }

            let employees = this.personsValue || [];
            let employesServices = employees.map(e => e.services.map(s => s.id));

            return establishments.map(e => {
                // SGA 22/11/22 : TODO Restreindre les services sur la consultation du user
                let services = !employesServices.length
                    ? e.services
                    : e.services.filter(s => employesServices.includes(s.id));
                return {
                    establishment: e.name,
                    services: services
                };
            });
        },

        establishmentOptions() {
            let establishments = this.establishments;

            let servicesIds = this.servicesValue ? this.servicesValue.concat(this.personsValue?.services || []).map(s => s.id) : []

            return establishments
                .map(e => {
                    return {
                        value: e.name,
                        name: e.name,
                        id: e.id,
                        services: servicesIds.length
                            ? e.services.filter(s => servicesIds.includes(s.id))
                            : e.services
                    };
                })
                .filter(e => !!e.services.length);
        },

        personsOptions() {
            let establishments = this.establishmentValue?.length
                ? this.establishmentValue
                : this.establishments;
            let servicesIds = this.servicesValue.map(s => s.id);

            establishments = establishments
                .map(e => {
                    return {
                        value: e.name,
                        name: e.name,
                        id: e.id,
                        services: servicesIds.length
                            ? e.services.filter(s => servicesIds.includes(s.id))
                            : e.services
                    };
                })
                .filter(e => !!e.services.length);

            return establishments.flatMap(e => {
                let employees = this.employees

                return e.services
                    .map(s => {
                        let employeeServices = employees.filter(
                            e => e.services.map(es => es.id).includes(s.id)
                        );
                        if (employeeServices.length) {
                            return {
                                establishmentsServices: `${e.name} - ${s.title}`,
                                pers: employeeServices
                            };
                        }
                        return null;
                    })
                    .filter(s => s);
            });
        },

        employeesFiltered() {
            let employees = this.personsValue.map(e => e.id);
            let services = this.servicesValue.map(e => e.id);
            let establishments = this.establishmentValue.map(e => e.id);

            if (employees.length) {
                return employees;
            }
            if (services.length) {
                return this.employees
                    .filter(e => !!services.filter(s => e.services.map(es => es.id).includes(s)).length)
                    .map(e => e.id);
            }
            if (establishments.length) {
                return this.employees
                    .filter(e => !!establishments.filter(ets => e.services.map(es => es.establishment_id).includes(ets)).length)
                    .map(e => e.id);
            }
            return null;
        },
    },

    watch: {
        servicesValue() {
            this.setFiltredEmployees();
        },

        establishmentValue() {
            this.setFiltredEmployees();
        },

        personsValue(value) {
            this.setFiltredEmployees();
        },
        employees() {
            let employeeId = getQueryParam("employee");
            if (employeeId) {
                this.filterEmployees(employeeId, this.personsOptions);
            }
        }
    },

    methods: {

        async loadEstablishment() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ["services"]
                    }
                })
                .then(response => {
                    this.establishments = response.data.data;
                    this.establishmentsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadEmployees() {
            return window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: {
                        perpage: 9999,
                        include: [
                            "services.establishment",
                            "services.sector",
                            "jobs.service.establishment",
                            "occupations",
                            "experiences.experienceType",
                            "experiences.establishment",
                            "finishedJobs",
                            "finishedOccupations",
                            "oldJobs.establishment",
                            "oldOccupations"
                        ]
                    }
                })
                .then(response => {

                    this.employees = this.employeesDisposal(response.data.data);
                    //this.employees = response.data.data;
                    this.employeesLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async setFiltredEmployees() {
            if (!this.isFiltered) {
                this.setEmployeesList([]);
                return;
            }
            let employeesIds = this.employeesFiltered.filter(
                e => !this.ratings[e]
            );
            if (employeesIds.length) {
                this.$vs.loading();
                try {
                    let ratings = await ratingService.getAllRatings({
                        employeesIds
                    });

                    employeesIds.forEach(id => {
                        this.$set(this.ratings, id, []);
                    });
                    ratings.forEach(r => {
                        this.ratings[r.employee_id].push(r);
                    });
                } catch (e) {
                    this.ratings = {};
                }
                this.$vs.loading.close();
            }

            let filtredList = this.employeesList.filter(e =>
                this.employeesFiltered.find(id => id == e.employee._id)
            );

            this.setEmployeesList(filtredList);
        },

        employeesDisposal(employees) {

            // Traitement des Jobs
            employees.forEach(e => {
                //If an employee has been updated/created/imported it hasn't exactly the same informations
                //Thus we need to do the distinction in the reconstruction
                let employeeStateIsComplete = !(
                    e.finished_jobs && e.current_jobs
                );
                if (!employeeStateIsComplete) {
                    e.deletedJobs = e.finished_jobs;
                    e.jobs = e.current_jobs;
                }

                e.jobs.forEach(j => {
                    j.start_date = fixDate(j.pivot.created_at);
                    j.end_date = fixDate(j.pivot.deleted_at);
                    j.intern = true; //a job stored in the application ?
                    //j.service = state.services.find(s => s.id === j.service_id);
                    j.service_title = j.service.title;
                    j.establishment_name = j.service.establishment.name;
                    // if (getAllObjectAttached) {
                    //     let stateJob = state.jobs.find(job => job.id === j.id);
                    //     if (stateJob) {
                    //         j.occupation_skills = stateJob.occupation_skills;
                    //     }
                    // }
                });

                e.old_jobs.forEach(obj => {
                    obj.intern = false;
                });

                if (employeeStateIsComplete) {
                    e.deletedJobs = e.jobs.filter(j =>
                        fixDate(j.pivot.deleted_at)
                    );
                    //CAUTION: This is this the line below which causes bad performances but there it's used for only few employees which have been updated/created/imported
                    e.jobs = e.jobs.filter(j => !fixDate(j.pivot.deleted_at));
                } else {
                    e.deletedJobs.forEach(j => {
                        j.start_date = fixDate(j.pivot.created_at);
                        j.end_date = fixDate(j.pivot.deleted_at);
                        j.intern = true; //a job stored in the application ?
                        j.service = state.services.find(
                            s => s.id === j.service_id
                        );
                        j.service_title = j.service.title;
                        j.establishment_name = j.service.establishment.name;
                        // if (getAllObjectAttached) {
                        //     let stateJob = state.jobs.find(
                        //         job => job.id === j.id
                        //     );
                        //     if (stateJob) {
                        //         j.occupation_skills =
                        //             stateJob.occupation_skills;
                        //     }
                        // }
                    });
                }

                e.deletedJobs = e.deletedJobs
                    ? e.deletedJobs.concat(e.old_jobs)
                    : e.old_jobs;
                e.allJobs = e.jobs.concat(e.deletedJobs);
            });

            // Traitement des occupations
            employees.forEach(e => {
                let employeeStateIsComplete = !(
                    e.finished_occupations && e.current_occupations
                );
                if (!employeeStateIsComplete) {
                    e.deletedOccupations = e.finished_occupations;
                    e.occupations = e.current_occupations;
                }

                e.occupations.forEach(o => {
                    o.start_date = fixDate(o.pivot.created_at);
                    o.end_date = fixDate(o.pivot.deleted_at);
                    o.intern = true; //a job stored in the application ? YES
                    // if (getAllObjectAttached) {
                    //     let stateOccupation = state.occupations.find(
                    //         occ => occ.id === o.id
                    //     );
                    //     if (stateOccupation) {
                    //         o.admin_occupation_skills =
                    //             stateOccupation.admin_occupation_skills;
                    //         o.occupation_skills =
                    //             stateOccupation.occupation_skills;
                    //     }
                    // }
                });

                e.old_occupations.forEach(obj => {
                    obj.intern = false;
                });

                if (employeeStateIsComplete) {
                    e.deletedOccupations = e.occupations.filter(o =>
                        fixDate(o.pivot.deleted_at)
                    );
                    e.occupations = e.occupations.filter(
                        j => !fixDate(j.pivot.deleted_at)
                    );
                } else {
                    e.deletedOccupations.forEach(o => {
                        o.start_date = fixDate(o.pivot.created_at);
                        o.end_date = fixDate(o.pivot.deleted_at);
                        o.intern = true; //a job stored in the application ? YES
                        if (getAllObjectAttached) {
                            let stateOccupation = state.occupations.find(
                                occ => occ.id === o.id
                            );
                            if (stateOccupation) {
                                o.admin_occupation_skills =
                                    stateOccupation.admin_occupation_skills;
                                o.occupation_skills =
                                    stateOccupation.occupation_skills;
                            }
                        }
                    });
                }

                e.deletedOccupations = e.deletedOccupations
                    ? e.deletedOccupations.concat(e.old_occupations)
                    : e.old_occupations;
                e.allOccupations = e.occupations.concat(e.deletedOccupations);
            });

            return employees;
        },

        customPlaceholder: function(val) {
            switch (val) {
                case "Postes occupés":
                    this.personsPlaceholder = "Rechercher un poste occupé";
                    break;
                case "Rechercher un poste occupé":
                    this.personsPlaceholder = "Postes occupés";
                    break;
                case "Postes souhaités":
                    this.skillsPlaceholder = "Rechercher un postes souhaité";
                    break;
                case "Rechercher un postes souhaité":
                    this.skillsPlaceholder = "Postes souhaités";
                    break;
                case "Établissement et services":
                    this.servicesPlaceholder =
                        "Rechercher un établissement ou un service";
                    break;
                case "Rechercher un établissement ou un service":
                    this.servicesPlaceholder = "Établissement et services";
                    break;
            }
        },

        shownEstablishments() {
            if (this.establishments && this.establishments.length > 0) {
                if (this.$store.state.multi) {
                    if (this.$acl.check("administrator")) {
                        return this.establishments;
                    } else {
                        const userEstablishment = this.establishments
                            .slice()
                            .filter(e =>
                                this.user.establishments
                                    .map(et => et.id)
                                    .includes(e.id)
                            );
                        if (userEstablishment.length > 0) {
                            return userEstablishment;
                        }
                    }
                }
                return this.establishments.slice(0, 1);
            }
            return [];
        },
        employeesFilteredByServicesAndEstablishments() {
            let toShow = [];
            const establishments =
                this.selectedEstablishments &&
                this.selectedEstablishments.length > 0
                    ? this.selectedEstablishments
                    : this.shownEstablishments;
            establishments.forEach(e => {
                let employees = [];
                const establishmentEmployees = this.employees
                    ? this.employees.filter(
                          employee =>
                              employee.service !== undefined &&
                              employee.service.establishment_id === e.id
                      )
                    : [];
                if (this.selectedServices && this.selectedServices.length > 0) {
                    const services = this.selectedServices.map(s => s.id);
                    employees = establishmentEmployees.filter(employee =>
                        services.includes(employee.service_id)
                    );
                } else {
                    employees = establishmentEmployees;
                }
                toShow.push(...employees);
            });
            return this.$_.uniqBy(toShow, "id");
        },

        getAcquiredOccupationSkillsByJobs(occupationSkills) {
            let acquiredOccupationSkills = {};
            occupationSkills.forEach(currentOs => {
                currentOs.pivots.forEach(pivot => {
                    if (pivot.level > 2) {
                        if (!acquiredOccupationSkills[pivot.job_id]) {
                            acquiredOccupationSkills[pivot.job_id] = {
                                jobTitle: currentOs.jobs.find(
                                    j => j.id == pivot.job_id
                                )?.title,
                                occupation_skills: []
                            };
                        }
                        acquiredOccupationSkills[
                            pivot.job_id
                        ].occupation_skills.push({
                            title: currentOs.title,
                            level: pivot.level,
                            sector: currentOs.sector
                        });
                    }
                });
            });
            Object.values(acquiredOccupationSkills).forEach(os => {
                if (os.occupation_skills.length > 1) {
                    os.occupation_skills.sort((a, b) =>
                        a.title.localeCompare(b.title)
                    );
                }
            });

            return acquiredOccupationSkills;
        },

        getEmployeeRatings(employeeId) {
            if (!Object.values(this.ratings).length) return [];
            return this.ratings[employeeId];
        },
        employeeName(employee) {
            return employee.first_name + " " + employee.last_name;
        },
        filterEmployees(employeeId, personsArray) {
            let personsOptions = window._.cloneDeep(personsArray);
            let employeeList = personsOptions.filter(p => {
                p.pers = p.pers.filter(pers => pers.id == employeeId);
                return !!p.pers[0];
            });

            employeeList = [...new Set(employeeList)];

            if (employeeList.length) {
                let exists = this.personsValue.find(p => p.id == employeeId);
                if (!exists) {
                    this.personsValue.push(employeeList[0].pers[0]);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@sass/variables.scss";

label {
    color: #626262;
    font-family: "Montserrat", Helvetica, Arial, sans;
}

.select::v-deep .multiselect {
    .multiselect__placeholder {
        color: $dark;
        font-size: 16px;
        margin-left: 10px;
    }

    .multiselect__content-wrapper {
        border: none;
    }

    .multiselect__input {
        background: $primary-light;
    }

    .multiselect__tag {
        background: $primary;
        &-icon:hover {
            background: red;
        }

        &-icon:after {
            color: $white;
        }
    }

    .multiselect__single {
        background: $primary-light;
        margin-top: 4px;
    }

    .multiselect__tags {
        min-height: 42px;
        background: $primary-light;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }

    .multiselect__content {
        background: $primary-light;
    }

    .multiselect__option--highlight {
        background: $primary;
        outline: none;
        color: #fff;
        &::after {
            display: none;
            &:hover {
                background: red;
            }
        }
    }
}

.filters-container {
    width: 100%;
}

h3 {
    text-align: left;
}

.selects-row {
    display: flex;
    justify-content: space-between;
}

.tier {
    width: 32%;
    text-align: left;
}
</style>
