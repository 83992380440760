var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vs-sidebar-group ml-2",
      class: [
        { "vs-sidebar-group-open": _vm.isOpen },
        { "disabled-item pointer-events-none": _vm.isDisabled }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "group-header w-full",
          attrs: { title: _vm.title },
          on: { click: _vm.clickGroup }
        },
        [
          _c(
            "span",
            { staticClass: "flex items-center w-full" },
            [
              !_vm.requiredPlan || _vm.planAllow(_vm.requiredPlan)
                ? _c("feather-icon", { attrs: { icon: _vm.icon } })
                : !_vm.shouldHideOptions()
                ? _c("CrownIcon", {
                    staticStyle: { width: "20px", "margin-right": "14px" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "truncate mr-3 select-none" }, [
                _vm._v(_vm._s(_vm.name))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("feather-icon", {
            class: [{ rotate90: _vm.isOpen }, "feather-grp-header-arrow"],
            attrs: { icon: "ChevronRightIcon", "svg-classes": "w-4 h-4" }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "vs-sidebar--tooltip" }, [
            _vm._v(_vm._s(_vm.name))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          ref: "items",
          staticClass: "vs-sidebar-group-items",
          style: _vm.styleItems
        },
        _vm._l(this.$slots.default, function(item, index) {
          return _c("li", { key: index }, [
            item.tag && _vm.planAllow(item.data.attrs["required-plan"])
              ? _c(
                  "div",
                  {
                    class: _vm.isActiveItem(item)
                      ? "vs-sidebar--item vs-sidebar-item-active"
                      : "vs-sidebar--item",
                    staticStyle: { position: "relative" },
                    attrs: { title: item.data.attrs.title }
                  },
                  [
                    _c(
                      "a",
                      { attrs: { href: item.data.attrs.href } },
                      [
                        item.data.attrs.icon
                          ? _c("feather-icon", {
                              attrs: { icon: item.data.attrs.icon }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "truncate",
                          domProps: { innerHTML: _vm._s(item.data.attrs.label) }
                        })
                      ],
                      1
                    )
                  ]
                )
              : item.tag &&
                !_vm.planAllow(item.data.attrs["required-plan"]) &&
                !_vm.shouldHideOptions()
              ? _c(
                  "div",
                  {
                    class: _vm.isActiveItem(item)
                      ? "vs-sidebar--item vs-sidebar-item-active"
                      : "vs-sidebar--item",
                    staticStyle: { position: "relative" },
                    attrs: { title: item.data.attrs.title }
                  },
                  [
                    _c(
                      "a",
                      { attrs: { href: item.data.attrs.href } },
                      [
                        item.data.attrs.icon
                          ? _c("feather-icon", {
                              attrs: { icon: item.data.attrs.icon }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "vs-icon",
                          {
                            staticClass: "menu-bullet",
                            attrs: { bg: "#FF9B3A", round: "" }
                          },
                          [
                            _c("CrownIcon", {
                              staticStyle: {
                                width: "16px",
                                height: "16px",
                                padding: "3px"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "truncate",
                          domProps: { innerHTML: _vm._s(item.data.attrs.label) }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }