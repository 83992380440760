<script>
export default {
    name: "GlobalStyles"
}
</script>

<template>

</template>

<style>
/** Réécriture règle de vue-js-multiselect **/
.multiselect__option--highlight {
    background: rgb(var(--vs-primary));
    color: white;
}
.multiselect__tag {
    background: rgba(var(--vs-primary), 0.5);
    font-size: 14px;
}
.multiselect__tag-icon.multiselect__option--highlight {
    background: rgba(var(--vs-primary), 0.5);
    color: #fff;

}
.multiselect__tag-icon:hover,
.multiselect__tag-icon:after:hover {
    background: rgba(var(--vs-primary), 0.5);

}
.multiselect__option--selected {
    background: rgba(var(--vs-primary), 0.5);
    color: #636b6f;
}
.multiselect__option--selected.multiselect__option--highlight {
    background: rgba(var(--vs-primary), 0.5);
}
</style>