<template>
    <div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <h3 class="mb-8">
                    Informations :
                </h3>
            </div>
        </div>

        <vs-row class="mb-6">
            <vs-col class="sm:w-1/5 w-full">
                <img
                    v-if="resource.photoUrl"
                    :src="resource.photoUrl"
                    width="120px"
                />
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <vs-row class="mb-10">
                    <vs-col vs-xs="12" vs-sm="6" vs-lg="4">
                        <b>Prénom : </b> {{ resource.first_name }}
                    </vs-col>
                    <vs-col vs-xs="12" vs-sm="6" vs-lg="4">
                        <b>NOM : </b> {{ resource.last_name.toUpperCase() }}
                    </vs-col>
                </vs-row>

                <vs-row class="mb-10">
                    <vs-col vs-xs="12" vs-sm="6" vs-lg="4">
                        <b>Date de naissance : </b> {{ formatDate(resource.birthday, '/') }}
                    </vs-col>
                    <vs-col vs-xs="12" vs-sm="6" vs-lg="4">
                        <b>Téléphone : </b> {{ resource.phone }}
                    </vs-col>
                </vs-row>

                <vs-row class="mb-10">
                    <vs-col vs-xs="12" vs-sm="6" vs-lg="4">
                        <b>Email : </b> {{ resource.email }}
                    </vs-col>
                    <vs-col vs-xs="12" vs-sm="6" vs-lg="4">

                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>

        <!-- Etablissement -->
        <vs-row v-for="(establishment, i) in establishmentsWithServices" :key="i" class="mb-10">
            <vs-col class="sm:w-1/5 w-full">
                <b>Établissement :</b> {{ establishment.name }}
            </vs-col>
            <vs-col class="sm:w-4/5 w-full">
                <vs-row>
                    <vs-col vs-xs="12" vs-sm="6" vs-lg="4">
                        <b>Date d'entrée :</b> {{ formatDate(establishment.start_date) }}
                    </vs-col>
                    <vs-col vs-xs="12" vs-sm="6" vs-lg="8">
                        <b>Service(s) : </b>
                        <span v-for="(service, j) in establishment.services" :key="j">
                            <vs-chip
                                class="ml-4"
                                style="display: inline-flex; float: none;"
                                color="primary"
                            >
                                {{ service.title }}
                            </vs-chip>
                        </span>
                    </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>

        <!-- Action -->
        <vs-row class="mb-10">
            <vs-col vs-type="flex" vs-justify="end">
                <vs-button

                    v-if="canMulti('update', 'employees', resource.services.map(s => s.id)) && resource.active_establishments.length > 0"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    @click="showFormExitClickHandle"

                >
                    Déclarer une sortie / Archiver
                </vs-button>

                <vs-button
                    v-if="canMulti('update', 'employees', resource.services.map(s => s.id))"
                    class="ml-10"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-edit"
                    @click="showFormUpdateClickHandle"
                >
                    Éditer
                </vs-button>

                <DialogPrompt
                    :active.sync="showPopupUpdate"
                    title="Mise à jour d'une personne"
                    cancel-text="Annuler"
                    accept-text="Enregistrer"
                    :is-valid="!formError"
                    @cancel="initResource"
                    @close="initResource"
                    @accept="saveResource"
                >
                    <EmployeeForm
                        v-model="resource"
                        formType="update"
                        @error="formError = $event"
                    />
                </DialogPrompt>

                <DialogPrompt
                    :active.sync="showPopupExit"
                    title="Déclarer une sortie / Archiver"
                    cancel-text="Annuler"
                    accept-text="Enregistrer"
                    :is-valid="!formError"
                    @cancel="initResource"
                    @close="initResource"
                    @accept="saveResourceExit"
                >
                    <EmployeeFormExit
                        v-model="resourceExit"
                        :employee="resource"
                        formType="update"
                        :endReasons="endReasons"
                        @error="formError = $event"
                    />
                </DialogPrompt>

            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import { formatDate } from "@assets/utils/helpers/helpers.js";
import EmployeeForm from '@components/views/rh/gestion/employee/EmployeeForm';
//import EmployeeHelper from '@components/views/rh/gestion/employee/EmployeeHelper';
import objectToFormData from "object-to-formdata";
import EmployeeFormExit from "./EmployeeExitForm";

export default {
    name: "EmployeeInfos",
    components: {
        EmployeeForm,
        EmployeeFormExit
    },
   // mixins: [EmployeeHelper],
    props: {
        employee: {
            type: Object,
            required: true
        },
        endReasons: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            log: console.log,
            resource: {},
            resourceExit: {},
            showPopupUpdate: false,
            showPopupExit: false,
            formError: true,
            establishmentsWithServices: []
        }
    },
    created() {
        this.initResource();
        this.setEstablishmentsWithServices();
    },
    computed: {

    },
    methods: {
        showFormUpdateClickHandle() {
            this.showPopupUpdate = true;
        },
        showFormExitClickHandle() {
            this.resourceExit = {};
            this.showPopupExit = true;
        },
        initResource() {
            Object.assign(this.resource, this.employee);
            if (!this.resource.establishments && this.resource.active_establishments) {
                this.resource.establishments = this.resource.active_establishments;
            }

            if (!this.resource.photoUrl) {
                this.resource.photoUrl = '/assets/images/employee-default.jpg';
            }
        },
        setEstablishmentsWithServices() {
            let establishments = [];

            const employee = this.resource ? this.resource : this.employee

            // Préparation des établissements depuis les services
            employee.services.forEach(item => {

                if (!establishments.find(e => e.id == item.establishment_id)) {
                    establishments.push({
                        id: item.establishment.id,
                        name: item.establishment.name,
                        start_date: null,
                        services: []
                    });
                }
            })

            employee.establishments.forEach(item => {
                const est = establishments.find(e => e.id == item.id);

                if (est) {
                    est.start_date = item.pivot.start_date;
                    est.services = employee.services.filter(s => s.establishment_id == est.id)
                }
            })

            this.establishmentsWithServices = establishments;
        },
        saveResource() {

            let services = {};
            this.resource.services.forEach((item, i) => {
                services[i] = {
                    id: item.id,
                    etp: item.etp,
                    pivot_id: item.pivot?.id
                };
            });

            let establishments = {};
            this.resource.establishments.forEach((item, i) => {
                establishments[i] = {
                    id: item.id,
                    start_date: item.start_date,
                    pivot_id: item.pivot?.id
                }
            });

            let data               = window._.cloneDeep(this.resource);
                data._method        = 'PATCH';
                data.occupations   = data.occupations ? data.occupations.map(el => el.id) : null;
                data.jobs          = data.jobs ? data.jobs.map(el => el.id) : null;
                data.services      = services;
                data.establishments = establishments;
                data.occupation_skills_rating_only = data.occupation_skills_rating_only ? 1 : 0;
                data.occupation_skills_self_rating = data.occupation_skills_self_rating ? 1 : 0;

            delete data['current_occupations'];
            delete data['current_jobs'];
            delete data['active_services'];
            delete data['active_establishments'];

            // On ne touche pas au parcours depuis cet enregistrement.
            // Le parcours peut être modifié depuis le projet personnalisé avec cette API
            delete data['occupations'];
            delete data['jobs'];

            window.axios({
                method: 'post',
                url: `/api/gestion/employees/${this.employee.id}`,
                data: objectToFormData(data),
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then(response => {

                if (response.status === 200 && response.data != '') {
                    this.notifySuccess('Succès', 'Élement modifié avec succès !')
                    this.resource.first_name = response.data.first_name;
                    this.resource.last_name = response.data.last_name;
                    this.resource.birthday = response.data.birthday;
                    this.resource.email = response.data.email;
                    this.resource.phone = response.data.phone;

                    this.resource.services = response.data.active_services;
                    this.resource.establishments = response.data.active_establishments;

                    this.setEstablishmentsWithServices();
                } else {
                    this.notifyError('Erreur', `Une erreur est survenue lors de l'enregistrement`)
                }

            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
                this.showPopupUpdate = true

            })
        },

        saveResourceExit() {

            let alert = `La sortie de l'établissement est définitive. Le profil de la personne ne sera plus visible sur l'établissement. Confirmez-vous ?`;
            let redirect = false;

            if (this.resource.establishments.length == 1 ) {
                alert = `La sortie de l'établissement est définitive. Le profil de la personne devient uniquement disponible dans le menu archivé. <br>
                    Les données d'évaluation ne seront plus disponibles, pensez à exporter les évaluations au préalable.
                    L’accès au compte portail de la Personne reste actif avec le même mot de passe.
                    Confirmez-vous ? `
                    redirect = true;
            }

            this.$swal({
                title: `Attention`,
                html: alert,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Je confirme',
                cancelButtonText: 'Non'
            }).then(response => {
                if (response.value) {
                    window.axios({
                        method: 'post',
                        url: `/api/gestion/employees/${this.employee.id}/establishment/${this.resourceExit.establishment.id}/exit`,
                        data: {
                            end_date: this.resourceExit.end_date,
                            end_reason: this.resourceExit.end_reason.name,
                            comment: this.resourceExit.comment
                        }
                    })
                    .then(response => {

                        if (response.status === 200 && response.data != '') {
                            this.notifySuccess('Succès', `Sortie de l'établissement avec succès !`)

                            if (redirect) {
                                window.location.href = "/rh/gestion/personnes";
                            } else {
                                Object.assign(this.resource, response.data);

                                this.resource.services = response.data.active_services;
                                this.resource.establishments = response.data.active_establishments;

                                this.setEstablishmentsWithServices();
                            }

                        } else {
                            this.notifyError('Erreur', `Une erreur est survenue lors de l'enregistrement`)
                        }

                    })
                    .catch((err, data) => {
                        this.notifyErrorValidation(err)
                        this.showPopupExit = true

                    })
                }
            });
        }
    },

    // TODO - enregistrement des modifications EmployeeForm
}
</script>
