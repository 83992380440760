var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        [
          _c("vs-col", [
            _c("p", { staticClass: "h4 mt-5" }, [
              _vm._v(_vm._s(_vm.projectNames.plural) + " à réaliser")
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            [
              _c(
                "vs-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    sst: true,
                    search: "",
                    "no-data-text": _vm.loading
                      ? "Chargement de vos données..."
                      : "Aucune donnée à afficher",
                    data: _vm.itemsData
                  },
                  on: { search: _vm.eventSearchItems, sort: _vm.eventSortByCol }
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c(
                        "vs-th",
                        [
                          _c(
                            "Sort",
                            {
                              attrs: { name: "last_name" },
                              on: { sort: _vm.eventSortByCol }
                            },
                            [_vm._v("Nom")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vs-th",
                        [
                          _c(
                            "Sort",
                            {
                              attrs: { name: "first_name" },
                              on: { sort: _vm.eventSortByCol }
                            },
                            [_vm._v("Prénom")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("vs-th", [
                        _vm._v(
                          "\n                        Établissements\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-th", [
                        _vm._v(
                          "\n                        Services\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("vs-th", [_vm._v("Poste occupés")]),
                      _vm._v(" "),
                      _c("vs-th", [_vm._v("Métier occupés ou en préparation")]),
                      _vm._v(" "),
                      _c("vs-th")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.itemsData, function(item, i) {
                    return _c(
                      "vs-tr",
                      { key: i },
                      [
                        _c("vs-td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/rh/gestion/personnes/" + item.id + "/bilans"
                              }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.get(item, ":last_name")) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("vs-td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/rh/gestion/personnes/" + item.id + "/bilans"
                              }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.get(item, ":first_name")) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("vs-td", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm
                                  .arrayUnique(
                                    _vm.get(item, ":services").map(function(s) {
                                      return s.establishment.name
                                    })
                                  )
                                  .join(", ")
                              ) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("vs-td", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm
                                  .arrayUnique(
                                    _vm.get(item, ":services").map(function(s) {
                                      return s.title
                                    })
                                  )
                                  .join(", ")
                              ) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "vs-td",
                          _vm._l(_vm.get(item, ":current_jobs"), function(
                            el,
                            index
                          ) {
                            return _c("vs-chip", { key: index }, [
                              _c("b", [_vm._v(_vm._s(el.title))])
                            ])
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-td",
                          _vm._l(
                            _vm.get(item, ":current_occupations"),
                            function(el, index) {
                              return _c("vs-chip", { key: index }, [
                                _c("b", [_vm._v(_vm._s(el.title))])
                              ])
                            }
                          ),
                          1
                        ),
                        _vm._v(" "),
                        _c("vs-td", { staticClass: "action" }, [
                          _c(
                            "div",
                            { staticClass: "action-wrapper" },
                            [
                              _vm.canMulti(
                                "update",
                                "ratings",
                                item.services.map(function(s) {
                                  return s.id
                                })
                              )
                                ? _c("vs-button", {
                                    attrs: {
                                      id: "createRatingEmployee_" + i,
                                      href:
                                        "/rh/gestion/personnes/" +
                                        item.id +
                                        "/bilans/creer",
                                      "v-tooltip":
                                        "Créer le " + _vm.projectNames.singular,
                                      color: "primary",
                                      type: "border",
                                      "icon-pack": "feather",
                                      icon: "icon-plus-square"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "vs-row",
                [
                  _vm.items.total > 0
                    ? _c(
                        "vs-col",
                        { staticClass: "mt-4" },
                        [
                          _c("vs-pagination", {
                            attrs: {
                              total: Math.ceil(
                                _vm.items.total / _vm.apiParams.perpage
                              )
                            },
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }